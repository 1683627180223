import { Injectable } from '@angular/core';
import { ApiService } from '@next-solutions/next-solutions-base';
import { HttpParams } from '@angular/common/http';
import { ModuleConst } from 'src/app/modules/module.const';
import { collectParams } from 'src/app/utils/booking/api.util';
import { CountNotificationResponseModel, NotificationModel } from '../models/notification.model';
import { PagingResponseModel } from 'src/app/components/doctor/booking/booking-create/booking-create.service';
import { Router } from '@angular/router';

@Injectable()
export class NotificationService {
  constructor(
    private _router: Router,
    private _apiService: ApiService,
  ) {}

  getNotifications(params: HttpParams) {
    return this._apiService.get<PagingResponseModel<NotificationModel>>(ModuleConst.BOOKING + '/notification', params);
  }

  getNotification(id: number) {
    const params = collectParams({});
    return this._apiService.get<PagingResponseModel<NotificationModel>>(ModuleConst.BOOKING + `/notification/${id}`, params);
  }

  putNotification(notification: NotificationModel) {
    return this._apiService.put(ModuleConst.BOOKING + `/notification/${notification.id}`, notification);
  }

  getCountNotifications() {
    const params = collectParams({});
    return this._apiService.get<CountNotificationResponseModel>(ModuleConst.BOOKING + '/notification/summary', params);
  }

  goNotification(notification: NotificationModel) {
    return this._router.navigate(['booking', 'detail', notification.appointment_id]);
  }

  seenAllNotification() {
    return this._apiService.post(ModuleConst.BOOKING + '/notification/seen-all', {});
  }
}
