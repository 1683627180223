<ns-breadcrumb></ns-breadcrumb>
<form [formGroup]='addEditForm' class='form add-edit-sos-tracking'>
  <div class='content'>
    <fieldset>
      <legend></legend>
      <div class='fieldSetContent' fxLayout='row wrap'>
        <div fxLayout='row wrap' class='row-wrap-padding' fxLayoutAlign='start start' fxFlex='50%'>
          <ns-input [placeholder]="moduleName+'.table.header.customerName'" name='customerName'
                    formControlName='customerName'
                    fxLayout='column' fxFlex='50%' [disabled]='true' required=true
                    fxFlex.xs='100%' fxFlex.sm='50%' fxFlex.md='50%' fxFlex.lg='50%'>
          </ns-input>
          <ns-input [placeholder]="moduleName+'.table.header.customerPhone'" name='customerPhone'
                    formControlName='customerPhone'
                    fxLayout='column' fxFlex='50%' [disabled]='true' required=true
                    fxFlex.xs='100%' fxFlex.sm='50%' fxFlex.md='50%' fxFlex.lg='50%'>
          </ns-input>
          <ns-input [placeholder]="moduleName+'.table.header.latitude'" name='latitude' formControlName='latitude'
                    fxLayout='column' fxFlex='50%' [disabled]='true' required=true
                    fxFlex.xs='100%' fxFlex.sm='50%' fxFlex.md='50%' fxFlex.lg='50%'>
          </ns-input>
          <ns-input [placeholder]="moduleName+'.table.header.longitude'" name='longitude' formControlName='longitude'
                    fxLayout='column' fxFlex='50%' [disabled]='true' required=true
                    fxFlex.xs='100%' fxFlex.sm='50%' fxFlex.md='50%' fxFlex.lg='50%'>
          </ns-input>
          <ns-input [placeholder]="moduleName+'.table.header.customerLocation'" name='customerLocation'
                    formControlName='customerLocation'
                    fxLayout='column' fxFlex='100%' [disabled]='true' required=true
                    fxFlex.xs='100%' fxFlex.sm='100%' fxFlex.md='100%' fxFlex.lg='100%'>
          </ns-input>
          <ns-date-picker name="logDate"
                          formControlName="logDate"
                          [disabled]="true"
                          [placeholder]="moduleName+'.table.header.logDate'"
                          fxLayout="column" fxFlex="50%"
                          fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="50%" fxFlex.lg="50%"
          ></ns-date-picker>
          <ns-multi-select-autocomplete [multiple]="false"
                                        [options]="operatorValues"
                                        [placeholder]="moduleName+'.table.header.operator'"
                                        name="operatorId" formControlName="operatorId"
                                        [disabled]="isView || !sosTrackingUtil.hasCustomerRequestedPermission()"
                                        [required]="true"
                                        fxLayout="column" fxFlex="50%"
                                        fxFlex.xs="50%" fxFlex.sm="50%" fxFlex.md="50%" fxFlex.lg="50%">
          </ns-multi-select-autocomplete>
          <ns-multi-select-autocomplete [multiple]="false"
                                        [options]="garageValues"
                                        [placeholder]="moduleName+'.table.header.garage'"
                                        name="garageId" formControlName="garageId"
                                        [required]="true"
                                        [disabled]='isView || !sosTrackingUtil.hasCustomerRequestedPermission()'
                                        fxLayout="column" fxFlex="100%"
                                        fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%" fxFlex.lg="100%">
          </ns-multi-select-autocomplete>
          <ns-input [placeholder]="moduleName+'.table.header.note'" name='note'
                    [disabled]="isView || !sosTrackingUtil.hasCustomerRequestedPermission()"
                    [multiline]="true" formControlName='note'
                    fxLayout='column' fxFlex='100%' required=true
                    fxFlex.xs='100%' fxFlex.sm='100%' fxFlex.md='100%' fxFlex.lg='100%'>
          </ns-input>
          <ns-counter-input [placeholder]="moduleName+'.table.header.amount'"
                            name="amount" formControlName="amount"
                            *ngIf="this.sosTracking && this.sosTracking.status !== 'CUSTOMER_CLICK'"
                            [disabled]="isView || !this.sosTracking || this.sosTracking?.status !== 'CUSTOMER_REQUESTED'"
                            fxLayout="column" fxFlex="50%"
                            fxFlex.xs="50%" fxFlex.sm="50%" fxFlex.md="50%" fxFlex.lg="50%">
          </ns-counter-input>
          <ns-counter-input [placeholder]="moduleName+'.table.header.minutes'"
                            name="minutes" formControlName="minutes"
                            *ngIf="this.sosTracking && this.sosTracking.status === 'CUSTOMER_REQUESTED'"
                            [disabled]="isView"
                            fxLayout="column" fxFlex="50%"
                            fxFlex.xs="50%" fxFlex.sm="50%" fxFlex.md="50%" fxFlex.lg="50%">
          </ns-counter-input>
          <ns-input [placeholder]="moduleName+'.table.header.expectedTime'"
                    name='expectedTime' formControlName='expectedTime'
                    *ngIf="this.sosTracking && this.sosTracking.status !== 'CUSTOMER_REQUESTED' && this.sosTracking.status !== 'CUSTOMER_CLICK'"
                    [disabled]="isView"
                    fxLayout='column' fxFlex='50%' [disabled]='true' required=true
                    fxFlex.xs='50%' fxFlex.sm='50%' fxFlex.md='50%' fxFlex.lg='50%'>
          </ns-input>
        </div>

        <div id="map" fxFlex='50%'></div>
      </div>
    </fieldset>
  </div>

  <div fxLayout="row wrap" class="row-wrap-padding">
    <div class="btn-sec">
      <button mat-button type="submit" class="primary Update-btn" (click)="onSubmit('/customer-requested')"
              [disabled]="isView || (this.sosTracking?.status !== 'CUSTOMER_REQUESTED' && this.sosTracking?.status !== 'CUSTOMER_CLICK')">
        <i class="fa fa-save"></i> {{moduleName + '.btnAddEditCustomerInfo'|translate}}
      </button>
      <button mat-button type="submit" class="primary Update-btn" (click)="onSubmit('/requested-garage')"
              [disabled]="isView || this.sosTracking?.status !== 'CUSTOMER_REQUESTED'">
        <i class="fa fa-save"></i> {{moduleName + '.btnAddEditGarageInfo'|translate}}
      </button>
      <button mat-button type="submit" class="primary Update-btn" (click)="back()">
        <i class="fa fa-times"></i> {{'btnBack'|translate}}
      </button>
    </div>
  </div>

</form>
