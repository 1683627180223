<div fxFlex="100%" fxLayout="column">
  <ns-breadcrumb></ns-breadcrumb>
  <div class="inspection-form-wrapper" fxFlex="100%" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="15px" [formGroup]="searchForm">
    <div fxFlex="50%" fxFlex.lt-sm="100%" fxLayout="column">
      <div fxFlex="100%" fxLayout='row' class='inspections-filter'>
        <ns-input [placeholder]="'booking.inspection.driver_phone_placeholder' | translate"
                  [label]="'booking.inspection.license_plate_or_driver_phone' | translate"
                  (keyup)="onInputDriverPhone($event)"
                  [errorMessages]="inputErrorMsg"
                  formControlName="driverPhoneNumber"
                  [patternFilter]="'^[0-9]*$'"
                  [required]="true"
                  fxLayout='column'
                  fxFlex='100%'>
        </ns-input>
        <ns-input [placeholder]="'booking.inspection.license_plate_placeholder' | translate"
                  (keyup)="onInputLicensePlate($event)"
                  [errorMessages]="inputErrorMsg"
                  formControlName="licensePlate"
                  [required]="false"
                  fxLayout='column'
                  fxFlex='100%'>
        </ns-input>
      </div>

      <div *ngIf="driverInfo" fxLayout="column" class="margin-bottom-15 customer-info">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-10">
          <span class="label--title">{{'booking.inspection.customer_info.title' | translate}}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
          <span class="label">{{'booking.inspection.customer_info.name' | translate}}</span>
          <span class="value">{{ driverInfo?.name }}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
          <span class="label">{{'booking.inspection.customer_info.phone' | translate}}</span>
          <span class="value">{{ driverInfo?.phone }}</span>
        </div>
        <div *ngIf="driverInfo?.driverAddresses" fxLayout="row" fxLayoutAlign="space-between center"
             class="margin-bottom-5">
          <span class="label">{{'booking.inspection.customer_info.address' | translate}}</span>
          <span class="value">{{ driverInfo?.driverAddresses.length > 0 ? driverInfo?.driverAddresses[0].address : '' }}</span>
        </div>

        <div fxFlex='100%' class="padding-15"></div>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-10">
          <span class="label--title">{{'booking.inspection.car_info.title' | translate}}</span>
        </div>
        <div fxLayout="column" class="margin-bottom-10 car-info"
             (click)="selectCar(car)"
             [ngClass]="{'selected': selectedCar?.id === car.id}"
             *ngFor="let car of driverInfo?.driverCars">

          <div fxLayout="column">

            <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
              <span class="label">{{'booking.inspection.car_info.license_plate' | translate}}</span>
              <span class="value">{{ car.licensePlate || '' }}</span>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
              <span class="label">{{'booking.inspection.car_info.brand' | translate}}</span>
              <span class="value">{{ car.car?.brand.name || '' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="50%" fxFlex.lt-sm="100%" fxLayout='column'>
      <div fxFlex="100%" fxLayout='row' class='inspections-filter'>
        <ns-multi-select-autocomplete [multiple]="true"
                                      [options]="carStatusValues"
                                      placeholder="{{'booking.step3.form.car_status' | translate}}"
                                      label="Tìm kiếm theo tình trạng, dịch vụ"
                                      formControlName="statuses"
                                      fxLayout="column" fxFlex="50%">
        </ns-multi-select-autocomplete>
        <ns-multi-select-autocomplete [multiple]="true"
                                      [options]="servicePackageValues"
                                      placeholder="{{'booking.step3.form.sub_services' | translate}}"
                                      label=" "
                                      formControlName="services"
                                      fxLayout="column" fxFlex="50%">
        </ns-multi-select-autocomplete>
      </div>
      <div class="inspections-scroller" lazyScrollTrigger [options]="options">
        <mat-vertical-stepper *ngIf="inspections && inspections.length > 0" [linear]="false" class="fix-history-steps">
          <mat-step *ngFor="let inspection of inspections; trackBy: inspectionId">
            <ng-template matStepLabel>
              <div (click)="viewInspection(inspection)" class="history padding-10"
                   [ngClass]="{'current': inspection.driverId === currentOwnerCar?.id}"
                   fxLayout="row" fxFlex="100%" fxLayoutAlign="space-between">
                <div fxLayout="column">
                  <span class="history__time margin-bottom-10">{{ inspection.garageHandOverCarDate | date: 'dd/MM/yyyy' : 'UTC +0' }}</span>
                  <span *ngIf="getCarStatuses(inspection.carStatuses)" class="history__detail"><strong>{{ 'booking.car-info.inspection.status' | translate }}</strong> {{ getCarStatuses(inspection.carStatuses) }}</span>
                  <span class="history__detail"><strong>{{ 'booking.car-info.inspection.service' | translate }}</strong> {{( getCarServices(inspection.listService) || 'booking.garage.service_name.' + inspection?.serviceType || '') | translate}}</span>
                </div>
                <div fxLayout="column" fxLayoutAlign="flex-start">
                  <i class="fa fa-info-circle" aria-hidden="true"></i>
                </div>
              </div>
            </ng-template>
          </mat-step>
        </mat-vertical-stepper>
      </div>
    </div>
  </div>
</div>
