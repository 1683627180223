<div class="waiting-confirm" fxLayout="column" fxFlex="100%" fxLayoutAlign="center center">
  <div fxLayout="column" fxLayoutAlign="center center">
    <div class="image margin-bottom-20"></div>
    <span class="text margin-bottom-10">{{'booking.step5.waiting_confirm' | translate}}</span>
    <span class="counter margin-bottom-20">{{ formatCounter(counter) }}</span>
    <button mat-button class="default outline" (click)="openDialog()">
      {{'booking.step5.waiting_cancel' | translate}}
    </button>
  </div>
</div>
