import { Injectable } from '@angular/core';
import { ApiService, UtilsService } from '@next-solutions/next-solutions-base';
import { ModuleConst } from 'src/app/modules/module.const';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Subject, catchError, takeUntil } from 'rxjs';
import { ZEGO_CONVERSATION_TYPE } from 'src/app/models/zegocloud/zego.model';

enum ServiceType {
  ZEGO = 'ZEGO',
  STRINGEE = 'STRINGEE',
}

enum MfeIncomingEvent {
  ALIVE = 'ALIVE',
  FETCH_APP_N_USER = 'FETCH_APP_N_USER',
  USER_LOGGED_IN = 'USER_LOGGED_IN',
  CONVERSATION_CHANGED = 'CONVERSATION_CHANGED',
  MESSAGE_SENT = 'MESSAGE_SENT',
}

export enum MfeOutgoingEvent {
  RESPONSE_APP_N_USER = 'RESPONSE_APP_N_USER',
  JOIN_GROUP = 'JOIN_GROUP',
}

export interface ZegoConvInfo {
  driverId?: number;
  garageId?: string;
  expertId?: string;
  operatorId?: string;
  requestTicketId?: string;
  convType?: ZEGO_CONVERSATION_TYPE;
}

interface ZegoConfig {
  appId: number;
  appSign?: string;
  serverSecret?: string;
  callbackSecret?: string;
}

interface ZegoUser {
  phone: string;
  token: string;
  type: ServiceType;
  userId: number;
  systemRefUserType: string;
  displayName: string;
}

interface ZegoCredential {
  user: ZegoUser;
  config: ZegoConfig;
}

interface EventData<V, T> {
  data: V,
  type: T,
}

const ZEGO_CHAT_SDK_SCRIPT_ID = 'zego-chat-sdk';
const ZEGO_CHAT_SDK_SCRIPT_STYLE = 'zego-chat-sdk-style';

@Injectable()
export class ChatService {
  config?: ZegoConfig;
  user?: ZegoUser;
  isLoggedIn$ = new BehaviorSubject(false);
  conversationChanged$ = new BehaviorSubject(false);
  pendingMessages: EventData<unknown, unknown>[] = [];
  broadcastChannel = new BroadcastChannel('chat_channel');
  loaded = new Subject();
  constructor(
    private apiService: ApiService,
    private utilsService: UtilsService,
  ) {
    this.registerBroadcastChannel();
  }

  registerBroadcastChannel() {
    this.broadcastChannel.onmessage = (event) => {
      const { type } = event.data;
      switch (type) {
        case MfeIncomingEvent.ALIVE:
          console.log('@== MfeIncomingEvent.ALIVE: ', event.data);
          forkJoin([
            this.loadZegoConfig(),
            this.getZegoToken(),
          ]).subscribe(([config, res]) => {
            this.config = config;
            this.user = res.find(_ => _.type === ServiceType.ZEGO);
            console.log('User Info is {}', this.user);
            const eventData: EventData<ZegoCredential, MfeOutgoingEvent>
              = { data: { user: this.user, config: this.config } as ZegoCredential, type: MfeOutgoingEvent.RESPONSE_APP_N_USER };
            this.sendCommand(eventData, true);
          })
          break;

        case MfeIncomingEvent.USER_LOGGED_IN:
          console.log('@== MfeIncomingEvent.USER_LOGGED_IN: ', event.data);
          this.isLoggedIn$.next(true);
          this.pendingMessages.forEach(message => {
            this.sendCommand(message, true);
          });
          this.pendingMessages = [];
          break;

        case MfeIncomingEvent.CONVERSATION_CHANGED:
          console.log('@== MfeIncomingEvent.CONVERSATION_CHANGED: ', event.data);
          this.conversationChanged$.next(true);
          break;
        case MfeIncomingEvent.MESSAGE_SENT:
          console.log('@== MfeIncomingEvent.MESSAGE_SENT: ', event.data);
          const { conversationId } = event.data?.data || {};
          this.sendChatNotify(conversationId);
          this.conversationChanged$.next(true);
          break;
      }
    };
  }

  sendCommand<U, V>(payload: EventData<U, V>, forced = false) {
    if (forced || this.isLoggedIn$.value) {
      console.log('@== Sending command: ', payload);
      this.broadcastChannel.postMessage(payload);
      return;
    }
    console.log('@== Command enqueued: ', payload);
    this.pendingMessages.push(payload);
  }

  private loadZegoConfig() {
    return this.apiService.get<ZegoConfig>(ModuleConst.BOOKING + '/zegocloud-basic-configuration/1', new HttpParams())
  }

  private getZegoToken() {
    return this.apiService.get<ZegoUser[]>(ModuleConst.BOOKING + '/external-token/get-token', new HttpParams())
  }

  initChatSdk() {
    this.loadScript();
    this.loadStyle()
  }


  private loadScript() {
    const existingSdk = document.querySelector(`#${ZEGO_CHAT_SDK_SCRIPT_ID}`);
    if (existingSdk) {
      console.log('Zego sdk already loaded...');
      return;
    }
    console.log('Preparing to load Zego sdk ...');
    const node = document.createElement('script');
    node.src = '/assets/js/index.zego.js';
    node.type = 'module';
    node.async = true;
    node.id = ZEGO_CHAT_SDK_SCRIPT_ID;
    node.charset = 'utf-8';
    node.onload = () => {
      this.loaded.next(true);
      this.loaded.complete();
    }
    document.querySelector('body')?.appendChild(node);
  }

  private loadStyle() {
    const existingSdk = document.querySelector(`#${ZEGO_CHAT_SDK_SCRIPT_STYLE}`);
    if (existingSdk) {
      console.log('Zego sdk style already loaded...');
      return;
    }
    console.log('Preparing to Zego sdk style...');
    const node = document.createElement('link');
    node.href = '/assets/css/index.zego.css';
    node.type = 'text/css';
    node.rel = 'stylesheet';
    node.id = ZEGO_CHAT_SDK_SCRIPT_STYLE;
    document.querySelector('head')?.appendChild(node);
  }

  removeAndAddUserToChat(convInfo: ZegoConvInfo, removeUsers: string[], inviteUsers: string[]) {
    return this.apiService.post(`${ModuleConst.BOOKING}/conversation`, convInfo)
      .pipe(catchError(error => {
        this.utilsService.showError(error);
        return error;
      }))
      .subscribe(async (conversation: any) => {
        const { convId } = conversation;
        await window.zim.kickGroupMembers(removeUsers, convId);
        await window.zim.inviteUsersIntoGroup(inviteUsers, convId);
      });
  }

  sendChatNotify(conversationId: string) {
    this.apiService.post(ModuleConst.BOOKING + '/notification/push-call', { type: 'ROOM_CHAT', uuid: conversationId })
      .subscribe(res => console.log(res));
  }
}
