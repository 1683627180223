import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService, AuthoritiesService, BaseSearchLayout, FormStateService, UtilsService } from '@next-solutions/next-solutions-base';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseService } from '../booking-create/firebase.service';
import { DestroyService } from '../../../../_services/destroy.service';
import { NotificationService } from '../services/notification.service';
import { collectParams } from '../../../../utils/booking/api.util';
import { NotificationModel } from '../models/notification.model';
import { LazyScrollTriggerOptions } from '../directives/lazy-scroll-trigger.directive';
import { finalize } from 'rxjs/operators';

const PAGE_SIZE = 10;

@Component({
  selector: 'app-doctor-booking-notification',
  templateUrl: 'notification.component.html',
  styleUrls: ['notification.component.scss'],
  providers: [DestroyService],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent extends BaseSearchLayout implements OnInit {
  notifications: NotificationModel[] = [];
  options: LazyScrollTriggerOptions = { canTrigger: true, trigger: this.initData.bind(this), threshold: 100 };
  pageNumber = 1;
  constructor(
    protected formBuilder: FormBuilder,
    protected router: Router,
    protected apiService: ApiService,
    protected utilsService: UtilsService,
    protected uiStateService: FormStateService,
    protected translateService: TranslateService,
    protected injector: Injector,
    protected activatedRoute: ActivatedRoute,
    protected authoritiesService: AuthoritiesService,
    private _destroy: DestroyService,
    private _firebaseService: FirebaseService,
    private _notificationService: NotificationService,
  ) {
    super(router, apiService, utilsService, uiStateService, translateService, injector, activatedRoute, authoritiesService,
      formBuilder.group({}));
  }

  ngOnInit() {
    this.initData(() => {});
  }

  initData(callback: () => void) {
    const params = collectParams({ pageSize: PAGE_SIZE, pageNumber: this.pageNumber });
    this._notificationService.getNotifications(params)
      .pipe(finalize(callback))
      .subscribe((response) => {
      this.notifications.push(...(response.content || []));
      this.options.canTrigger = !response.last;
      if (this.options.canTrigger) {
        this.pageNumber += 1;
      }
      callback();
    })
  }

  readNotification(notification: NotificationModel) {
    notification.read = true;
    return this._notificationService.putNotification(notification).subscribe(() => {
      this.updateReadNotification(notification.id);
      this._notificationService.goNotification(notification);
    });
  }

  updateReadNotification(id: number) {
    const notification = this.notifications.find(_ => _.id === id);
    if (!notification) return;
    notification.isRead = true;
    return notification;
  }

  notifyId(index: number, item: NotificationModel) {
    return item.id;
  }
}
