import { Directive, ElementRef, HostListener, Input } from '@angular/core';

export interface LazyScrollTriggerOptions {
  threshold: number;
  trigger?: (arg: () => void) => void;
  canTrigger: boolean;
}

@Directive({
  selector: '[lazyScrollTrigger]'
})
export class LazyScrollTriggerDirective {
  @Input() options: LazyScrollTriggerOptions = { threshold: 100, canTrigger: true };
  isLoading = false;
  constructor(
    private el: ElementRef,
  ) {}

  @HostListener('scroll')
  onScroll() {
    if (
      this.el.nativeElement.scrollHeight -
      this.el.nativeElement.scrollTop -
      this.el.nativeElement.clientHeight
      <= this.options.threshold
    ) {
      if (this.options.trigger && this.options.canTrigger && !this.isLoading) {
        this.isLoading = true;
        this.options.trigger(() => this.isLoading = false);
      }
    }
  }
}
