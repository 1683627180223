<ns-breadcrumb></ns-breadcrumb>
<div class="searchFormGroup">
  <form [formGroup]="form" class="booking-configuration form">
    <div fxLayout="row wrap" class="row-wrap-padding">
      <ns-input
        [type]="'number'"
        [label]="'booking.table.header.config_time_garage_booking'"
        [errorMessages]="renderErrorMsg(minTimeWithNewRequestTickets, maxTimeWithNewRequestTickets)"
        [disabled]="isDisabled"
        name="timeGarageBooking"
        formControlName="timeWithNewRequestTickets"
        fxLayout="column"
        fxFlex="50%"
        fxFlex.xs="100%"
        fxFlex.sm="75%"
        fxFlex.md="50%"
        fxFlex.lg="50%"
      >
      </ns-input>
    </div>

    <div fxLayout="row wrap" class="row-wrap-padding">
      <ns-input
        [type]="'number'"
        [label]="'booking.table.header.config_time_handover_car'"
        [errorMessages]="renderErrorMsg(minTimeWithGarageHandedOverCar, maxTimeWithGarageHandedOverCar)"
        [disabled]="isDisabled"
        name="timeHandOverCar"
        formControlName="timeWithGarageHandedOverCar"
        fxLayout="column"
        fxFlex="50%"
        fxFlex.xs="100%"
        fxFlex.sm="75%"
        fxFlex.md="50%"
        fxFlex.lg="50%"
      >
      </ns-input>
    </div>

    <div fxLayout="row wrap" class="row-wrap-padding margin-horizontal-10">
      <button
        mat-button
        class="primary"
        [matTooltip]="'booking.common.update' | translate"
        [disabled]="form.invalid"
        (click)="updateBookingConfiguration()"
      >
        {{ (isDisabled ? 'booking.common.update' : 'booking.table.header.save') | translate }}
      </button>
    </div>
  </form>
</div>
