<div class="service-selector" fxLayout="column">
  <div class="padding-vertical-15 padding-horizontal-20 border-bottom" fxLayout fxLayoutAlign="space-between center">
    <span class="step-label">{{'booking.stepper.step3' | translate}}</span>
    <div fxLayout="row">
      <button mat-button class="primary outline" (click)="prevStep()">
        {{'booking.step3.back' | translate}}
      </button>
      <button mat-button class="primary" (click)="nextStep()" [disabled]="form.invalid">
        {{'booking.step3.next' | translate}}
      </button>
    </div>
  </div>
  <form [formGroup]="form" class="padding-20" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center flex-start">
    <div fxLayout="column" fxFlex="100%" fxFlex.gt-md="50%" ngClass.lt-lg="full-width" class="padding-horizontal-20">
      <ns-input [placeholder]="'booking.step3.form.placeholder' | translate"
                [label]="'booking.step3.form.km_run' | translate"
                formControlName="kmRun"
                fxLayout="column"
                type="text"
                [maxLength]="6"
                [patternFilter]="'[0-9]'"
                [errorMessages]="inputErrorMsg"
                fxFlex='100%'>
      </ns-input>
      <ns-multi-select-autocomplete [multiple]="true"
                                    [options]="servicePackageValues"
                                    placeholder="{{'booking.step3.form.sub_services' | translate}}"
                                    formControlName="serviceIds"
                                    fxLayout="column" fxFlex="100%">
      </ns-multi-select-autocomplete>
      <div fxLayout="column" fxFlex="100%" formArrayName="servicePackageFormArray">
        <mat-expansion-panel class="expansion" *ngFor="let control of servicePackageFormArray.controls; let i = index">
          <mat-expansion-panel-header class="expansion__name">
            <mat-panel-title>
              {{ getPackageName(i) }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="expansion__body">
            <ns-multi-select-autocomplete [multiple]="true"
                                          [options]="packagesOptions[i]"
                                          placeholder="{{'booking.step3.form.sub_services' | translate}}"
                                          [formControl]="control"
                                          fxLayout="column" fxFlex="100%">
            </ns-multi-select-autocomplete>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
    <div fxLayout="column" fxFlex="100%" fxFlex.gt-md="50%" ngClass.lt-lg="full-width" class="padding-horizontal-20">
      <app-doctor-booking-car-info></app-doctor-booking-car-info>
    </div>
  </form>
</div>
