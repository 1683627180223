import {Component, Injectable, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import {ApiService, AuthoritiesService, FormStateService} from '@next-solutions/next-solutions-base';
import {AuthenticationService} from "../../_services/authentication.service";
import {NavService} from "../../_services/nav.service";
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
@Injectable()
export class LoginComponent implements OnInit {
  form: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
    remember: new FormControl(''),
  });
  error: string | null | undefined;

  constructor(public dialog: MatDialog, private formBuilder: FormBuilder, private router: Router,
              private authenticationService: AuthenticationService, private apiService: ApiService,
              private cookieService: CookieService, private location: Location, private translateService: TranslateService,
              private authoritiesService: AuthoritiesService, protected formStateService: FormStateService,
              private navService: NavService) {
  }

  submit() {
    this.resetInfo();
    if (this.form.valid) {
      const body = new HttpParams()
        .set('username', this.form.controls.username.value)
        .set('password', this.form.controls.password.value)
        .set('grant_type', 'password');

      this.authenticationService.login(body.toString()).subscribe(data => {
        window.sessionStorage.setItem('token', JSON.stringify(data));
        window.localStorage.setItem('token', JSON.stringify(data));
        this.cookieService.set('remember', this.form.controls.remember.value.toString());
        this.hideLoginModal();
        // console.log(this.location.isCurrentPathEqualTo('/logout'));
        // if (this.location.isCurrentPathEqualTo('/logout')) {
        this.router.navigate(['/home']).then();

        // this.location.back();
        // }
      }, error => {
        this.translateService.get('login.error').subscribe(e => {
          this.error = e;
        });
      });
    }
  }

  resetInfo() {
    this.authoritiesService.me = null;
    this.navService.navItems = null;
    this.formStateService.setMapState(new Map<string, FormGroup>());
  }

  ngOnInit() {
    window.sessionStorage.removeItem('token');
  }

  showLoginModal() {
    return this.dialog.open(LoginComponent, {disableClose: true});
  }

  hideLoginModal() {
    this.dialog.closeAll();
  }

}
