import { Injectable } from '@angular/core';
import { ApiService, AuthoritiesService, UtilsService } from '@next-solutions/next-solutions-base';
import { TranslateService } from '@ngx-translate/core';
import { GarageDayOffModel } from '../../models/cms/garage-day-off.model';

@Injectable({ providedIn: 'root' })
export class GarageDayOffUtil {
  constructor(protected authoritiesService: AuthoritiesService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected translateService: TranslateService) {
  }

  hasAddEditPermission(gdo: GarageDayOffModel | null): boolean {
    if (gdo && gdo.id) {
      return this.authoritiesService.hasAuthority('patch/garageDayOff/{id}');
    } else {
      return this.authoritiesService.hasAuthority('post/garageDayOff');
    }
  }

  hasDeletePermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/garageDayOff/delete/{id}');
  }

}
