import { Injectable } from '@angular/core';
import { ApiService } from '@next-solutions/next-solutions-base';
import { BookingModel, CarStatusModel, SuperServiceModel, SurveyModel } from 'src/app/models/booking/booking.model';
import { ModuleConst } from 'src/app/modules/module.const';
import {
  PopupConfirmationComponent,
  PopupConfirmationResult,
} from 'src/app/components/doctor/booking/components/popup-confirmation/popup-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ChatService, ZegoConvInfo } from 'src/app/components/doctor/booking/chat/chat.service';
import { ZEGO_CONVERSATION_TYPE } from 'src/app/models/zegocloud/zego.model';

interface ExpertData {
  id: number;
  name: string;
  phone: string;
}

interface OperatorData {
  id: number;
  name: string;
  phone: string;
}

interface ServiceTicketServiceData {
  name: string;
  price: number;
  details: string;
  refId?: number;
  type: string;
  warrantyTime?: string;
  id?: number;
}

export interface BookingDetailData {
  appointmentId: number;
  expertProcessingStatus: string;
  survey: Partial<SurveyModel>[];
  garageId: number;
  expert: ExpertData;
  operator: OperatorData;
  carStatuses: CarStatusModel[];
  appointmentDate: string;
  listService: Partial<SuperServiceModel>[];
  serviceTicketServices: ServiceTicketServiceData[];
  requestUtilities: any[];
}

@Injectable()
export class BookingDetailService {
  oldBooking?: Partial<BookingModel>;
  data!: Partial<BookingDetailData>;
  isInvalid = new BehaviorSubject<boolean>(false);
  constructor(
    private _apiService: ApiService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _matDialog: MatDialog,
    private _router: Router,
    private _chatService: ChatService,
  ) {}

  buildRequest(): Partial<BookingDetailData> {
    return this.data;
  }

  updateData(data: Partial<BookingDetailData>) {
    this.data = {
      ...this.data,
      ...data,
    };
  }

  saveOldBooking(data: Partial<BookingModel>) {
    this.oldBooking = data;
  }

  saveBooking(data: Partial<BookingDetailData>) {
    this._matDialog.open(PopupConfirmationComponent, {
      disableClose: false,
      data: {
        title: 'booking.booking-detail.save.title',
        body: '',
        confirm: 'booking.booking-detail.save.confirm',
        cancel: 'booking.booking-detail.save.cancel',
      },
    }).afterClosed().subscribe((dialogResponse: PopupConfirmationResult) => {
      if (dialogResponse === PopupConfirmationResult.CONFIRM) {
        return this._apiService
          .put(ModuleConst.BOOKING + `/expert/appointment-schedule/${data.appointmentId}`, data)
          .subscribe((_) => {
            this._toastrService.success(this._translateService.instant('booking.booking-detail.save.success'));
            this._router.navigate(['other', 'booking']);
            const convInfo: ZegoConvInfo = {
              garageId: this.oldBooking?.garageRefId,
              driverId: this.oldBooking?.driverId,
              expertId: this.oldBooking?.expertRefId,
              requestTicketId: String(data.appointmentId),
              convType: ZEGO_CONVERSATION_TYPE.ROOM_CHAT,
            }
            console.log('@== convInfo', convInfo);
            if (this.oldBooking?.expertRefId !== data.expert?.id) {
              console.log('@== this.oldBooking?.expertRefId ', this.oldBooking?.expertRefId);
              this._chatService.removeAndAddUserToChat(convInfo, [String(this.oldBooking?.expertRefId)], [String(data.expert?.id)]);
            }
          });
      }
    });
  }

  resetData() {
    this.data = {} as Partial<BookingDetailData>;
  }
}
