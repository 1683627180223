import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import {
  ApiService,
  AuthoritiesService,
  BaseSearchLayout,
  DateUtilService,
  FormStateService,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import { ModuleConst } from 'src/app/modules/module.const';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-booking-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigurationComponent extends BaseSearchLayout implements OnInit {
  minTimeWithNewRequestTickets = 1; // minutes
  maxTimeWithNewRequestTickets = 120; // minutes

  minTimeWithGarageHandedOverCar = 1; // day
  maxTimeWithGarageHandedOverCar = 120; // days

  isDisabled = true;

  form = this.formBuilder.group({
    timeWithNewRequestTickets: [
      this.minTimeWithNewRequestTickets,
      [
        Validators.required,
        Validators.min(this.minTimeWithNewRequestTickets),
        Validators.max(this.maxTimeWithNewRequestTickets),
      ],
    ],
    timeWithGarageHandedOverCar: [
      this.minTimeWithGarageHandedOverCar,
      [
        Validators.required,
        Validators.min(this.minTimeWithGarageHandedOverCar),
        Validators.max(this.maxTimeWithGarageHandedOverCar),
      ],
    ],
  });

  constructor(
    protected formBuilder: FormBuilder,
    protected router: Router,
    protected apiService: ApiService,
    protected utilsService: UtilsService,
    protected uiStateService: FormStateService,
    protected translateService: TranslateService,
    protected injector: Injector,
    protected activatedRoute: ActivatedRoute,
    protected authoritiesService: AuthoritiesService,
    protected dateUtilService: DateUtilService,
    protected toastrService: ToastrService,
  ) {
    super(
      router,
      apiService,
      utilsService,
      uiStateService,
      translateService,
      injector,
      activatedRoute,
      authoritiesService,
      formBuilder.group({}),
    );
  }

  ngOnInit(): void {
    this.getBookingConfiguration();
  }

  ngOnDestroy(): void {
    this.form.reset();
  }

  renderErrorMsg(min: number, max: number) {
    return new Map<string, () => string>()
      .set('required', () => this.translateService.instant('common.error.required'))
      .set('min', () => this.translateService.instant('booking.common.validation.min', { min }))
      .set('max', () => this.translateService.instant('booking.common.validation.max', { max }));
  }

  getBookingConfiguration() {
    this.apiService
      .get<{ [key: string]: number }>(`${ModuleConst.BOOKING}/system-config`, new HttpParams())
      .subscribe(({ timeWithGarageHandedOverCar, timeWithNewRequestTickets }) => {
        this.form.setValue({
          timeWithGarageHandedOverCar,
          timeWithNewRequestTickets,
        });
      });
  }

  updateBookingConfiguration() {
    if (!this.isDisabled) {
      const params = {
        timeWithGarageHandedOverCar: Number(this.form.get('timeWithGarageHandedOverCar')?.value),
        timeWithNewRequestTickets: Number(this.form.get('timeWithNewRequestTickets')?.value),
      };

      const apiCall = this.apiService.put(`${ModuleConst.BOOKING}/system-config`, params);

      this.utilsService.execute(
        apiCall,
        () => {
          this.toastrService.success(this.translateService.instant('common.update.success'));
          this.isDisabled = !this.isDisabled;
        },
        'common.update.success',
        'booking.booking-detail.save.title',
      );
    } else this.isDisabled = !this.isDisabled;
  }
}
