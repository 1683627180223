import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, map, Observable } from 'rxjs';
import { DriverModel } from 'src/app/models/cms/driver.model';
import { DriverCarModel } from 'src/app/models/cms/driver-car.model';
import { ApiService, Page, UtilsService } from '@next-solutions/next-solutions-base';
import { GarageModel } from 'src/app/models/cms/garage.model';
import { ModuleConst } from 'src/app/modules/module.const';
import { collectParams } from 'src/app/utils/booking/api.util';
import { environment as env } from '../../../../../environments/environment';
import { AreaModel } from 'src/app/models/cms/area.model';
import { CountryModel } from 'src/app/models/config/country.model';
import { CarStatus } from '../steps-create/step3/step3.component';
import { MatDialog } from '@angular/material/dialog';
import {
  PopupConfirmationComponent,
  PopupConfirmationResult,
} from 'src/app/components/doctor/booking/components/popup-confirmation/popup-confirmation.component';
import { InspectionModel } from 'src/app/components/doctor/booking/models/inspection.model';
import { ServiceModel } from 'src/app/models/service/service.model';

export enum BookingCreateStep {
  // STEP_1,
  STEP_2,
  STEP_3,
  STEP_4,
  STEP_5,
}

export enum BookingServiceType {
  RESCUE = 'RESCUE',
  UPGRADE = 'UPGRADE',
  REPAIR = 'REPAIR',
  MAINTENANCE = 'MAINTENANCE',
  PAINT = 'PAINT',
  SPA = 'SPA',
}

export interface GenericModel {
  [key: string]: any,
}

export interface Step1DataModel {
  serviceType: BookingServiceType;
}

export interface Step2DataModel {
  driverInfo?: DriverModel;
  carInfo?: DriverCarModel;
  phone?: string;
}

export interface Step3DataModel {
  carStatuses: number[] | null;
  carDescription?: string | null;
  kmRun?: number | null;
  servicePackageIds?: number[];
  serviceIds?: number[];
  servicePackageFormArray?: number[][];
  carStatusAdditionalValues?: CarStatus[],
  rawCarStatuses?: CarStatus[],
  rawServices?: ServiceModel[],
}

export interface Utility {
  id: number;
  name: string;
  icon: string;
}

export interface Step4DataModel {
  bookingDate?: string;
  bookingHour?: string;
  timeFrame?: number | null;
  address?: string;
  distance?: number;
  utilities?: number[];
  rawUtilities?: Utility[];
  rawAddress?: any;
  placeId?: string,
}

export interface Step5DataModel {
  garage?: any;
}

export interface CountryAreaModel {
  areas: AreaModel[];
  countries: CountryModel[];
}

interface BookingServiceData {
  // step1?: Step1DataModel;
  step2?: Step2DataModel;
  step3?: Step3DataModel;
  step4?: Step4DataModel;
  step5?: Step5DataModel;
}

export interface BookingDoneData {
  name: string;
  address: string;
  time: Date;
}

export enum ServiceType {
  SERVICEPACKAGE = 'SERVICEPACKAGE',
  SERVICE = 'SERVICE',
}

export interface PagingResponseModel<T> extends Page {
  content: T[];
  pageable?: {
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean
    };
    pageNumber: number;
    pageSize: number;
    offset: number;
    unpaged: boolean;
    paged: boolean
  };
  last: boolean;
  totalElements: number;
  totalPages: number;
  first: boolean;
  sort?: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean
  };
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;
}

@Injectable()
export class BookingCreateService {
  step = new BehaviorSubject(BookingCreateStep.STEP_2);
  addresses = new BehaviorSubject<CountryAreaModel | undefined>(undefined);
  private _data = new BehaviorSubject<BookingServiceData>({
    // step1: undefined,
    step2: undefined,
    step3: undefined,
    step4: undefined,
    step5: undefined,
  });

  constructor(
    private _apiService: ApiService,
    private _matDialog: MatDialog,
    private _utilsService: UtilsService,
  ) {}

  get data() {
    return this._data as Observable<BookingServiceData>;
  }

  getData() {
    return this._data.value;
  }

  nextStep(step?: BookingCreateStep) {
    this.step.next(step ?? this.step.value + 1);
  }

  prevStep(confirm = true, step?: BookingCreateStep) {
    if (!confirm) return this.step.next(step ?? this.step.value - 1);
    this._matDialog.open(PopupConfirmationComponent, {
      disableClose: false,
      data: {},
    }).afterClosed().subscribe((response: PopupConfirmationResult) => {
      if (response === PopupConfirmationResult.CONFIRM) {
        this.step.next(step ?? this.step.value - 1);
      }
    });
  }

  // saveData({ step1, step2, step3, step4, step5 }: BookingServiceData) {
  //   this._data.next({
  //     // step1: step1 ?? this._data.value.step1,
  //     step2: step2 ?? this._data.value.step2,
  //     step3: step3 ?? this._data.value.step3,
  //     step4: step4 ?? this._data.value.step4,
  //     step5: step5 ?? this._data.value.step5,
  //   });
  // }
  saveData({ step2, step3, step4, step5 }: BookingServiceData) {
    this._data.next({
      // step1: step1 ?? this._data.value.step1,
      step2: step2 ?? this._data.value.step2,
      step3: step3 ?? this._data.value.step3,
      step4: step4 ?? this._data.value.step4,
      step5: step5 ?? this._data.value.step5,
    });
  }

  clearData() {
    this.step.next(BookingCreateStep.STEP_2);
    this._data.next({
      // step1: undefined,
      step2: undefined,
      step3: undefined,
      step4: undefined,
      step5: undefined,
    });
  }

  // buildBookingRequest() {
  //   const { step1, step2, step3, step4, step5 } = this.getData();
  //   const carStatuses = step3?.carStatuses ? step3?.carStatuses.map(id => {
  //     return step3?.rawCarStatuses?.find(_ => _.id === id);
  //   }) : [];
  //   const carAdditionalStatuses = step3?.carStatusAdditionalValues?.map(_ => ({
  //     value: _.value
  //   })) ?? [];
  //   const garageUtilities = step4?.utilities?.map(id => {
  //     return step4?.rawUtilities?.find(_ => _.id === id);
  //   }) ?? [];
  //   const servicePackageIds = step3?.servicePackageIds || [];
  //   const services = [...(step3?.serviceIds || [])]
  //     .map(id => {
  //       const service = step3?.rawServices?.find(rawS => rawS.id === id);
  //       return { id, name: service?.name, serviceType: ServiceType.SERVICE }
  //     });
  //   return {
  //     driverId: Number(step2?.driverInfo?.id),
  //     carId: Number(step2?.carInfo?.carId),
  //     driverCarId: Number(step2?.carInfo?.id),
  //     carKm: step3?.kmRun,
  //     description: step3?.carDescription,
  //     carStatuses: [
  //       ...carStatuses,
  //       ...carAdditionalStatuses,
  //     ],
  //     services,
  //     appointmentDate: step4?.bookingDate,
  //     appointmentTime: step4?.bookingHour,
  //     garageId: step5?.garage?.id + '',
  //     type: step1?.serviceType,
  //     addressMark: {
  //       placeId: step4?.placeId,
  //       address: step4?.address,
  //     },
  //     utilities: garageUtilities,
  //     diagnosticResults: '',
  //     distanceFilter: step4?.distance
  //   }
  // }

  buildBookingRequest() {
    const { step2, step3, step4, step5 } = this.getData();
    const carStatuses = step3?.carStatuses ? step3?.carStatuses.map(id => {
      return step3?.rawCarStatuses?.find(_ => _.id === id);
    }) : [];
    const carAdditionalStatuses = step3?.carStatusAdditionalValues?.map(_ => ({
      value: _.value
    })) ?? [];
    const garageUtilities = step4?.utilities?.map(id => {
      return step4?.rawUtilities?.find(_ => _.id === id);
    }) ?? [];
    const servicePackageIds = step3?.servicePackageIds || [];
    const services = [...(step3?.serviceIds || [])]
      .map(id => {
        const service = step3?.rawServices?.find(rawS => rawS.id === id);
        return { id, name: service?.name, serviceType: ServiceType.SERVICE }
      });
    return {
      driverId: Number(step2?.driverInfo?.id),
      carId: Number(step2?.carInfo?.carId),
      driverCarId: Number(step2?.carInfo?.id),
      carKm: step3?.kmRun,
      description: step3?.carDescription,
      carStatuses: [
        ...carStatuses,
        ...carAdditionalStatuses,
      ],
      services,
      appointmentDate: step4?.bookingDate,
      timeFrame: step4?.timeFrame,
      garageId: step5?.garage?.id + '',
      addressMark: {
        placeId: step4?.placeId,
        address: step4?.address,
      },
      utilities: garageUtilities,
      diagnosticResults: '',
      distanceFilter: step4?.distance
    }
  } 

  createBooking() {
    const data = new FormData();
    const params = this.buildBookingRequest();
    data.append('source', this._utilsService.toBlobJon(params));
    return this._apiService.post(ModuleConst.BOOKING + '/v2/appointment-schedule', data);
  }

  assignGarage() {
    const data = new FormData();
    const params = this.buildBookingRequest();
    data.append('source', this._utilsService.toBlobJon(params));
    return this._apiService.post(ModuleConst.BOOKING + '/expert-appointment-schedule', data);
  }

  /**
   * Booking APIs
   */

  fetchGarage(id: number) {
    const params = collectParams({});
    return this._apiService.get<GarageModel>(ModuleConst.CMS + `/garage/${id}`, params);
  }

  fetchAddresses() {
    if (this.addresses.value) {
      return this.addresses as Observable<CountryAreaModel>;
    }
    const params = collectParams({ pageSize: env.INTEGER_MAX_VALUE, pageNumber: '1', status: 'ACCEPTED' });
    return forkJoin([
      this._apiService.get<GenericModel>(ModuleConst.CMS + '/area', params),
      this._apiService.get<GenericModel>(ModuleConst.CMS + '/country', params),
    ]).pipe(
      map(([respArea, respCountry]) => {
        const data = {
          areas: respArea?.content || [],
          countries: respCountry?.content || []
        };
        this.addresses.next(data);
        return data;
      }),
    )
  }

  fetchInspectionHistory(carNumber: string, pageNumber = 1, pageSize = 10, statuses = [], services = [])
    : Observable<PagingResponseModel<InspectionModel>> {
    const params = { pageNumber, pageSize, carNumber, statuses, services };
    return this._apiService.post(ModuleConst.BOOKING + '/inspection-result-profile', params) as
      Observable<PagingResponseModel<InspectionModel>>;
  }
}
