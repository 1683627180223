import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {
  ApiService,
  AuthoritiesService,
  BaseAddEditLayout,
  DateUtilService,
  Page,
  SelectModel,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';
import { EnumUtil } from 'src/app/utils/enum.util';
import {ActionTypeEnum} from 'src/app/enums/action.type.enum';
import {SuperStatusEnum} from 'src/app/enums/super.status.enum';
import {CarStatusModel} from 'src/app/models/booking/booking.model';
import {ModuleConst} from 'src/app/modules/module.const';
import {BookingUtil} from 'src/app/utils/booking/booking.util';

@Component({
  selector: 'app-booking-add-edit-car-status',
  templateUrl: './add-edit-car-status.component.html',
  styleUrls: ['./add-edit-car-status.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddEditCarStatusComponent extends BaseAddEditLayout implements OnInit {

  moduleName = 'booking';
  statusValues: SelectModel[] = [];
  carStatus: CarStatusModel | null = null;
  isView = false;
  isDetail = false;
  isAccept = false;

  constructor(protected activatedRoute: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected location: Location,
              protected translateService: TranslateService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected bookingUtil: BookingUtil,
              protected authoritiesService: AuthoritiesService,
              private dateUtilService: DateUtilService,
              private router: Router) {
    super(activatedRoute, location, translateService, utilsService, authoritiesService);
    this.addEditForm = this.formBuilder.group({
      id: [''],
      value: [''],
      status: ['_'],
    });

    this.isView = this.activatedRoute.routeConfig?.data?.actionType === ActionTypeEnum._VIEW;
  }

  async ngOnInit() {
    super.ngOnInit();
    EnumUtil.enum2SelectModel(SuperStatusEnum, this.statusValues, 'EDIT');
    if (this.isEdit) {
      this.isDetail = this.router.url.includes('/detail/');
      this.carStatus =
        await this.apiService.get(ModuleConst.BOOKING + '/car-status/' + this.id, new HttpParams()).toPromise() as CarStatusModel;
      this.addEditForm.setValue(UtilsService.reduceEntityAttributeForFormControl(this.addEditForm, this.carStatus));
    } else {
      this.addEditForm.get('status')?.setValue(EnumUtil.getKeysByValues(SuperStatusEnum, [SuperStatusEnum._DRAFT]));
    }
  }

  onSubmit() {
    const carStatus: CarStatusModel = this.addEditForm.value;
    console.log(carStatus);
    const patchCarStatus: CarStatusCreateRequest = {value: carStatus.value};
    // Call API
    const apiCall = this.isEdit ?
      this.apiService.patch(ModuleConst.BOOKING + '/car-status/' + carStatus.id, patchCarStatus) :
      this.apiService.post(ModuleConst.BOOKING + '/car-status', carStatus);
    const action = this.isEdit ? 'edit' : 'add';
    this.utilsService.execute(apiCall, this.onSuccessFunc,
      'common.' + action + '.success',
      'common.confirm.' + action, ['car_status.']);
  }

  onAccept() {
    console.log('accept');
    this.bookingUtil.onAccept(this.carStatus, this.onSuccessFunc);
  }

  onReject() {
    console.log('reject');
    this.bookingUtil.onReject(this.carStatus, this.onSuccessFunc);
  }
}
export interface CarStatusCreateRequest {
  value: string | number | undefined;
}
