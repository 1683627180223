import { GarageEmployeeModel } from './../../../../models/cms/garage-employee.model';
import { ExpertModel } from './../../../../models/cms/expert.model';
import { HttpParams } from '@angular/common/http';
import {Component, ElementRef, Inject, Injector, OnInit, Optional, ViewChild, ViewEncapsulation} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import {
  AlignEnum,
  ApiService,
  AuthoritiesService,
  BaseSearchLayout,
  ButtonFields,
  ColumnFields,
  DateUtilService,
  FormStateService,
  IconTypeEnum,
  Page,
  SelectModel,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import { TranslateService } from '@ngx-translate/core';
import {takeUntil, zip} from 'rxjs';
import { SuperStatusEnum } from 'src/app/enums/super.status.enum';
import {
  BookingModel,
  BOOKING_BEHAVIORS,
  REQUEST_TICKET_STATUS,
  SERIVCE_TYPE,
  SERVICE_TICKET_STATUS,
  SURVEY_STATUS,
} from 'src/app/models/booking/booking.model';
import { GarageModel } from 'src/app/models/cms/garage.model';
import { ModuleConst } from 'src/app/modules/module.const';
import { BookingUtil } from 'src/app/utils/booking/booking.util';
import { EnumUtil } from 'src/app/utils/enum.util';
import { environment } from 'src/environments/environment';
import { BrandModel } from 'src/app/models/cms/brand.model';
import { ServiceModel } from 'src/app/models/service/service.model';
import { DatePipe } from '@angular/common';
import { ZEGO_CONVERSATION_TYPE } from 'src/app/models/zegocloud/zego.model';
import {DestroyService} from 'src/app/_services/destroy.service';

@Component({
  selector: 'app-doctor-booking-list-pending',
  templateUrl: './booking-list-pending.component.html',
  providers: [DestroyService],
  encapsulation: ViewEncapsulation.None,
})
export class BookingListPendingComponent extends BaseSearchLayout implements OnInit {
  moduleName = 'booking';
  columns: ColumnFields[] = [];
  buttons: ButtonFields[] = [];

  requestTicketStatusValues: SelectModel[] = [];
  serviceTicketStatusValues: SelectModel[] = [];
  surveyStatusValues: SelectModel[] = [];
  serviceValues: SelectModel[] = [];
  supporterValues: SelectModel[] = [];
  garageValues: SelectModel[] = [];
  expertValues: SelectModel[] = [];
  brandValues: SelectModel[] = [];

  isAdvancedSearch = false;
  isPopup: boolean;

  numberOfPendingRequest = 0;

  get expandHeaderButton() {
    return environment.EXPAND_HEADER_BUTTON;
  }

  constructor(
    protected formBuilder: FormBuilder,
    protected router: Router,
    protected apiService: ApiService,
    protected utilsService: UtilsService,
    protected uiStateService: FormStateService,
    protected translateService: TranslateService,
    protected injector: Injector,
    protected activatedRoute: ActivatedRoute,
    protected authoritiesService: AuthoritiesService,
    protected dateUtilService: DateUtilService,
    private datePipe: DatePipe,
    public bookingUtil: BookingUtil,
    public matDialog: MatDialog,
    private _destroy: DestroyService,
    private _authoritiesService: AuthoritiesService,
    @Optional() public matDialogRef: MatDialogRef<BookingModel>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: boolean,
  ) {
    super(
      router,
      apiService,
      utilsService,
      uiStateService,
      translateService,
      injector,
      activatedRoute,
      authoritiesService,
      formBuilder.group({
        driver: [''],
        requestTicketStatus: [''],
        serviceIds: [''],
        timeBooking: [''],
        startDate: [''],
        endDate: [''],
        supporterIds: [''],
        garageIds: [''],
        expertIds: [''],
        serviceTicketStatus: [''],
        carLicensePlate: [''],
        carBrandIds: [''],
        ticketId: [''],
        surveyStatus: [''],
      }),
    );

    this.isPopup = data;
    this.isAdvancedSearch = data;

    this.initColumns();
    this.initButtons();
    this.initDataFilters();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onSubmit();
  }

  ngOnDestroy(): void {
    this.onResetForm();
  }

  initColumns() {
    this.columns.push(
      {
        columnDef: 'stt',
        header: 'stt',
        title: (e: BookingModel) => `${UtilsService.calcPosition(e, this.results, this.paging)}`,
        cell: (e: BookingModel) => `${UtilsService.calcPosition(e, this.results, this.paging)}`,
        className: 'mat-column-stt',
        align: AlignEnum.CENTER,
      },
      {
        columnDef: 'requestTicket',
        header: 'request_ticket',
        title: (e: BookingModel) => `${e.requestTicketId ?? ''}`,
        cell: (e: BookingModel) => `${e.requestTicketId ?? ''}`,
        className: 'mat-column-request-ticket',
      },
      {
        columnDef: 'requestTicketStatus',
        header: 'request_ticket_status',
        title: (e: BookingModel) =>
          e.requestTicketStatus
            ? `${this.utilsService.getEnumValueTranslated(REQUEST_TICKET_STATUS, e.requestTicketStatus)}`
            : '',
        cell: (e: BookingModel) =>
          e.requestTicketStatus
            ? `${this.utilsService.getEnumValueTranslated(REQUEST_TICKET_STATUS, e.requestTicketStatus)}`
            : '',
        className: 'mat-column-request-ticket-status',
      },
      {
        columnDef: 'serviceTicket',
        header: 'service_ticket',
        title: (e: BookingModel) => `${e.serviceTicketId ?? ''}`,
        cell: (e: BookingModel) => `${e.serviceTicketId ?? ''}`,
        className: 'mat-column-service-ticket',
      },
      {
        columnDef: 'serviceTicketStatus',
        header: 'service_ticket_status',
        title: (e: BookingModel) =>
          e.serviceTicketStatus
            ? `${this.utilsService.getEnumValueTranslated(SERVICE_TICKET_STATUS, e.serviceTicketStatus)}`
            : '',
        cell: (e: BookingModel) =>
          e.serviceTicketStatus
            ? `${this.utilsService.getEnumValueTranslated(SERVICE_TICKET_STATUS, e.serviceTicketStatus)}`
            : '',
        className: 'mat-column-service-ticket-status',
      },
      {
        columnDef: 'driverName',
        header: 'customer',
        title: (e: BookingModel) => `${e.driverName ?? ''}`,
        cell: (e: BookingModel) => `${e.driverName ?? ''}`,
        className: 'mat-column-driver-name',
      },
      {
        columnDef: 'driverPhone',
        header: 'phone_number',
        title: (e: BookingModel) => `${e.driverPhone ?? ''}`,
        cell: (e: BookingModel) => `${e.driverPhone ?? ''}`,
        className: 'mat-column-driver-phone',
      },
      {
        columnDef: 'timeBooking',
        header: 'time_booking',
        title: (e: BookingModel) =>
          `${e.appointmentDate ? this.datePipe.transform(e.appointmentDate, 'HH:mm, dd/MM/yyyy', 'UTC') : ''}`,
        cell: (e: BookingModel) =>
          `${e.appointmentDate ? this.datePipe.transform(e.appointmentDate, 'HH:mm, dd/MM/yyyy', 'UTC') : ''}`,
        className: 'mat-column-time-booking',
      },
      {
        columnDef: 'timeCreate',
        header: 'time_create',
        title: (e: BookingModel) =>
          `${e.createDate ? this.datePipe.transform(e.createDate, 'HH:mm, dd/MM/yyyy', 'UTC') : ''}`,
        cell: (e: BookingModel) =>
          `${e.createDate ? this.datePipe.transform(e.createDate, 'HH:mm, dd/MM/yyyy', 'UTC') : ''}`,
        className: 'mat-column-time-create',
        isExpandOptionColumn: () => true,
      },
      {
        columnDef: 'creator',
        header: 'creator',
        title: (e: BookingModel) => `${e.createName ?? ''}`,
        cell: (e: BookingModel) => `${e.createName ?? ''}`,
        className: 'mat-column-creator',
        isExpandOptionColumn: () => true,
      },
      {
        columnDef: 'carLicensePlate',
        header: 'car_license_plate',
        title: (e: BookingModel) => `${e.carNumber ?? ''}`,
        cell: (e: BookingModel) => `${e.carNumber ?? ''}`,
        className: 'mat-column-car-license-plate',
        isExpandOptionColumn: () => true,
      },
      {
        columnDef: 'carBrand',
        header: 'car_brand',
        title: (e: BookingModel) => `${e.carBrand ?? ''}`,
        cell: (e: BookingModel) => `${e.carBrand ?? ''}`,
        className: 'mat-column-car-band',
        isExpandOptionColumn: () => true,
      },
      {
        columnDef: 'garage',
        header: 'garage',
        title: (e: BookingModel) => `${e.garageName ?? ''}`,
        cell: (e: BookingModel) => `${e.garageName ?? ''}`,
        className: 'mat-column-garage',
        isExpandOptionColumn: () => true,
      },
      {
        columnDef: 'service',
        header: 'service',
        title: (e: BookingModel) => `${e.listService ?? ''}`,
        cell: (e: BookingModel) =>
          `${e.listService?.length ? e.listService.map((service) => service.name).join(', ') : ''}`,
        className: 'mat-column-service',
        isExpandOptionColumn: () => true,
      },
      {
        columnDef: 'expert',
        header: 'expert',
        title: (e: BookingModel) => `${e.expertName ?? ''}`,
        cell: (e: BookingModel) => `${e.expertName ?? ''}`,
        className: 'mat-column-expert',
        isExpandOptionColumn: () => true,
      },
      {
        columnDef: 'operator',
        header: 'operator',
        title: (e: BookingModel) => `${e.operatorName ?? ''}`,
        cell: (e: BookingModel) => `${e.operatorName ?? ''}`,
        className: 'mat-column-operator',
        isExpandOptionColumn: () => true,
      },
      {
        columnDef: 'surveyStatus',
        header: 'survey_status',
        title: (e: BookingModel) =>
          e.surveyStatus ? `${this.utilsService.getEnumValueTranslated(SURVEY_STATUS, e.surveyStatus)}` : '',
        cell: (e: BookingModel) =>
          e.surveyStatus ? `${this.utilsService.getEnumValueTranslated(SURVEY_STATUS, e.surveyStatus)}` : '',
        className: 'mat-column-survey-status',
        isExpandOptionColumn: () => true,
      },
    );
  }

  initButtons() {
    this.buttons.push(
      {
        columnDef: 'edit',
        color: 'warn',
        icon: 'fa fa-pen',
        iconType: IconTypeEnum.FONT_AWESOME,
        click: 'addOrEdit',
        className: 'primary',
        title: 'common.title.edit',
        display: (e: BookingModel) => true,
        disabled: (e: BookingModel) =>
          !this.bookingUtil.hasPermission(e) || !this.bookingUtil.checkBookingBehavior(BOOKING_BEHAVIORS.EDIT, e),
      },
      {
        columnDef: 'detail',
        color: 'warn',
        icon: 'fa fa-eye',
        iconType: IconTypeEnum.FONT_AWESOME,
        click: 'viewDetail',
        className: 'primary',
        title: 'common.title.detail',
        display: (e: BookingModel) => true,
      },
      {
        columnDef: 'audio_call',
        color: 'warn',
        icon: 'fa fa-phone-alt',
        iconType: IconTypeEnum.FONT_AWESOME,
        click: 'audioCall',
        className: 'primary',
        title: 'booking.common.audio_call',
        disabled: (e: BookingModel) => !this.bookingUtil.hasPermission(e),
        display: () => true,
      },
      {
        columnDef: 'video_call',
        color: 'warn',
        icon: 'fa fa-solid fa-video',
        iconType: IconTypeEnum.FONT_AWESOME,
        click: 'videoCall',
        className: 'primary',
        title: 'booking.common.video_call',
        disabled: (e: BookingModel) => !this.bookingUtil.hasPermission(e),
        display: () => true,
      },
      {
        columnDef: 'chat',
        color: 'warn',
        icon: 'fa fa-solid fa-comment',
        iconType: IconTypeEnum.FONT_AWESOME,
        click: 'chat',
        className: 'primary',
        title: 'booking.common.chat',
        disabled: (e: BookingModel) =>
          !this.bookingUtil.hasPermission(e) ||
          this.hideChatByTicketStatus(e.requestTicketStatus) ||
          this.bookingUtil.isOP(),
        display: () => true,
      },
    );
  }

  hideChatByTicketStatus(status: string) {
    return EnumUtil.getKeysByValues(REQUEST_TICKET_STATUS, [
      REQUEST_TICKET_STATUS._NEW,
      REQUEST_TICKET_STATUS._PENDING,
      REQUEST_TICKET_STATUS._GARAGE_CONFIRMED,
      REQUEST_TICKET_STATUS._COMPLETED,
      REQUEST_TICKET_STATUS._CANCELED,
    ])
      .replace(/\s/g, '')
      .split(',')
      .includes(status);
  }

  initDataFilters() {
    const params = new HttpParams()
      .set('status', EnumUtil.getKeysByValues(SuperStatusEnum, [SuperStatusEnum._ACCEPTED]))
      .set('pageNumber', '1')
      .set('pageSize', environment.INTEGER_MAX_VALUE);

    zip(
      this.apiService.get<Page>(ModuleConst.CMS + '/garage', params),
      this.apiService.get<Page>(ModuleConst.CMS + '/expert', params),
      this.apiService.get<Page>(ModuleConst.CMS + '/operator', params),
      this.apiService.get<Page>(ModuleConst.CMS + '/brand', params),
      this.apiService.get<Page>(ModuleConst.CMS + '/service', params),
    ).subscribe(([pageGarage, pageExpert, pageSupporter, pageBrand, pageService]) => {
      if (pageGarage?.content?.length >= 0) {
        this.garageValues = pageGarage.content.map((garage: GarageModel) => new SelectModel(garage.id, garage.name));
      }
      if (pageExpert?.content?.length >= 0) {
        this.expertValues = pageExpert.content.map((expert: ExpertModel) => new SelectModel(expert.id, expert.name));
      }
      if (pageSupporter?.content?.length >= 0) {
        this.supporterValues = pageSupporter.content.map(
          (supporter: GarageEmployeeModel) => new SelectModel(supporter.id, supporter.name),
        );
      }
      if (pageBrand?.content?.length >= 0) {
        this.brandValues = pageBrand.content.map((brand: BrandModel) => new SelectModel(brand.id, brand.name));
      }
      if (pageService.content?.length >= 0) {
        this.serviceValues = pageService.content.map(
          (service: ServiceModel) => new SelectModel(service.id, service.name),
        );
      }
    });

    EnumUtil.enum2SelectModel(REQUEST_TICKET_STATUS, this.requestTicketStatusValues, 'SEARCH', true, this.utilsService);
    EnumUtil.enum2SelectModel(SERVICE_TICKET_STATUS, this.serviceTicketStatusValues, 'SEARCH', true, this.utilsService);
    EnumUtil.enum2SelectModel(SURVEY_STATUS, this.surveyStatusValues, 'SEARCH', true, this.utilsService);
  }

  collectParams(): HttpParams {
    const map = Object.fromEntries(
      [
        'driver',
        'serviceIds',
        'startDate',
        'endDate',
        'supporterIds',
        'garageIds',
        'expertIds',
        'carBrandIds',
        'carLicensePlate',
        'ticketId',
      ].map((s) => [s, s]),
    );
    let params = this._collectParams(this.searchForm, map);
    params = params.set(
      'requestTicketStatus', 'PENDING'
    );
    params = params.set(
      'serviceTicketStatus',
      `${this.searchForm.get('serviceTicketStatus')?.value}`.replace('_', '').replace(/,_/g, ','),
    );
    params = params.set(
      'surveyStatus',
      `${this.searchForm.get('surveyStatus')?.value}`.replace('_', '').replace(/,_/g, ','),
    );

    params = params.set(
      'serviceTypes',
      EnumUtil.getKeysByValues(SERIVCE_TYPE, [
        SERIVCE_TYPE._PAINT,
        SERIVCE_TYPE._MAINTENANCE,
        SERIVCE_TYPE._REPAIR,
        SERIVCE_TYPE._SPA,
      ]).replace(/ /g, ''),
    );
    return params;
  }

  search() {
    const params = this.collectParams();
    this._fillData(ModuleConst.BOOKING + '/expert/appointment-schedule', params);
  }

  viewDetail(row: BookingModel) {
    this.router.navigateByUrl(`/booking/detail/${row.requestTicketId}`);
  }

  addOrEdit(row: BookingModel) {
    this.router.navigateByUrl(`/booking/edit/${row.requestTicketId}`);
  }

  createNewBooking(row: BookingModel | null) {
    this.router.navigate([this.router.url.replace('list', 'add')]).then();
  }

  audioCall(row: BookingModel) {
    this.openZegoCloud(ZEGO_CONVERSATION_TYPE.AUDIO_CALL, row);
  }

  videoCall(row: BookingModel) {
    this.openZegoCloud(ZEGO_CONVERSATION_TYPE.VIDEO_CALL, row);
  }

  openZegoCloud(type: ZEGO_CONVERSATION_TYPE, row: BookingModel) {
    const url = `/#/booking/ipcc/call/${row.requestTicketId}?type=${type}`;
    const nextWindow = window.open(url, '_blank');
    const token = window.sessionStorage.getItem('token');
    nextWindow?.sessionStorage.setItem('token', token || '');
  }

  chat(row: BookingModel) {
    if (row) {
      const url = `/#/booking/chat?requestTicketId=${row.requestTicketId}&type=${ZEGO_CONVERSATION_TYPE.ROOM_CHAT}`;
      this.router.navigate(['booking', 'chat'], {
        queryParams: { requestTicketId: row.requestTicketId, type: ZEGO_CONVERSATION_TYPE.ROOM_CHAT },
      });
      // const nextWindow = window.open(url, '_blank');
      // const token = window.sessionStorage.getItem('token');
      // nextWindow?.sessionStorage.setItem('token', token || '');
    }
  }

  onResetForm() {
    this.searchForm.reset();
    this.searchForm.patchValue({
      driver: [],
      requestTicketStatus: [],
      serviceIds: [],
      timeBooking: [],
      supporterIds: [],
      garageIds: [],
      expertIds: [],
      serviceTicketStatus: [],
      carLicensePlate: [],
      carBrandIds: [],
      ticketId: [],
      surveyStatus: [],
    });
  }

  openPendingRequest() {
    console.log('123');
  }
}
