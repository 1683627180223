import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  PopupConfirmationComponent,
  PopupConfirmationResult,
} from 'src/app/components/doctor/booking/components/popup-confirmation/popup-confirmation.component';

@Component({
  selector: 'app-doctor-booking-waiting-confirm',
  templateUrl: './waiting-confirm.component.html',
  styleUrls: ['./waiting-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class WaitingConfirmComponent implements OnInit, OnDestroy {
  @Output() cancel = new EventEmitter<void>();
  counter = 1200;
  timer?: NodeJS.Timer;
  constructor(
    private _matDialog: MatDialog,
    private _cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.timer = setInterval(() => {
      if (this.counter <= 0) {
        clearInterval(this.timer);
        this.cancel.emit();
        this._cdr.markForCheck();
        return;
      }
      this.counter -= 1;
      this._cdr.markForCheck();
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  formatCounter(counter = 0) {
    const minutes = Math.floor(counter / 60);
    const seconds = counter - minutes * 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
  }

  openDialog() {
    this._matDialog.open(PopupConfirmationComponent, {
      disableClose: false,
      width: '1000px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: {
        title: 'booking.cancel-confirmation.title',
        body: '',
        confirm: 'booking.cancel-confirmation.confirm',
        cancel: 'booking.cancel-confirmation.cancel',
      },
    }).afterClosed().subscribe((response: PopupConfirmationResult) => {
      if (response === PopupConfirmationResult.CONFIRM) {
        this.cancel.emit();
      }
    });
  }
}
