import { HttpParams } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  OnInit,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import {
  BaseSearchLayout,
  ColumnFields,
  ButtonFields,
  SelectModel,
  ApiService,
  UtilsService,
  FormStateService,
  AuthoritiesService,
  DateUtilService,
  AlignEnum,
  Page,
  ColumnTypes,
} from '@next-solutions/next-solutions-base';
import { TranslateService } from '@ngx-translate/core';
import { AreaTypeEnum } from 'src/app/enums/area.type.enum';
import { SuperStatusEnum } from 'src/app/enums/super.status.enum';
import { AreaModel } from 'src/app/models/cms/area.model';
import { GarageModel } from 'src/app/models/cms/garage.model';
import { ModuleConst } from 'src/app/modules/module.const';
import { GarageUtil } from 'src/app/utils/cms/garage.util';
import { EnumUtil } from 'src/app/utils/enum.util';
import { environment } from 'src/environments/environment';

interface DialogData {
  garageId: number;
  preQuery: {
    pageSize: string;
    pageNumber: string;
    status: string;
    bookingDate: string;
    bookingTime: string;
    placeId: number;
    maxDistance: number | string;
    utilityIds: any[];
    servicePackageIds: any[];
    serviceIds: any[];
    currentLat: number;
    currentLng: number;
  };
}

@Component({
  selector: 'app-popup-choose-garage',
  templateUrl: './popup-choose-garage.component.html',
  styleUrls: ['./popup-choose-garage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PopupChooseGarageComponent extends BaseSearchLayout implements OnInit {
  moduleName = 'booking';
  PAGE_SIZE = 5;

  columns: ColumnFields[] = [];
  buttons: ButtonFields[] = [];

  areas: AreaModel[] = [];
  provinceOptions: SelectModel[] = [];
  districtOptions: SelectModel[] = [];
  selectedGarageId: number | null | undefined = undefined;

  get expandHeaderButton() {
    return environment.EXPAND_HEADER_BUTTON;
  }

  constructor(
    protected formBuilder: FormBuilder,
    protected router: Router,
    protected apiService: ApiService,
    protected utilsService: UtilsService,
    protected uiStateService: FormStateService,
    protected translateService: TranslateService,
    protected injector: Injector,
    protected activatedRoute: ActivatedRoute,
    protected authoritiesService: AuthoritiesService,
    protected dateUtilService: DateUtilService,
    public matDialog: MatDialog,
    public garageUtil: GarageUtil,
    @Optional() public matDialogRef: MatDialogRef<boolean>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    super(
      router,
      apiService,
      utilsService,
      uiStateService,
      translateService,
      injector,
      activatedRoute,
      authoritiesService,
      formBuilder.group({
        name: [''],
        contactPointPhone: [''],
        districtId: [''],
        provinceId: [''],
      }),
    );

    this.paging.pageSize = this.PAGE_SIZE;
    this.initColumns();
    this.initDataFilters();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.selectedGarageId = this.data.garageId || undefined;
  }

  ngAfterViewInit(): void {
    this.onSubmit();
  }

  ngOnDestroy(): void {
    this.onResetForm();
  }

  initColumns() {
    this.columns.push(
      {
        columnDef: 'isSelected',
        header: 'isSelected',
        headerClassName: 'header-is-selected',
        title: (e: GarageModel) => ``,
        cell: (e: GarageModel) => ``,
        className: 'mat-column-isDefault',
        isShowHeader: false,
        display: (e: GarageModel) => true,
        columnType: (e: GarageModel) => ColumnTypes.CHECKBOX,
        align: AlignEnum.CENTER,
        onCellValueChange: (e: GarageModel) => {
          this.results.data?.forEach((row: any) => {
            if (row.id !== e.id) {
              row.isSelected = false;
            }
            this.selectedGarageId = e.isSelected ? e.id : undefined;
          });
        },
      },
      {
        columnDef: 'name',
        header: 'garage',
        title: (e: GarageModel) => `${e.name ? e.name : ''}`,
        cell: (e: GarageModel) => `${e.name ? e.name : ''}`,
        className: 'mat-column-gara-name',
      },
      {
        columnDef: 'contactPointPhone',
        header: 'phone_number',
        title: (e: GarageModel) => `${e.contactPointPhone ? e.contactPointPhone : ''}`,
        cell: (e: GarageModel) => `${e.contactPointPhone ? e.contactPointPhone : ''}`,
        className: 'mat-column-contactPointPhone',
        align: AlignEnum.CENTER,
      },
      {
        columnDef: 'provinceName',
        header: 'province_id',
        title: (e: GarageModel) =>
          `${e.provinceId ? this.areas.find((province) => province.id == e.provinceId)?.name : ''}`,
        cell: (e: GarageModel) =>
          `${e.provinceId ? this.areas.find((province) => province.id == e.provinceId)?.name : ''}`,
        className: 'mat-column-province',
      },
      {
        columnDef: 'districtName',
        header: 'district_id',
        title: (e: GarageModel) =>
          `${e.districtId ? this.areas.find((district) => district.id == e.districtId)?.name : ''}`,
        cell: (e: GarageModel) =>
          `${e.districtId ? this.areas.find((district) => district.id == e.districtId)?.name : ''}`,
        className: 'mat-column-district',
      },
      {
        columnDef: 'address',
        header: 'address',
        title: (e: GarageModel) => `${e.address ?? ''}`,
        cell: (e: GarageModel) => `${e.address ?? ''}`,
        className: 'mat-column-address',
      },
      {
        columnDef: 'totalProcessingRequest',
        header: 'total_processing_request',
        title: (e: GarageModel) => `${e.totalProcessingRequest ?? ''}`,
        cell: (e: GarageModel) => `${e.totalProcessingRequest ?? ''}`,
        className: 'mat-column-total-processing-request',
      },
    );
  }

  initDataFilters() {
    const params = new HttpParams()
      .set('status', EnumUtil.getKeysByValues(SuperStatusEnum, [SuperStatusEnum._ACCEPTED]))
      .set('pageNumber', '1')
      .set('pageSize', environment.INTEGER_MAX_VALUE);

    this.apiService.get<Page>(ModuleConst.CMS + '/area', params).subscribe((pageArea) => {
      if (pageArea && pageArea.hasOwnProperty('content')) {
        this.areas = (pageArea as Page).content;
        this.provinceOptions = this.areas
          .filter((a) => a.type === EnumUtil.getKeysByValues(AreaTypeEnum, [AreaTypeEnum._PROVINCE]))
          ?.map((a) => new SelectModel(a.id, a.name))
          .sort((a: SelectModel, b: SelectModel) => {
            return a.displayValue.localeCompare(b.displayValue);
          });
      }
    });
  }

  onProvinceChange(province: SelectModel[]) {
    if (province.length > 0) {
      this.loadDistrictByProvince(province);
    }
  }

  loadDistrictByProvince(province: any) {
    this.districtOptions = this.areas
      .filter(
        (a) =>
          a.type === EnumUtil.getKeysByValues(AreaTypeEnum, [AreaTypeEnum._DISTRICT]) &&
          a.parentId === province[0]?.value,
      )
      ?.map((a) => new SelectModel(a.id, a.name))
      .sort((a: SelectModel, b: SelectModel) => {
        return a.displayValue.localeCompare(b.displayValue);
      });
    this.searchForm.patchValue({ districtId: '' });
  }

  removeNullUndefined = (obj: object) =>
    Object.entries(obj).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {});

  search() {
    let params = null;
    const { name, districtId, provinceId, contactPointPhone } = this.searchForm.value;

    if (!name && !districtId && !provinceId && !contactPointPhone) {
      params = new HttpParams({ fromObject: this.removeNullUndefined(this.data.preQuery) })
        .set('pageSize', this.paging.pageSize)
        .set('pageNumber', this.paging.pageNumber);
      this._fillData(ModuleConst.BOOKING + '/garage/to-booking-cms', params);
    } else {
      this.paging.pageSize = this.PAGE_SIZE;
      const map = Object.fromEntries(['name', 'contactPointPhone', 'districtId', 'provinceId'].map((s) => [s, s]));
      params = this._collectParams(this.searchForm, map).set(
        'status',
        EnumUtil.getKeysByValues(SuperStatusEnum, [SuperStatusEnum._ACCEPTED]),
      );
      this._fillData(ModuleConst.BOOKING + '/garage', params);
    }
  }

  assignGarage() {
    const garage = this.results.data.find((el) => el.id === this.selectedGarageId);
    this.matDialogRef.close({ garage: garage });
  }

  onResetForm() {
    this.searchForm.reset();
    this.searchForm.patchValue({
      name: '',
      contactPointPhone: '',
      districtId: '',
      provinceId: '',
    });
    this.districtOptions = [];
  }

  closeModal() {
    this.matDialogRef.close();
  }

  afterSearch = (data: Page) => {
    if (data?.content) {
      data.content.forEach((el: any) => {
        if (el.id === this.selectedGarageId) el['isSelected'] = true;
        else el['isSelected'] = false;
      });
    }
  };
}
