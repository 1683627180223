import {Injectable} from "@angular/core";
import {ApiService, AuthoritiesService, UtilsService} from "@next-solutions/next-solutions-base";
import {CancelReasonModel} from "../../models/cms/cancel-reason.model";
import {SuperStatusEnum} from "../../enums/super.status.enum";
import {MatDialogConfig} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {HttpParams} from "@angular/common/http";
import {ModuleConst} from "../../modules/module.const";

@Injectable({providedIn: 'root'})
export class CancelReasonUtil {
  constructor(protected authoritiesService: AuthoritiesService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected translateService: TranslateService) {
  }

  hasAddEditPermission(e: CancelReasonModel | null): boolean {
    if (e && e.id) {
      return this.authoritiesService.hasAuthority('patch/cancelreason/{id}')
        && this.authoritiesService.hasAuthority('patch/sso/user/{id}');
    } else {
      return this.authoritiesService.hasAuthority('post/cancelreason')
        && this.authoritiesService.hasAuthority('post/sso/user');
    }
  }

  hasViewDetailPermission(): boolean {
    return this.authoritiesService.hasAuthority('get/cancelreason/{id}');
  }

  hasAcceptPermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/cancelreason/{id}/accept');
  }

  isDisableAccept(a: CancelReasonModel | null): boolean {
    if (a) {
      return SuperStatusEnum['_' + a.status] === SuperStatusEnum._ACCEPTED;
    }
    return false;
  }

  hasRejectPermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/cancelreason/{id}/reject');
  }

  isDisableReject(a: CancelReasonModel | null): boolean {
    if (a) {
      return SuperStatusEnum['_' + a.status] === SuperStatusEnum._REJECTED;
    }
    return true;
  }

  onAccept(cancelReason: CancelReasonModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (cancelReason) {
      const method = this.apiService.patch(ModuleConst.CMS + '/cancelreason/' + cancelReason.id + '/accept', null);
      this.utilsService.execute(method, onSuccessFunc, 'common.accept.success',
        'common.confirm.accept', ['cancel.reason.']);
    }
  }

  onReject(cancelReason: CancelReasonModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (cancelReason) {
      const strOk = this.translateService.instant('common.ok');
      const strCancel = this.translateService.instant('common.cancel');
      const strReasonReject = this.translateService.instant('common.reject.reason');
      const dialogConfig: MatDialogConfig<any> = {
        data: {
          customClass: 'reject_reason_dialog hint-outside',
          msg: strReasonReject,
          type: 'INPUT_CONFIRM',
          btnOKString: strOk,
          btnCancelString: strCancel,
        }
      };
      this.utilsService.showConfirmInputDialog('common.reject.reason', [], dialogConfig)
        .afterClosed().subscribe(result => {
        if (result && result.value) {
          const params = new HttpParams().set('rejectReason', result.value);
          this.apiService.patch(`/cms/cancelreason/${cancelReason.id}/reject`, null, {params})
            .subscribe(data => {
                onSuccessFunc(data, 'common.reject.success');
              },
              error => {
                this.utilsService.showError(error);
              });
        }
      });
    }
  }
}
