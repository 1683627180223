import { Component, ViewChild, ElementRef } from '@angular/core';
import { NavService } from '../../_services/nav.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';
import { AuthoritiesService, SingletonTranslateService } from '@next-solutions/next-solutions-base';
import { ChangePasswordComponent } from '../../components/change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { BookingUtil } from 'src/app/utils/booking/booking.util';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent {
  selectedLanguage: string;
  currentTheme: string;
  languageKey: string = 'lang' + environment.CLIENT_ID;
  @ViewChild('userName') userName!: ElementRef;

  constructor(
    public navService: NavService,
    private translate: TranslateService,
    public cookieService: CookieService,
    private appComponent: AppComponent,
    private router: Router,
    private location: Location,
    protected authoritiesService: AuthoritiesService,
    protected singletonTranslateService: SingletonTranslateService,
    private matDialog: MatDialog,
    public bookingUtil: BookingUtil,
  ) {
    this.selectedLanguage = '' + window.sessionStorage.getItem(this.languageKey);

    if (this.cookieService.get('theme') === 'undefined' || this.cookieService.get('theme') === '') {
      this.currentTheme = environment.DEFAULT_THEME;
    } else {
      this.currentTheme = this.cookieService.get('theme');
    }

    appComponent.changeTheme(this.currentTheme);
    this.cookieService.set('theme', this.currentTheme);
  }

  onChangeLanguage(event: any) {
    this.selectedLanguage = event.value;
    window.sessionStorage.setItem(this.languageKey, event.value);
    this.singletonTranslateService.currentLanguage.next(event.value);
  }

  onChangeTheme(event: any) {
    this.currentTheme = event.value;
    this.appComponent.changeTheme(this.currentTheme);
    this.cookieService.set('theme', this.currentTheme);
  }

  goToHome() {
    this.router.navigate(['home']);
  }

  getLanguages() {
    return environment.LANGS;
  }

  get environment() {
    return environment;
  }

  get username() {
    return !!this.authoritiesService.me ? this.authoritiesService.me.name : '';
  }

  showUserMenu() {
    const username = this.userName.nativeElement;
    if (username.classList.contains('off')) {
      username.classList.remove('off');
    } else {
      username.classList.add('off');
    }
  }

  openDialogChangePassword() {
    this.matDialog.open(ChangePasswordComponent, {
      width: '80%',
      height: '80%',
    });
  }
}
