<ns-breadcrumb></ns-breadcrumb>
<div class="searchFormGroup">
  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="form">
    <div fxLayout="row wrap" class="row-wrap-padding">
      <ns-input
        [placeholder]="moduleName + '.table.header.customer_placeholder'"
        name="driver"
        formControlName="driver"
        fxLayout="column"
        fxFlex="25%"
        fxFlex.xs="100%"
        fxFlex.sm="50%"
        fxFlex.md="50%"
        fxFlex.lg="25%"
      >
      </ns-input>
      <ns-multi-select-autocomplete
        [multiple]="true"
        [options]="expertProcessingStatusVaules"
        [placeholder]="moduleName + '.table.header.status'"
        [label]="moduleName + '.table.header.expert_processing_status'"
        name="expertProcessingStatus"
        formControlName="expertProcessingStatus"
        fxLayout="column"
        fxFlex="25%"
        fxFlex.xs="100%"
        fxFlex.sm="50%"
        fxFlex.md="50%"
        fxFlex.lg="25%"
      >
      </ns-multi-select-autocomplete>
      <ns-range-date-picker
        name="timeBooking"
        formControlName="timeBooking"
        [label]="moduleName + '.table.header.time_booking'"
        [requiredToDate]="false"
        [requiredFromDate]="false"
        [placeholderFromDate]="'fromDate'"
        [placeholderToDate]="'toDate'"
        [fromDateControlName]="'startDate'"
        [toDateControlName]="'endDate'"
        fxLayout="column"
        fxFlex="25%"
        fxFlex.xs="100%"
        fxFlex.sm="50%"
        fxFlex.md="50%"
        fxFlex.lg="25%"
      >
      </ns-range-date-picker>
      <ns-multi-select-autocomplete
        [multiple]="true"
        [options]="expertValues"
        [placeholder]="moduleName + '.table.header.expert'"
        name="expertValues"
        formControlName="expertIds"
        fxLayout="column"
        fxFlex="25%"
        fxFlex.xs="100%"
        fxFlex.sm="50%"
        fxFlex.md="50%"
        fxFlex.lg="25%"
      >
      </ns-multi-select-autocomplete>
    </div>

    <div class="buttonArea">
      <button mat-button type="submit" class="primary Update-btn" [disabled]="searchForm.invalid">
        {{ 'btnSearch' | translate }}
      </button>
      <button mat-button type="button" class="secondary outline reset-btn" (click)="onResetForm()">
        {{ 'btnReset' | translate }}
      </button>
    </div>
  </form>
  <div class="result-area margin-bottom-20" fxLayout fxLayoutAlign="space-between center">
    <h3 class="margin-0">{{ paging.totalElements }} {{ ' ' + ('common.results' | translate) }}</h3>
    <div class="button-area" fxLayout>
      <button mat-button class="primary reset-btn" (click)="getListGara()">
        <span>{{ 'booking.garages' | translate }}</span>
        <i class="fa fa-clipboard-list margin-left-10" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="acbox">
    <ns-table
      [columns]="columns"
      [buttons]="buttons"
      [paging]="paging"
      [moduleName]="moduleName"
      [results]="results"
      (pagingChange)="pagingChange($event)"
      (clickAction)="onRowButtonClick($event)"
    >
    </ns-table>
  </div>
</div>
