<div fxFlex="100%" fxLayout="column">
  <ns-breadcrumb></ns-breadcrumb>
  <div fxLayout="column" lazyScrollTrigger [options]="options" class="notifications">
    <div *ngFor="let notification of notifications; trackBy: notifyId"
         [ngClass]="{'read': notification.isRead}"
         (click)="readNotification(notification)"
         class="notification padding-vertical-15 padding-horizontal-20"
         fxFlex="100%" fxLayout="row" fxLayoutGap="20px">
      <div image [imageSrc]="notification.image || 'assets/icons/booking/default-logo.svg'" class="notification__img"></div>
      <div fxLayout="column" fxLayoutGap="8px" class="padding-right-20">
        <div class="notification__title" [innerHTML]="notification.content"></div>
        <div class="notification__time">{{ notification.sent_date | timeAgo }}</div>
      </div>
    </div>
  </div>
</div>
