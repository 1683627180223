<ns-breadcrumb></ns-breadcrumb>
<div class='searchFormGroup'>
  <form [formGroup]='searchForm' (ngSubmit)='onSubmit()' class='form'>
    <div fxLayout='row wrap' class='row-wrap-padding'>
      <ns-input [placeholder]="moduleName+'.table.header.customerName'" name='customerName'
                formControlName='customerName'
                fxLayout='column' fxFlex='25%'
                fxFlex.xs='100%' fxFlex.sm='50%' fxFlex.md='50%' fxFlex.lg='25%'>
      </ns-input>
      <ns-input [placeholder]="moduleName+'.table.header.customerPhone'" name='customerPhone'
                formControlName='customerPhone'
                fxLayout='column' fxFlex='25%'
                fxFlex.xs='100%' fxFlex.sm='50%' fxFlex.md='50%' fxFlex.lg='25%'>
      </ns-input>
      <ns-range-date-picker name='logDate'
                            formControlName='logDate'
                            [label]="moduleName+'.table.header.logDate'"
                            [requiredToDate]='false'
                            [requiredFromDate]='false'
                            [placeholderFromDate]="'fromDate'"
                            [placeholderToDate]="'toDate'"
                            [fromDateControlName]="'fromDate'"
                            [toDateControlName]="'toDate'"
                            fxLayout='column' fxFlex='25%'
                            fxFlex.xs='100%' fxFlex.sm='50%' fxFlex.md='50%' fxFlex.lg='25%'>
      </ns-range-date-picker>
      <ns-multi-select-autocomplete [multiple]='false'
                                    [options]='garageValues'
                                    [placeholder]="moduleName+'.table.header.garage'"
                                    name='garageId' formControlName='garageId'
                                    fxLayout='column' fxFlex='25%'
                                    fxFlex.xs='100%' fxFlex.sm='50%' fxFlex.md='50%' fxFlex.lg='25%'>
      </ns-multi-select-autocomplete>
      <ng-container *ngIf='isAdvancedSearch'>
        <ns-multi-select-autocomplete [multiple]='false'
                                      [options]='operatorValues'
                                      [placeholder]="moduleName+'.table.header.operator'"
                                      name='operatorId' formControlName='operatorId'
                                      fxLayout='column' fxFlex='25%'
                                      fxFlex.xs='100%' fxFlex.sm='50%' fxFlex.md='50%' fxFlex.lg='25%'>
        </ns-multi-select-autocomplete>
        <ns-multi-select-autocomplete [multiple]='false'
                                      [options]='statusValues'
                                      [placeholder]="moduleName+'.table.header.status'"
                                      name='status' formControlName='status'
                                      fxLayout='column' fxFlex='25%'
                                      fxFlex.xs='100%' fxFlex.sm='50%' fxFlex.md='50%' fxFlex.lg='25%'>
        </ns-multi-select-autocomplete>
      </ng-container>
    </div>
    <div fxLayout='row wrap' class='row-wrap-padding'>
      <div fxLayout='column' fxFlex='100%' fxLayoutAlign='center center' class='advanced-search'>
        <span ngClass="{{isAdvancedSearch ? 'hide_advance' : 'show_advance'}}"
              (click)='toggleAdvancedSearch()'>{{(isAdvancedSearch ? 'common.advanced.search.collapse' : 'common.advanced.search.expand') | translate}}
          <i ngClass="{{isAdvancedSearch ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}}"></i></span>
      </div>
    </div>
    <div class='buttonArea'>
      <div>
        <button mat-button type='submit' class='primary Update-btn' [disabled]='searchForm.invalid'>
          {{'btnSearch'|translate}}
        </button>
        <button mat-button type='button' class='secondary outline reset-btn'
                (click)='onResetForm()'>
          {{'btnReset'|translate}}
        </button>
      </div>
    </div>
  </form>
  <div class="result-area margin-bottom-20" fxLayout fxLayoutAlign="space-between center">
    <h3 class="margin-0">{{paging.totalElements}} {{' ' + ('common.results'| translate) }}</h3>
  </div>
  <div class='acbox'>
    <ns-table [columns]='columns' [buttons]='sosTrackingUtil.getButtons()'
              [paging]='paging'
              [moduleName]='moduleName' [results]='results'
              [expandHeaderButton]="expandHeaderButton"
              (pagingChange)='pagingChange($event)'
              (clickAction)='onRowButtonClick($event)'>
    </ns-table>
  </div>
</div>


