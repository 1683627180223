<div (click)="openNotificationList()" class="indicator-wrapper" #notificationAnchor>
  <i class="fas fa-bell"></i>
  <span *ngIf="count" class="indicator-count">{{ count > 99 ? '99+' : count }}</span>
</div>

<ng-template cdkPortal #notificationListTemplate="cdkPortal">
  <div class="notification-indicator-wrapper">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-horizontal-20 padding-vertical-15">
      <a class="cta-notification">{{ 'notification-indicator.title' | translate }}</a>
      <a class="cta-notification" (click)="goNotification()">{{ 'notification-indicator.all' | translate }}</a>
    </div>
    <div fxLayout="column" lazyScrollTrigger [options]="options"
         class="notifications">
      <div *ngIf="notificationList && notificationList.length === 0" class="no-notifications">{{ 'notification-indicator.empty' | translate }}</div>
      <div *ngFor="let notification of notificationList; trackBy: notifyId"
           [ngClass]="{'read': notification.isRead}"
           (click)="openNotification(notification)"
           class="notification padding-vertical-15 padding-horizontal-20"
           fxFlex="100%" fxLayout="row" fxLayoutGap="20px">
        <div image [imageSrc]="notification.image || 'assets/icons/booking/default-logo.svg'" class="notification__img"></div>
        <div fxLayout="column" fxLayoutGap="8px" class="padding-right-20">
          <div class="notification__title" [innerHTML]="notification.content"></div>
          <div class="notification__time">{{ notification.sent_date | timeAgo }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
