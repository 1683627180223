import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DestroyService } from 'src/app/_services/destroy.service';
import { takeUntil } from 'rxjs';
import { ChatService } from 'src/app/components/doctor/booking/chat/chat.service';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/components/doctor/booking/booking-create/firebase.service';
import { filter } from 'rxjs/operators';
import { MessageType } from 'src/app/components/doctor/booking/booking-create/firebase.model';

@Component({
  selector: 'app-doctor-booking-chat-indicator',
  templateUrl: 'chat-indicator.component.html',
  styleUrls: ['chat-indicator.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatIndicatorComponent implements OnInit {
  hasMessage = false;
  count = 0;
  constructor(
    private _chatService: ChatService,
    private _firebaseService: FirebaseService,
    private _destroy: DestroyService,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
  ) {}

  ngOnInit() {
    this.initData();
  }

  initData() {
    this._firebaseService.getMessage()
      .pipe(
        takeUntil(this._destroy),
        filter(message => {
          if (!message) return false;
          const { data: fbData } = message;
          console.log('@== message', message);
          const objData = JSON.parse(fbData?.data || '');
          console.log('@== fbData', fbData);
          return [MessageType.ROOM_CHAT, MessageType.PEER_CHAT].includes(objData.convType)
        })
      )
      .subscribe(_ => {
        this.count = 1;
        this._cdr.detectChanges();
      });
    this._chatService.conversationChanged$
      .pipe(takeUntil(this._destroy))
      .subscribe(changes => {
        if (!changes) {
          this.count = 0;
          this._cdr.detectChanges();
          return;
        }
        this.count = 1;
        this._cdr.detectChanges();
      });
  }

  clearChatNotification() {
    this.count = 0;
    this._cdr.detectChanges();
    this._router.navigate(['booking', 'chat']);
  }
}
