import { Injectable } from '@angular/core';
import { ApiService, AuthoritiesService, UtilsService } from '@next-solutions/next-solutions-base';
import { SuperStatusEnum } from '../../enums/super.status.enum';
import { MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';
import { DriverModel } from '../../models/cms/driver.model';
import { environment } from '../../../environments/environment';
import { ModuleConst } from '../../modules/module.const';

@Injectable({providedIn: 'root'})
export class DriverUtil {
  constructor(protected authoritiesService: AuthoritiesService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected translateService: TranslateService) {
  }

  isExpertOrOp(): boolean {
    try {
      const currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '');
      return !!(
        currentUser &&
        ((currentUser.systemRefUserType === 'EXPERT') || (currentUser.systemRefUserType === 'OPERATOR'))
      );
    } catch (err) {
      return false;
    }
  }

  hasAddEditPermission(e: DriverModel | null): boolean {
    if (e && e.id) {
      return this.authoritiesService.hasAuthority('patch/driver/{id}')
        && this.authoritiesService.hasAuthority('patch/sso/user/{id}');
    } else {
      return this.authoritiesService.hasAuthority('post/driver')
        && this.authoritiesService.hasAuthority('post/sso/user');
    }
  }

  hasViewDetailPermission(): boolean {
    return this.authoritiesService.hasAuthority('get/driver/{id}');
  }

  hasAcceptPermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/driver/{id}/accept')
      && this.authoritiesService.hasAuthority('post/sso/user/active');
  }

  isDisableAccept(e: DriverModel | null): boolean {
    if (e) {
      return SuperStatusEnum['_' + e.status] === SuperStatusEnum._ACCEPTED;
    }
    return false;
  }

  hasRejectPermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/driver/{id}/reject')
      && this.authoritiesService.hasAuthority('post/sso/user/deactive');
  }

  hasResetPasswordPermission(): boolean {
    return this.authoritiesService.hasAuthority('post/sso/user/reset-password');
  }

  isDisableReject(e: DriverModel | null): boolean {
    if (e) {
      return SuperStatusEnum['_' + e.status] === SuperStatusEnum._REJECTED;
    }
    return true;
  }

  onAccept(e: DriverModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (e) {
      const method = this.apiService.patch(ModuleConst.CMS + '/driver/' + e.id + '/accept', null);
      this.utilsService.execute(method, onSuccessFunc, 'common.accept.success',
        'common.confirm.accept', ['driver.']);
    }
  }

  onReject(e: DriverModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (e) {
      const strOk = this.translateService.instant('common.ok');
      const strCancel = this.translateService.instant('common.cancel');
      const strReasonReject = this.translateService.instant('common.reject.reason');
      const dialogConfig: MatDialogConfig<any> = {
        data: {
          customClass: 'reject_reason_dialog hint-outside',
          msg: strReasonReject,
          type: 'INPUT_CONFIRM',
          btnOKString: strOk,
          btnCancelString: strCancel,
        }
      };
      this.utilsService.showConfirmInputDialog('common.reject.reason', [], dialogConfig)
        .afterClosed().subscribe(result => {
        if (result && result.value) {
          const params = new HttpParams().set('rejectReason', result.value);
          this.apiService.patch(`/cms/driver/${e.id}/reject`, null, {params})
            .subscribe(data => {
                onSuccessFunc(data, 'common.reject.success');
              },
              error => {
                this.utilsService.showError(error);
              });
        }
      });
    }
  }

  onResetPassword(e: DriverModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (e) {
      const method = this.apiService.post('/user/reset-password', e.username, {}, environment.BASE_AUTHORIZATION_URL);
      this.utilsService.execute(method, onSuccessFunc, 'common.reset-password.success',
        'common.confirm.reset-password', ['driver.']);
    }
  }

  hasExportDataPermission(): boolean {
    return this.authoritiesService.hasAuthority('post/export-data/driver');
  }

  hasImportExcelPermission(): boolean {
    return this.authoritiesService.hasAuthority('post/import-data/driver');
  }

  hasDownloadTemplatePermission(): boolean {
    return this.authoritiesService.hasAuthority('post/download-template/driver');
  }
}
