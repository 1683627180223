import { FormGroup } from '@angular/forms';
import { SuperEntity } from '@next-solutions/next-solutions-base';
import { ServiceModel } from '../service/service.model';

export enum REQUEST_TICKET_STATUS {
  _NEW = 'booking.request_ticket_status.new',
  _PENDING = 'booking.request_ticket_status.pending',
  _GARAGE_CONFIRMED = 'booking.request_ticket_status.confirmed',
  _CUSTOMER_CHECKED_IN = 'booking.request_ticket_status.booked',
  _IN_PROCESSING = 'booking.request_ticket_status.in_processing',
  _COMPLETED = 'booking.request_ticket_status.completed',
  _CANCELED = 'booking.request_ticket_status.canceled',
}

export enum SERVICE_TICKET_STATUS {
  _CHECKING = 'booking.service_ticket_status.checking',
  _CHECKED = 'booking.service_ticket_status.checked',
  _WAITING_CUSTOMER_APPROVE_PRICE = 'booking.service_ticket_status.waiting_customer_approve_price',
  _FIXING = 'booking.service_ticket_status.fixing',
  _WAIT_FOR_PAY = 'booking.service_ticket_status.wait_for_pay',
  _PAID = 'booking.service_ticket_status.paid',
  _GARAGE_HANDED_OVER_CAR = 'booking.service_ticket_status.garage_handed_over_car',
  _COMPLETED = 'booking.service_ticket_status.completed',
  _REFUSE_PRICE_QUOTATION = 'booking.service_ticket_status.refuse_price_quotation',
  _CANCELED = 'booking.service_ticket_status.canceled',
}

export enum SURVEY_STATUS {
  _PRICE_CHECKED = 'booking.survey_status.price_checked',
  _NOT_PRICE_CHECKED = 'booking.survey_status.not_price_checked',
}

export enum SERIVCE_TYPE {
  _MAINTENANCE = 'booking.service.name4',
  _UPGRADE = 'booking.service.name5',
  _PAINT = 'booking.service.name6',
  _SPA = 'booking.service.name7',
  _REPAIR = 'booking.service.name3',
}

export enum EXPERT_PROCESSING_STATUS {
  _PENDING = 'booking.expert_processing_status.pending',
  _IN_PROCESSING = 'booking.expert_processing_status.in_processing',
  _PROCESSED = 'booking.expert_processing_status.processed',
}

export enum SERVICE_LABEL {
  SERVICEPACKAGE = 'SERVICEPACKAGE',
  SERVICE = 'SERVICE',
}

export enum GARA_STATUS {
  _WAITING = 'booking.gara_status.waiting',
  _REJECTED = 'booking.gara_status.rejected',
  _ACCEPTED = 'booking.gara_status.accepted',
}

export enum BOOKING_BEHAVIORS {
  EDIT = 'EDIT',
  CANCEL = 'CANCEL',
  ASSIGN_GARA = 'ASSIGN_GARA',
  CHANGE_BOOKING_INFO = 'CHANGE_BOOKING_INFO',
  SURVEY = 'SURVEY',
  ASSIGN_EXPERT_AND_OPERATOR = 'ASSIGN_EXPERT_AND_OPERATOR',
  ADD_SERVICE = 'ADD_SERVICE',
}

export interface Service {
  id: number | string;
  name: string;
  type: string;
  price: number;
  refId: string;
}

export interface CarStatusModel {
  id?: string | number;
  value?: string | number;
  status?: 'ACCEPTED' | 'DRAFT' | 'REJECTED' | string;
  ref_id?: number;
  carStatusId?: string | number;
  displayValue?: string;
  isNew?: boolean;
}

export interface CarStatusImageModel {
  id: number;
  imageUrl: string;
}

export interface SuperServiceModel {
  id: string | number;
  name: string;
  type: SERVICE_LABEL;
  children: any[];
  serviceEntities?: ServiceModel[];
}

export interface SurveyModel {
  id: string | number;
  refId?: string | number;
  name: string;
  minPrice: number | string;
  maxPrice: number | string;
}

export interface ServiceTicketService {
  children: any[];
  details?: string;
  name: string;
  price: number;
  refId: number;
  type: string;
  warrantyTime: string;
}

export class ServiceWarrantyModel extends SuperEntity {
  serviceName: string = '';
  garageName: string = '';
  warrantyTime: string | null = null;
  isInDate: boolean | null = null;

  constructor(form: FormGroup | number) {
    super();
    if (form instanceof FormGroup) {
      const fId = form.get('id');
      if (fId) {
        this.id = fId.value;
      }
      const fServiceName = form.get('serviceName');
      if (fServiceName) {
        this.serviceName = fServiceName.value;
      }
      const fGarageName = form.get('garageName');
      if (fGarageName) {
        this.garageName = fGarageName.value;
      }
      const fWarrantyTime = form.get('warrantyTime');
      if (fWarrantyTime) {
        this.warrantyTime = fWarrantyTime.value;
      }
      const fIsInDate = form.get('isInDate');
      if (fIsInDate) {
        this.isInDate = fIsInDate.value;
      }
    } else {
      this.id = form;
    }
  }
}

export class BookingModel extends SuperEntity {
  serviceTicketId!: string;
  requestTicketId!: number | string;
  appointmentDate!: string;
  garageHandOverCarDate!: string;

  driverId!: number;
  driverName!: string;
  driverPhone!: string;
  driverAddress!: string;

  carRefId!: string | number;
  carNumber!: string;
  carBrand!: string;
  carChassisNumber!: string;
  carEngineNumber!: string;
  carKm!: string;
  carImg!: string;
  carManufacturedYear!: string;
  carBodyStyle!: string;
  carFuel!: string;
  carHorsePower!: string;
  carSeat!: string;
  carEngine!: string;
  carGear!: string;
  carOrigin!: string;
  carModel!: string;

  garageRefId!: string;
  garageName!: string;
  garagePhone!: string;
  garageAddress!: string;
  garageHandleStatus!: string;

  expertRefId!: string;
  expertName!: string;
  expertPhone!: string;

  operatorId!: string;
  operatorName!: string;
  operatorPhone!: string;

  requestTicketStatus!: string;
  serviceTicketStatus!: string;

  createDate!: string;
  createUser!: string;
  createName!: string;

  listService!: Service[];
  services!: Service[];
  servicePackages!: Service[];

  expertProcessingStatus?: EXPERT_PROCESSING_STATUS;
  description?: string;
  contract?: any;

  surveyStatus?: SURVEY_STATUS;

  reason!: string;
  canceledDate!: string;

  carStatuses!: CarStatusModel[];
  carStatusImages!: CarStatusImageModel[];
  diagnosticResults!: string;
  distanceFilter!: string | number;
  distanceName!: string;
  placeId!: string | number;
  requestUtilities!: {
    icon?: string;
    id?: string | number;
    name?: string;
    refId?: string | number;
  }[];
  userLatitude!: number;
  userLongitude!: number;
  survey!: SurveyModel[];
  maxSurveyTotalPrice!: string | number;
  minSurveyTotalPrice!: string | number;
  totalPrice!: string | number;
  expectedDeliveryDate!: string | number;
  serviceBookingMediaResult!: { id: number; imageUrl: string }[];
  carInspectionResult!: string;
  serviceTicketServicesMedia!: { id: number; imageUrl: string }[];
  serviceTicketServices?: ServiceTicketService[];
  serviceTicketServiceFormArray?: any[];
  serviceType?: SERIVCE_TYPE = undefined;

  refRequestTicket?: BookingModel;

  timeFrameContent?: string;
  voucherInfo?: string;
  voucherName?: string;

  constructor(form: FormGroup | number) {
    super();
    if (form instanceof FormGroup) {
      this.id = form.get('id')?.value ?? '';
      this.appointmentDate = form.get('appointmentDate')?.value ?? '';
      this.garageHandOverCarDate = form.get('garageHandOverCarDate')?.value ?? '';
      this.requestTicketId = form.get('requestTicketId')?.value ?? '';
      this.serviceTicketId = form.get('serviceTicketId')?.value ?? '';

      this.driverId = form.get('driverId')?.value ?? '';
      this.driverName = form.get('driverName')?.value ?? '';
      this.driverPhone = form.get('driverPhone')?.value ?? '';

      this.carRefId = form.get('carRefId')?.value ?? '';
      this.carNumber = form.get('carNumber')?.value ?? '';
      this.carBrand = form.get('carBrand')?.value ?? '';

      this.garageName = form.get('garageName')?.value ?? '';
      this.garageRefId = form.get('garageRefId')?.value ?? '';
      this.garageAddress = form.get('garageAddress')?.value ?? '';
      this.garageHandleStatus = form.get('garageHandleStatus')?.value ?? '';

      this.expertRefId = form.get('expertRefId')?.value ?? '';
      this.expertName = form.get('expertName')?.value ?? '';
      this.expertPhone = form.get('expertPhone')?.value ?? '';

      this.operatorId = form.get('operatorId')?.value ?? '';
      this.operatorPhone = form.get('operatorPhone')?.value ?? '';
      this.operatorName = form.get('operatorName')?.value ?? '';

      this.requestTicketStatus = form.get('requestTicketStatus')?.value ?? '';
      this.serviceTicketStatus = form.get('serviceTicketStatus')?.value ?? '';

      this.createDate = form.get('createDate')?.value ?? '';
      this.createUser = form.get('createUser')?.value ?? '';
      this.createName = form.get('createName')?.value ?? '';

      this.listService = form.get('listService')?.value ?? '';

      // For booking upgrade
      this.expertProcessingStatus = form.get('expertProcessingStatus')?.value ?? '';
      this.description = form.get('description')?.value ?? '';
      this.contract = form.get('contract')?.value ?? null;

      // For booking cancel
      this.canceledDate = form.get('canceledDate')?.value ?? '';
      this.reason = form.get('reason')?.value ?? '';

      // For booking detail
      this.driverAddress = form.get('driverAddress')?.value ?? '';

      this.carChassisNumber = form.get('carChassisNumber')?.value ?? '';
      this.carEngineNumber = form.get('carEngineNumber')?.value ?? '';
      this.carKm = form.get('carKm')?.value ?? '';
      this.carImg = form.get('carImg')?.value ?? '';
      this.carManufacturedYear = form.get('carManufacturedYear')?.value ?? '';
      this.carBodyStyle = form.get('carBodyStyle')?.value ?? '';
      this.carHorsePower = form.get('carHorsePower')?.value ?? '';
      this.carFuel = form.get('carFuel')?.value ?? '';
      this.carSeat = form.get('carSeat')?.value ?? '';
      this.carGear = form.get('carGear')?.value ?? '';
      this.carEngine = form.get('carEngine')?.value ?? '';
      this.carOrigin = form.get('carOrigin')?.value ?? '';
      this.carModel = form.get('carModel')?.value ?? '';

      this.garagePhone = form.get('garagePhone')?.value ?? '';

      this.carStatuses = form.get('carStatuses')?.value ?? [];
      this.carStatusImages = form.get('carStatusImages')?.value ?? '';
      this.diagnosticResults = form.get('diagnosticResults')?.value ?? '';
      this.distanceFilter = form.get('distanceFilter')?.value ?? '';
      this.distanceName = form.get('distanceName')?.value ?? '';
      this.placeId = form.get('placeId')?.value ?? '';
      this.requestUtilities = form.get('requestUtilities')?.value ?? '';
      this.userLatitude = form.get('userLatitude')?.value ?? '';
      this.userLongitude = form.get('userLongitude')?.value ?? '';
      this.survey = form.get('survey')?.value ?? '';
      this.maxSurveyTotalPrice = form.get('maxSurveyTotalPrice')?.value ?? '';
      this.serviceBookingMediaResult = form.get('serviceBookingMediaResult')?.value ?? '';
      this.carInspectionResult = form.get('carInspectionResult')?.value ?? '';
      this.serviceTicketServicesMedia = form.get('serviceTicketServicesMedia')?.value ?? '';
      this.serviceTicketServices = form.get('serviceTicketServices')?.value ?? '';
      this.serviceTicketServiceFormArray = form.get('serviceTicketServiceFormArray')?.value ?? '';
      this.serviceType = form.get('serviceType')?.value ?? '';
      this.totalPrice = form.get('totalPrice')?.value ?? 0;
      this.expectedDeliveryDate = form.get('expectedDeliveryDate')?.value ?? '';
      this.refRequestTicket = form.get('refRequestTicket')?.value ?? '';
    } else this.id = form;
  }
}
