import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from './shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { QuillModule } from 'ngx-quill';
import { MultiTranslateHttpLoader } from '@next-solutions/next-solutions-base';
import { BookingListComponent } from '../components/doctor/booking/booking-list/booking-list.component';
import { BookingRoutingModule } from '../routing/booking.routing.module';
import { BookingCreateComponent } from '../components/doctor/booking/booking-create/booking-create.component';
import { Step1Component } from '../components/doctor/booking/steps-create/step1/step1.component';
import { Step2Component } from '../components/doctor/booking/steps-create/step2/step2.component';
import { Step3Component } from '../components/doctor/booking/steps-create/step3/step3.component';
import { Step4Component } from '../components/doctor/booking/steps-create/step4/step4.component';
import { BookingCreateService } from '../components/doctor/booking/booking-create/booking-create.service';
import { Step5Component } from '../components/doctor/booking/steps-create/step5/step5.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CarUtil } from '../utils/cms/car.util';
import { BrandUtil } from '../utils/cms/brand.util';
import { FuelUtil } from '../utils/cms/fuel.util';
import { BodystyleUtil } from '../utils/cms/bodystyle.util';
import { UtilityUtil } from '../utils/cms/utility.util';
import { AreaUtil } from '../utils/cms/area.util';
import { DriverUtil } from '../utils/cms/driver.util';
import { CancelReasonUtil } from '../utils/cms/cancel-reason.util';
import { GarageGroupUtil } from '../utils/cms/garage-group.util';
import { GarageUtil } from '../utils/cms/garage.util';
import { GarageOwnerUtil } from '../utils/cms/garage-owner.util';
import { GarageEmployeeUtil } from '../utils/cms/garage-employee.util';
import { GarageContractUtil } from '../utils/cms/garage-contract.util';
import { OperatorUtil } from '../utils/cms/operator.util';
import { ExpertUtil } from '../utils/cms/expert.util';
import { GarageDayOffUtil } from '../utils/cms/garage-day-off.util';
import { GaragePromoteUtil } from '../utils/cms/garage-promote.util';
import { CarInfoComponent } from '../components/doctor/booking/components/car-info/car-info.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { BookingUtil } from '../utils/booking/booking.util';
import { BookingUpgradeListComponent } from '../components/doctor/booking/booking-upgrade-list/booking-upgrade-list.component';
import { GaragesComponent } from '../components/doctor/booking/booking-upgrade-list/garages/garages.component';
import { PopupChangeProcessingStatusComponent } from '../components/doctor/booking/components/popup-change-processing-status/popup-change-processing-status.component';
import { BookingCancelListComponent } from '../components/doctor/booking/booking-cancel-list/booking-cancel-list.component';
import { GarageInfoComponent } from '../components/doctor/booking/components/gara-info/garage-info.component';
import { WaitingConfirmComponent } from '../components/doctor/booking/components/waiting-confirm/waiting-confirm.component';
import { DoneComponent } from '../components/doctor/booking/components/done/done.component';
import { BookingListPendingComponent } from 'src/app/components/doctor/booking/booking-list-pending/booking-list-pending.component';
// import { ImageDirective } from '../components/doctor/booking/directives/image.directive';
import { CancelConfirmationComponent } from '../components/doctor/booking/components/cancel-confirmation/cancel-confirmation.component';
import { Step3PaintSpaComponent } from '../components/doctor/booking/steps-create/step3-paint-spa/step3-paint-spa.component';
import { NotificationComponent } from '../components/doctor/booking/notification/notification.component';
import { CarStatusComponent } from '../components/doctor/booking/car-status/car-status.component';
import { AddEditCarStatusComponent } from 'src/app/components/doctor/booking/car-status/add-edit-car-status/add-edit-car-status.component';
// import {
// NotificationIndicatorComponent } from '../components/doctor/booking/components/notification-indicator/notification-indicator.component';
// import { TimeAgoPipe } from '../components/doctor/booking/pipes/time-ago.pipe';
// import { LazyScrollTriggerDirective } from '../components/doctor/booking/directives/lazy-scroll-trigger.directive';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { BookingDetailComponent } from '../components/doctor/booking/booking-detail/booking-detail.component';
import { PopupConfirmationComponent } from 'src/app/components/doctor/booking/components/popup-confirmation/popup-confirmation.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ApiInterceptor } from 'src/app/components/doctor/booking/interceptors/api.interceptor';
import { SnackBarComponent } from 'src/app/components/doctor/booking/components/snack-bar/snack-bar.component';
import { NotificationService } from 'src/app/components/doctor/booking/services/notification.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { PopupCancelBookingComponent } from '../components/doctor/booking/components/popup-cancel-booking/popup-cancel-booking.component';
import { ServiceDetailComponent } from 'src/app/components/doctor/booking/service-detail/service-detail.component';
import { BookingDetailService } from 'src/app/components/doctor/booking/services/booking-detail.service';
import { PopupChooseGarageComponent } from '../components/doctor/booking/components/popup-choose-garage/popup-choose-garage.component';
import { CarInfoViewComponent } from 'src/app/components/doctor/booking/components/car-info-view/car-info-view.component';
import { InspectionComponent } from 'src/app/components/doctor/booking/inspection/inspection.component';
import { InspectionDetailComponent } from 'src/app/components/doctor/booking/inspection-detail/inspection-detail.component';
import { ChatComponent } from 'src/app/components/doctor/booking/chat/chat.component';
import { ZegocloudCallComponent } from '../components/doctor/zegocloud/zegocloud-call/zegocloud-call.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfigurationComponent } from '../components/doctor/booking/configuration/configuration.component';

export function MultiTranslateHttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/', suffix: '.json' },
    { prefix: './assets/i18n/cms/', suffix: '.json' },
    { prefix: './assets/i18n/booking/', suffix: '.json' },
  ]);
}

@NgModule({
    declarations: [
        BookingListComponent,
        BookingCreateComponent,
        Step1Component,
        Step2Component,
        Step3Component,
        Step4Component,
        Step5Component,
        CarInfoComponent,
        GarageInfoComponent,
        WaitingConfirmComponent,
        DoneComponent,
        // ImageDirective,
        CancelConfirmationComponent,
        Step3PaintSpaComponent,
        NotificationComponent,
        // NotificationIndicatorComponent,
        // LazyScrollTriggerDirective,
        BookingUpgradeListComponent,
        GaragesComponent,
        PopupChangeProcessingStatusComponent,
        BookingCancelListComponent,
        // TimeAgoPipe,
        BookingDetailComponent,
        PopupConfirmationComponent,
        SnackBarComponent,
        PopupCancelBookingComponent,
        ServiceDetailComponent,
        PopupChooseGarageComponent,
        CarInfoViewComponent,
        InspectionComponent,
        InspectionDetailComponent,
        ChatComponent,
        ZegocloudCallComponent,
        ConfigurationComponent,
        CarStatusComponent,
        AddEditCarStatusComponent,
        BookingListPendingComponent
    ],
    imports: [
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: MultiTranslateHttpLoaderFactory,
                deps: [HttpClient],
            },
            isolate: true,
        }),
        QuillModule.forRoot(),
        BookingRoutingModule,
        MatExpansionModule,
        OverlayModule,
        PortalModule,
        MatSnackBarModule,
    ],
    entryComponents: [],
    providers: [
        BookingCreateService,
        NotificationService,
        BookingDetailService,
        CarUtil,
        BrandUtil,
        FuelUtil,
        BodystyleUtil,
        UtilityUtil,
        AreaUtil,
        DriverUtil,
        CancelReasonUtil,
        GarageGroupUtil,
        GarageUtil,
        GarageOwnerUtil,
        GarageEmployeeUtil,
        GarageContractUtil,
        OperatorUtil,
        ExpertUtil,
        GarageDayOffUtil,
        GaragePromoteUtil,
        BookingUtil,
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: {displayDefaultIndicatorType: false},
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true,
        },
        DecimalPipe,
        DatePipe,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        BookingListPendingComponent
    ]
})
export class BookingModule {}
