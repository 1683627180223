<ns-breadcrumb *ngIf="!isPopup"></ns-breadcrumb>
<div class="searchFormGroup">
  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="form">
    <div fxLayout="row wrap" class="row-wrap-padding">
      <ns-input
        [placeholder]="moduleName + '.table.header.customer_placeholder'"
        name="driver"
        formControlName="driver"
        fxLayout="column"
        fxFlex="25%"
        fxFlex.xs="100%"
        fxFlex.sm="50%"
        fxFlex.md="50%"
        fxFlex.lg="25%"
      >
      </ns-input>
      <ns-multi-select-autocomplete
        [multiple]="true"
        [options]="requestTicketStatusValues"
        [placeholder]="moduleName + '.table.header.status'"
        [label]="moduleName + '.table.header.request_ticket_status'"
        name="requestTicketStatus"
        formControlName="requestTicketStatus"
        fxLayout="column"
        fxFlex="25%"
        fxFlex.xs="100%"
        fxFlex.sm="50%"
        fxFlex.md="50%"
        fxFlex.lg="25%"
      >
      </ns-multi-select-autocomplete>
      <ns-multi-select-autocomplete
        [multiple]="true"
        [options]="serviceValues"
        [placeholder]="moduleName + '.table.header.service'"
        name="serviceValues"
        formControlName="serviceIds"
        fxLayout="column"
        fxFlex="25%"
        fxFlex.xs="100%"
        fxFlex.sm="50%"
        fxFlex.md="50%"
        fxFlex.lg="25%"
      >
      </ns-multi-select-autocomplete>
      <ns-range-date-picker
        name="timeBooking"
        formControlName="timeBooking"
        [label]="moduleName + '.table.header.time_booking'"
        [requiredToDate]="false"
        [requiredFromDate]="false"
        [placeholderFromDate]="'fromDate'"
        [placeholderToDate]="'toDate'"
        [fromDateControlName]="'startDate'"
        [toDateControlName]="'endDate'"
        fxLayout="column"
        fxFlex="25%"
        fxFlex.xs="100%"
        fxFlex.sm="50%"
        fxFlex.md="50%"
        fxFlex.lg="25%"
      >
      </ns-range-date-picker>

      <ng-container *ngIf="isAdvancedSearch">
        <ns-multi-select-autocomplete
          [multiple]="true"
          [options]="garageValues"
          [placeholder]="moduleName + '.table.header.garage'"
          name="garageValues"
          formControlName="garageIds"
          fxLayout="column"
          fxFlex="25%"
          fxFlex.xs="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="25%"
        >
        </ns-multi-select-autocomplete>
        <ns-multi-select-autocomplete
          [multiple]="true"
          [options]="serviceTicketStatusValues"
          [placeholder]="moduleName + '.table.header.status'"
          [label]="moduleName + '.table.header.service_ticket_status'"
          name="serviceTicketStatusValues"
          formControlName="serviceTicketStatus"
          fxLayout="column"
          fxFlex="25%"
          fxFlex.xs="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="25%"
        >
        </ns-multi-select-autocomplete>
        <ns-multi-select-autocomplete
          [multiple]="true"
          [options]="expertValues"
          [placeholder]="moduleName + '.table.header.expert'"
          name="expertValues"
          formControlName="expertIds"
          fxLayout="column"
          fxFlex="25%"
          fxFlex.xs="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="25%"
        >
        </ns-multi-select-autocomplete>
        <ns-multi-select-autocomplete
          [multiple]="true"
          [options]="supporterValues"
          [placeholder]="moduleName + '.table.header.operator'"
          name="supporterValues"
          formControlName="supporterIds"
          fxLayout="column"
          fxFlex="25%"
          fxFlex.xs="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="25%"
        >
        </ns-multi-select-autocomplete>
        <ns-input
          [placeholder]="moduleName + '.table.header.car_license_plate'"
          name="carLicensePlate"
          formControlName="carLicensePlate"
          fxLayout="column"
          fxFlex="25%"
          fxFlex.xs="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="25%"
        >
        </ns-input>
        <ns-multi-select-autocomplete
          [multiple]="true"
          [options]="brandValues"
          [placeholder]="moduleName + '.table.header.car_brand'"
          name="brandValues"
          formControlName="carBrandIds"
          fxLayout="column"
          fxFlex="25%"
          fxFlex.xs="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="25%"
        >
        </ns-multi-select-autocomplete>
        <ns-input
          [type]="'number'"
          [label]="moduleName + '.table.header.ticket_id'"
          [placeholder]="moduleName + '.table.header.ticket_id_placeholder'"
          name="ticketId"
          formControlName="ticketId"
          fxLayout="column"
          fxFlex="25%"
          fxFlex.xs="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="25%"
        >
        </ns-input>
        <ns-multi-select-autocomplete
          [multiple]="true"
          [options]="surveyStatusValues"
          [placeholder]="moduleName + '.table.header.status'"
          [label]="moduleName + '.table.header.survey_status'"
          name="surveyStatus"
          formControlName="surveyStatus"
          fxLayout="column"
          fxFlex="25%"
          fxFlex.xs="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="25%"
        >
        </ns-multi-select-autocomplete>
      </ng-container>
    </div>

    <div fxLayout="row wrap" class="row-wrap-padding">
      <div fxLayout="column" fxFlex="100%" fxLayoutAlign="center center" class="advanced-search">
        <span ngClass="{{ isAdvancedSearch ? 'hide_advance' : 'show_advance' }}" (click)="toggleAdvancedSearch()"
          >{{ (isAdvancedSearch ? 'common.advanced.search.collapse' : 'common.advanced.search.expand') | translate }}
          <i ngClass="{{ isAdvancedSearch ? 'fa fa-chevron-up' : 'fa fa-chevron-down' }}"></i
        ></span>
      </div>
    </div>
    <div class="buttonArea">
      <button mat-button type="submit" class="primary Update-btn" [disabled]="searchForm.invalid">
        {{ 'btnSearch' | translate }}
      </button>
      <button mat-button type="button" class="secondary outline reset-btn" (click)="onResetForm()">
        {{ 'btnReset' | translate }}
      </button>
    </div>
  </form>
  <div class="result-area margin-bottom-20" fxLayout fxLayoutAlign="space-between center">
    <h3 class="margin-0">{{ paging.totalElements }} {{ ' ' + ('common.results' | translate) }}</h3>
    <div class="button-area" fxLayout>
      <button mat-button class="primary reset-btn" (click)="createNewBooking(null)">
        <i class="fa fa-plus" aria-hidden="true"></i> {{ 'btnAdd' | translate }}
      </button>
    </div>
  </div>
  <div class="acbox">
    <ns-table
      [columns]="columns"
      [buttons]="buttons"
      [paging]="paging"
      style="position: relative"
      [moduleName]="moduleName"
      [results]="results"
      [expandHeaderButton]="expandHeaderButton"
      (pagingChange)="pagingChange($event)"
      (clickAction)="onRowButtonClick($event)"
    >
    </ns-table>
  </div>
</div>
