export interface PostUserLoginRequest {
  device_token: string;
  platform: string;
  voip?: string;
}

export interface PostUserLogoutRequest {
  device_token: string;
  platform: string;
  voip?: string;
  userId: number;
  userType: string;
}

export enum MessageType {
  EXPERT_CREATE_APPOINTMENT = 'EXPERT_CREATE_APPOINTMENT',
  DRIVER_CREATE_APPOINTMENT = 'DRIVER_CREATE_APPOINTMENT',
  DRIVER_CANCELED_APPOINTMENT = 'DRIVER_CANCELED_APPOINTMENT',
  DRIVER_CHECK_IN = 'DRIVER_CHECK_IN',
  DRIVER_UPDATE_APPOINTMENT = 'DRIVER_UPDATE_APPOINTMENT',
  DRIVER_REMIND_APPOINTMENT = 'DRIVER_REMIND_APPOINTMENT',
  DRIVER_GARAGE_SEND_INSPECTION_REPORT = 'DRIVER_GARAGE_SEND_INSPECTION_REPORT',
  DRIVER_GARAGE_SEND_PRICE_QUOTATION = 'DRIVER_GARAGE_SEND_PRICE_QUOTATION',
  DRIVER_APPROVE_NEW_QUOTATION = 'DRIVER_APPROVE_NEW_QUOTATION',
  DRIVER_GARAGE_HANDED_OVER_CAR = 'DRIVER_GARAGE_HANDED_OVER_CAR',
  DRIVER_GARAGE_DONE_FIXED = 'DRIVER_GARAGE_DONE_FIXED',
  DRIVER_CONFIRM_PAYMENT = 'DRIVER_CONFIRM_PAYMENT',
  DRIVER_EXPERT_ASSIGN_ANOTHER_GARAGE = 'DRIVER_EXPERT_ASSIGN_ANOTHER_GARAGE',
  DRIVER_EXPERT_UPDATE_APPOINTMENT = 'DRIVER_EXPERT_UPDATE_APPOINTMENT',
  DRIVER_APPOINTMENT_NEW_COMMENT = 'DRIVER_APPOINTMENT_NEW_COMMENT',
  GARAGE_NEW_APPOINTMENT = 'GARAGE_NEW_APPOINTMENT',
  GARAGE_NEW_APPOINTMENT_BOOKING_SUCCESSFULLY = 'GARAGE_NEW_APPOINTMENT_BOOKING_SUCCESSFULLY',
  GARAGE_DRIVER_CANCELED_APPOINTMENT = 'GARAGE_DRIVER_CANCELED_APPOINTMENT',
  GARAGE_UPDATE_APPOINTMENT = 'GARAGE_UPDATE_APPOINTMENT',
  GARAGE_DRIVER_APPROVE_QUOTATION = 'GARAGE_DRIVER_APPROVE_QUOTATION',
  GARAGE_DRIVER_REJECT_QUOTATION = 'GARAGE_DRIVER_REJECT_QUOTATION',
  GARAGE_UPDATE_QUOTATION = 'GARAGE_UPDATE_QUOTATION',
  GARAGE_CONFIRM_PAYMENT = 'GARAGE_CONFIRM_PAYMENT',
  GARAGE_HANDED_OVER_CAR = 'GARAGE_HANDED_OVER_CAR',
  GARAGE_EXPERT_ASSIGN_ANOTHER_GARAGE = 'GARAGE_EXPERT_ASSIGN_ANOTHER_GARAGE',
  GARAGE_EXPERT_UPDATE_APPOINTMENT = 'GARAGE_EXPERT_UPDATE_APPOINTMENT',
  EXPERT_NEW_APPOINTMENT = 'EXPERT_NEW_APPOINTMENT',
  EXPERT_DRIVER_CANCELED_APPOINTMENT = 'EXPERT_DRIVER_CANCELED_APPOINTMENT',
  EXPERT_GARAGE_HANDED_OVER_CAR = 'EXPERT_GARAGE_HANDED_OVER_CAR',
  EXPERT_ASSIGN_ANOTHER_GARAGE = 'EXPERT_ASSIGN_ANOTHER_GARAGE',
  EXPERT_UPDATE_APPOINTMENT = 'EXPERT_UPDATE_APPOINTMENT',
  EXPERT_GARAGE_REJECT_APPOINTMENT = 'EXPERT_GARAGE_REJECT_APPOINTMENT',
  EXPERT_ALL_GARAGE_REJECT_APPOINTMENT = 'EXPERT_ALL_GARAGE_REJECT_APPOINTMENT',
  SYSTEM_REMIND = 'SYSTEM_REMIND',
  DRIVER_CONFIRM_RECEIVED_CAR = 'DRIVER_CONFIRM_RECEIVED_CAR',
  PEER_CHAT = 'PEER_CHAT',
  ROOM_CHAT = 'ROOM_CHAT',
}

export interface FirebaseMessage {
  data?: {
    appointmentId: number;
    createdDate?: string;
    subject?: string;
    detail?: string;
    type: MessageType,
    data?: string;
  }
  title?: string;
  body?: string;
  type: MessageType;
}

export interface MfsFirebaseMessage {
  from: string;
  messageId: string;
  notification?: {
    title: string;
    body: string;
  },
  data: {
    payload: string;
    type: MessageType;
    appointmentId: number;
    notificationId: number;
    createdDate: string;
    sender: number;
    data?: string;
    subject?: string;
    content?: string;
  }
}
