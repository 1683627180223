import { finalize } from 'rxjs';
import { ApiService, Page, UtilsService } from '@next-solutions/next-solutions-base';
import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { SuperStatusEnum } from 'src/app/enums/super.status.enum';
import { EnumUtil } from 'src/app/utils/enum.util';
import { environment } from 'src/environments/environment';
import { ModuleConst } from 'src/app/modules/module.const';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CancelReasonModel } from 'src/app/models/cms/cancel-reason.model';
import { FormBuilder } from '@angular/forms';

interface DialogData {
  type: boolean;
  id: number;
}

enum APPLY_FOR {
  SERVICE = 'SERVICE',
  REQUEST = 'REQUEST',
}

@Component({
  selector: 'app-popup-cancel-booking',
  templateUrl: './popup-cancel-booking.component.html',
  styleUrls: ['./popup-cancel-booking.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PopupCancelBookingComponent implements OnInit {
  moduleName = 'booking';
  cancelReasons: CancelReasonModel[] = [];
  cancelForm = this._fb.group({
    otherReason: [''],
  });

  selectedReason: CancelReasonModel | null = null;

  constructor(
    public apiService: ApiService,
    public utilsService: UtilsService,
    private _fb: FormBuilder,
    @Optional() public matDialogRef: MatDialogRef<boolean>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    const params = new HttpParams()
      .set('status', EnumUtil.getKeysByValues(SuperStatusEnum, [SuperStatusEnum._ACCEPTED]))
      .set('pageNumber', '1')
      .set('pageSize', environment.INTEGER_MAX_VALUE)
      .set('applyFor', this.data.type ? APPLY_FOR.SERVICE : APPLY_FOR.REQUEST);

    this.apiService.get<Page>(ModuleConst.CMS + '/cancelreason', params).subscribe((cancelReasons: any) => {
      if (cancelReasons && cancelReasons.hasOwnProperty('content')) {
        this.cancelReasons = (cancelReasons as Page).content;
      }
    });
  }

  chooseCancelReason(id: number) {
    this.selectedReason = this.cancelReasons[id];
  }

  changeOtherReason() {
    this.selectedReason = null;
  }

  cancelBooking() {
    const params = {
      reason: this.selectedReason?.reason ?? this.cancelForm.get('otherReason')?.value,
    };

    this.apiService
      .post(`${ModuleConst.BOOKING}/expert/appointment-schedule/${this.data.id}/canceled`, params)
      .pipe(
        finalize(() => {
          this.matDialogRef.close({ action: 'getBookingById' });
        }),
      )
      .subscribe((res: any) => {
        this.utilsService.onSuccessFunc(res?.message ?? 'common.default.success');
      });
  }

  selectGarage() {
    this.matDialogRef.close({ action: 'selectGarage' });
  }
}
