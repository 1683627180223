<div class="customer-info-selector" fxLayout="column">
  <div class="padding-vertical-15 padding-horizontal-20 border-bottom" fxLayout fxLayoutAlign="space-between center">
    <span class="step-label">{{'booking.stepper.step2' | translate}}</span>
    <div fxLayout="row">
      <button mat-button class="primary outline" (click)="createDriver()">
        <i class="fa fa-plus"></i> {{'booking.step2.new_customer' | translate}}
      </button>
      <button mat-button class="primary" (click)="nextStep()" [disabled]="!driverInfo || form.invalid || !selectedCar">
        {{'booking.step2.next' | translate}}
      </button>
    </div>
  </div>
  <form [formGroup]="form" class="padding-20" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center flex-start" >
    <div fxLayout="column" fxFlex="100%" fxFlex.md="100%" fxFlex.lg="50%" ngClass.lt-lg="full-width"
         class="padding-horizontal-20 padding-top-10 customer-info">
      <ns-input [placeholder]="'booking.step2.phone_number_placeholder' | translate"
                [label]="'booking.step2.phone_number' | translate"
                (keyup)="onInput($event)"
                [maxLength]="10"
                [patternFilter]="'^[0-9]'"
                [required]="true"
                [errorMessages]="inputErrorMsg"
                formControlName="phone"
                fxLayout='column'
                fxFlex='100%'>
      </ns-input>

      <!-- Thong tin khach hang -->
      <div fxLayout="column" class="margin-bottom-15" *ngIf="driverInfo">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-10">
          <span class="label--title">{{'booking.step2.customer_info' | translate}}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
          <span class="label">{{'booking.step2.customer_info.name' | translate}}</span>
          <span class="value">{{ driverInfo.name }}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
          <span class="label">{{'booking.step2.customer_info.phone' | translate}}</span>
          <span class="value">{{ driverInfo.phone }}</span>
        </div>
        <div *ngIf="buildAddress()" fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
          <span class="label">{{'booking.step2.customer_info.address' | translate}}</span>
          <span class="value">{{ buildAddress() }}</span>
        </div>
      </div>

      <!-- Chon xe -->
      <div fxLayout="column" class="margin-bottom-14" *ngIf="driverInfo">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-15">
          <span class="label--title">{{'booking.step2.select_car' | translate}}</span>
          <span class="label--small" *ngIf="!isEmptyCar" (click)="newCar()">{{'booking.step2.new_car' | translate}}</span>
        </div>
        <div fxLayout="column" class=car-infos>
          <div fxLayout="row" class="margin-bottom-10 car-info"
               (click)="selectCar(car)"
               [ngClass]="{'selected': selectedCar?.id === car.id}"
               *ngFor="let car of driverInfo.driverCars">
            <div class="car-info__image" image [imageSrc]="car.carImg"></div>
            <div fxLayout="column">
              <span class="car-info__bks">{{ car.licensePlate }}</span>
              <span class="car-info__last" *ngIf="!!car.numOfEdits"><span class="ic ic-setting"></span> {{'booking.step2.car.last_fix' | translate}}
                {{ car.lastModDate || 'N/A' }} {{ ' - ' + car.garageName + ' ' + car.garageAddress}}</span>
              <span class="car-info__num"><span class="ic ic-fix"></span> {{'booking.step2.car.fix_number' | translate}} {{ car.numOfEdits || 0 }}</span>
              <span class="car-info__num"><span class="ic ic-fix"></span> {{'booking.step2.car.km_number' | translate}} {{ car.carKm || 'N/A' }} {{ ' km' }}</span>
            </div>
          </div>
        </div>
        <div class="empty-car" *ngIf="isEmptyCar" fxLayoutAlign="center center" fxLayout="column">
          <small class="text-error">{{'booking.step2.driver.empty.car' | translate}}</small>
          <div class="empty-car__img margin-vertical-20"></div>
          <button mat-button class="primary" (click)="newCar()">
            {{'booking.step2.new_car' | translate}}
          </button>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="100%" fxFlex.md="100%" fxFlex.lg="50%" ngClass.lt-lg="full-width" class="padding-horizontal-20" [ngClass]="{ 'invisible': !selectedCar }">
      <app-doctor-booking-car-info></app-doctor-booking-car-info>
    </div>
  </form>
</div>
