<div class="garage-selector" fxLayout="column">
  <div *ngIf="(currentPage | async) === StepPages.VIEW_LIST || (currentPage | async) === StepPages.VIEW_DETAIL"
       class="padding-vertical-15 padding-horizontal-20 border-bottom"
       fxLayout fxLayoutAlign="space-between center">
    <span class="step-label">{{'booking.stepper.step5' | translate}}</span>
    <div fxLayout="row">
      <button mat-button class="primary outline" (click)="prevStep()">
        {{'booking.step5.back' | translate}}
      </button>
      <button mat-button class="primary" (click)="nextStep()" [disabled]="!selectedGarage">
        {{'booking.step5.next' | translate}}
      </button>
      <button mat-button class="primary" (click)="assignGarage()" [disabled]="!selectedGarage">
        {{'booking.step5.assign.garage' | translate}}
      </button>
    </div>
  </div>
  <form *ngIf="(currentPage | async) === StepPages.VIEW_LIST" class="padding-20" fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="100%" fxFlex.md="80%" class="garages" *ngIf="garages && garages.length > 0">
      <div *ngFor="let garage of garages" [ngClass]="{'active' : selectedGarage?.id === garage.id}"
           (click)="selectGarage(garage)"
           class="garage padding-vertical-15 padding-horizontal-20" fxFlex="100%" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="16px" fxLayout="row">
        <div class="garage__image margin-right-15" image [imageSrc]="garage?.avatar"></div>
        <div class="garage__info" fxLayout="row" fxLayout.lt-sm="column">
          <div class="padding-right-15 padding-bottom-15" fxLayout="column" fxLayoutAlign="flex-start">
            <div class="garage__name margin-bottom-10" (click)="viewGarage(garage)">{{ garage.name }}</div>
            <div class="garage__address margin-bottom-5"><i class="fa fa-map-marker-alt"></i> {{ garage.address }}</div>
            <div class="garage__phone"><i class="fa fa-phone-alt"></i> {{garage.contactPointPhone}}</div>
          </div>
          <div class="garage__price" fxLayout="column" fxLayoutAlign="center flex-start">
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!garages || !garages.length" class="padding-20" fxLayout="row" fxLayoutAlign="center center">
      {{'booking.step5.no-garages' | translate}}
    </div>
  </form>
  <ng-container *ngIf="(currentPage | async) === StepPages.VIEW_DETAIL">
    <div fxFlex="100%" fxFlex.sm="100%" fxFlex.md="50%" fxFlex.lg="25%" class="padding-vertical-15 padding-horizontal-15">
      <button mat-button class="primary outline" (click)="viewList()">
        {{'booking.step5.back_garages' | translate}}
      </button>
    </div>
    <app-doctor-booking-garage-info [garageId]="viewingGarageId"></app-doctor-booking-garage-info>
  </ng-container>
  <ng-container *ngIf="(currentPage | async) === StepPages.WAITING">
    <app-doctor-booking-waiting-confirm (cancel)="viewList()"></app-doctor-booking-waiting-confirm>
  </ng-container>
  <ng-container *ngIf="(currentPage | async) === StepPages.DONE">
    <app-doctor-booking-done (done)="done()" [data]="bookingDoneData"></app-doctor-booking-done>
  </ng-container>
</div>
