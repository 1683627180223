<div fxFlex="100%" fxLayout="row wrap" class="full-width" *ngIf="garage">
  <div fxFlex="50%" fxFlex.lt-md="100%" fxLayout="column" class="padding-15 margin-bottom-20">
    <!--  Thong tin garage  -->
    <div class="margin-bottom-20">
      <div fxLayout="row" fxLayoutAlign="space-between start" class="margin-bottom-20">
        <span class="label">{{'booking.garage.detail' | translate}}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between start" class="margin-bottom-10">
        <span class="label">{{'booking.garage.detail.name' | translate}}</span>
        <span class="value">{{ garage.name }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between start" class="margin-bottom-10">
        <span class="label">{{'booking.garage.detail.phone' | translate}}</span>
        <span class="value">{{ garage.contactPointPhone }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between start" class="margin-bottom-10">
        <span class="label">{{'booking.garage.detail.address' | translate}}</span>
        <span class="value">{{ buildAddress() }}</span>
      </div>
    </div>
    <div *ngIf="garage.description?.length" class="margin-bottom-20">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-20">
        <span class="label">{{'booking.garage.detail.description' | translate}}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-10">
        <span class="value--description" [innerHTML]="garage.description || ''"></span>
      </div>
    </div>
    <div class="margin-bottom-20">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-20">
        <span class="label">{{'booking.garage.detail.service' | translate}}</span>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="space-between" class="margin-bottom-10">
        <div *ngFor="let service of services"
             fxFlex="50%" fxFlex.md="33.33333333333%" class="service-group padding-right-10">
          <span class="title title--{{service?.key}}">{{('booking.garage.service_name.' + service?.key || '') | translate}}</span>
          <ul class="service-items padding-left-20 margin-top-5">
            <li *ngFor="let sub of service?.data" class="service-items__name">{{ sub.name }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex="50%" fxFlex.lt-md="100%" fxLayout="column" class="padding-15 margin-bottom-20">
    <div class="garage-image margin-bottom-20" image [imageSrc]="garage.avatar"></div>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-15">
      <span class="label">{{'booking.garage.detail.utilities' | translate}}</span>
    </div>
    <div fxLayout="column" fxLayoutGap="10px" class="utilities">
      <ul class="service-items padding-left-20 margin-top-5">
        <li class="service-items__name" *ngFor="let util of garage.utilities">{{ util.name }}</li>
      </ul>
    </div>
  </div>
</div>
