import { Injectable } from '@angular/core';
import { ApiService, AuthoritiesService, UtilsService } from '@next-solutions/next-solutions-base';
import { TranslateService } from '@ngx-translate/core';
import { GarageContractFileModel } from '../../models/cms/garage-contract-file.model';

@Injectable({ providedIn: 'root' })
export class GarageContractUtil {
  constructor(protected authoritiesService: AuthoritiesService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected translateService: TranslateService) {
  }

  hasAddEditPermission(contractFile: GarageContractFileModel | null): boolean {
    if (contractFile && contractFile.id) {
      return this.authoritiesService.hasAuthority('patch/contractfile/{id}');
    } else {
      return this.authoritiesService.hasAuthority('post/contractfile');
    }
  }
}
