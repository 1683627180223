import { catchError } from 'rxjs/operators';
import { zip, throwError } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import {
  ApiService,
  AuthoritiesService,
  BaseSearchLayout,
  FormStateService,
  UploadModel,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import { ZEGO_CONVERSATION_TYPE, ZEGO_VIDEO_RESOLUTION } from 'src/app/models/zegocloud/zego.model';
import { ModuleConst } from 'src/app/modules/module.const';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BookingModel } from 'src/app/models/booking/booking.model';
import { environment } from 'src/environments/environment';
import { BookingUtil } from 'src/app/utils/booking/booking.util';

export enum CALLER_STATUS {
  AGREE = 'AGREE',
  REJECT = 'REJECT',
}

@Component({
  selector: 'app-zegocloud-call',
  templateUrl: './zegocloud-call.component.html',
  styleUrls: ['./zegocloud-call.component.scss'],
})
export class ZegocloudCallComponent extends BaseSearchLayout implements OnInit {
  appID!: string;
  convId!: string;
  convType!: ZEGO_CONVERSATION_TYPE;
  driverId!: number | string;
  garageId!: number | string;
  requestTicketId!: number | string;
  driverPhone!: number | string;
  driverName!: string;
  driverAvatar: string = '';
  garageName!: string;
  expertName!: string;

  userId!: number | string;

  @ViewChild('zegoCloud') zegoCloud!: ElementRef;

  constructor(
    protected apiService: ApiService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected utilsService: UtilsService,
    protected uiStateService: FormStateService,
    protected translateService: TranslateService,
    protected injector: Injector,
    protected activatedRoute: ActivatedRoute,
    protected authoritiesService: AuthoritiesService,
    protected bookingUtil: BookingUtil,
  ) {
    super(
      router,
      apiService,
      utilsService,
      uiStateService,
      translateService,
      injector,
      activatedRoute,
      authoritiesService,
      formBuilder.group({}),
    );
  }

  // step0: Check status of receiver
  // step1: Get zego configuration (appID)
  // step2: Get token via BE
  // step3: get conversation information
  // step4: Create room
  // step5: Push notification to BE with roomID
  ngOnInit(): void {
    this.getConversationRequestParams();
  }

  getConversationRequestParams() {
    this.requestTicketId = this.route.snapshot.paramMap.get('ticketId') || '';
    this.convType = this.route.snapshot.queryParamMap.get('type') as ZEGO_CONVERSATION_TYPE;

    this.apiService
      .get(`${ModuleConst.BOOKING}/expert/appointment-schedule/${this.requestTicketId}`, new HttpParams())
      .subscribe((booking) => {
        this.driverId = (booking as BookingModel).driverId;
        this.userId = 'CarDoctor' + (booking as BookingModel).expertRefId + 'EXPERT';
        this.driverPhone = (booking as BookingModel).driverPhone;

        const currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '');
        currentUser && ((booking as BookingModel).expertRefId = currentUser.systemRefUserId);
        this.getReciverStatus();
      });
  }

  // Get status of receiver(currently: role driver) to make a call
  getReciverStatus() {
    if (this.driverPhone) {
      this.apiService
        .post(`${ModuleConst.BOOKING}/expert/get-available-status/${this.driverPhone}`, new HttpParams())
        .subscribe((response: any) => {
          if (response.driverAvatar) {
            const type = UploadModel.getFileType(response.driverAvatar);
            const url = environment.BASE_URL + '/' + response.driverAvatar;
            this.driverAvatar = `${new UploadModel('', null, url, type, +this.driverId)}`;
          }

          zip([this.getZegoConfiguration(), this.generateToken(), this.getConversationInformation()])
            .pipe(
              catchError((error) => {
                this.utilsService.showError(error);
                return throwError(() => error);
              }),
            )
            .subscribe(([config, getToken, conversation]: [any, any, any]) => {
              this.appID = config.appId;
              this.driverName = conversation.driverName;
              this.convId = conversation.convId;
              this.expertName = conversation.expertName;
              const token = getToken.find((item: any) => item.type === 'ZEGO').token;
              this.renderZegoCloud(token);
            });
        });
    }
  }

  // Generate token to init zego call
  generateToken() {
    return this.apiService.get(`${ModuleConst.BOOKING}/external-token/get-token`, new HttpParams());
  }

  // Get Zego information
  getZegoConfiguration() {
    return this.apiService.get(`${ModuleConst.BOOKING}/zegocloud-basic-configuration/1`, new HttpParams());
  }

  // Get Conversation information
  getConversationInformation() {
    const params = {
      convType: this.convType,
      driverId: this.driverId,
    };

    return this.apiService.post(`${ModuleConst.BOOKING}/conversation`, params);
  }

  // Create instance object from Kit Token
  renderZegoCloud(token: any) {
    try {
      // @ts-ignore:next-line
      const kitToken = ZegoUIKitPrebuilt.generateKitTokenForProduction(
        this.appID,
        `${token}`,
        `${this.convId}`,
        `${this.userId}`,
        `${this.expertName}`,
      );

      // @ts-ignore:next-line
      const zego = ZegoUIKitPrebuilt.create(kitToken);

      // Start a call
      zego.joinRoom({
        container: this.zegoCloud.nativeElement,
        scenario: {
          mode: this.convType,
        },
        turnOnCameraWhenJoining: this.convType === ZEGO_CONVERSATION_TYPE.VIDEO_CALL,
        showMyCameraToggleButton: this.convType === ZEGO_CONVERSATION_TYPE.VIDEO_CALL,
        showScreenSharingButton: false,
        showTextChat: false,
        showUserList: false,
        showPinButton: false,
        videoResolutionList: [
          ZEGO_VIDEO_RESOLUTION._180P,
          ZEGO_VIDEO_RESOLUTION._360P,
          ZEGO_VIDEO_RESOLUTION._480P,
          ZEGO_VIDEO_RESOLUTION._720P,
        ],
        videoResolutionDefault: ZEGO_VIDEO_RESOLUTION._720P,
        onJoinRoom: () => this.pushCallNotification(),
        onLeaveRoom: () => this.endCall(),
        onUserLeave: () => this.endCall(),
        onUserAvatarSetter: (userList: any) => {
          userList.forEach((user: any) => {
            if (user.userID == this.userId) user.setUserAvatar('/assets/icons/booking/default-caller-avatar.svg');
            else if (user.userID == this.driverId) user.setUserAvatar(this.driverAvatar);
          });
        },
      });
    } catch (error) {
      this.utilsService.showError(error as string);
    }
  }

  // Call when make a call (support push notification on app driver to join call on background in app)
  pushCallNotification() {
    const params = {
      type: this.convType,
      uuid: this.convId,
    };
    this.apiService.post(`${ModuleConst.BOOKING}/notification/push-call`, params).subscribe((response: any) => {});
  }

  endCall() {
    window.close();
  }
}
