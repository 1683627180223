import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/components/doctor/booking/components/snack-bar/snack-bar.component';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private _snackBar: MatSnackBar,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        console.log('@== err', err);
        const data = {
          message: typeof err === 'string' ? err : err.message,
        };
        // this.openSnackBar(data);
        return throwError(err);
      }),
    );
  }

  openSnackBar(data: { message?: string, name?: string }) {
    this._snackBar.openFromComponent(SnackBarComponent, {
      duration: 3000,
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
      data,
    });
  }
}
