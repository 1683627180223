import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthoritiesResolverService } from '@next-solutions/next-solutions-base';
import { ActionTypeEnum } from '../enums/action.type.enum';
import { BookingListComponent } from '../components/doctor/booking/booking-list/booking-list.component';
import { BookingCreateComponent } from '../components/doctor/booking/booking-create/booking-create.component';
import { BookingUpgradeListComponent } from '../components/doctor/booking/booking-upgrade-list/booking-upgrade-list.component';
import { BookingCancelListComponent } from '../components/doctor/booking/booking-cancel-list/booking-cancel-list.component';
import { BookingDetailComponent } from '../components/doctor/booking/booking-detail/booking-detail.component';
import { NotificationComponent } from 'src/app/components/doctor/booking/notification/notification.component';
import { InspectionComponent } from 'src/app/components/doctor/booking/inspection/inspection.component';
import { InspectionDetailComponent } from 'src/app/components/doctor/booking/inspection-detail/inspection-detail.component';
import { ChatComponent } from 'src/app/components/doctor/booking/chat/chat.component';
import { ZegocloudCallComponent } from '../components/doctor/zegocloud/zegocloud-call/zegocloud-call.component';
import { ConfigurationComponent } from '../components/doctor/booking/configuration/configuration.component';
import { CarStatusComponent } from 'src/app/components/doctor/booking/car-status/car-status.component';
import {
  AddEditCarStatusComponent
} from 'src/app/components/doctor/booking/car-status/add-edit-car-status/add-edit-car-status.component';
import {
  BookingListPendingComponent
} from 'src/app/components/doctor/booking/booking-list-pending/booking-list-pending.component';

const routes: Routes = [
  {
    path: 'list',
    data: { breadcrumb: 'menu.booking.list' },
    component: BookingListComponent,
    resolve: { me: AuthoritiesResolverService },
  },
  {
    path: 'list-pending',
    data: { breadcrumb: 'menu.booking.list.pending' },
    component: BookingListPendingComponent,
    resolve: { me: AuthoritiesResolverService },
  },
  {
    path: 'upgrades',
    component: BookingUpgradeListComponent,
    data: { breadcrumb: 'menu.booking.upgrade', actionType: ActionTypeEnum._VIEW },
    resolve: { me: AuthoritiesResolverService },
  },
  {
    path: 'cancel',
    component: BookingCancelListComponent,
    data: { breadcrumb: 'menu.booking.cancel', actionType: ActionTypeEnum._VIEW },
    resolve: { me: AuthoritiesResolverService },
  },
  {
    path: 'detail/:id',
    component: BookingDetailComponent,
    data: { breadcrumb: 'menu.booking.detail', actionType: ActionTypeEnum._VIEW },
    resolve: { me: AuthoritiesResolverService },
  },
  {
    path: 'add',
    component: BookingCreateComponent,
    data: { breadcrumb: 'menu.booking.add', actionType: ActionTypeEnum._ADD },
    resolve: { me: AuthoritiesResolverService },
  },
  {
    path: 'edit/:id',
    component: BookingDetailComponent,
    data: { breadcrumb: 'menu.booking.edit', actionType: ActionTypeEnum._EDIT },
    resolve: { me: AuthoritiesResolverService },
  },
  {
    path: 'configuration',
    component: ConfigurationComponent,
    data: { breadcrumb: 'menu.booking.configuration', actionType: ActionTypeEnum._VIEW },
    resolve: { me: AuthoritiesResolverService },
  },
  {
    path: 'notification',
    component: NotificationComponent,
    data: { breadcrumb: 'menu.booking.notification', actionType: ActionTypeEnum._VIEW },
    resolve: { me: AuthoritiesResolverService },
  },
  {
    path: 'inspection',
    component: InspectionComponent,
    data: { breadcrumb: 'menu.booking.inspection', actionType: ActionTypeEnum._VIEW },
    resolve: { me: AuthoritiesResolverService },
  },
  {
    path: 'inspection/:id',
    component: InspectionDetailComponent,
    data: { breadcrumb: 'menu.booking.inspection-detail', actionType: ActionTypeEnum._VIEW },
    resolve: { me: AuthoritiesResolverService },
  },
  {
    path: 'car-status',
    data: { breadcrumb: 'menu.booking.car_status.', actionType: ActionTypeEnum._VIEW },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: CarStatusComponent,
        resolve: { me: AuthoritiesResolverService },
      },
      {
        path: 'add',
        component: AddEditCarStatusComponent,
        data: { breadcrumb: 'menu.booking.car_status.add', actionType: ActionTypeEnum._ADD },
        resolve: { me: AuthoritiesResolverService },
      },
      {
        path: 'edit/:id',
        component: AddEditCarStatusComponent,
        data: { breadcrumb: 'menu.booking.car_status.edit', actionType: ActionTypeEnum._EDIT },
        resolve: { me: AuthoritiesResolverService },
      },
      {
        path: 'detail/:id',
        component: AddEditCarStatusComponent,
        data: { breadcrumb: 'menu.booking.car_status.detail', actionType: ActionTypeEnum._VIEW },
        resolve: { me: AuthoritiesResolverService },
      },
    ]
  },
  {
    path: 'chat',
    component: ChatComponent,
    data: { breadcrumb: 'menu.booking.chat', actionType: ActionTypeEnum._VIEW },
    resolve: { me: AuthoritiesResolverService },
  },
  {
    path: 'ipcc',
    children: [
      {
        path: 'call/:ticketId',
        pathMatch: 'full',
        component: ZegocloudCallComponent,
        resolve: { me: AuthoritiesResolverService },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BookingRoutingModule {}
