import {Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
  AlignEnum,
  ApiService,
  AuthoritiesService,
  BaseSearchLayout,
  ColumnFields,
  DateUtilService,
  FormStateService,
  Page,
  SelectModel,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import {HttpParams} from '@angular/common/http';
import {SuperStatusEnum} from '../../enums/super.status.enum';
import {EnumUtil} from '../../utils/enum.util';
import {MatDialog} from '@angular/material/dialog';
import {ModuleConst} from '../../modules/module.const';
import {environment} from '../../../environments/environment';
import {SosTrackingModel} from '../../models/sos-tracking.model';
import {GarageModel} from '../../models/cms/garage.model';
import {SosStatusEnum} from '../../enums/sos.status.enum';
import {OperatorModel} from '../../models/cms/operator.model';
import {DatePipe} from '@angular/common';
import {SosTrackingUtil} from "../../utils/sos-tracking.util";


@Component({
  selector: 'app-sos-tracking',
  templateUrl: './sos-tracking.component.html',
  styleUrls: ['./sos-tracking-support/sos-tracking-support.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SosTrackingComponent extends BaseSearchLayout implements OnInit {

  moduleName = 'sos-tracking';
  useDefault = true;
  columns: ColumnFields[] = [];
  statusValues: SelectModel[] = [];
  garageValues: SelectModel[] = [];
  operatorValues: SelectModel[] = [];
  paramsObject: any;

  get expandHeaderButton() {
    return environment.EXPAND_HEADER_BUTTON;
  }

  constructor(protected formBuilder: FormBuilder,
              protected router: Router,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected uiStateService: FormStateService,
              protected translateService: TranslateService,
              protected injector: Injector,
              protected dateUtilService: DateUtilService,
              protected activatedRoute: ActivatedRoute,
              public matDialog: MatDialog,
              protected authoritiesService: AuthoritiesService,
              protected datePipe: DatePipe,
              protected sosTrackingUtil: SosTrackingUtil) {
    super(router, apiService, utilsService, uiStateService, translateService, injector, activatedRoute, authoritiesService,
      formBuilder.group({
        customerName: [''],
        customerPhone: [''],
        logDate: [''],
        latitude: [''],
        longitude: [''],
        garageId: [''],
        operatorId: [''],
        note: [''],
        expertSupportTime: [''],
        expertSupport: [''],
        expectedTime: [''],
        operatorSupportTime: [''],
        operatorSupport: [''],
        amount: [''],
        status: ['_CUSTOMER_CLICK'],
        fromDate: [''],
        toDate: [''],
      }));
    this.paging.pageSize = 50;
    this.columns.push(
      {
        columnDef: 'stt', header: 'stt',
        title: (e: SosTrackingModel) => `${UtilsService.calcPosition(e, this.results, this.paging)}`,
        cell: (e: SosTrackingModel) => `${UtilsService.calcPosition(e, this.results, this.paging)}`,
        className: 'mat-column-stt',
        align: AlignEnum.CENTER,
      },
      ...sosTrackingUtil.getColumns()
    );
  }

  async ngOnInit() {
    this.activatedRoute.queryParamMap.
      subscribe((queryParam) => {
          this.paramsObject  = { ...queryParam };
          this.searchForm.get('customerPhone')?.setValue(`0${this.paramsObject.params.customerNumber.substring(2)}` + '');
    });
    EnumUtil.enum2SelectModel(SosStatusEnum, this.statusValues, 'SEARCH');
    const params = new HttpParams()
      .set('status', EnumUtil.getKeysByValues(SuperStatusEnum, [SuperStatusEnum._ACCEPTED]))
      .set('pageNumber', '1')
      .set('pageSize', environment.INTEGER_MAX_VALUE);
    Promise.all([
      this.apiService.get<Page>(ModuleConst.CMS + '/garage', params).toPromise(),
      this.apiService.get<Page>(ModuleConst.CMS + '/operator', params).toPromise(),
    ]).then(([pageGarage, pageOperator]) => {
      if (pageGarage && pageGarage.content?.length >= 0) {
        this.garageValues = pageGarage.content.map((garage: GarageModel) => new SelectModel(garage.id, garage.name));
      }
      if (pageOperator && pageOperator.content?.length >= 0) {
        this.operatorValues = pageOperator.content.map((operator: OperatorModel) => new SelectModel(operator.id, operator.name));
      }
    });
    super.ngOnInit();
    this.onSubmit();
  }

  search() {
    const params = this.collectParams();
    this._fillData(ModuleConst.CMS + '/sos-tracking/expert-search', params);
  }

  collectParams(): HttpParams {
    const map = Object.fromEntries(['customerName', 'customerPhone', 'garageId', 'operatorId', 'fromDate', 'toDate'].map(s => [s, s]));
    let params = this._collectParams(this.searchForm, map);
    params = params.set('status', `${this.searchForm.get('status')?.value}`.replace('_', ''));
    // if (!this.searchForm.get('fromDate')?.value) {
    //   const date = new Date();
    //   params = params.set('fromDate', `${this.datePipe.transform(date, 'yyyy-MM-dd')} 00:00:00.000Z`);
    // }
    if (!this.searchForm.get('fromDate')?.value) {
      params = params.set('fromDate', `2022-12-01 00:00:00.000Z`);
    }
    return params;
  }

  onResetForm() {
    this.searchForm.reset();
    this.searchForm.patchValue({
      customerName: '',
      customerPhone: '',
      logDate: '',
      garageId: '',
      operatorId: '',
      note: '',
      expertSupportTime: '',
      expertSupport: '',
      expectedTime: '',
      operatorSupportTime: '',
      operatorSupport: '',
      amount: '',
      status: '_',
    });
  }

  addOrEdit(row: SosTrackingModel) {
    this.router.navigate([this.router.url, 'edit', row.id]).then();
  }

  viewDetail(row: SosTrackingModel) {
    console.log(row);
    this.router.navigate([this.router.url, 'detail', row.id]).then();
  }

  confirmCustomer(row: SosTrackingModel) {
    this.sosTrackingUtil.onConfirmCustomer(row, this.onSuccessFunc);
  }

  garageReject(row: SosTrackingModel) {
    this.sosTrackingUtil.onGarageReject(row, this.onSuccessFunc);
  }

  customerCancel(row: SosTrackingModel) {
    this.sosTrackingUtil.onCustomerCancel(row, this.onSuccessFunc);
  }

}
