import {SuperEntity} from '@next-solutions/next-solutions-base';
import {FormGroup} from '@angular/forms';
import {GarageModel} from './cms/garage.model';
import {OperatorModel} from './cms/operator.model';

export class SosTrackingModel extends SuperEntity {
  customerName: string = '';
  customerPhone: string = '';
  logDate: string = ''
  latitude?: string = '';
  longitude?: string = '';
  garageId: number | null | undefined = null;
  operatorId: number | null | undefined = null;
  garage: GarageModel | undefined;
  operator: OperatorModel | undefined;
  customerLocation: string = '';
  note?: string = '';
  expertSupportTime?: string = '';
  expertSupport?: string = '';
  expectedTime?: string = '';
  operatorSupportTime?: string = '';
  operatorSupport?: string = '';
  amount?: string = '';
  overExpectedTime?: boolean;

  status: 'CUSTOMER_CLICK' | 'CUSTOMER_REQUESTED' | 'REQUESTED_GARAGE' | 'CONFIRM_CUSTOMER' | 'CUSTOMER_CANCEL' | 'GARAGE_REJECT' | 'CUSTOMER_CANNOT_GET_LOCATION' | string = '';

  minutes: number | null = null;

  constructor(form: FormGroup | number) {
    super();
    if (form instanceof FormGroup) {
      const fId = form.get('id');
      if (fId) {
        this.id = fId.value;
      }
      const fCustomerName = form.get('customerName');
      if (fCustomerName) {
        this.customerName = fCustomerName.value;
      }
      const fCustomerPhone = form.get('customerPhone');
      if (fCustomerPhone) {
        this.customerPhone = fCustomerPhone.value;
      }
      const fLogDate = form.get('logDate');
      if (fLogDate) {
        this.logDate = fLogDate.value;
      }
      const fLatitude = form.get('latitude');
      if (fLatitude) {
        this.latitude = fLatitude.value;
      }
      const fLongitude = form.get('longitude');
      if (fLongitude) {
        this.longitude = fLongitude.value;
      }
      const fGarageId = form.get('garageId');
      if (fGarageId) {
        this.garageId = fGarageId.value;
      }
      const fOperatorId = form.get('operatorId');
      if (fOperatorId) {
        this.operatorId = fOperatorId.value;
      }
      const fExpertSupportTime = form.get('expertSupportTime');
      if (fExpertSupportTime) {
        this.expertSupportTime = fExpertSupportTime.value;
      }
      const fExpertSupport = form.get('expertSupport');
      if (fExpertSupport) {
        this.expertSupport = fExpertSupport.value;
      }
      const fOperatorSupportTime = form.get('operatorSupportTime');
      if (fOperatorSupportTime) {
        this.operatorSupportTime = fOperatorSupportTime.value;
      }
      const fOperatorSupport = form.get('operatorSupport');
      if (fOperatorSupport) {
        this.operatorSupport = fOperatorSupport.value;
      }
      const fExpectedTime = form.get('expectedTime');
      if (fExpectedTime) {
        this.expectedTime = fExpectedTime.value;
      }
      const fAmount = form.get('amount');
      if (fAmount) {
        this.amount = fAmount.value;
      }
      const fNote = form.get('note');
      if (fNote) {
        this.note = fNote.value;
      }
      const fCustomerLocation = form.get('customerLocation');
      if (fCustomerLocation) {
        this.customerLocation = fCustomerLocation.value;
      }
      const fStatus = form.get('status');
      if (fStatus) {
        this.status = fStatus.value;
      }
      const fMinutes = form.get('minutes');
      if (fMinutes) {
        this.minutes = fMinutes.value;
      }
    } else {
      this.id = form;
    }
  }
}
