import {Injectable} from "@angular/core";
import {
  AlignEnum,
  ApiService,
  AuthoritiesService,
  ButtonFields,
  ColumnFields,
  DateUtilService,
  IconTypeEnum,
  SuperEntity,
  UtilsService
} from "@next-solutions/next-solutions-base";
import {TranslateService} from "@ngx-translate/core";
import {MatTableDataSource} from "@angular/material/table";
import {SosTrackingModel} from "../models/sos-tracking.model";
import {EnumUtil} from "./enum.util";
import {SosStatusEnum} from "../enums/sos.status.enum";
import {MatDialogConfig} from "@angular/material/dialog";
import {DriverModel} from "../models/cms/driver.model";
import {ModuleConst} from "../modules/module.const";
import {SuperStatusEnum} from "../enums/super.status.enum";

@Injectable({providedIn: 'root'})
export class SosTrackingUtil {

  constructor(protected authoritiesService: AuthoritiesService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected translateService: TranslateService,
              protected dateUtilService: DateUtilService) {
  }

  hasViewDetailPermission(): boolean {
    return this.authoritiesService.hasAuthority('get/sos-tracking/{id}');
  }

  hasCustomerRequestedPermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/sos-tracking/{id}/customer-requested');
  }

  hasOperatorSearchPermission(): boolean {
    return this.authoritiesService.hasAuthority('get/sos-tracking/operator-search');
  }

  hasGarageRejectPermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/sos-tracking/{id}/garage-reject');
  }

  hasCustomerCancelPermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/sos-tracking/{id}/customer-cancel');
  }

  hasConfirmCustomerPermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/sos-tracking/{id}/confirm-customer');
  }

  hasData(results: MatTableDataSource<SuperEntity>): boolean {
    return results && results.data && results.data.length > 0;
  }

  getColumns(): ColumnFields[] {
    return [
      {
        columnDef: 'customerName', header: 'customerName',
        title: (e: SosTrackingModel) => `${e.customerName ? e.customerName : ''}`,
        cell: (e: SosTrackingModel) => `${e.customerName ? e.customerName : ''}`,
        className: 'mat-column-customerName',
      },
      {
        columnDef: 'customerPhone', header: 'customerPhone',
        title: (e: SosTrackingModel) => `${e.customerPhone ? e.customerPhone : ''}`,
        cell: (e: SosTrackingModel) => `${e.customerPhone ? e.customerPhone : ''}`,
        className: 'mat-column-customerPhone',
      },
      {
        columnDef: 'logDate', header: 'logDate',
        title: (e: SosTrackingModel) => `${e.logDate ? this.dateUtilService.convertDateTimeToDisplay(e.logDate) : ''}`,
        cell: (e: SosTrackingModel) => `${e.logDate ? this.dateUtilService.convertDateTimeToDisplay(e.logDate) : ''}`,
        className: 'mat-column-logDate',
        align: AlignEnum.CENTER,
        isExpandOptionColumn: () => true,
      },
      {
        columnDef: 'customerLocation', header: 'customerLocation',
        title: (e: SosTrackingModel) => `${e.customerLocation ? e.customerLocation : ''}`,
        cell: (e: SosTrackingModel) => `${e.customerLocation ? e.customerLocation : ''}`,
        className: 'mat-column-customerLocation',
        display: (e: SosTrackingModel) => e.status === EnumUtil.getKeysByValues(SosStatusEnum, [SosStatusEnum._CUSTOMER_CLICK])
      },
      {
        columnDef: 'latitude', header: 'latitude',
        title: (e: SosTrackingModel) => `${e.latitude ? e.latitude : ''}`,
        cell: (e: SosTrackingModel) => `${e.latitude ? e.latitude : ''}`,
        className: 'mat-column-latitude',
        align: AlignEnum.RIGHT,
        isExpandOptionColumn: () => true,
      },
      {
        columnDef: 'longitude', header: 'longitude',
        title: (e: SosTrackingModel) => `${e.longitude ? e.longitude : ''}`,
        cell: (e: SosTrackingModel) => `${e.longitude ? e.longitude : ''}`,
        className: 'mat-column-longitude',
        align: AlignEnum.RIGHT,
        isExpandOptionColumn: () => true,
      },
      {
        columnDef: 'garage', header: 'garage',
        title: (e: SosTrackingModel) => `${e.garage ? e.garage.name : ''}`,
        cell: (e: SosTrackingModel) => `${e.garage ? e.garage.name : ''}`,
        className: 'mat-column-garage',
        display: (e: SosTrackingModel) =>
          e.status === EnumUtil.getKeysByValues(SosStatusEnum, [SosStatusEnum._CUSTOMER_CLICK])
          || e.status === EnumUtil.getKeysByValues(SosStatusEnum, [SosStatusEnum._REQUESTED_GARAGE])
      },
      {
        columnDef: 'expertSupportTime', header: 'expertSupportTime',
        title: (e: SosTrackingModel) => `${e.expertSupportTime ? this.dateUtilService.convertDateTimeToDisplay(e.expertSupportTime) : ''}`,
        cell: (e: SosTrackingModel) => `${e.expertSupportTime ? this.dateUtilService.convertDateTimeToDisplay(e.expertSupportTime) : ''}`,
        className: 'mat-column-expertSupportTime',
        display: (e: SosTrackingModel) =>
          e.status === EnumUtil.getKeysByValues(SosStatusEnum, [SosStatusEnum._CUSTOMER_REQUESTED])
      },
      {
        columnDef: 'status', header: 'status',
        title: (e: SosTrackingModel) => `${this.utilsService.getEnumValueTranslated(SosStatusEnum, `${e.status}`)}`,
        cell: (e: SosTrackingModel) => `${this.utilsService.getEnumValueTranslated(SosStatusEnum, `${e.status}`)}`,
        className: 'mat-column-status',
        align: AlignEnum.CENTER,
        isExpandOptionColumn: () => true,
      }
    ]
  }

  getButtons(): ButtonFields[] {
    return [
      {
        columnDef: 'edit',
        color: 'warn',
        icon: 'fa fa-pen',
        iconType: IconTypeEnum.FONT_AWESOME,
        click: 'addOrEdit',
        className: 'primary',
        title: 'common.title.edit',
        display: () => true,
        disabled: (e: SosTrackingModel) =>
          e.status !== EnumUtil.getKeysByValues(SosStatusEnum, [SosStatusEnum._CUSTOMER_CLICK])
          && e.status !== EnumUtil.getKeysByValues(SosStatusEnum, [SosStatusEnum._CUSTOMER_REQUESTED])
      },
      {
        columnDef: 'detail',
        color: 'warn',
        icon: 'fa fa-eye',
        iconType: IconTypeEnum.FONT_AWESOME,
        click: 'viewDetail',
        className: 'primary',
        title: 'common.title.detail',
        display: (e: SosTrackingModel) => this.hasViewDetailPermission(),
      },
      {
        columnDef: 'accept',
        color: 'primary',
        icon: 'fa fa-check',
        iconType: IconTypeEnum.FONT_AWESOME,
        click: 'confirmCustomer',
        className: 'primary',
        title: 'common.title.accept',
        display: (e: DriverModel) => this.hasConfirmCustomerPermission(),
        disabled: (e: SosTrackingModel) => e.status !== EnumUtil.getKeysByValues(SosStatusEnum, [SosStatusEnum._REQUESTED_GARAGE]),
      },
      {
        columnDef: 'reject',
        color: 'warn',
        icon: 'fa fa-ban',
        iconType: IconTypeEnum.FONT_AWESOME,
        click: 'garageReject',
        className: 'danger',
        title: 'common.title.reject',
        display: (e: SosTrackingModel) => this.hasGarageRejectPermission(),
        disabled: (e: SosTrackingModel) =>
          e.status !== EnumUtil.getKeysByValues(SosStatusEnum, [SosStatusEnum._CUSTOMER_REQUESTED])
          && e.status !== EnumUtil.getKeysByValues(SosStatusEnum, [SosStatusEnum._REQUESTED_GARAGE])
      },
      {
        columnDef: 'cancel',
        color: 'warn',
        icon: 'fa fa-times',
        iconType: IconTypeEnum.FONT_AWESOME,
        click: 'customerCancel',
        className: 'danger',
        title: 'common.title.cancel',
        display: (e: SosTrackingModel) => this.hasCustomerCancelPermission(),
        disabled: (e: SosTrackingModel) =>
          e.status !== EnumUtil.getKeysByValues(SosStatusEnum, [SosStatusEnum._CUSTOMER_CLICK])
          && e.status !== EnumUtil.getKeysByValues(SosStatusEnum, [SosStatusEnum._CUSTOMER_REQUESTED])
          && e.status !== EnumUtil.getKeysByValues(SosStatusEnum, [SosStatusEnum._REQUESTED_GARAGE])
      }
    ];
  }

  onGarageReject(row: SosTrackingModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (row) {
      const strOk = this.translateService.instant('common.ok');
      const strCancel = this.translateService.instant('common.cancel');
      const strReasonReject = this.translateService.instant('sos-tracking.reason.reject');
      const dialogConfig: MatDialogConfig<any> = {
        data: {
          customClass: 'reject_reason_dialog hint-outside',
          msg: strReasonReject,
          type: 'INPUT_CONFIRM',
          btnOKString: strOk,
          btnCancelString: strCancel,
        },
      };
      this.utilsService.showConfirmInputDialog('sos-tracking.reason.reject', [], dialogConfig)
        .afterClosed().subscribe(result => {
        if (result && result.value) {
          this.apiService.patch(ModuleConst.CMS + `/sos-tracking/${row.id}/garage-reject`, result.value)
            .subscribe(data => {
                onSuccessFunc(data, 'common.reject.success');
              },
              error => {
                this.utilsService.showError(error);
              });
        }
      });
    }
  }

  onCustomerCancel(row: SosTrackingModel, onSuccessFunc: (data: any, onSuccessMessage?: string) => void) {
    if (row) {
      const strOk = this.translateService.instant('common.ok');
      const strCancel = this.translateService.instant('common.cancel');
      const strReasonReject = this.translateService.instant('sos-tracking.reason.cancel');
      const dialogConfig: MatDialogConfig<any> = {
        data: {
          customClass: 'reject_reason_dialog hint-outside',
          msg: strReasonReject,
          type: 'INPUT_CONFIRM',
          btnOKString: strOk,
          btnCancelString: strCancel,
        },
      };
      this.utilsService.showConfirmInputDialog('sos-tracking.reason.cancel', [], dialogConfig)
        .afterClosed().subscribe(result => {
        if (result && result.value) {
          this.apiService.patch(ModuleConst.CMS + `/sos-tracking/${row.id}/customer-cancel`, result.value)
            .subscribe(data => {
                onSuccessFunc(data, 'common.cancel.success');
              },
              error => {
                this.utilsService.showError(error);
              });
        }
      });
    }
  }

  onConfirmCustomer(row: SosTrackingModel, onSuccessFunc: (data: any, onSuccessMessage?: string) => void) {
    if (row) {
      const method = this.apiService.patch(ModuleConst.CMS + '/sos-tracking/' + row.id + '/confirm-customer', null);
      this.utilsService.execute(method, onSuccessFunc, 'common.confirm.customer.success',
        'common.confirm.customer');
    }
  }
}
