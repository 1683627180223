import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService, AuthoritiesService, BaseSearchLayout, FormStateService, UtilsService } from '@next-solutions/next-solutions-base';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DestroyService } from '../../../../_services/destroy.service';
import { ModuleConst } from 'src/app/modules/module.const';
import { BookingModel } from 'src/app/models/booking/booking.model';
import { HttpParams } from '@angular/common/http';
import { BookingUtil } from 'src/app/utils/booking/booking.util';

@Component({
  selector: 'app-doctor-booking-inspection-detail',
  templateUrl: 'inspection-detail.component.html',
  styleUrls: ['inspection-detail.component.scss'],
  providers: [DestroyService],
  encapsulation: ViewEncapsulation.None,
})
export class InspectionDetailComponent extends BaseSearchLayout implements OnInit {
  inspection?: BookingModel;
  id?: string;
  selectingServiceBookingMediaResultImage? = '';
  selectingServiceTicketMediaResultImage? = '';
  constructor(
    protected formBuilder: FormBuilder,
    protected router: Router,
    protected apiService: ApiService,
    protected utilsService: UtilsService,
    protected uiStateService: FormStateService,
    protected translateService: TranslateService,
    protected injector: Injector,
    protected activatedRoute: ActivatedRoute,
    protected authoritiesService: AuthoritiesService,
    protected bookingUtil: BookingUtil,
    private _destroy: DestroyService,
  ) {
    super(router, apiService, utilsService, uiStateService, translateService, injector, activatedRoute, authoritiesService,
      formBuilder.group({}));
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id') ?? '';
    this.initData();
  }

  initData() {
    this.apiService
      .get<BookingModel>(`${ModuleConst.BOOKING}/expert/appointment-schedule/${this.id}`, new HttpParams())
      .subscribe((booking) => {
        this.inspection = booking;
      });
  }

  formatCurrency(value: string | number, delimiter = '.', postfix = 'đ') {
    return this.bookingUtil.formatCurrency(value, delimiter, postfix);
  }

  selectServiceBookingMediaResultImage(url: string) {
    if (this.selectingServiceBookingMediaResultImage === url) {
      this.selectingServiceBookingMediaResultImage = undefined;
      return undefined;
    }
    this.selectingServiceBookingMediaResultImage = url;
  }

  selectServiceTicketMediaResultImage(url: string) {
    if (this.selectingServiceTicketMediaResultImage === url) {
      this.selectingServiceTicketMediaResultImage = undefined;
      return undefined;
    }
    this.selectingServiceTicketMediaResultImage = url;
  }
}
