<ns-breadcrumb></ns-breadcrumb>
<mat-tab-group>
  <mat-tab [label]="moduleName + '.table.header.request_ticket' | translate">
    <form [formGroup]="addEditForm" class="form">
      <div class="content">
        <fieldset>
          <legend class="margin-vertical-20"></legend>
          <!-- Booking info -->
          <div fxLayout="row wrap" class="row-wrap-padding">
            <legend></legend>
            <div fxLayout="row wrap" class="row-wrap-padding" fxFlex="100%" fxFlex.lt-md="100%">
              <ns-input
                [label]="moduleName + '.table.header.booking_id'"
                name="requestTicketId"
                formControlName="requestTicketId"
                fxLayout="column"
                fxFlex="25%"
                [disabled]="true"
                fxFlex.xs="100%"
                fxFlex.sm="50%"
                fxFlex.md="50%"
                fxFlex.lg="25%"
              >
              </ns-input>
              <ns-multi-select-autocomplete
                [multiple]="false"
                [options]="serviceTypeValues"
                [label]="moduleName + '.table.header.booking_type'"
                [disabled]="true"
                name="serviceType"
                formControlName="serviceType"
                fxLayout="column"
                fxFlex="25%"
                fxFlex.xs="100%"
                fxFlex.sm="50%"
                fxFlex.md="50%"
                fxFlex.lg="25%"
              >
              </ns-multi-select-autocomplete>
              <ns-multi-select-autocomplete
                [multiple]="false"
                [options]="requestTicketValues"
                [label]="moduleName + '.table.header.status'"
                [disabled]="true"
                name="requestTicketStatus"
                formControlName="requestTicketStatus"
                fxLayout="column"
                fxFlex="25%"
                fxFlex.xs="100%"
                fxFlex.sm="50%"
                fxFlex.md="50%"
                fxFlex.lg="25%"
              >
              </ns-multi-select-autocomplete>
              <ns-input
                *ngIf="booking?.reason"
                [label]="moduleName + '.table.header.reason'"
                [multiline]="true"
                name="reason"
                formControlName="reason"
                fxLayout="column"
                fxFlex="75%"
                [disabled]="true"
                fxFlex.xs="100%"
                fxFlex.sm="100%"
                fxFlex.md="100%"
                fxFlex.lg="75%"
              >
              </ns-input>
            </div>
          </div>
          <hr />
          <!-- Customer info -->
          <div fxLayout="row wrap" class="row-wrap-padding margin-left-10">
            <legend class="margin-vertical-20">{{ moduleName + '.table.header.customer_info' | translate }}</legend>
            <div class="fieldSetContent" fxLayout="row wrap" class="row-wrap-padding" fxFlex="100%" fxFlex.lt-md="100%">
              <ns-input
                [label]="moduleName + '.table.header.customer_name'"
                name="driverName"
                formControlName="driverName"
                fxLayout="column"
                fxFlex="25%"
                [disabled]="true"
                fxFlex.xs="100%"
                fxFlex.sm="50%"
                fxFlex.md="50%"
                fxFlex.lg="25%"
              >
              </ns-input>
              <ns-input
                [label]="moduleName + '.table.header.phone_number'"
                name="driverPhone"
                formControlName="driverPhone"
                fxLayout="column"
                fxFlex="25%"
                [disabled]="true"
                fxFlex.xs="100%"
                fxFlex.sm="50%"
                fxFlex.md="50%"
                fxFlex.lg="25%"
              >
              </ns-input>
              <ns-input
                [label]="moduleName + '.table.header.address'"
                [multiline]="true"
                name="driverAddress"
                formControlName="driverAddress"
                fxLayout="column"
                fxFlex="75%"
                [disabled]="true"
                fxFlex.xs="100%"
                fxFlex.sm="100%"
                fxFlex.md="100%"
                fxFlex.lg="75%"
              >
              </ns-input>
            </div>
          </div>
          <hr />
          <!-- Car Info -->
          <div fxLayout="row wrap" class="row-wrap-padding margin-left-10">
            <legend class="margin-vertical-20 car-info">
              <span>{{ moduleName + '.table.header.car_info' | translate }}</span>
              <div class="float-right">
                <button
                  mat-button
                  type="button"
                  class="primary outline"
                  (click)="openPageOnNewTab('/#/booking/inspection?driverPhone=' + booking?.driverPhone)"
                >
                  {{ moduleName + '.table.header.view_medical_record' | translate }}
                </button>
                <button mat-button type="button" class="primary outline" (click)="viewCarDetail()">
                  {{ moduleName + '.table.header.view_detail' | translate }}
                </button>
              </div>
            </legend>
            <div class="fieldSetContent" fxLayout="row wrap" class="row-wrap-padding" fxFlex="100%" fxFlex.lt-md="100%">
              <ns-input
                [label]="moduleName + '.table.header.car_license_plate'"
                name="carNumber"
                formControlName="carNumber"
                fxLayout="column"
                fxFlex="25%"
                [disabled]="true"
                fxFlex.xs="100%"
                fxFlex.sm="50%"
                fxFlex.md="50%"
                fxFlex.lg="25%"
              >
              </ns-input>
              <ns-input
                [label]="moduleName + '.table.header.car_brand'"
                name="carBrand"
                formControlName="carBrand"
                fxLayout="column"
                fxFlex="25%"
                [disabled]="true"
                fxFlex.xs="100%"
                fxFlex.sm="50%"
                fxFlex.md="50%"
                fxFlex.lg="25%"
              >
              </ns-input>
            </div>
          </div>
          <hr />
          <!-- Gara Info -->
          <div fxLayout="row wrap" class="row-wrap-padding margin-left-10">
            <legend class="margin-vertical-20 gara-info">
              <span>{{ moduleName + '.garage.detail' | translate }}</span>
              <div class="float-right">
                <button
                  mat-button
                  type="button"
                  class="primary outline"
                  *ngIf="garaUtil.hasViewDetailPermission()"
                  (click)="openPageOnNewTab('#/cms/garage/detail/' + booking?.garageRefId)"
                >
                  {{ moduleName + '.table.header.view_detail' | translate }}
                </button>
                <button
                  mat-button
                  type="button"
                  class="primary outline"
                  *ngIf="
                    !isView &&
                    bookingUtil.hasPermission(booking) &&
                    bookingUtil.checkBookingBehavior(BOOKING_BEHAVIORS.ASSIGN_GARA, booking)
                  "
                  (click)="selectGarage()"
                >
                  {{ moduleName + '.table.header.choose_garage' | translate }}
                </button>
              </div>
            </legend>
            <div class="fieldSetContent" fxLayout="row wrap" class="row-wrap-padding" fxFlex="100%" fxFlex.lt-md="100%">
              <ns-input
                [label]="moduleName + '.garage.detail.name'"
                name="garageName"
                formControlName="garageName"
                fxLayout="column"
                fxFlex="25%"
                [disabled]="true"
                fxFlex.xs="100%"
                fxFlex.sm="50%"
                fxFlex.md="50%"
                fxFlex.lg="25%"
              >
              </ns-input>
              <ns-input
                [label]="moduleName + '.table.header.phone_number'"
                name="garagePhone"
                formControlName="garagePhone"
                fxLayout="column"
                fxFlex="25%"
                [disabled]="true"
                fxFlex.xs="100%"
                fxFlex.sm="50%"
                fxFlex.md="50%"
                fxFlex.lg="25%"
              >
              </ns-input>
              <ns-multi-select-autocomplete
                [multiple]="false"
                [options]="garageHandleStatusValues"
                [label]="moduleName + '.table.header.status'"
                [disabled]="true"
                name="garageHandleStatus"
                formControlName="garageHandleStatus"
                fxLayout="column"
                fxFlex="25%"
                fxFlex.xs="100%"
                fxFlex.sm="50%"
                fxFlex.md="50%"
                fxFlex.lg="25%"
              >
              </ns-multi-select-autocomplete>
              <ns-input
                [label]="moduleName + '.table.header.address'"
                [multiline]="true"
                name="garageAddress"
                formControlName="garageAddress"
                fxLayout="column"
                fxFlex="75%"
                [disabled]="true"
                fxFlex.xs="100%"
                fxFlex.sm="100%"
                fxFlex.md="100%"
                fxFlex.lg="75%"
              >
              </ns-input>
            </div>
          </div>
          <hr />
          <!-- Expert Info -->
          <div fxLayout="row wrap" class="row-wrap-padding margin-left-10">
            <legend class="margin-vertical-20 gara-info">
              <span>{{ moduleName + '.table.header.expert' | translate }}</span>
            </legend>
            <div class="fieldSetContent" fxLayout="row wrap" class="row-wrap-padding" fxFlex="100%" fxFlex.lt-md="100%">
              <ns-multi-select-autocomplete
                [multiple]="false"
                [options]="expertValues"
                [label]="moduleName + '.table.header.expert_name'"
                name="expertRefId"
                [disabled]="
                  isView ||
                  !bookingUtil.isMasterExpert() ||
                  !bookingUtil.checkBookingBehavior(BOOKING_BEHAVIORS.ASSIGN_EXPERT_AND_OPERATOR, booking)
                "
                formControlName="expertRefId"
                (selectionChange)="changeExpert($event)"
                fxLayout="column"
                fxFlex="25%"
                fxFlex.xs="100%"
                fxFlex.sm="50%"
                fxFlex.md="50%"
                fxFlex.lg="25%"
              >
              </ns-multi-select-autocomplete>
              <ns-input
                [label]="moduleName + '.table.header.phone_number'"
                name="expertPhone"
                formControlName="expertPhone"
                fxLayout="column"
                fxFlex="25%"
                [disabled]="true"
                fxFlex.xs="100%"
                fxFlex.sm="50%"
                fxFlex.md="50%"
                fxFlex.lg="25%"
              >
              </ns-input>
            </div>
          </div>
          <hr />
          <!-- Operator Info -->
          <div fxLayout="row wrap" class="row-wrap-padding margin-left-10">
            <legend class="margin-vertical-20 gara-info">
              <span>{{ moduleName + '.table.header.operator' | translate }}</span>
            </legend>
            <div class="fieldSetContent" fxLayout="row wrap" class="row-wrap-padding" fxFlex="100%" fxFlex.lt-md="100%">
              <ns-multi-select-autocomplete
                [multiple]="false"
                [options]="operatorValues"
                [label]="moduleName + '.table.header.operator_name'"
                [disabled]="
                  isView ||
                  !bookingUtil.isMasterExpert() ||
                  !bookingUtil.checkBookingBehavior(BOOKING_BEHAVIORS.ASSIGN_EXPERT_AND_OPERATOR, booking)
                "
                name="operatorId"
                formControlName="operatorId"
                (selectionChange)="changeOperator($event)"
                fxLayout="column"
                fxFlex="25%"
                fxFlex.xs="100%"
                fxFlex.sm="50%"
                fxFlex.md="50%"
                fxFlex.lg="25%"
              >
              </ns-multi-select-autocomplete>
              <ns-input
                [label]="moduleName + '.table.header.phone_number'"
                name="operatorPhone"
                formControlName="operatorPhone"
                fxLayout="column"
                fxFlex="25%"
                [disabled]="true"
                fxFlex.xs="100%"
                fxFlex.sm="50%"
                fxFlex.md="50%"
                fxFlex.lg="25%"
              >
              </ns-input>
            </div>
          </div>
          <hr />
          <!-- Booking Info -->
          <div fxLayout="row wrap" class="row-wrap-padding margin-left-10">
            <legend class="margin-vertical-20 booking-info">
              <span>{{ moduleName + '.table.header.booking_info' | translate }}</span>
            </legend>
            <div fxLayout="row wrap" class="row-wrap-padding" fxFlex="100%" fxFlex.lt-md="100%">
              <div fxLayout="column" fxFlex="100%">
                <ns-smart-table
                  *ngIf="booking?.carStatuses?.length"
                  name="carStatuses"
                  formControlName="carStatuses"
                  [isSticky]="true"
                  [moduleName]="moduleName"
                  [columns]="carStatusColumns"
                  [buttons]="carStatusButtons"
                  (clickAction)="onRowButtonClick($event)"
                  [minRow]="1"
                  class="no-even-odd"
                  fxLayout="column"
                  fxFlex="100%"
                >
                </ns-smart-table>

                <button type="button" mat-icon-button #addCarStatusBtn [matMenuTriggerFor]="menu"></button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="addCarStatus('NEW')">
                    {{ moduleName + '.step3.form.car_status.new' | translate }}
                  </button>
                  <button mat-menu-item (click)="addCarStatus('SELECT')">
                    {{ moduleName + '.table.header.select_car_status' | translate }}
                  </button>
                </mat-menu>
              </div>
              <div fxLayout="column" fxFlex="100%">
                <ns-multi-upload-file
                  name="carStatusImages"
                  formControlName="carStatusImages"
                  [isFix]="false"
                  [required]="false"
                  [disabled]="true"
                  [accept]="[FileTypes.PNG, FileTypes.JPG]"
                >
                </ns-multi-upload-file>
              </div>
              <ns-date-picker
                name="appointmentDate"
                [label]="moduleName + '.table.header.appointment_date'"
                formControlName="appointmentDate"
                [required]="true"
                [disabled]="isView || !bookingUtil.checkBookingBehavior(BOOKING_BEHAVIORS.CHANGE_BOOKING_INFO, booking)"
                [minDate]="minDate"
                [maxDate]="maxDate"
                (onChange)="onBookingDateChange($event)"
                fxLayout="column"
                fxFlex="25%"
                fxFlex.xs="100%"
                fxFlex.sm="50%"
                fxFlex.md="50%"
                fxFlex.lg="25%"
              >
              </ns-date-picker>
              <ns-multi-select-autocomplete
                [options]="hourValues"
                [multiple]="false"
                [required]="true"
                [disabled]="isView || !bookingUtil.checkBookingBehavior(BOOKING_BEHAVIORS.CHANGE_BOOKING_INFO, booking)"
                formControlName="appointmentTime"
                [label]="moduleName + '.table.header.appointment_time'"
                fxLayout="column"
                fxFlex="25%"
                fxFlex.xs="100%"
                fxFlex.sm="50%"
                fxFlex.md="50%"
                fxFlex.lg="25%"
              >
              </ns-multi-select-autocomplete>
              <ns-input
                [label]="moduleName + '.step3.form.km_run'"
                name="carKm"
                formControlName="carKm"
                [disabled]="true"
                fxLayout="column"
                fxFlex="25%"
                fxFlex.xs="100%"
                fxFlex.sm="50%"
                fxFlex.md="50%"
                fxFlex.lg="25%"
              >
              </ns-input>
              <ns-input
                [label]="moduleName + '.table.header.expectation_km_to_garage'"
                name="distanceFilter"
                formControlName="distanceFilter"
                [disabled]="true"
                fxLayout="column"
                fxFlex="25%"
                fxFlex.xs="100%"
                fxFlex.sm="50%"
                fxFlex.md="50%"
                fxFlex.lg="25%"
              >
              </ns-input>
              <ns-input
                [label]="moduleName + '.table.header.location'"
                [multiline]="true"
                [disabled]="true"
                name="distanceName"
                formControlName="distanceName"
                fxLayout="column"
                fxFlex="75%"
                fxFlex.xs="100%"
                fxFlex.sm="100%"
                fxFlex.md="100%"
                fxFlex.lg="75%"
              >
              </ns-input>
              <ns-input
                [label]="moduleName + '.table.header.description'"
                [multiline]="true"
                [disabled]="true"
                name="description"
                formControlName="description"
                fxFlex="75%"
                fxFlex.xs="100%"
                fxFlex.sm="100%"
                fxFlex.md="100%"
                fxFlex.lg="75%"
                fxLayout="column"
              >
              </ns-input>
              <ns-input
                *ngIf="addEditForm.get('diagnosticResults')?.value"
                [label]="moduleName + '.table.header.diagnostic_result'"
                [multiline]="true"
                name="diagnosticResults"
                formControlName="diagnosticResults"
                [disabled]="true"
                fxFlex="75%"
                fxFlex.xs="100%"
                fxFlex.sm="100%"
                fxFlex.md="100%"
                fxFlex.lg="75%"
                fxLayout="column"
              >
              </ns-input>
              <div fxLayout="column" fxFlex="100%">
                <ns-flat-tree
                  name="utilities"
                  formControlName="requestUtilities"
                  [label]="moduleName + '.table.header.utilities'"
                  [fullDatasource]="utilityTree"
                  [disabled]="
                    isView || !bookingUtil.checkBookingBehavior(BOOKING_BEHAVIORS.CHANGE_BOOKING_INFO, booking)
                  "
                  [isCollapseSelected]="false"
                  [isSearchOutSide]="false"
                  [selectedLabelTitle]="moduleName + '.table.header.utilities.selected'"
                  fxLayout="column"
                  fxFlex="100%"
                >
                </ns-flat-tree>
              </div>
              <div fxLayout="column" fxFlex="100%">
                <ns-flat-tree
                  name="services"
                  formControlName="services"
                  [label]="moduleName + '.table.header.services'"
                  [fullDatasource]="serviceTree"
                  [disabled]="
                    isView || !bookingUtil.checkBookingBehavior(BOOKING_BEHAVIORS.CHANGE_BOOKING_INFO, booking)
                  "
                  [isCollapseSelected]="false"
                  [isSearchOutSide]="false"
                  [selectedLabelTitle]="moduleName + '.table.header.services.selected'"
                  (selectionChange)="reRenderSurvey($event)"
                  fxLayout="column"
                  fxFlex="100%"
                >
                </ns-flat-tree>
              </div>
              <div fxLayout="column" fxFlex="100%">
                <ns-smart-table
                  *ngIf="addEditForm.get('survey')?.value?.length"
                  name="survey"
                  formControlName="survey"
                  [isSticky]="true"
                  [moduleName]="moduleName"
                  [columns]="surveyColumns"
                  [buttons]="surveyButtons"
                  (clickAction)="onRowButtonClick($event)"
                  [minRow]="1"
                  class="no-even-odd"
                  fxLayout="column"
                  fxFlex="100%"
                >
                </ns-smart-table>
              </div>
              <div fxLayout="column" fxFlex="100%">
                <ns-smart-table
                  *ngIf="addEditForm.get('refRequestTicket')?.value"
                  name="refRequestTicket"
                  formControlName="refRequestTicket"
                  [isSticky]="true"
                  [moduleName]="moduleName"
                  [columns]="refRequestTicketColumns"
                  [buttons]="refRequestTicketButtons"
                  (clickAction)="onRowButtonClick($event)"
                  [minRow]="1"
                  class="no-even-odd"
                  fxLayout="column"
                  fxFlex="100%"
                >
                </ns-smart-table>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </form>
  </mat-tab>
  <mat-tab *ngIf="booking?.serviceTicketStatus" [label]="moduleName + '.table.header.service_ticket' | translate">
    <app-service-detail></app-service-detail>
  </mat-tab>
  <mat-tab disabled>
    <ng-template mat-tab-label>
      <button
        mat-button
        type="button"
        class="secondary outline"
        *ngIf="
          bookingUtil.hasPermission(booking) && bookingUtil.checkBookingBehavior(BOOKING_BEHAVIORS.CANCEL, booking)
        "
        (click)="cancelBooking()"
      >
        {{ moduleName + '.table.header.cancel_booking' | translate }}
      </button>
      <button
        mat-button
        type="button"
        class="primary"
        *ngIf="bookingUtil.hasPermission(booking) && bookingUtil.checkBookingBehavior(BOOKING_BEHAVIORS.EDIT, booking)"
        [disabled]="bookingDetailService.isInvalid | async"
        (click)="editBooking()"
      >
        {{ moduleName + (isView ? '.table.header.update' : '.table.header.save') | translate }}
      </button>
    </ng-template>
  </mat-tab>
</mat-tab-group>
<div *ngIf="bookingUtil.hasPermission(booking)" class="zego-buttons">
  <button
    id="audio-call"
    class="mat-warn primary zego-btn"
    mat-icon-button
    [matTooltip]="'booking.common.audio_call' | translate"
    (click)="audioCall()"
  >
    <em class="fa fa-phone-alt"></em>
  </button>
  <button
    id="video-call"
    class="mat-warn primary zego-btn"
    mat-icon-button
    [matTooltip]="'booking.common.video_call' | translate"
    (click)="videoCall()"
  >
    <em class="fa fa-solid fa-video"></em>
  </button>
  <button
    id="chat"
    class="mat-warn primary zego-btn"
    mat-icon-button
    [matTooltip]="'booking.common.chat' | translate"
    [disabled]="hideChatByTicketStatus(booking?.requestTicketStatus) || this.bookingUtil.isOP()"
    (click)="chat()"
  >
    <em class="fa fa-solid fa-comment"></em>
  </button>
</div>
