import { Component, forwardRef, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  ApiService,
  AuthoritiesService,
  BaseAddEditLayout,
  FileTypes,
  SelectModel,
  UploadModel,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, NgControl } from '@angular/forms';
import { DOCUMENT, Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { BookingUtil } from 'src/app/utils/booking/booking.util';
import { GarageUtil } from 'src/app/utils/cms/garage.util';
import { MatDialog } from '@angular/material/dialog';
import { EnumUtil } from 'src/app/utils/enum.util';
import {
  BOOKING_BEHAVIORS,
  BookingModel,
  REQUEST_TICKET_STATUS,
  SERIVCE_TYPE,
  SERVICE_LABEL,
  SERVICE_TICKET_STATUS,
  SuperServiceModel,
} from 'src/app/models/booking/booking.model';
import { ModuleConst } from 'src/app/modules/module.const';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SuperStatusEnum } from 'src/app/enums/super.status.enum';
import { ActionTypeEnum } from 'src/app/enums/action.type.enum';
import { BookingDetailService } from 'src/app/components/doctor/booking/services/booking-detail.service';
import { takeUntil } from 'rxjs';
import { DestroyService } from 'src/app/_services/destroy.service';
import {Lightbox} from 'ngx-lightbox';

@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    DestroyService,
    {
      provide: NgControl,
      useExisting: forwardRef(() => ServiceDetailComponent),
    }
  ],
})

export class ServiceDetailComponent extends BaseAddEditLayout implements OnInit {
  BOOKING_BEHAVIORS = BOOKING_BEHAVIORS;
  moduleName = ModuleConst.BOOKING;
  FileTypes = FileTypes;
  minDate = new Date();
  isView = true;
  isQuoteByImage = false;
  serviceTypeValues: SelectModel[] = [];
  requestTicketValues: SelectModel[] = [];
  serviceTicketValues: SelectModel[] = [];
  serviceValues: SelectModel[] = [];
  booking?: BookingModel;
  _albums: any = [];
  _albumsService: any = [];
  selectingImage? = '';
  selectingResultImage? = '';
  serviceTicketServiceSubForm = this.formBuilder.group({
    serviceTicketServiceFormArray: this.formBuilder.array<FormGroup>([]),
  });
  constructor(
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected location: Location,
    protected translateService: TranslateService,
    protected apiService: ApiService,
    protected utilsService: UtilsService,
    protected authoritiesService: AuthoritiesService,
    private bookingDetailService: BookingDetailService,
    private destroy: DestroyService,
    public bookingUtil: BookingUtil,
    public garaUtil: GarageUtil,
    public matDialog: MatDialog,
    public lightbox: Lightbox,
    @Inject(DOCUMENT) public document: Document,
  ) {
    super(activatedRoute, location, translateService, utilsService, authoritiesService);
    this.addEditForm = this.formBuilder.group({
      // Phiếu yêu cầu
      requestTicketId: [''], // ID lịch hẹn
      serviceTicketId: [''], // ID lịch hẹn
      totalPrice: [''], // ID lịch hẹn
      expectedDeliveryDate: [''], // ID lịch hẹn
      serviceType: [''], // loại dịch vụ
      requestTicketStatus: [
        EnumUtil.getKeysByValues(REQUEST_TICKET_STATUS, [REQUEST_TICKET_STATUS._COMPLETED]),
      ], // trạng thái phiếu yêu cầu
      serviceTicketStatus: [
        EnumUtil.getKeysByValues(SERVICE_TICKET_STATUS, [SERVICE_TICKET_STATUS._COMPLETED]),
      ], // trạng thái phiếu yêu cầu

      garageHandOverDate: [''], // Ngay ban giao
      listService: [[]], // các dịch vụ
      services: [[]], // các dịch vụ con
      servicePackages: [[]], // các gói dịch vụ

      maxSurveyTotalPrice: [''], // tổng chi phí
      minSurveyTotalPrice: [''], // tổng chi phí

      // Phiếu dịch vụ
      serviceBookingMediaResult: [[]],
      serviceTicketServicesMedia: [[]], // ảnh báo giá dịch vụ của phiếu dịch vụ
      serviceTicketServices: [[]], // dịch vụ phiếu dịch vụ
      serviceBookingMediaReceipts: [''], // hoá đơn
      carInspectionResult: [''], // kết quả khám
    });
    this.isView = this.activatedRoute.routeConfig?.data?.actionType === ActionTypeEnum._VIEW;
  }

  async ngOnInit() {
    super.ngOnInit();
    this.initData();
    this.saveDataListener();
  }

  initData() {
    EnumUtil.enum2SelectModel(REQUEST_TICKET_STATUS, this.requestTicketValues, 'EDIT', true, this.utilsService);
    EnumUtil.enum2SelectModel(SERVICE_TICKET_STATUS, this.serviceTicketValues , 'EDIT', true, this.utilsService);
    EnumUtil.enum2SelectModel(SERIVCE_TYPE, this.serviceTypeValues, 'EDIT', true, this.utilsService);
    this.apiService.get<BookingModel>(`${ModuleConst.BOOKING}/expert/appointment-schedule/${this.id}`, new HttpParams())
      .subscribe((booking) => {
        this.booking = booking;
        this.isQuoteByImage = Boolean(this.booking.serviceTicketServicesMedia && this.booking.serviceTicketServicesMedia.length);
        this.addEditForm.setValue(UtilsService.reduceEntityAttributeForFormControl(this.addEditForm, this.booking));
        const totalPrice = this.booking.totalPrice === null ? null : this.bookingUtil.formatCurrency(this.booking.totalPrice, '.','');
        this.addEditForm.get('totalPrice')?.setValue(totalPrice);
        this.initServiceBookingMediaResult();
        this.initServiceTicketServicesMediaResult();
        this.initServiceTicketServices();
        this.initServices();
      });
  }

  initServices() {
    let params = new HttpParams()
      .set('status', EnumUtil.getKeysByValues(SuperStatusEnum, [SuperStatusEnum._ACCEPTED]))
      .set('pageNumber', '1')
      .set('pageSize', environment.INTEGER_MAX_VALUE);
    if (this.booking?.serviceType) params = params.set('type', this.booking.serviceType);
    this.apiService
      .get<SuperServiceModel[]>(ModuleConst.CMS + '/service/supers-service', params)
      .subscribe((pageServices) => {
        pageServices.forEach((service: SuperServiceModel) => {
          if (service.type === SERVICE_LABEL.SERVICE) {
            this.serviceValues.push({
              value: service.id,
              displayValue: service.name,
              rawData: service,
              disabled: false,
            });
          }
        });
      });
  }

  initServiceBookingMediaResult() {
    console.log('initServiceBookingMediaResult');
    const files = this.booking?.serviceBookingMediaResult;
    if (files && files.length > 0) {
      const urls = files.map((value) => {
        if (value && value.imageUrl) {
          const type = UploadModel.getFileType(value.imageUrl);
          const id = value.id ? value.id : undefined;
          const url = environment.BASE_URL + '/' + value.imageUrl;
          return new UploadModel('', null, url, type, id);
        }
      });
      this.addEditForm.get('serviceBookingMediaResult')?.setValue(urls);
    }
    this.addEditForm.get('serviceBookingMediaResult')?.value.forEach((img: any) => {
      const album = {
        src: img.previewValue,
        caption: 'No cap',
        thumb: img.previewValue,
      }
      console.log(album.src);
      this._albums.push(album);
    })
  }

  initServiceTicketServicesMediaResult() {
    console.log('Init Media');
    const files = this.booking?.serviceTicketServicesMedia;
    if (files && files.length > 0) {
      const urls = files.map((value) => {
        if (value && value.imageUrl) {
          const type = UploadModel.getFileType(value.imageUrl);
          const id = value.id ? value.id : undefined;
          const url = environment.BASE_URL + '/' + value.imageUrl;
          return new UploadModel('', null, url, type, id);
        }
      });
      this.addEditForm.get('serviceTicketServicesMedia')?.setValue(urls);
    }
    this.addEditForm.get('serviceTicketServicesMedia')?.value.forEach((img: any) => {
      const album = {
        src: img.previewValue,
        caption: 'No cap',
        thumb: img.previewValue,
      }
      console.log(album.src);
      this._albumsService.push(album);
    })
  }

  initServiceTicketServices() {
    if (!this.booking) return;
    this.booking.serviceTicketServices?.forEach((service) => {
      if (service.type === SERVICE_LABEL.SERVICEPACKAGE) return;
      this.serviceTicketServiceFormArray.push(this.formBuilder.group({
        name: [service.name || ''],
        price: [this.bookingUtil.formatCurrency(service.price, '.', '') || 0],
        details: [service.details || ''],
        refId: [service.refId || -1],
        type: [service.type || ''],
        warrantyTime: [service.warrantyTime || ''],
      }));
    });
  }

  selectImage(url: string) {
    if (this.selectingImage === url) {
      this.selectingImage = undefined;
      return undefined;
    }
    this.selectingImage = url;
  }

  selectResultImage(url: string) {
    if (this.selectingResultImage === url) {
      this.selectingResultImage = undefined;
      return undefined;
    }
    this.selectingResultImage = url;
    console.log('Url: ', url);
    console.log(this.selectingResultImage);
  }

  get serviceTicketServiceFormArray() {
    return this.serviceTicketServiceSubForm.get('serviceTicketServiceFormArray') as FormArray;
  }

  onAddService() {
    const group = this.formBuilder.group({
      name: '',
      price: 0,
      details: '',
      refId: 0,
      type: '',
      warrantyTime: '',
    })
    this.serviceTicketServiceFormArray.push(group);
  }

  onRemoveService(index: number) {
    this.serviceTicketServiceFormArray.removeAt(index);
  }

  calculateTotalServicePrice() {
    let total = 0;
    for (const control of this.serviceTicketServiceFormArray.controls) {
      const price = `${control.get('price')?.value}`;
      const reg = price ? price.replace(/\./g, '') : '';
      total += Number(reg) || 0;
    }
    return this.bookingUtil.formatCurrency(total) || 0;
  }

  saveDataListener() {
    this.serviceTicketServiceFormArray.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(values => {
        this.bookingDetailService.isInvalid.next(false);
        const isValidPrice = values.find((_: { price: any; }) => _.price === '');
        if (isValidPrice) this.bookingDetailService.isInvalid.next(true);
        this.bookingDetailService.updateData({
          serviceTicketServices: values.reduce((acc: any[], service: any) => {
            const found = this.serviceValues.find(_ => _.rawData.id === service.refId);
            console.log('@== found', found, service, this.serviceValues)
            if (!found) return acc;
            const price = `${service.price}`.replace(/\./g, '');
            return [...acc, {
              ...service,
              price,
              name: found.rawData?.name,
              type: found.rawData?.type,
              id: found.value,
            }]
          }, []),
        });
      });
  }

  getImageIndex(url = '') {
    return this.addEditForm.get('serviceTicketServicesMedia')?.value.findIndex((_: any) => _.previewValue === url) + 1;
  }

  onPriceChange(control: FormControl) {
    return (value: string) => {
      control.setValue(String(value).replace(/^0+/g, ''));
    }
  }

  onKeyDown($event: KeyboardEvent) {
    if (($event.target as HTMLInputElement).value === '' && $event.key === '0') {
      return false;
    }
    if (
      Number(($event.target as HTMLInputElement).value) === 0 ||
      ($event.target as HTMLInputElement).value === '' && $event.key === '0'
    ) {
      ($event.target as HTMLInputElement).value = '';
    }
    return true;
  }

  open(index: number): void {
    this.lightbox.open(this._albums, index);
  }

  openService(index: number): void {
    this.lightbox.open(this._albumsService, index);
  }

  close(): void {
    this.lightbox.close();
  }
}
