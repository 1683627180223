import { zip } from 'rxjs';
import { BookingUtil } from 'src/app/utils/booking/booking.util';
import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  AlignEnum,
  ApiService,
  AuthoritiesService,
  BaseAddEditLayout,
  ButtonFields,
  ColumnFields,
  ColumnTypes,
  FileTypes,
  FlatTreeNode,
  IconTypeEnum,
  Page,
  SelectModel,
  UploadModel,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { DatePipe, DecimalPipe, DOCUMENT, Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';
import { ActionTypeEnum } from '../../../../enums/action.type.enum';
import { SuperStatusEnum } from '../../../../enums/super.status.enum';
import { EnumUtil } from '../../../../utils/enum.util';
import { UtilityModel } from '../../../../models/cms/utility.model';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../../environments/environment';
import { ModuleConst } from '../../../../modules/module.const';
import {
  BookingModel,
  GARA_STATUS,
  REQUEST_TICKET_STATUS,
  SERIVCE_TYPE,
  SERVICE_LABEL,
  SERVICE_TICKET_STATUS,
  SuperServiceModel,
  SurveyModel,
  CarStatusModel,
  Service,
  BOOKING_BEHAVIORS,
} from 'src/app/models/booking/booking.model';
import { ExpertModel } from 'src/app/models/cms/expert.model';
import { GarageUtil } from 'src/app/utils/cms/garage.util';
import { Utils } from 'src/app/utils/utils';
import { OperatorModel } from 'src/app/models/cms/operator.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { PopupCancelBookingComponent } from '../components/popup-cancel-booking/popup-cancel-booking.component';
import {
  BookingDetailData,
  BookingDetailService,
} from 'src/app/components/doctor/booking/services/booking-detail.service';
import { PopupChooseGarageComponent } from '../components/popup-choose-garage/popup-choose-garage.component';
import { CarInfoViewComponent } from 'src/app/components/doctor/booking/components/car-info-view/car-info-view.component';
import { GarageModel } from 'src/app/models/cms/garage.model';
import { ZEGO_CONVERSATION_TYPE } from 'src/app/models/zegocloud/zego.model';

@Component({
  selector: 'app-booking-detail',
  templateUrl: './booking-detail.component.html',
  styleUrls: ['./booking-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BookingDetailComponent extends BaseAddEditLayout implements OnInit, OnDestroy {
  moduleName = 'booking';

  serviceTypeValues: SelectModel[] = [];
  requestTicketValues: SelectModel[] = [];
  serviceTicketValues: SelectModel[] = [];
  expertValues: SelectModel[] = [];
  operatorValues: SelectModel[] = [];
  garageHandleStatusValues: SelectModel[] = [];
  carStatusValues: SelectModel[] = [];

  booking: BookingModel | null = null;

  utilityTree: FlatTreeNode[] = [];
  serviceTree: FlatTreeNode[] = [];
  servicePakageTree: FlatTreeNode[] = [];

  carStatusColumns: ColumnFields[] = [];
  carStatusButtons: ButtonFields[] = [];

  surveyColumns: ColumnFields[] = [];
  surveyButtons: ButtonFields[] = [];

  refRequestTicketColumns: ColumnFields[] = [];
  refRequestTicketButtons: ButtonFields[] = [];

  isView = false;
  baseURL = environment.BASE_URL;
  minDate = new Date();
  maxDate = new Date(new Date().setDate(this.minDate.getDate() + 6));
  hourValues = this.generateHourValues();

  pageServices: SuperServiceModel[] = [];
  pageUtilities: UtilityModel[] = [];
  BOOKING_BEHAVIORS = BOOKING_BEHAVIORS;

  @ViewChild(MatMenuTrigger) addCarStatusBtn: MatMenuTrigger | undefined;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected location: Location,
    protected translateService: TranslateService,
    protected apiService: ApiService,
    protected utilsService: UtilsService,
    protected authoritiesService: AuthoritiesService,
    public bookingUtil: BookingUtil,
    public garaUtil: GarageUtil,
    public matDialog: MatDialog,
    protected router: Router,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    public bookingDetailService: BookingDetailService,
    @Inject(DOCUMENT) public document: Document,
  ) {
    super(activatedRoute, location, translateService, utilsService, authoritiesService);
    this.addEditForm = this.formBuilder.group({
      // Phiếu yêu cầu
      requestTicketId: [''], // ID lịch hẹn
      serviceType: [''], // loại dịch vụ
      requestTicketStatus: [EnumUtil.getKeysByValues(REQUEST_TICKET_STATUS, [REQUEST_TICKET_STATUS._COMPLETED])], // trạng thái phiếu yêu cầu

      driverName: [''], // tên KH
      driverPhone: [''], // sdt KH
      driverAddress: [''], // địa chỉ KH

      carNumber: [''], // biển số xe
      carBrand: [''], // hãng xe
      carChassisNumber: [''], // số khung
      carEngineNumber: [''], // số máy
      carModel: [''], // dòng xe
      carKm: [''], // số km đã chạy
      carImg: [''], // ảnh xe
      carManufacturedYear: [''], // Năm sản xuất
      carBodyStyle: [''], // Kiểu dáng
      carFuel: [''], // Nhiên liệu
      carHorsePower: [''], // Mã lực
      carSeat: [''], // Số chỗ
      carEngine: [''], // Động cơ
      carGear: [''], // Hộp số
      carOrigin: [''], // Xuất xứ

      garageRefId: [''], // ID gara
      garageName: [''], // tên gara
      garagePhone: [''], // sđt gara
      garageAddress: [''], // địa chỉ gara
      garageHandleStatus: [''], // trạng thái gara

      expertRefId: [''], // ID chuyên gia
      expertName: [''], // Tên chuyên gia
      expertPhone: [''], // sđt chuyên gia

      operatorId: [''], // Id nhân viên hỗ trợ
      operatorName: [''], // Tên nhân viên hỗ trợ
      operatorPhone: [''], // sđt nhân viên hỗ trợ

      carStatuses: [''], // IDs tình trạng xe    car_statuses ????
      carStatusImages: [''], // ảnh tình trạng xe

      diagnosticResults: [''], // kết quả chuẩn đoán
      processingStatus: [''], // trạng thái phiếu dịch vụ

      listService: [[]], // các dịch vụ
      services: [[]], // các dịch vụ con
      servicePackages: [[]], // các gói dịch vụ

      distanceFilter: [''], // Khoảng cách mong muốn tới Gara
      distanceName: [''], // vị trí xe

      appointmentDate: [''], // thời gian đặt lịch
      appointmentTime: [''],

      requestUtilities: [[]], // IDs các tiện ích
      description: [''], // mô tả tình trạng xe

      userLatitude: [''], // lat
      userLongitude: [''], // long
      placeId: [''], // placeId

      survey: [''], // các khảo sát giá
      maxSurveyTotalPrice: [''], // tổng chi phí
      minSurveyTotalPrice: [''], // tổng chi phí

      serviceHistories: [''], // lịch sử dịch vụ
      reason: [''], // Lí do huỷ

      refRequestTicket: [''], // lịch sử phiếu yêu cầu
    });

    this.isView = this.activatedRoute.routeConfig?.data?.actionType === ActionTypeEnum._VIEW;
    this.initCarStatusTable();
    this.initSurveyTable();
    this.initRefRequestTicketTable();
  }

  async ngOnInit() {
    super.ngOnInit();
    this.initData();
  }

  ngOnDestroy(): void {
    this.resetForm();
    this.bookingDetailService.resetData();
  }

  resetForm() {
    this.booking = null;
    this.addEditForm.reset();
    this.addEditForm.patchValue({
      requestTicketId: [], // ID lịch hẹn
      serviceType: [], // loại dịch vụ
      requestTicketStatus: [], // trạng thái phiếu yêu cầu

      driverName: [], // tên KH
      driverPhone: [], // sdt KH
      driverAddress: [], // địa chỉ KH

      carNumber: [], // biển số xe
      carBrand: [], // hãng xe
      carChassisNumber: [], // số khung
      carEngineNumber: [], // số máy
      carModel: [], // dòng xe
      carKm: [], // số km đã chạy
      carImg: [], // ảnh xe
      carManufacturedYear: [], // Năm sản xuất
      carBodyStyle: [], // Kiểu dáng
      carFuel: [], // Nhiên liệu
      carHorsePower: [], // Mã lực
      carSeat: [], // Số chỗ
      carEngine: [], // Động cơ
      carGear: [], // Hộp số
      carOrigin: [], // Xuất xứ

      garageRefId: [], // ID gara
      garageName: [], // tên gara
      garagePhone: [], // sđt gara
      garageAddress: [], // địa chỉ gara
      garageHandleStatus: [], // trạng thái gara

      expertRefId: [], // ID chuyên gia
      expertName: [], // Tên chuyên gia
      expertPhone: [], // sđt chuyên gia

      operatorId: [], // Id nhân viên hỗ trợ
      operatorName: [], // Tên nhân viên hỗ trợ
      operatorPhone: [], // sđt nhân viên hỗ trợ

      carStatuses: [], // IDs tình trạng xe    car_statuses ????
      carStatusImages: [], // ảnh tình trạng xe

      diagnosticResults: [], // kết quả chuẩn đoán
      processingStatus: [], // trạng thái phiếu dịch vụ

      listService: [[]], // các dịch vụ
      services: [[]], // các dịch vụ con
      servicePackages: [[]], // các gói dịch vụ

      distanceFilter: [], // Khoảng cách mong muốn tới Gara
      distanceName: [], // vị trí xe

      appointmentDate: [], // thời gian đặt lịch
      appointmentTime: [],

      requestUtilities: [[]], // IDs các tiện ích
      description: [], // mô tả tình trạng xe

      userLatitude: [], // lat
      userLongitude: [], // long
      placeId: [], // placeId

      survey: [], // các khảo sát giá
      maxSurveyTotalPrice: [], // tổng chi phí
      minSurveyTotalPrice: [], // tổng chi phí

      serviceHistories: [], // lịch sử dịch vụ
      reason: [], // Lí do huỷ

      refRequestTicket: [],
    });
    this.serviceTypeValues = [];
    this.requestTicketValues = [];
    this.serviceTicketValues = [];
    this.expertValues = [];
    this.operatorValues = [];
    this.garageHandleStatusValues = [];
    this.carStatusValues = [];
    this.booking = null;
    this.utilityTree = [];
    this.serviceTree = [];
    this.servicePakageTree = [];
    this.carStatusColumns = [];
    this.carStatusButtons = [];
    this.surveyColumns = [];
    this.surveyButtons = [];
    this.refRequestTicketColumns = [];
  }

  get FileTypes() {
    return FileTypes;
  }

  initSurveyTable() {
    this.surveyColumns = [
      ...[
        {
          columnDef: 'stt',
          header: 'stt',
          title: (e: SurveyModel) => `${Utils.getPosition(e, this.addEditForm.get('survey')?.value)}`,
          cell: (e: SurveyModel) => `${Utils.getPosition(e, this.addEditForm.get('survey')?.value)}`,
          className: 'mat-column-stt',
          isShowHeader: true,
          display: (e: SurveyModel) => true,
          align: AlignEnum.CENTER,
          footers: [
            {
              columnDef: 'total',
              title: (e: SurveyModel) => `Total`,
              cell: (e: SurveyModel) => this.translateService.instant('booking.table.header.total'),
              colspan: 2,
              align: AlignEnum.CENTER,
              display: (e: SurveyModel) => true,
            },
          ],
        },
        {
          columnDef: 'name',
          header: 'service',
          title: (e: SurveyModel) => `${e.name ?? ''}`,
          cell: (e: SurveyModel) => `${e.name ?? ''}`,
          className: 'mat-column-service',
          isShowHeader: true,
          display: (e: SurveyModel) => true,
          footers: [],
        },
        {
          columnDef: 'minPrice',
          header: 'min_price',
          title: (e: SurveyModel) => (e.minPrice ? `${e.minPrice}` : ''),
          cell: (e: SurveyModel) => (e.minPrice ? `${this.formatPriceToString(e.minPrice)}` : ''),
          className: 'mat-column-min-price',
          isShowHeader: true,
          display: (e: SurveyModel) => true,
          columnType: (e: SurveyModel) =>
            this.isView || !this.bookingUtil.checkBookingBehavior(BOOKING_BEHAVIORS.SURVEY, this.booking)
              ? ColumnTypes.VIEW
              : ColumnTypes.INPUT,
          errorMessage: new Map<string, () => string>().set('required', () => 'common.required'),
          onCellValueChange: (e: SurveyModel) => this.changePrice(e),
          align: AlignEnum.RIGHT,
          footers: [
            {
              columnDef: 'minSurveyTotalPrice',
              title: (e: SurveyModel[]) => `${this.calculateSurveyPrice(e, 'MIN_PRICE') ?? ''}`,
              cell: (e: SurveyModel[]) => `${this.calculateSurveyPrice(e, 'MIN_PRICE') ?? ''}`,
              colspan: 1,
              display: (e: SurveyModel) => true,
              align: AlignEnum.RIGHT,
            },
          ],
        },
        {
          columnDef: 'maxPrice',
          header: 'max_price',
          title: (e: SurveyModel) => (e.maxPrice ? `${e.maxPrice}` : ''),
          cell: (e: SurveyModel) => (e.maxPrice ? `${this.formatPriceToString(e.maxPrice)}` : ''),
          className: 'mat-column-max-price',
          isShowHeader: true,
          display: (e: SurveyModel) => true,
          columnType: (e: SurveyModel) =>
            this.isView || !this.bookingUtil.checkBookingBehavior(BOOKING_BEHAVIORS.SURVEY, this.booking)
              ? ColumnTypes.VIEW
              : ColumnTypes.INPUT,
          errorMessage: new Map<string, () => string>()
            .set('required', () => 'common.required')
            .set('largerThanMinPrice', () => 'booking.common.validation.larger_than_min_price'),
          onCellValueChange: (e: SurveyModel) => this.changePrice(e, 'MAX_PRICE'),
          align: AlignEnum.RIGHT,
          footers: [
            {
              columnDef: 'maxSurveyTotalPrice',
              title: (e: SurveyModel[]) => `${this.calculateSurveyPrice(e, 'MAX_PRICE') ?? ''}`,
              cell: (e: SurveyModel[]) => `${this.calculateSurveyPrice(e, 'MAX_PRICE') ?? ''}`,
              colspan: 1,
              display: (e: SurveyModel) => true,
              align: AlignEnum.RIGHT,
            },
          ],
        },
      ],
    ];
  }

  initRefRequestTicketTable() {
    this.refRequestTicketColumns.push(
      ...[
        {
          columnDef: 'requestTicket',
          header: 'request_ticket_id',
          title: (e: BookingModel) => `${e.requestTicketId ?? ''}`,
          cell: (e: BookingModel) => `${e.requestTicketId ?? ''}`,
          className: 'mat-column-request-ticket',
        },
        {
          columnDef: 'reason-status',
          header: 'status',
          title: (e: BookingModel) =>
            `${this.utilsService.getEnumValueTranslated(REQUEST_TICKET_STATUS, e.requestTicketStatus)}`,
          cell: (e: BookingModel) =>
            `${this.utilsService.getEnumValueTranslated(REQUEST_TICKET_STATUS, e.requestTicketStatus)}`,
          className: 'mat-column-reason-status',
        },
        {
          columnDef: 'reason',
          header: 'reason',
          title: (e: BookingModel) => `${e.reason ?? ''}`,
          cell: (e: BookingModel) => `${e.reason ?? ''}`,
          className: 'mat-column-reason',
        },
      ],
    );

    this.refRequestTicketButtons.push(
      ...[
        {
          columnDef: 'detail',
          color: 'warn',
          icon: 'fa fa-eye',
          iconType: IconTypeEnum.FONT_AWESOME,
          click: 'goToBookingById',
          className: 'primary',
          title: 'common.title.detail',
          display: (e: BookingModel) => true,
        },
      ],
    );
  }

  changePrice(survey: SurveyModel, type: 'MIN_PRICE' | 'MAX_PRICE' = 'MIN_PRICE') {
    if (type == 'MIN_PRICE') survey.minPrice = this.formatPriceToString(survey.minPrice);
    if (type == 'MAX_PRICE') survey.maxPrice = this.formatPriceToString(survey.maxPrice);
  }

  calculateSurveyPrice(survey: SurveyModel[], type: 'MIN_PRICE' | 'MAX_PRICE' = 'MIN_PRICE') {
    let result = 0;
    survey.forEach(
      (el: SurveyModel) =>
        (result +=
          type === 'MIN_PRICE'
            ? this.formatStringToPrice(el.minPrice) || 0
            : this.formatStringToPrice(el.maxPrice) || 0),
    );
    return this.formatPriceToString(result);
  }

  formatPriceToString(price: string | number) {
    return `${price}`
      .replace(/\D/g, '')
      .replace(/\s/g, '')
      .replace(/\./g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  formatStringToPrice(price: any) {
    return +`${price}`.toString().replace(/\./g, '');
  }

  initCarStatusTable() {
    this.carStatusColumns.push(
      ...[
        {
          columnDef: 'stt',
          header: 'stt',
          title: (e: CarStatusModel) => `${Utils.getPosition(e, this.addEditForm.get('carStatuses')?.value)}`,
          cell: (e: CarStatusModel) => `${Utils.getPosition(e, this.addEditForm.get('carStatuses')?.value)}`,
          className: 'mat-column-stt',
          isShowHeader: true,
          display: (e: CarStatusModel) => true,
          align: AlignEnum.CENTER,
        },
        // For edit: columDef must map with key. Ex: columnDef: 'value', cell: e.value
        {
          columnDef: 'value',
          header: 'car_status',
          title: (e: CarStatusModel) => `${this.isView && e.value ? e.value : e.displayValue || ''}`,
          cell: (e: CarStatusModel) => `${this.isView && e.value ? e.value : e.displayValue || ''}`,
          className: 'mat-column-car-status',
          isShowHeader: true,
          display: (e: CarStatusModel) => true,
          isRequired: true,
          errorMessage: new Map<string, () => string>().set('required', () => 'common.required'),
          columnType: (e: CarStatusModel) => {
            if (
              this.isView ||
              !this.bookingUtil.checkBookingBehavior(BOOKING_BEHAVIORS.CHANGE_BOOKING_INFO, this.booking)
            )
              return ColumnTypes.VIEW;
            return e?.isNew ? ColumnTypes.INPUT : ColumnTypes.MULTI_SELECT_AUTOCOMPLETE;
          },
          optionValues: () => this.carStatusValues,
        },
      ],
    );

    this.carStatusButtons.push({
      columnDef: 'remove',
      color: 'warn',
      icon: 'clear',
      title: 'common.title.clear',
      click: 'onRemoveCarStatus',
      isShowHeader: true,
      display: (e: CarStatusModel) => true,
      disabled: (e: CarStatusModel) =>
        this.isView ||
        this.addEditForm.get('carStatuses')?.value?.length <= 1 ||
        !this.bookingUtil.checkBookingBehavior(BOOKING_BEHAVIORS.CHANGE_BOOKING_INFO, this.booking),
      header: {
        columnDef: 'add',
        color: 'warn',
        icon: 'add',
        title: 'common.title.add',
        click: 'onAddCarStatus',
        display: (e: CarStatusModel) => true,
        disabled: (e: CarStatusModel) =>
          this.isView || !this.bookingUtil.checkBookingBehavior(BOOKING_BEHAVIORS.CHANGE_BOOKING_INFO, this.booking),
      },
    });
  }

  initData() {
    EnumUtil.enum2SelectModel(REQUEST_TICKET_STATUS, this.requestTicketValues, 'EDIT');
    EnumUtil.enum2SelectModel(SERVICE_TICKET_STATUS, this.serviceTicketValues, 'EDIT');
    EnumUtil.enum2SelectModel(SERIVCE_TYPE, this.serviceTypeValues, 'EDIT');
    EnumUtil.enum2SelectModel(GARA_STATUS, this.garageHandleStatusValues, 'EDIT');

    const params = new HttpParams()
      .set('status', EnumUtil.getKeysByValues(SuperStatusEnum, [SuperStatusEnum._ACCEPTED]))
      .set('pageNumber', '1')
      .set('pageSize', environment.INTEGER_MAX_VALUE);

    const apis = [
      this.apiService.get<Page>(ModuleConst.CMS + '/expert', params),
      this.apiService.get<Page>(ModuleConst.CMS + '/operator', params),
      this.apiService.get<Page>(ModuleConst.CMS + '/utility', params),
      this.apiService.get<Page>(ModuleConst.BOOKING + '/car-status', params),
    ];

    zip(apis).subscribe(async ([pageExpert, pageOperator, pageUtility, pageCarStatus]) => {
      if (pageExpert && pageExpert.hasOwnProperty('content')) {
        this.expertValues = (pageExpert as Page).content.map(
          (expert: ExpertModel) => new SelectModel(expert.id, expert.name, this.isView, expert.phone),
        );
      }

      if (pageOperator && pageOperator.hasOwnProperty('content')) {
        this.operatorValues = (pageOperator as Page).content.map(
          (operator: OperatorModel) => new SelectModel(operator.id, operator.name, this.isView, operator.phone),
        );
      }

      if (pageUtility && pageUtility.hasOwnProperty('content')) {
        this.pageUtilities = (pageUtility as Page).content as UtilityModel[];
        this.utilityTree = (pageUtility as Page).content.map(
          (utility: UtilityModel) => <FlatTreeNode>{ value: utility.id, displayValue: utility.name },
        );
      }

      if (pageCarStatus && pageCarStatus.hasOwnProperty('content')) {
        this.carStatusValues = [];
        this.carStatusValues = (pageCarStatus as Page).content.map(
          (carStatus: CarStatusModel) => new SelectModel(carStatus.id, `${carStatus.value}`, this.isView),
        );
      }
    });

    this.getBookingById();
  }

  getBookingById() {
    this.apiService
      .get(`${ModuleConst.BOOKING}/expert/appointment-schedule/${this.id}`, new HttpParams())
      .subscribe(async (booking) => {
        if (booking) {
          this.booking = booking as unknown as BookingModel;
          this.bookingDetailService.saveOldBooking({ ...this.booking });
          this.addEditForm.setValue(UtilsService.reduceEntityAttributeForFormControl(this.addEditForm, this.booking));

          // Push booking utilities in utilityTree
          if (this.booking.requestUtilities?.length) {
            this.booking.requestUtilities.forEach((utility) => {
              if (!this.utilityTree.some((el) => el.value === utility.refId))
                this.utilityTree = [
                  ...this.utilityTree,
                  <FlatTreeNode>{ value: utility.refId, displayValue: utility.name },
                ];
            });
          }

          // Get listService by booking type
          let params = new HttpParams()
            .set('status', EnumUtil.getKeysByValues(SuperStatusEnum, [SuperStatusEnum._ACCEPTED]))
            .set('pageNumber', '1')
            .set('pageSize', environment.INTEGER_MAX_VALUE);
          if (this.booking?.serviceType) params = params.set('type', this.booking.serviceType);

          await this.apiService.get(ModuleConst.CMS + '/service/supers-service', params).subscribe((pageService) => {
            if (pageService) {
              this.pageServices = pageService as SuperServiceModel[];
              this.serviceTree = [];
              this.servicePakageTree = [];
              (pageService as any).forEach((service: SuperServiceModel) => {
                if (service.type === SERVICE_LABEL.SERVICE) {
                  this.serviceTree = [
                    ...this.serviceTree,
                    <FlatTreeNode>{ value: service.id, displayValue: service.name },
                  ];
                } else
                  this.servicePakageTree = [
                    ...this.servicePakageTree,
                    <FlatTreeNode>{ value: service.id, displayValue: service.name },
                  ];
              });
            }
          });

          // Push booking services in serviceTree
          if (this.booking.listService?.length) {
            this.booking.listService.forEach((service) => {
              if (
                service.type === SERVICE_LABEL.SERVICE &&
                !this.serviceTree.some((el) => el.value === service.refId)
              ) {
                this.serviceTree = [
                  ...this.serviceTree,
                  <FlatTreeNode>{ value: service.refId, displayValue: service.name },
                ];
              }
              if (
                service.type === SERVICE_LABEL.SERVICEPACKAGE &&
                !this.servicePakageTree.some((el) => el.value === service.refId)
              ) {
                this.servicePakageTree = [
                  ...this.servicePakageTree,
                  <FlatTreeNode>{ value: service.refId, displayValue: service.name },
                ];
              }
            });
          }

          this.initServicesAndServicePackages();

          if (this.booking.refRequestTicket)
            this.addEditForm.get('refRequestTicket')?.setValue([this.booking.refRequestTicket]);

          if (this.booking?.survey?.length) {
            const tempSurvey = this.booking?.survey.map((el: SurveyModel) => {
              return {
                id: el.refId,
                name: el.name,
                minPrice: el.minPrice,
                maxPrice: el.maxPrice,
              };
            });
            this.addEditForm.get('survey')?.setValue(tempSurvey);
          } else if (!this.isView) this.initSurveyByServices();

          if (this.booking?.carStatuses) this.initCarStatus();

          if (this.booking?.carStatusImages) this.initCarStatusImages();

          if (this.booking?.requestUtilities?.length)
            this.addEditForm.get('requestUtilities')?.setValue(this.booking?.requestUtilities.map((u) => u.refId));

          if (this.booking?.appointmentDate) {
            this.addEditForm
              .get('appointmentDate')
              ?.setValue(
                `${this.datePipe.transform(this.booking?.appointmentDate, 'YYYY-MM-dd', 'UTC')} 00:00:00.000Z`,
              );

            this.addEditForm
              .get('appointmentTime')
              ?.setValue(this.datePipe.transform(this.booking?.appointmentDate, 'HH:mm', 'UTC'));
          }
        }
      });
  }

  initServicesAndServicePackages() {
    let services: any = [];
    let servicePackages: any = [];

    this.booking?.listService?.forEach((s) => {
      if (s.type === SERVICE_LABEL.SERVICE) {
        services.push(s.refId);
      } else {
        servicePackages.push(s.refId);
      }
    });

    this.addEditForm.get('services')?.setValue(services);
    this.addEditForm.get('servicePackages')?.setValue(servicePackages);
  }

  initSurveyByServices() {
    const survey = this.booking?.listService.map((el: Service) => {
      if (el.type === SERVICE_LABEL.SERVICE)
        return {
          id: el.refId,
          name: el.name,
          minPrice: null,
          maxPrice: null,
        };
    });

    this.addEditForm.get('survey')?.setValue(survey);
  }

  initCarStatusImages() {
    const files = this.booking?.carStatusImages;
    if (files && files.length > 0) {
      const urls = files.map((value) => {
        if (value && value.imageUrl) {
          const type = UploadModel.getFileType(value.imageUrl);
          const id = value.id ? value.id : undefined;
          const url = environment.BASE_URL + '/' + value.imageUrl;
          return new UploadModel('', null, url, type, id);
        }
      });
      this.addEditForm.get('carStatusImages')?.setValue(urls);
    }
  }

  // Push booking carStatus in carStatusValues
  initCarStatus() {
    let carStatus = this.booking?.carStatuses;
    if (carStatus) {
      carStatus = carStatus.map((el: CarStatusModel) => {
        this.carStatusValues = [
          ...this.carStatusValues,
          <SelectModel>{
            value: el.carStatusId,
            displayValue: el.value,
          },
        ];
        return <SelectModel>{
          value: el.carStatusId,
          displayValue: el.value,
        };
      });

      if (!this.isView) this.addEditForm.get('carStatuses')?.setValue(carStatus);
      else this.addEditForm.get('carStatuses')?.setValue(this.booking?.carStatuses);
    }
  }

  onRemoveCarStatus(row: CarStatusModel, index: number) {
    const carStatus: CarStatusModel[] = this.addEditForm.get('carStatuses')?.value;
    if (carStatus?.length) {
      carStatus.splice(index, 1);
      this.addEditForm.get('carStatuses')?.setValue(carStatus);
    }
  }

  onAddCarStatus() {
    this.addCarStatusBtn?.openMenu();
  }

  addCarStatus(type: 'NEW' | 'SELECT') {
    let carStatus: CarStatusModel[] = this.addEditForm.get('carStatuses')?.value;
    if (!carStatus) {
      carStatus = [];
    }
    const newCarStaus: CarStatusModel = {
      value: '',
    };

    newCarStaus['isNew'] = type === 'NEW';
    carStatus.push(newCarStaus);
    this.addEditForm.get('carStatuses')?.setValue(carStatus);
  }

  viewCarDetail() {
    this.matDialog
      .open(CarInfoViewComponent, {
        maxWidth: '90vw',
        maxHeight: '90vh',
        width: '500px',
        panelClass: 'car-info-view-component-panel-class',
        data: this.booking?.carRefId,
      })
      .afterClosed();
  }

  onBookingDateChange(date: string) {
    this.hourValues = this.generateHourValues(date);
  }

  selectGarage() {
    const {
      garageRefId,
      placeId,
      distanceFilter,
      appointmentDate,
      appointmentTime,
      services,
      servicePackages,
      requestUtilities,
      userLatitude,
      userLongitude,
    } = this.addEditForm.value;

    this.matDialog
      .open(PopupChooseGarageComponent, {
        maxWidth: '90vw',
        maxHeight: '90vh',
        panelClass: 'choose-garage-modal',
        data: {
          garageId: garageRefId,
          preQuery: {
            pageNumber: '1',
            status: EnumUtil.getKeysByValues(SuperStatusEnum, [SuperStatusEnum._ACCEPTED]),
            bookingDate: appointmentDate,
            bookingTime: appointmentTime,
            placeId,
            maxDistance: distanceFilter,
            utilityIds: (requestUtilities || []).join(','),
            servicePackageIds: (servicePackages || []).join(','),
            serviceIds: (services || []).join(','),
            currentLat: userLatitude,
            currentLng: userLongitude,
          },
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res.garage) {
          const garage: GarageModel = res.garage;
          this.addEditForm.get('garageRefId')?.setValue(garage.id);
          this.addEditForm.get('garageName')?.setValue(garage.name);
          this.addEditForm.get('garagePhone')?.setValue(garage.contactPointPhone);
          this.addEditForm.get('garageAddress')?.setValue(garage.address);
          this.addEditForm.get('garageHandleStatus')?.setValue('ACCEPTED');
        }
      });
  }

  editBooking() {
    if (this.isView) {
      return this.router.navigateByUrl(`/booking/edit/${this.booking?.requestTicketId}`);
    }

    const {
      requestTicketId,
      survey,
      garageRefId,
      expertRefId,
      expertName,
      expertPhone,
      operatorId,
      operatorName,
      operatorPhone,
      carStatuses,
      appointmentDate,
      appointmentTime,
      services,
      requestUtilities,
    } = this.addEditForm.value;
    const params: Partial<BookingDetailData> = {
      appointmentId: requestTicketId,
      survey: this.transformSurvey(survey),
      garageId: garageRefId,
      expert: {
        id: expertRefId,
        name: expertName,
        phone: expertPhone,
      },
      operator: {
        id: operatorId,
        name: operatorName,
        phone: operatorPhone,
      },
      carStatuses: this.transformCarStatuses(carStatuses),
      appointmentDate: this.transformAppointmentDate(appointmentDate, appointmentTime),
      listService: this.transformServices(services),
      requestUtilities: this.transformUtilities(requestUtilities),
    };

    this.bookingDetailService.updateData(params);
    return this.saveBooking();
  }

  transformAppointmentDate(date: string, time: string) {
    return `${this.datePipe.transform(new Date(date), 'yyyy-MM-dd')} ${time}:00.000Z`;
  }

  transformCarStatuses(carStatuses = []) {
    let res: any = [];
    carStatuses.forEach((el: CarStatusModel) => {
      if (el.isNew) {
        res.push({
          value: el.value,
        });
      } else
        res.push({
          id: el.value,
          value: el.displayValue ?? '' + this.carStatusValues.find((item) => item.value === el.value)?.displayValue,
        });
    });

    return res;
  }

  transformSurvey(survey = []) {
    let res: any = [];
    survey = survey.filter((el: SurveyModel) => el.minPrice !== '' || el.maxPrice !== '');
    survey.forEach((el: SurveyModel) => {
      res.push({
        id: el.id,
        minPrice: this.formatStringToPrice(el.minPrice),
        maxPrice: this.formatStringToPrice(el.maxPrice),
        name: el.name,
      });
    });

    return res;
  }

  transformServices(serviceIds = []): Partial<SuperServiceModel>[] {
    let res: any = [];
    serviceIds.forEach((serviceId) => {
      this.pageServices.forEach((el: SuperServiceModel) => {
        if (el.id === serviceId && el.type === SERVICE_LABEL.SERVICE)
          res.push({
            id: el.id,
            type: el.type,
            name: el.name,
          });
      });
    });
    return res;
  }

  reRenderSurvey(listService: FlatTreeNode[]) {
    if (!this.isView) {
      let newSurvey: SurveyModel[] = [];
      let currentSurvey: SurveyModel[] = this.addEditForm.get('survey')?.value || [];

      listService.forEach((service) => {
        let survey = currentSurvey.find((el) => el.id === service.value);
        newSurvey.push({
          id: service.value,
          name: service.displayValue,
          minPrice: survey?.minPrice ? this.formatPriceToString(survey.minPrice) : '',
          maxPrice: survey?.maxPrice ? this.formatPriceToString(survey.maxPrice) : '',
        });
      });

      this.addEditForm.get('survey')?.setValue(newSurvey);
      this.initSurveyTable();
    }
  }

  transformUtilities(requestUtilityIds = []) {
    let res: any = [];
    requestUtilityIds.forEach((utilityId) => {
      this.pageUtilities.forEach((el: UtilityModel) => {
        if (el.id === utilityId) res.push(el);
      });
    });
    return res;
  }

  saveBooking() {
    const request = this.bookingDetailService.buildRequest();
    this.bookingDetailService.saveBooking(request);
  }

  goToBookingById(booking: BookingModel) {
    return this.router.navigateByUrl(`/booking/detail/${booking?.requestTicketId}`);
  }

  cancelBooking() {
    this.matDialog
      .open(PopupCancelBookingComponent, {
        width: '1000px',
        maxHeight: '90vh',
        data: {
          type: !!this.booking?.serviceTicketId,
          id: this.booking?.requestTicketId,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res?.action === 'getBookingById') this.getBookingById();
        if (res?.action === 'selectGarage') this.selectGarage();
      });
  }

  changeExpert(event: any) {
    const expert = this.expertValues.find((e: SelectModel) => e.value === event);
    this.addEditForm.get('expertPhone')?.setValue(expert?.rawData);
    this.addEditForm.get('expertName')?.setValue(expert?.displayValue);
  }

  changeOperator(event: any) {
    const operator = this.operatorValues.find((e: SelectModel) => e.value === event);
    this.addEditForm.get('operatorPhone')?.setValue(operator?.rawData);
    this.addEditForm.get('operatorName')?.setValue(operator?.displayValue);
  }

  disableHourValues(date: string, hour: number, minute: number) {
    const STEP = 30;
    const receivedDate = new Date(date);
    const currentDate = new Date();
    const isToday =
      receivedDate.getFullYear() === currentDate.getFullYear() &&
      receivedDate.getMonth() === currentDate.getMonth() &&
      receivedDate.getDay() === currentDate.getDay();
    if (isToday) {
      const currentHour = currentDate.getHours();
      const currentMinute = currentDate.getMinutes();
      return currentHour * 60 + currentMinute + STEP >= hour * 60 + minute;
    }
    return false;
  }

  generateHourValues(date = ''): SelectModel[] {
    const STEP = 30;
    let minHour = 0;
    let minMin = 0;

    const results = [];
    for (let i = minHour; i < 24; i++) {
      for (let j = minMin; j < 60; j += STEP) {
        const data = `${this.formatNumber(i)}:${this.formatNumber(j)}`;
        results.push({
          value: data,
          displayValue: data,
          disabled: this.disableHourValues(date, i, j),
          rawData: data,
        });
      }
    }
    return results;
  }

  formatNumber(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  openPageOnNewTab(page: string) {
    const nextWindow = window.open(page, '_blank');
    const token = window.sessionStorage.getItem('token');
    nextWindow?.sessionStorage.setItem('token', token || '');
  }

  audioCall() {
    this.openZegoCloud(ZEGO_CONVERSATION_TYPE.AUDIO_CALL, this.booking);
  }

  videoCall() {
    this.openZegoCloud(ZEGO_CONVERSATION_TYPE.VIDEO_CALL, this.booking);
  }

  openZegoCloud(type: ZEGO_CONVERSATION_TYPE, row: BookingModel | null) {
    if (row) {
      const url = `/#/booking/ipcc/call/${row.requestTicketId}?type=${type}`;
      const nextWindow = window.open(url, '_blank');
      const token = window.sessionStorage.getItem('token');
      nextWindow?.sessionStorage.setItem('token', token || '');
    }
  }

  chat() {
    if (this.booking) {
      // const url = `/#/booking/chat?requestTicketId=${this.booking.requestTicketId}&type=${ZEGO_CONVERSATION_TYPE.ROOM_CHAT}`;
      // const nextWindow = window.open(url, '_blank');
      // const token = window.sessionStorage.getItem('token');
      // nextWindow?.sessionStorage.setItem('token', token || '');
      this.router.navigate(['booking', 'chat'], {
        queryParams: { requestTicketId: this.booking.requestTicketId, type: ZEGO_CONVERSATION_TYPE.ROOM_CHAT },
      });
    }
  }

  hideChatByTicketStatus(status: string) {
    return EnumUtil.getKeysByValues(REQUEST_TICKET_STATUS, [
      REQUEST_TICKET_STATUS._NEW,
      REQUEST_TICKET_STATUS._PENDING,
      REQUEST_TICKET_STATUS._GARAGE_CONFIRMED,
      REQUEST_TICKET_STATUS._COMPLETED,
      REQUEST_TICKET_STATUS._CANCELED,
    ])
      .replace(/\s/g, '')
      .split(',')
      .includes(status);
  }
}
