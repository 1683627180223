import { Injectable } from '@angular/core';
import { ApiService, AuthoritiesService, UtilsService } from '@next-solutions/next-solutions-base';
import { SuperStatusEnum } from '../../enums/super.status.enum';
import { MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';
import { BrandModel } from '../../models/cms/brand.model';
import { ModuleConst } from '../../modules/module.const';

@Injectable({ providedIn: 'root' })
export class BrandUtil {
  constructor(protected authoritiesService: AuthoritiesService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected translateService: TranslateService) {
  }

  hasAddEditPermission(brand: BrandModel | null): boolean {
    if (brand && brand.id) {
      return this.authoritiesService.hasAuthority('patch/brand/{id}');
    } else {
      return this.authoritiesService.hasAuthority('post/brand');
    }
  }

  hasAcceptPermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/brand/{id}/accept');
  }

  isDisableAccept(e: BrandModel | null): boolean {
    if (e) {
      return SuperStatusEnum['_' + e.status] === SuperStatusEnum._ACCEPTED;
    }
    return false;
  }

  hasRejectPermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/brand/{id}/reject');
  }

  isDisableReject(e: BrandModel | null): boolean {
    if (e) {
      return SuperStatusEnum['_' + e.status] === SuperStatusEnum._REJECTED;
    }
    return true;
  }

  onAccept(brand: BrandModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (brand) {
      const method = this.apiService.patch(ModuleConst.CMS + '/brand/' + brand.id + '/accept', null);
      this.utilsService.execute(method, onSuccessFunc, 'common.accept.success',
        'common.confirm.accept', ['brand.']);
    }
  }

  onReject(brand: BrandModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (brand) {
      const strOk = this.translateService.instant('common.ok');
      const strCancel = this.translateService.instant('common.cancel');
      const strReasonReject = this.translateService.instant('common.reject.reason');
      const dialogConfig: MatDialogConfig<any> = {
        data: {
          customClass: 'reject_reason_dialog hint-outside',
          msg: strReasonReject,
          type: 'INPUT_CONFIRM',
          btnOKString: strOk,
          btnCancelString: strCancel,
        },
      };
      this.utilsService.showConfirmInputDialog('common.reject.reason', [], dialogConfig)
        .afterClosed().subscribe(result => {
        if (result && result.value) {
          const params = new HttpParams().set('rejectReason', result.value);
          this.apiService.patch(`/cms/brand/${brand.id}/reject`, null, { params })
            .subscribe(data => {
                onSuccessFunc(data, 'common.reject.success');
              },
              error => {
                this.utilsService.showError(error);
              });
        }
      });
    }
  }

  hasExportDataPermission(): boolean {
    return this.authoritiesService.hasAuthority('post/export-data/brand');
  }

  hasImportExcelPermission(): boolean {
    return this.authoritiesService.hasAuthority('post/import-data/brand');
  }

  hasDownloadTemplatePermission(): boolean {
    return this.authoritiesService.hasAuthority('post/download-template/brand');
  }
}
