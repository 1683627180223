import {
  BookingModel,
  BOOKING_BEHAVIORS,
  REQUEST_TICKET_STATUS,
  SERVICE_TICKET_STATUS, CarStatusModel,
} from './../../models/booking/booking.model';
import { Injectable } from '@angular/core';
import { ApiService, AuthoritiesService, UtilsService } from '@next-solutions/next-solutions-base';
import { TranslateService } from '@ngx-translate/core';
import { EnumUtil } from '../enum.util';
import {ModuleConst} from 'src/app/modules/module.const';
import {SuperStatusEnum} from 'src/app/enums/super.status.enum';

@Injectable({ providedIn: 'root' })
export class BookingUtil {
  constructor(
    protected authoritiesService: AuthoritiesService,
    protected apiService: ApiService,
    protected utilsService: UtilsService,
    protected translateService: TranslateService,
  ) {}

  hasPermission(booking: BookingModel | null): boolean {
    return this.isMasterExpert() || this.isExpertOrOP(booking);
  }

  isMasterExpert(): boolean {
    return this.authoritiesService.hasAuthority('/master-expert');
  }

  isExpertOrOP(booking: BookingModel | null): boolean {
    try {
      const currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '');
      return !!(
        currentUser &&
        ((currentUser.systemRefUserId === booking?.expertRefId && currentUser.systemRefUserType === 'EXPERT') ||
          (currentUser.systemRefUserId === booking?.operatorId && currentUser.systemRefUserType === 'OPERATOR'))
      );
    } catch (err) {
      return false;
    }
  }

  isExpert(booking: BookingModel | null): boolean {
    try {
      const currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '');
      return !!(
        currentUser &&
        currentUser.systemRefUserId === booking?.expertRefId &&
        currentUser.systemRefUserType === 'EXPERT'
      );
    } catch (err) {
      return false;
    }
  }

  isOP(): boolean {
    try {
      const currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '');
      return !!(currentUser && currentUser.systemRefUserType === 'OPERATOR');
    } catch (err) {
      return false;
    }
  }

  checkBookingBehavior(buttonType: string, booking: BookingModel | null): boolean {
    let inValidRequestStatus: string;
    let inValidServiceStatus: string;

    switch (buttonType) {
      // button chỉnh sửa
      case BOOKING_BEHAVIORS.EDIT:
      // chỉ định expert, operator
      case BOOKING_BEHAVIORS.ASSIGN_EXPERT_AND_OPERATOR:
        inValidRequestStatus = EnumUtil.getKeysByValues(REQUEST_TICKET_STATUS, [REQUEST_TICKET_STATUS._CANCELED]);
        inValidServiceStatus = EnumUtil.getKeysByValues(SERVICE_TICKET_STATUS, [
          SERVICE_TICKET_STATUS._CANCELED,
          SERVICE_TICKET_STATUS._REFUSE_PRICE_QUOTATION,
        ]);

        if (booking?.serviceTicketStatus)
          return (
            !inValidRequestStatus.includes(booking?.requestTicketStatus as string) ||
            !inValidServiceStatus.includes(booking?.serviceTicketStatus as string)
          );

        return !inValidRequestStatus.includes(booking?.requestTicketStatus as string);

      // button huỷ lịch
      case BOOKING_BEHAVIORS.CANCEL:
        inValidRequestStatus = EnumUtil.getKeysByValues(REQUEST_TICKET_STATUS, [
          REQUEST_TICKET_STATUS._NEW,
          REQUEST_TICKET_STATUS._PENDING,
          REQUEST_TICKET_STATUS._GARAGE_CONFIRMED,
          REQUEST_TICKET_STATUS._CUSTOMER_CHECKED_IN,
          REQUEST_TICKET_STATUS._IN_PROCESSING,
        ]);

        inValidServiceStatus = EnumUtil.getKeysByValues(SERVICE_TICKET_STATUS, [
          SERVICE_TICKET_STATUS._CHECKING,
          SERVICE_TICKET_STATUS._CHECKED,
          SERVICE_TICKET_STATUS._WAITING_CUSTOMER_APPROVE_PRICE,
        ]);

        if (booking?.serviceTicketStatus)
          return (
            inValidRequestStatus.includes(booking?.requestTicketStatus as string) ||
            inValidServiceStatus.includes(booking?.serviceTicketStatus as string)
          );

        return inValidRequestStatus.includes(booking?.requestTicketStatus as string);

      // button chỉ định gara, giờ, phút đặt hẹn, tình trạng xe
      case BOOKING_BEHAVIORS.ASSIGN_GARA:
      case BOOKING_BEHAVIORS.CHANGE_BOOKING_INFO:
        inValidRequestStatus = EnumUtil.getKeysByValues(REQUEST_TICKET_STATUS, [
          REQUEST_TICKET_STATUS._NEW,
          REQUEST_TICKET_STATUS._PENDING,
          REQUEST_TICKET_STATUS._GARAGE_CONFIRMED,
        ]);

        return inValidRequestStatus.includes(booking?.requestTicketStatus as string);

      // show khảo sát giá
      case BOOKING_BEHAVIORS.SURVEY:
        inValidRequestStatus = EnumUtil.getKeysByValues(REQUEST_TICKET_STATUS, [
          REQUEST_TICKET_STATUS._NEW,
          REQUEST_TICKET_STATUS._PENDING,
          REQUEST_TICKET_STATUS._GARAGE_CONFIRMED,
          REQUEST_TICKET_STATUS._CUSTOMER_CHECKED_IN,
          REQUEST_TICKET_STATUS._IN_PROCESSING,
        ]);

        inValidServiceStatus = EnumUtil.getKeysByValues(SERVICE_TICKET_STATUS, [
          SERVICE_TICKET_STATUS._CHECKING,
          SERVICE_TICKET_STATUS._CHECKED,
          SERVICE_TICKET_STATUS._WAITING_CUSTOMER_APPROVE_PRICE,
        ]);

        if (booking?.serviceTicketStatus)
          return (
            inValidRequestStatus.includes(booking?.requestTicketStatus as string) ||
            inValidServiceStatus.includes(booking?.serviceTicketStatus as string)
          );

        return inValidRequestStatus.includes(booking?.requestTicketStatus as string);

      // thêm báo giá ở phiếu dịch vụ
      case BOOKING_BEHAVIORS.ADD_SERVICE:
        inValidRequestStatus = EnumUtil.getKeysByValues(REQUEST_TICKET_STATUS, [REQUEST_TICKET_STATUS._COMPLETED]);

        inValidServiceStatus = EnumUtil.getKeysByValues(SERVICE_TICKET_STATUS, [
          SERVICE_TICKET_STATUS._COMPLETED,
          SERVICE_TICKET_STATUS._GARAGE_HANDED_OVER_CAR,
        ]);

        return (
          inValidRequestStatus.includes(booking?.requestTicketStatus as string) &&
          inValidServiceStatus.includes(booking?.serviceTicketStatus as string)
        );

      default:
        return true;
    }
  }

  formatCurrency(total: number | string, delimiter = '.', currency = 'đ', defaultValue = '0') {
    console.log('@== total', total);
    const result = String(Number(String(total || '').replace(/\./g, '')) || '')
      .split('')
      .reverse()
      .reduce((acc: string[], digit, index, self) => {
        acc.push(digit);
        if ((index + 1) % 3 === 0 && index < self.length - 1) {
          acc.push(delimiter);
        }
        return acc;
      }, []);
    const formatted = result.reverse().join('');
    console.log('@== formatted', formatted);
    return `${formatted || defaultValue}${currency}`;
  }

  onAccept(carStatus: CarStatusModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (carStatus) {
      const method = this.apiService.patch(ModuleConst.BOOKING + '/car-status/' + carStatus.id + '/accept', null);
      this.utilsService.execute(method, onSuccessFunc, 'common.accept.success',
        'common.confirm.accept', ['car_status.']);
    }
  }

  onReject(carStatus: CarStatusModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (carStatus) {
      const method = this.apiService.patch(ModuleConst.BOOKING + '/car-status/' + carStatus.id + '/reject', null);
      this.utilsService.execute(method, onSuccessFunc, 'common.accept.success',
        'common.confirm.reject', ['car_status.']);
    }
  }

  isDisableAccept(a: CarStatusModel | null): boolean {
    if (a) {
      return SuperStatusEnum['_' + a.status] === SuperStatusEnum._ACCEPTED;
    }
    return false;
  }

  isDisableReject(a: CarStatusModel | null): boolean {
    if (a) {
      return SuperStatusEnum['_' + a.status] === SuperStatusEnum._REJECTED;
    }
    return true;
  }

  isShowChat() {
    try {
      const currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '');
      return !!(currentUser && (currentUser.systemRefUserType === 'OPERATOR' || currentUser.systemRefUserType === 'EXPERT'));
    } catch (err) {
      return false;
    }
  }

}
