import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BookingCreateService, GenericModel } from '../../booking-create/booking-create.service';
import { FormBuilder, Validators } from '@angular/forms';
import { debounceTime, forkJoin, map, mergeMap, takeUntil, throwError } from 'rxjs';
import { ApiService } from '@next-solutions/next-solutions-base';
import { ModuleConst } from 'src/app/modules/module.const';
import { collectParams } from 'src/app/utils/booking/api.util';
import { environment as env } from '../../../../../../environments/environment';
import { CountryModel } from 'src/app/models/config/country.model';
import { AreaModel } from 'src/app/models/cms/area.model';
import { DriverModel } from 'src/app/models/cms/driver.model';
import { DriverCarModel } from 'src/app/models/cms/driver-car.model';
import { DestroyService } from 'src/app/_services/destroy.service';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-doctor-booking-create-step-2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [DestroyService],
})
export class Step2Component implements OnInit {
  driverInfo?: DriverModel;
  selectedCar?: DriverCarModel;
  form = this._fb.group({
    phone: ['', Validators.required],
  });
  areas: AreaModel[] = [];
  countries: CountryModel[] = [];
  isSearchDriverError = false;
  isEmptyCar = false;
  inputErrorMsg = new Map<string, () => string>()
    .set('required', () => this._translateService.instant('common.required'))
    .set('phoneNotFound', () => this._translateService.instant('booking.step2.driver.search.error'));

  constructor(
    private _bookingCreateService: BookingCreateService,
    private _translateService: TranslateService,
    private _apiService: ApiService,
    private _destroy: DestroyService,
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private _fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.initData();
    this.form.valueChanges
      .pipe(
        debounceTime(500),
        takeUntil(this._destroy),
      )
      .subscribe((values) => {
        if (!values?.phone) return;
        this.searchDriver(values?.phone || '').subscribe(data => {
          if (data?.driverCars && data?.driverCars.length > 0 && data?.phone === values?.phone) {
            const { step2 } = this._bookingCreateService.getData();
            this.driverInfo = data;
            this.selectCar(step2?.carInfo ? step2?.carInfo : undefined);
            this.isEmptyCar = false;
          } else if (data?.driverCars && data?.driverCars.length === 0 && data?.phone === values?.phone) {
            this.driverInfo = data;
            this.selectCar(undefined);
            this.isEmptyCar = true;
          } else {
            this.form.get('phone')?.setErrors({ phoneNotFound: true });
            this.driverInfo = undefined;
            this.selectCar(undefined);
            this.isEmptyCar = true;
          }
          this._cdr.markForCheck();
        });
    });
    this._bookingCreateService.fetchAddresses()
      .pipe(takeUntil(this._destroy))
      .subscribe(({ areas, countries }) => {
        this.areas = areas;
        this.countries = countries;
        this._cdr.markForCheck();
      });
  }

  initData() {
    const { step2 } = this._bookingCreateService.getData();
    this.selectedCar = step2?.carInfo;
    this.driverInfo = step2?.driverInfo;
    this.form.get('phone')?.setValue(step2?.phone || '');
    this.form.updateValueAndValidity({ onlySelf: true, emitEvent: true });
  }

  nextStep() {
    this._bookingCreateService.saveData({
      step2: {
        driverInfo: this.driverInfo,
        carInfo: this.selectedCar,
        phone: this.form.get('phone')?.value || '',
      }
    });
    this._bookingCreateService.nextStep();
  }

  onInput($event: any) {
    this.form.patchValue({ phone: $event.target.value });
    this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  fetchAddresses() {
    const params = collectParams({ pageSize: env.INTEGER_MAX_VALUE, pageNumber: '1', status: 'ACCEPTED' });
    return forkJoin([
      this._apiService.get<GenericModel>(ModuleConst.CMS + '/area', params),
      this._apiService.get<GenericModel>(ModuleConst.CMS + '/country', params),
    ]).pipe(
      map(([respArea, respCountry]) => ({
        areas: respArea?.content || [],
        countries: respCountry?.content || []
      }))
    )
  }

  searchDriver(phone = '') {
    this.isSearchDriverError = false;
    const driverParams = collectParams({ phone }, true);
    return this._apiService.get<GenericModel>(ModuleConst.CMS + '/driver', driverParams)
      .pipe(
        map(resp => {
          if (!resp?.content) return undefined;
          const content: GenericModel = resp.content;
          if (!content || !content[0]) {
            this.driverInfo = undefined;
            this.selectCar(undefined);
            this.form.get('phone')?.setErrors({ phoneNotFound: true });
            this._cdr.markForCheck();
          }
          return content ? content[0] : undefined
        }),
        mergeMap((driver) => {
          const detailParams = collectParams({}, false)
          return this._apiService.get<GenericModel>(ModuleConst.BOOKING + `/driver/${driver?.id}`, detailParams)
            .pipe(
              map((driverDetail) => ({
                ...driver,
                ...driverDetail,
              }))
            )
        }),
        catchError(error => {
          this.isSearchDriverError = true;
          this._cdr.markForCheck();
          return throwError(error);
        })
      );
  }

  selectCar(carInfo?: DriverCarModel) {
    this.selectedCar = carInfo;
    const { step2 } = this._bookingCreateService.getData();
    this._bookingCreateService.saveData({ step2: {
        ...step2,
        carInfo,
      },
    });
  }

  newCar() {
    this._router.navigate(['cms', 'driver', 'edit', this.driverInfo?.id])
  }

  createDriver() {
    this._router.navigate(['cms', 'driver', 'add'])
  }

  buildAddress(): string {
    if (!this.driverInfo) return '';
    this.driverInfo.driverAddresses = this.driverInfo.driverAddresses ?? [];
    if (this.driverInfo.driverAddresses.length === 0) return '';
    const { districtId, provinceId, wardId, address } = this.driverInfo.driverAddresses[0];
    const district = this.areas.find(area => area.type === 'DISTRICT' && area.id === districtId);
    const province = this.areas.find(area => area.type === 'PROVINCE' && area.id === provinceId);
    const ward = this.areas.find(area => area.type === 'WARD' && area.id === wardId);
    if (ward && district && province) {
      return `${address ? address : ''}, ${ward?.name}, ${district?.name}, ${province?.name}`;
    }
    return '';
  }

  getCarImg(carImg = ''): string {
    return `url(${carImg || '/assets/icons/booking/default.png'})`;
  }
}
