import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BookingCreateService, PagingResponseModel } from '../../booking-create/booking-create.service';
import { DriverCarModel } from 'src/app/models/cms/driver-car.model';
import { DestroyService } from 'src/app/_services/destroy.service';
import { takeUntil } from 'rxjs';
import { InspectionModel } from 'src/app/components/doctor/booking/models/inspection.model';
import { CarStatus } from 'src/app/components/doctor/booking/models/car-status.model';
import { LazyScrollTriggerOptions } from 'src/app/components/doctor/booking/directives/lazy-scroll-trigger.directive';
import {ServiceTicketService, ServiceWarrantyModel} from 'src/app/models/booking/booking.model';
import { Router } from '@angular/router';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-doctor-booking-car-info',
  templateUrl: './car-info.component.html',
  styleUrls: ['./car-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [DestroyService],
})
export class CarInfoComponent implements OnInit {
  carInfo?: DriverCarModel;
  inspections: InspectionModel[] = [];
  warrantyInfo: ServiceWarrantyModel[] = [{serviceName: '1abc', warrantyTime: '20/03/2023', garageName: 'Gara Binh Minh', isInDate: true}];
  pageNumber = 1;
  pageSize = 5;
  canNext = true;
  isFirst = true;
  get options(): LazyScrollTriggerOptions {
    return { canTrigger: this.canNext, trigger: this.fetchInspectionHistory.bind(this) as any, threshold: 100 };
  }

  constructor(
    private _bookingCreateService: BookingCreateService,
    private _destroy: DestroyService,
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this._bookingCreateService.data
      .pipe(takeUntil(this._destroy))
      .subscribe(({ step2 }) => {
        this.carInfo = step2?.carInfo;
        this.carInfo?.serviceWarranty.forEach((e: ServiceWarrantyModel) => {
          const timeWarranty = this.datePipe.transform(e.warrantyTime, 'yyyy/MM/dd', 'UTC');
          const timeNow = this.datePipe.transform(new Date(), 'yyyy/MM/dd', 'UTC');
          e.warrantyTime = this.datePipe.transform(e.warrantyTime, 'dd/MM/yyyy', 'UTC');
          if (Date.parse(timeWarranty ? timeWarranty : '01/01/1000') < Date.parse(timeNow ? timeNow : '01/01/1000')) {
            e.isInDate = false;
          }
          else {
            e.isInDate = true;
          }
        })
        this._cdr.markForCheck();
        this.isFirst = true;
        this.fetchInspectionHistory(() => {});
      });
  }

  fetchInspectionHistory(callback: () => void) {
    if (this.isFirst) {
      this.pageNumber = 1;
      this.inspections = [];
    }
    if (!this.carInfo?.licensePlate) return;
    this._bookingCreateService.fetchInspectionHistory(this.carInfo?.licensePlate, this.pageNumber, this.pageSize)
      .pipe(takeUntil(this._destroy))
      .subscribe((page: PagingResponseModel<InspectionModel>) => {
        this.inspections?.push(...page.content);
        this.canNext = !page.last;
        if (this.canNext) this.pageNumber += 1;
        this.isFirst = false;
        callback();
        this._cdr.markForCheck();
      });
  }

  getCarStatuses(statuses: CarStatus[] = []) {
    return statuses.reduce((acc, status, index) => {
      if (index === statuses.length - 1) return acc + `${status.value}`;
      return acc + `${status.value}, `;
    }, '');
  }

  getCarServices(services: ServiceTicketService[] = []) {
    if (!services) return '';
    return services.reduce((acc, service, index) => {
      if (index === services.length - 1) return acc + `${service.name}`;
      return acc + `${service.name}, `;
    }, '');
  }

  goInspection(id: string | number) {
    const nextWindow = window.open(`/#/booking/inspection/${id}`, '_blank');
    const token = window.sessionStorage.getItem('token');
    nextWindow?.sessionStorage.setItem('token', token || '');
  }
}
