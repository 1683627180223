import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'timeAgo',
  pure: true,
})
export class TimeAgoPipe implements PipeTransform {
  constructor(
    private _datePipe: DatePipe,
    private _translate: TranslateService,
  ) { }

  /**
   * A = thời gian hiện tại - thời gian tạo
   * A < 1 phút: Vừa xong
   * 1 phút < A < 1 giờ: x phút trước (1 phút → 59 phút) (e.g: 1 phút trước, 16 phút trước)
   * 1 giờ < A < 1 ngày: x giờ trước (1 giờ → 23 giờ) (e.g: 2 giờ trước,...)
   * A > 1 ngày: DD/MM/YYYY hh:mm (e.g: 11/08/2022 10:20)
   */
  transform(data: string | Date): string {
    try {
      const currentDate = new Date();
      const inputDate = new Date(data);
      const diff = (currentDate.getTime() - inputDate.getTime()) / 1000;
      if (diff < 60) {
        return this._translate.instant('pipe.time.ago.just_now');
      }
      if (diff < 60 * 60 && diff >= 60) {
        const num = Math.floor(diff / 60);
        return this._translate.instant('pipe.time.ago.minutes', { num });
      }
      if (diff < 24 * 60 * 60 && diff >= 60 * 60) {
        const num = Math.floor(diff / (60 * 60));
        return this._translate.instant('pipe.time.ago.hours', { num });
      }
      if (diff >= 24 * 60 * 60) {
        return this._datePipe.transform(inputDate, 'dd/MM/yyyy hh:mm') ||
          this._translate.instant('pipe.time.ago.invalid_date');
      }
      return this._translate.instant('pipe.time.ago.invalid_date');
    } catch (e) {
      console.error(e);
      return this._translate.instant('pipe.time.ago.invalid_date');
    }
  }
}
