export enum SosStatusEnum {
  _ = 'sos-tracking.status.all',
  _CUSTOMER_CLICK = 'sos-tracking.status.customer.click',
  _CUSTOMER_REQUESTED = 'sos-tracking.status.customer.requested',
  _REQUESTED_GARAGE = 'sos-tracking.status.requested.garage',
  _CONFIRM_CUSTOMER = 'sos-tracking.status.confirm.customer',
  _CUSTOMER_CANCEL = 'sos-tracking.status.customer.cancel',
  _GARAGE_REJECT = 'sos-tracking.status.garage.reject',
  _CUSTOMER_CANNOT_GET_LOCATION = 'sos-tracking.status.customer.cannot.get.location',
}


