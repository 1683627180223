<div mat-dialog-title class="cancel-header">
  <h3 class="cancel-title">{{ 'booking.table.header.cancel_booking' | translate }}</h3>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <div class="cancel-booking-popup padding-20">
    <div class="cancel-hint">{{ 'booking.table.header.cancel_booking_hint' | translate }}</div>
    <div *ngFor="let item of cancelReasons; let i = index" class="cancel-options">
      <div
        [ngClass]="{ 'cancel-option': true, 'selected-option': selectedReason?.id === item.id }"
        (click)="chooseCancelReason(i)"
      >
        {{ item.reason }}
      </div>
    </div>
    <form [formGroup]="cancelForm" class="form">
      <ns-input
        [label]="' '"
        [placeholder]="moduleName + '.table.header.other_reason'"
        [maxLength]="100"
        name="otherReason"
        [multiline]="true"
        formControlName="otherReason"
        fxLayout="column"
        fxFlex="100%"
        (onChange)="changeOtherReason()"
        (click)="changeOtherReason()"
        [ngClass]="{ 'selected-other-option': !selectedReason && cancelForm.get('otherReason')?.value }"
      >
      </ns-input>
    </form>
    <div class="cancel-btn">
      <button
        mat-button
        class="primary"
        (click)="cancelBooking()"
        [disabled]="!selectedReason && !cancelForm.get('otherReason')?.value"
      >
        {{ moduleName + '.table.header.confirm' | translate }}
      </button>
      <button mat-button class="primary outline" (click)="selectGarage()">
        {{ moduleName + '.table.header.change_garage' | translate }}
      </button>
    </div>
  </div>
</div>
