<ns-breadcrumb></ns-breadcrumb>
<div class="searchFormGroup">
  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="form">
    <div fxLayout="row wrap" class="row-wrap-padding">
      <ns-input [placeholder]="moduleName+'.table.header.car_status'" name="value" formControlName="value"
                fxLayout="column" fxFlex="25%"
                fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="50%" fxFlex.lg="25%">
      </ns-input>
      <ns-multi-select-autocomplete [multiple]="false"
                                    [options]="statusValues"
                                    [placeholder]="moduleName+'.table.header.status'"
                                    name="status" formControlName="status"
                                    fxLayout="column" fxFlex="25%"
                                    fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="50%" fxFlex.lg="25%">
      </ns-multi-select-autocomplete>
    </div>
    <div class="buttonArea">
      <button mat-button type="submit" class="primary Update-btn" [disabled]="searchForm.invalid">
        {{ 'btnSearch' | translate }}
      </button>
      <button mat-button type="button" class="secondary outline reset-btn" (click)="onResetForm()">
        {{ 'btnReset' | translate }}
      </button>
    </div>
  </form>
  <div class="result-area margin-bottom-20" fxLayout fxLayoutAlign="space-between center">
    <h3 class="margin-0">{{ paging.totalElements }} {{ ' ' + ('common.results' | translate) }}</h3>
    <div class="button-area" fxLayout>
      <button mat-button class="primary reset-btn" (click)="addOrEdit(null)">
        <i class="fa fa-plus" aria-hidden="true"></i> {{ 'btnAdd' | translate }}
      </button>
    </div>
  </div>
  <div class="acbox">
    <ns-table
      [columns]="columns"
      [buttons]="buttons"
      [paging]="paging"
      style="position: relative"
      [moduleName]="moduleName"
      [results]="results"
      [expandHeaderButton]="expandHeaderButton"
      (pagingChange)="pagingChange($event)"
      (clickAction)="onRowButtonClick($event)"
    >
    </ns-table>
  </div>
</div>
