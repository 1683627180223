import {UtilsService} from "@next-solutions/next-solutions-base";
import {Observable} from "rxjs";

export class Utils {
  static getPosition(e: any, arr: any[] | undefined) {
    return !!arr && arr.length > 0 ? (arr.indexOf(e) + 1).toString() : '';
  }

  static customExecuteErrorHandle(utilsService: UtilsService,
                                  method: () => Observable<any>,
                                  onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void,
                                  onSuccessMessage: string,
                                  confirmMsg: string,
                                  confirmMsgParamsFormat: string[] = [],
                                  onErrorFunc: (err: any) => void,
                                  confirmDialogButtonOk: string = 'common.OK', confirmDialogButtonCancel: string = 'common.Cancel',
  ) {
    utilsService.showConfirmDialog(confirmMsg, confirmMsgParamsFormat, '', [], undefined, confirmDialogButtonOk, confirmDialogButtonCancel)
      .afterClosed().subscribe(result => {
      if (result && result.value) {
        method().subscribe((data: any) => {
          onSuccessFunc(data, onSuccessMessage);
        }, error1 => {
          if (error1 !== '401') {
            onErrorFunc(error1);
          }
        });
      }
    });
  }
}
