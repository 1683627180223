import { Component, OnInit } from '@angular/core';
import { LoginComponent } from '../login/login.component';
import { environment } from '../../../environments/environment';
import { OAuth2AuthenticationDto } from '@next-solutions/next-solutions-base';
import { FirebaseService } from 'src/app/components/doctor/booking/booking-create/firebase.service';

@Component({
  selector: 'app-logout',
  template: ``,
  styles: [``],
})
export class LogoutComponent implements OnInit {
  constructor(
    private loginComponent: LoginComponent,
    private firebaseService: FirebaseService,
  ) {
  }

  ngOnInit() {
    const token = window.sessionStorage.getItem('token');
    const currentUser = window.sessionStorage.getItem('currentUser');
    window.sessionStorage.removeItem('token');
    this.unregisterExternalServices(token || '', currentUser || '');
    const dlgRef = this.loginComponent.showLoginModal();
    dlgRef.afterClosed().subscribe(() => {
      // this.onMouseLeave();
    });
    window.zim.logout()
  }

  unregisterExternalServices(token: string, currentUser: string) {
    this.firebaseService.removeToken(token, currentUser)?.subscribe();
  }
}
