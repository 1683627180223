export enum ZEGO_CONVERSATION_TYPE {
  VIDEO_CALL = 'VIDEO_CALL',
  AUDIO_CALL = 'AUDIO_CALL',
  ROOM_CHAT = 'ROOM_CHAT',
  PEER_CHAT = 'PEER_CHAT',
}

export enum ZEGO_SCENARIO_MODEL {
  ONE_ON_ONE_CALL = 'OneONoneCall',
  GROUP_CALL = 'GroupCall',
  VIDEO_CONFERENCE = 'VideoConference',
  LIVE_STREAMING = 'LiveStreaming',
}

export enum ZEGO_VIDEO_RESOLUTION {
  _180P = '180p',
  _360P = '360p',
  _480P = '480p',
  _720P = '720p',
}
