<ns-breadcrumb></ns-breadcrumb>
<div class="create-booking-component-wrapper" fxLayout="column">
  <div class="padding-10" fxLayout="column" fxLayoutAlign="center center">
    <mat-stepper class="mat-stepper-custom" labelPosition="bottom"
                 [selectedIndex]="bookingStep | async"
                 (selectionChange)="changeStepperHandler($event)">
      <!-- <mat-step>
        <ng-template matStepLabel [interacted]="false">{{'booking.stepper.step1' | translate}}</ng-template>
      </mat-step> -->
      <mat-step>
        <ng-template matStepLabel [interacted]="false">{{'booking.stepper.step2' | translate}}</ng-template>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel [interacted]="false">{{'booking.stepper.step3' | translate}}</ng-template>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel [interacted]="false">{{'booking.stepper.step4' | translate}}</ng-template>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel [interacted]="false">{{'booking.stepper.step5' | translate}}</ng-template>
      </mat-step >
    </mat-stepper>
  </div>

  <!-- <ng-container *ngIf="BookingCreateStep.STEP_1 === (bookingStep | async)">
    <app-doctor-booking-create-step-1></app-doctor-booking-create-step-1>
  </ng-container> -->

  <ng-container *ngIf="BookingCreateStep.STEP_2 === (bookingStep | async)">
    <app-doctor-booking-create-step-2></app-doctor-booking-create-step-2>
  </ng-container>

  <ng-container *ngIf="BookingCreateStep.STEP_3 === (bookingStep | async) && !isSpaPaintMaintain">
    <app-doctor-booking-create-step-3></app-doctor-booking-create-step-3>
  </ng-container>

  <ng-container *ngIf="BookingCreateStep.STEP_3 === (bookingStep | async) && isSpaPaintMaintain">
    <app-doctor-booking-create-step-3-paint-spa></app-doctor-booking-create-step-3-paint-spa>
  </ng-container>

  <ng-container *ngIf="BookingCreateStep.STEP_4 === (bookingStep | async)">
    <app-doctor-booking-create-step-4></app-doctor-booking-create-step-4>
  </ng-container>

  <ng-container *ngIf="BookingCreateStep.STEP_5 === (bookingStep | async)">
    <app-doctor-booking-create-step-5></app-doctor-booking-create-step-5>
  </ng-container>


</div>
