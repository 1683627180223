import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BookingCreateService, GenericModel, Utility } from '../../booking-create/booking-create.service';
import { ApiService, Page, SelectModel } from '@next-solutions/next-solutions-base';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { DestroyService } from 'src/app/_services/destroy.service';
import { TranslateService } from '@ngx-translate/core';
import { collectParams } from 'src/app/utils/booking/api.util';
import { environment as env } from '../../../../../../environments/environment';
import { ModuleConst } from 'src/app/modules/module.const';
import { UtilityModel } from 'src/app/models/cms/utility.model';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { debounceTime, fromEvent, takeUntil } from 'rxjs';

@Component({
  selector: 'app-doctor-booking-create-step-4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DestroyService],
})
export class Step4Component implements OnInit {
  @ViewChild('addressListTemplate', { static: true }) addressListTemplate!: ElementRef;
  @ViewChild('addressInputRef', { static: true }) addressInputRef!: ElementRef;
  overlayRef?: OverlayRef;
  addressList: any[] = [];
  selectedAddress: any;
  utilityValues: SelectModel[] = [];
  rawUtilities: Utility[] = [];
  hourValues = this.generateHourValues();
  timeFrame: SelectModel[] = [
    { value: 1, displayValue: 'Buổi sáng (08h00-10h00)', rawData: undefined, disabled: false },
    { value: 2, displayValue: 'Buổi sáng (10h00-12h00)', rawData: undefined, disabled: false },
    { value: 3, displayValue: 'Buổi chiều (13h00-15h00)', rawData: undefined, disabled: false },
    { value: 4, displayValue: 'Buổi chiều (15h00-17h00)', rawData: undefined, disabled: false },
  ];
  minDate = new Date();
  maxDate = new Date(new Date().setDate(this.minDate.getDate() + 7));

  inputErrorMsg = new Map<string, () => string>().set('max', () =>
    this._translate.instant('booking.common.validation.max', { max: 100 }),
  );

  form = this._fb.group({
    bookingDate: [''],
    bookingHour: [''],
    timeFrame: new FormControl<number | null>(1, [Validators.max(10)]),
    address: [''],
    distance: [0, Validators.max(100)],
    utilities: new FormControl<number[]>([]),
    placeId: [''],
  });

  constructor(
    private _bookingCreateService: BookingCreateService,
    private _overlay: Overlay,
    private _apiService: ApiService,
    private _translate: TranslateService,
    private _destroy: DestroyService,
    private _cdr: ChangeDetectorRef,
    private _fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.getUtilities();
    this.searchAddress();
    this.initData();
  }

  initData() {
    const { step4 } = this._bookingCreateService.getData();
    if (!step4) return;
    const { bookingDate, bookingHour, address, distance, utilities, placeId } = step4;
    this.form.patchValue({
      bookingDate,
      bookingHour,
      address,
      distance,
      placeId,
      utilities,
    });
    this.form.updateValueAndValidity({ emitEvent: true, onlySelf: false });
    this._cdr.markForCheck();
  }

  getUtilities() {
    const pagingParams = collectParams({ pageSize: env.INTEGER_MAX_VALUE, pageNumber: '1', status: 'ACCEPTED' });
    return this._apiService.get<Page>(ModuleConst.CMS + '/utility', pagingParams).subscribe((page) => {
      this.rawUtilities = page.content;
      this.utilityValues = page.content.map((item: UtilityModel) => ({
        value: item.id,
        displayValue: item.name,
        rawData: item,
        disabled: false,
      }));
    });
  }

  getAddressList() {
    const { address, distance } = this.form.value;
    if (!address || !address.trim()) {
      this.form.get('address')?.setErrors({ required: true });
      this._cdr.markForCheck();
      return;
    }
    const params = collectParams({
      text: address,
      maxDistance: distance,
    });
    return this._apiService
      .get<GenericModel>(ModuleConst.CMS + '/garage/sos/autocomplete', params)
      .subscribe((page) => {
        this.addressList = page.body?.predictions;
        this._cdr.markForCheck();
      });
  }

  selectAddress(address: any) {
    this.selectedAddress = address;
    this.form.get('address')?.patchValue(address.description);
    this.form.get('placeId')?.patchValue(address.place_id);
    if (this.overlayRef?.hasAttached()) {
      this.overlayRef?.detach();
    }
  }

  nextStep() {
    const { bookingDate, bookingHour, timeFrame, address, distance, utilities, placeId } = this.form.value;
    this._bookingCreateService.saveData({
      step4: {
        bookingDate: bookingDate ?? '',
        bookingHour: bookingHour ?? '',
        timeFrame,
        address: address ?? '',
        distance: distance ?? 0,
        utilities: utilities ?? [],
        rawAddress: this.selectedAddress,
        rawUtilities: this.rawUtilities,
        placeId: placeId ?? '',
      },
    });
    this._bookingCreateService.nextStep();
  }

  prevStep() {
    this._bookingCreateService.prevStep();
  }

  generateHourValues(date = ''): SelectModel[] {
    const STEP = 30;
    const receivedDate = new Date(date);
    const currentDate = new Date();
    const isToday =
      receivedDate.getFullYear() === currentDate.getFullYear() &&
      receivedDate.getMonth() === currentDate.getMonth() &&
      receivedDate.getDay() === currentDate.getDay();
    let minHour = 0;
    let minMin = 0;
    if (isToday) {
      minHour = currentDate.getHours();
      if (currentDate.getMinutes() - STEP > 0) {
        minMin = 30;
        minHour = minHour < 23 ? minHour + 1 : 0;
      } else if (currentDate.getMinutes() - STEP < 0) {
        minMin = 0;
        minHour = minHour < 23 ? minHour + 1 : 0;
      }
    }

    const results = [];
    for (let i = minHour; i < 24; i++) {
      for (let j = minMin; j < 60; j += STEP) {
        const data = `${this.formatNumber(i)}:${this.formatNumber(j)}`;
        results.push({
          value: data,
          displayValue: data,
          disabled: false,
          rawData: data,
        });
      }
    }
    return results;
  }

  formatNumber(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  onBookingDateChange(date: string) {
    this.hourValues = this.generateHourValues(date);
  }

  searchAddress() {
    fromEvent(this.addressInputRef.nativeElement, 'input')
      .pipe(debounceTime(500), takeUntil(this._destroy))
      .subscribe((_) => {
        this.getAddressList();
      });
  }

  openAddressList() {
    if (!this.overlayRef) {
      const positionStrategy = this._overlay
        .position()
        .flexibleConnectedTo(this.addressInputRef)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
        ]);
      const scrollStrategy = this._overlay.scrollStrategies.reposition();
      const bounding = this.addressInputRef.nativeElement.getBoundingClientRect();
      this.overlayRef = this._overlay.create({ positionStrategy, scrollStrategy });
      this.overlayRef.updateSize({ width: bounding.width });
      this.overlayRef.outsidePointerEvents().subscribe(() => {
        if (this.overlayRef?.hasAttached()) {
          this.overlayRef?.detach();
        }
      });
    }
    if (!this.overlayRef?.hasAttached()) {
      this.overlayRef.attach(this.addressListTemplate);
    }
  }
}
