import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BookingCreateService, BookingCreateStep, BookingServiceType } from './booking-create.service';
import { ApiService, AuthoritiesService, BaseSearchLayout, FormStateService, UtilsService } from '@next-solutions/next-solutions-base';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseService } from './firebase.service';

@Component({
  selector: 'app-doctor-booking-create',
  templateUrl: './booking-create.component.html',
  styleUrls: ['./booking-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BookingCreateComponent extends BaseSearchLayout implements OnInit, OnDestroy {
  BookingCreateStep = BookingCreateStep;
  BookingServiceType = BookingServiceType;
  services = [
    BookingServiceType.RESCUE,
    BookingServiceType.UPGRADE,
    BookingServiceType.REPAIR,
    BookingServiceType.MAINTENANCE,
    BookingServiceType.PAINT,
    BookingServiceType.SPA,
  ];

  constructor(
    protected formBuilder: FormBuilder,
    protected router: Router,
    protected apiService: ApiService,
    protected utilsService: UtilsService,
    protected uiStateService: FormStateService,
    protected translateService: TranslateService,
    protected injector: Injector,
    protected activatedRoute: ActivatedRoute,
    protected authoritiesService: AuthoritiesService,
    private _bookingCreateService: BookingCreateService,
    private _firebaseService: FirebaseService,
  ) {
    super(router, apiService, utilsService, uiStateService, translateService, injector, activatedRoute, authoritiesService,
      formBuilder.group({}));
  }

  get bookingStep() {
    return this._bookingCreateService.step;
  }

  ngOnInit(): void {
    console.log(this.bookingStep);
  }

  selectService(serviceType: BookingServiceType) {
    // this._bookingCreateService.saveData({ step1: { serviceType } });
    this._bookingCreateService.nextStep();
  }

  changeStepperHandler(step: any) {
    // this._bookingCreateService.nextStep(step.selectedIndex);
  }

  // get isSpaPaintMaintain() {
  //   const { step1 } = this._bookingCreateService.getData();
  //   if (step1) {
  //     return [BookingServiceType.SPA, BookingServiceType.PAINT, BookingServiceType.MAINTENANCE].includes(step1.serviceType);
  //   }
  //   return false;
  // }

  get isSpaPaintMaintain() {
    return false;
  }

  ngOnDestroy() {
    this._bookingCreateService.clearData();
  }
}
