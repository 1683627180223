<div class="booking-info-selector" fxLayout="column">
  <div class="padding-vertical-15 padding-horizontal-20 border-bottom" fxLayout fxLayoutAlign="space-between center">
    <span class="step-label">{{'booking.stepper.step4' | translate}}</span>
    <div fxLayout="row">
      <button mat-button class="primary outline" (click)="prevStep()">
        {{'booking.step4.back' | translate}}
      </button>
      <button mat-button class="primary" (click)="nextStep()" [disabled]="form.invalid">
        {{'booking.step4.next' | translate}}
      </button>
    </div>
  </div>
  <form [formGroup]="form" class="padding-20" fxFlex="100%" fxLayout="row">
    <div fxLayout="row" fxFlex="50%" fxFlex.lt-md="100%" fxLayoutAlign="flex-end flex-start">
      <div fxLayout="column" fxFlex="100%" class="padding-horizontal-20">
        <div fxLayout="row" fxLayoutGap="12px">
          <ns-date-picker formControlName='bookingDate'
                          [minDate]="minDate"
                          [maxDate]="maxDate"
                          (onChange)="onBookingDateChange($event)"
                          [required]="true"
                          [placeholder]="'DD/MM/YYYY'"
                          label="{{'booking.step4.form.booking_date' | translate}}"
                          fxLayout='column' fxFlex='50%'
                          fxFlex.xs='100%' fxFlex.sm='100%'
          ></ns-date-picker>
          <ns-multi-select-autocomplete [options]="timeFrame"
                                        [multiple]="false"
                                        [required]="true"
                                        placeholder="{{'booking.step4.form.hour' | translate}}"
                                        formControlName="timeFrame"
                                        fxLayout="column" fxFlex='50%'
                                        fxFlex.xs='100%' fxFlex.sm='100%'>
          </ns-multi-select-autocomplete>
        </div>
        <div fxLayout="row" fxFlex='100%' #addressInputRef>
          <ns-input [placeholder]="'booking.step4.form.address' | translate"
                    [label]="'booking.step4.form.address' | translate"
                    [required]="true"
                    (input)="openAddressList()"
                    (click)="openAddressList()"
                    formControlName="address"
                    fxLayout='column'
                    fxFlex='100%'>
          </ns-input>
        </div>
        <ng-template cdkPortal #addressListTemplate="cdkPortal">
          <div fxLayout="column" class="booking-step4-address-list-wrapper" *ngIf="addressList && addressList.length > 0">
            <div class="booking-step4-address"
                 *ngFor="let address of addressList"
                 (click)="selectAddress(address)"
            >
              {{ address.description }}
            </div>
          </div>
        </ng-template>
        <!-- <ns-input [placeholder]="'booking.step4.form.distance.placeholder' | translate"
                  [label]="'booking.step4.form.distance' | translate"
                  [required]="true"
                  [patternFilter]="'[0-9]'"
                  [maxLength]="3"
                  [errorMessages]="inputErrorMsg"
                  formControlName="distance"
                  fxLayout='column'
                  fxFlex.xs='100%' fxFlex.sm='50%' fxFlex.md='50%'>
        </ns-input> -->
        <!-- <ns-multi-select-autocomplete [multiple]="true"
                                      [options]="utilityValues"
                                      placeholder="{{'booking.step4.form.sub_services' | translate}}"
                                      formControlName="utilities"
                                      fxLayout="column" fxFlex="25%"
                                      fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="50%">
        </ns-multi-select-autocomplete> -->
      </div>
    </div>
  </form>
</div>
