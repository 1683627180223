import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { LogoutComponent } from '../components/logout/logout.component';
import { AuthoritiesResolverService } from '@next-solutions/next-solutions-base';
import {SosTrackingComponent} from '../components/sos-tracking/sos-tracking.component';
import {ActionTypeEnum} from "../enums/action.type.enum";
import {AddEditSosTrackingComponent} from '../components/sos-tracking/add-edit-sos-tracking/add-edit-sos-tracking.component';
import {SosTrackingSupportComponent} from "../components/sos-tracking/sos-tracking-support/sos-tracking-support.component";


const routes: Routes = [
  { path: '', component: DashboardComponent, resolve: { me: AuthoritiesResolverService } },
  { path: 'home', component: DashboardComponent, resolve: { me: AuthoritiesResolverService } },
  { path: 'dashboard', component: DashboardComponent, resolve: { me: AuthoritiesResolverService } },
  {
    path: 'booking',
    data: {breadcrumb: ''},
    loadChildren: () => import('../modules/booking.module').then(m => m.BookingModule),
  },
  {
    path: 'cms',
    data: {breadcrumb: ''},
    loadChildren: () => import('../modules/cms.module').then(m => m.CmsModule),
  },
  {
    path: 'diagnostic',
    data: {breadcrumb: ''},
    loadChildren: () => import('../modules/diagnostic.module').then(d => d.DiagnosticModule),
  },
  {
    path: 'service',
    data: {breadcrumb: ''},
    loadChildren: () => import('../modules/service.module').then(d => d.ServiceModule),
  },
  {
    path: 'config',
    data: { breadcrumb: '' },
    loadChildren: () => import('../modules/config.module').then(d => d.ConfigModule),
  },
  // {
  //   path: 'sso',
  //   data: { breadcrumb: '' },
  //   loadChildren: () => import('../../../../../../ac_base/CMS/src/app/modules/sso.module').then(m => m.SsoModule),
  // },
  {
    path: 'sos-tracking',
    data: {breadcrumb: 'menu.sos-tracking.'},
    children: [
      {
        path: '',
        pathMatch: 'full',
        data: {breadcrumb: 'menu.sos-tracking.'},
        component: SosTrackingComponent,
        resolve: {me: AuthoritiesResolverService},
      },
      {
        path: 'edit/:id',
        component: AddEditSosTrackingComponent,
        data: {breadcrumb: 'menu.sos-tracking.edit', actionType: ActionTypeEnum._EDIT},
        resolve: {me: AuthoritiesResolverService},
      },
      {
        path: 'detail/:id',
        component: AddEditSosTrackingComponent,
        data: {breadcrumb: 'menu.sos-tracking.detail', actionType: ActionTypeEnum._VIEW},
        resolve: {me: AuthoritiesResolverService},
      },
    ]
  },
  {
    path: 'sos-tracking-support',
    data: {breadcrumb: 'menu.sos-tracking-support.'},
    children: [
      {
        path: '',
        pathMatch: 'full',
        data: {breadcrumb: 'menu.sos-tracking-support.'},
        component: SosTrackingSupportComponent,
        resolve: {me: AuthoritiesResolverService},
      },
      {
        path: 'detail/:id',
        component: AddEditSosTrackingComponent,
        data: {breadcrumb: 'menu.sos-tracking-support.detail', actionType: ActionTypeEnum._VIEW},
        resolve: {me: AuthoritiesResolverService},
      },
      {
        path: 'edit/:id',
        component: AddEditSosTrackingComponent,
        data: {breadcrumb: 'menu.sos-tracking.edit', actionType: ActionTypeEnum._EDIT},
        resolve: {me: AuthoritiesResolverService},
      }
    ]
  },
  {path: 'logout', component: LogoutComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: 'reload',
      // preloadingStrategy: PreloadAllModules,
    },
  ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
