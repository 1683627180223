import { Component, Inject, inject, Optional } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

export interface SnackBarData {
  message?: string;
  name?: string;
}

@Component({
  selector: 'app-booking-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls:['./snack-bar.component.scss'],
})
export class SnackBarComponent {
  snackBarRef = inject(MatSnackBarRef);
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData,
  ) {
    console.log('@== data', data);
  }
}
