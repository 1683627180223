<div fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="column">
    <span class="text-white ellipsis" matSnackBarLabel>
      {{ data.message }}
    </span>
  </div>
  <div fxLayout="column" matSnackBarActions class="margin-horizontal-5">
    <span class="action-btn" matSnackBarAction (click)="snackBarRef.dismissWithAction()">Close</span>
  </div>
</div>
