<div *ngIf="data" class="booking-done" fxLayout="column" fxFlex="100%" fxLayoutAlign="center center">
  <div fxLayout="column" fxLayoutAlign="center center">
    <div class="image margin-bottom-20"></div>
    <span class="text margin-bottom-10">{{'booking.step5.booking_done' | translate}}</span>
    <div fxLayout="column" fxFlexAlign="start start" class="full-width">
      <span class="name margin-bottom-5"><i class="fal fa-garage"></i> {{ data.name }}</span>
      <span class="address margin-bottom-5"><i class="fal fa-map-marker-alt"></i> {{data.address}}</span>
      <span class="booking-time margin-bottom-5"><i class="fal fa-clock"></i> {{data.time | date : 'HH:mm dd/MM/yyyy'}}</span>
    </div>
    <button mat-button class="primary" (click)="done.emit()">
      {{'booking.step5.booking_done.button' | translate}}
    </button>
  </div>
</div>
