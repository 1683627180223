<h3 mat-dialog-title class="cancel-title">{{ 'booking.step2.car_detail.popup-title' | translate }}</h3>
<div mat-dialog-content *ngIf="driverCar" fxLayout="column" class="detail-car">
  <div class="detail-car__image margin-bottom-20" image [imageSrc]="driverCar?.carImg"></div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
    <span class="label">{{'booking.step2.car_detail.bks' | translate}}</span>
    <span class="value">{{ driverCar?.licensePlate }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
    <span class="label">{{'booking.step2.car_detail.engine_num' | translate}}</span>
    <span class="value">{{ driverCar?.engineNumber }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
    <span class="label">{{'booking.step2.car_detail.struct_num' | translate}}</span>
    <span class="value">{{ driverCar?.chassisNumber }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
    <span class="label">{{'booking.step2.car_detail.from' | translate}}</span>
    <span class="value">{{ driverCar?.country?.name }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
    <span class="label">{{'booking.step2.car_detail.year' | translate}}</span>
    <span class="value">{{ driverCar?.manufacturedYear }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
    <span class="label">{{'booking.step2.car_detail.brand' | translate}}</span>
    <span class="value">{{ driverCar?.car?.brand?.name }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
    <span class="label">{{'booking.step2.car_detail.model' | translate}}</span>
    <span class="value">{{ driverCar?.car?.model }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
    <span class="label">{{'booking.step2.car_detail.start_year' | translate}}</span>
    <span class="value">{{ driverCar?.car?.startYear }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
    <span class="label">{{'booking.step2.car_detail.body_style' | translate}}</span>
    <span class="value">{{ driverCar?.car?.bodyStyle?.name }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
    <span class="label">{{'booking.step2.car_detail.fuel' | translate}}</span>
    <span class="value">{{ driverCar?.car?.fuel?.name }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
    <span class="label">{{'booking.step2.car_detail.horse_power' | translate}}</span>
    <span class="value">{{ driverCar?.car?.horsepower }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
    <span class="label">{{'booking.step2.car_detail.seat' | translate}}</span>
    <span class="value">{{ driverCar?.car?.seat }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
    <span class="label">{{'booking.step2.car_detail.engine' | translate}}</span>
    <span class="value">{{ driverCar?.car?.engine }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
    <span class="label">{{'booking.step2.car_detail.box' | translate}}</span>
    <span class="value">{{ driverCar?.car?.gear}}</span>
  </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end center" class="btn-actions">
  <button mat-button class="primary outline" (click)="close()">
    {{ 'booking.step2.car_detail.btn-ok' | translate }}
  </button>
</div>
