import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { ApiService } from '@next-solutions/next-solutions-base';
import { FirebaseMessage, MfsFirebaseMessage, PostUserLoginRequest, PostUserLogoutRequest } from './firebase.model';
import { ModuleConst } from 'src/app/modules/module.const';
import { BehaviorSubject } from 'rxjs';
import { environment as env } from 'src/environments/environment';

@Injectable()
export class FirebaseService {
  app = initializeApp(env.FIREBASE_CONFIG);
  message = new BehaviorSubject<FirebaseMessage | undefined>(undefined);
  broadcastChannel = new BroadcastChannel('notification_channel');

  constructor(
    private _apiService: ApiService,
  ) {
    this.initService();
  }

  initService() {
    this.registerBroadcastChannel();
    this.registerServiceWorker();
    this.registerMessaging();
  }

  registerBroadcastChannel() {
    this.broadcastChannel.onmessage = (event) => {
      const message = this.getParsedPayload(event.data);
      this.message.next(message);
    };
  }

  registerMessaging() {
    // Message directly from firebase
    const messaging = getMessaging();
    onMessage(messaging, (payload: any) => {
      console.log('Message received. ', payload);
      const message = this.getParsedPayload(payload as MfsFirebaseMessage);
      this.message.next(message);
    });
  }

  getParsedPayload(payload: MfsFirebaseMessage): FirebaseMessage | undefined {
    console.log('Message received from sw. ', payload);
    const { data } = payload;
    if (!data) return;
    const { type, appointmentId, subject, content } = data;
    // If message is an command;
    if (/__COMMAND__/g.test(type)) {
      const token = window.sessionStorage.getItem('token');
      const nextWindow = window.open(`/#/booking/detail/${data?.appointmentId}`, '_blank');
      nextWindow?.sessionStorage.setItem('token', token || '');
      return undefined;
    }

    return {
      title: subject,
      body: content,
      type,
      data: {
        appointmentId,
        type,
        data: data.data,
      }
    }
  }

  getMessage() {
    return this.message.asObservable();
  }

  registerServiceWorker() {
    console.log('registerServiceWorker');
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('firebase-messaging-sw.js',
        { type: 'classic' })
        .then((registration) => {
        console.log('Service worker registration succeeded:', registration);
        this.requestPermission()
      }, (error) => {
        console.error(`Service worker registration failed: ${error}`);
      });
    } else {
      console.error('Service workers are not supported.');
    }
  }

  requestPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        this.requestToken();
      } else {
        console.log('Notification permission denied.');
      }
    });
  }

  requestToken() {
    const messaging = getMessaging();
    console.log(messaging);
    getToken(messaging).then((token) => {
      if (token) {
        console.log('@== Retrieved token: ', token);
        this.saveToken(token).subscribe((resp) => {
          console.log('@== Saved token: ', resp);
        });
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  }

  saveToken(token: string) {
    const body: PostUserLoginRequest = {
      device_token: token,
      platform: 'WEB',
    }
    sessionStorage.setItem('device_token', token);

    return this._apiService.post(ModuleConst.BOOKING + '/users/login', body)
  }

  removeToken(token: string, currentUser: string) {
    if (!token || !currentUser) return;
    const user = JSON.parse(currentUser || '');
    const body: PostUserLogoutRequest = {
      device_token: token,
      platform: 'WEB',
      userId: user.systemRefUserId,
      userType: user.systemRefUserType,
    }

    return this._apiService.post(ModuleConst.BOOKING + '/users/logout', body)
  }
}
