import { Component, ElementRef, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ApiService, AuthoritiesService, BaseSearchLayout, FormStateService, UtilsService } from '@next-solutions/next-solutions-base';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseService } from '../booking-create/firebase.service';
import { DestroyService } from 'src/app/_services/destroy.service';
import { ChatService, MfeOutgoingEvent, ZegoConvInfo } from 'src/app/components/doctor/booking/chat/chat.service';
import { ModuleConst } from 'src/app/modules/module.const';
import { catchError } from 'rxjs/operators';
import { ZEGO_CONVERSATION_TYPE } from 'src/app/models/zegocloud/zego.model';
import { HttpParams } from '@angular/common/http';
import { BookingModel } from 'src/app/models/booking/booking.model';
import { BookingUtil } from 'src/app/utils/booking/booking.util';

declare global {
  interface Window {
    __zego_chat__: any;
    __app_id__: number;
    zim: any;
    ZIM: any;
  }
}

@Component({
  selector: 'app-doctor-booking-chat',
  templateUrl: 'chat.component.html',
  styleUrls: ['chat.component.scss'],
  providers: [DestroyService],
  encapsulation: ViewEncapsulation.None,
})
export class ChatComponent extends BaseSearchLayout implements OnInit {
  @ViewChild('zegoChat', { static: true }) zegoChat!: ElementRef;
  appInstance: any;
  requestTicketId?: string;
  driverId?: number;
  expertId?: string;
  garageId?: string;
  operatorId?: string;
  driverPhone?: string;
  convType?: ZEGO_CONVERSATION_TYPE;
  constructor(
    protected formBuilder: FormBuilder,
    protected router: Router,
    protected apiService: ApiService,
    protected utilsService: UtilsService,
    protected uiStateService: FormStateService,
    protected translateService: TranslateService,
    protected injector: Injector,
    protected activatedRoute: ActivatedRoute,
    protected authoritiesService: AuthoritiesService,
    private _destroy: DestroyService,
    private _firebaseService: FirebaseService,
    private _chatService: ChatService,
    private bookingUtil: BookingUtil,
  ) {
    super(router, apiService, utilsService, uiStateService, translateService, injector, activatedRoute, authoritiesService,
      formBuilder.group({}));
  }

  ngOnInit() {
    this._chatService.initChatSdk();
    this.renderZegoChat();
  }

  renderZegoChat() {
    if (window.__zego_chat__) {
      window.__zego_chat__.mount(this.zegoChat.nativeElement);
      this.getConversationRequestParams();
      return;
    }
    this._chatService.loaded.subscribe(() => {
      this.appInstance = window.__zego_chat__.mount('#zego-chat');
      this.getConversationRequestParams();
    });
  }

  getConversationRequestParams() {
    this.requestTicketId = this.activatedRoute.snapshot.queryParamMap.get('requestTicketId') || '';
    this.convType = this.activatedRoute.snapshot.queryParamMap.get('type') as ZEGO_CONVERSATION_TYPE;
    if (!this.requestTicketId || !this.convType) return;

    this.apiService
      .get<BookingModel>(`${ModuleConst.BOOKING}/expert/appointment-schedule/${this.requestTicketId}`, new HttpParams())
      .subscribe((booking) => {
        this.driverId = booking.driverId;
        this.expertId = booking.expertRefId;
        this.garageId = booking.garageRefId;
        this.operatorId = booking.operatorId;
        this.driverPhone = booking.driverPhone;

        const currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '');
        if (currentUser && currentUser.systemRefUserType !== 'EXPERT' && currentUser.systemRefUserType !== 'OPERATOR'){
          const error = this.translateService.instant('booking.common.validation.wrong_chat_info');
          this.utilsService.showError(error);
          return;
        }
        this.initConversation();
      });
  }

  initConversation() {
    const convInfo: ZegoConvInfo = {
      garageId: this.garageId,
      driverId: this.driverId,
      expertId: this.expertId,
      operatorId: this.operatorId,
      requestTicketId: this.requestTicketId,
      convType: this.convType,
    }
    return this.apiService.post(`${ModuleConst.BOOKING}/conversation`, convInfo)
      .pipe(catchError(error => {
        this.utilsService.showError(error);
        return error;
      }))
      .subscribe((conversation: any) => {
        this._chatService.sendCommand({
          data: {
            ...conversation,
            // convId: conversation.convId.replace(/[a-z]|-/g, ''),
          },
          type: MfeOutgoingEvent.JOIN_GROUP,
        })
      });
  }
}
