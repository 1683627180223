<div class="dialog_wrapper">
  <div class="dialog_header">
    <div class="dialog_title">{{(data.title ?? defaultData.title) | translate}}</div>
  </div>
  <div class="dialog_content padding-15">
    <p>{{(data.body ?? defaultData.body) | translate}}</p>
  </div>
  <div class="dialog_footer">
    <div fxLayout="row" fxLayoutAlign="flex-end">
      <button mat-button class="default outline" (click)="cancel()">
        {{ (data.cancel ?? defaultData.cancel) | translate }}
      </button>
      <button mat-button class="primary" (click)="confirm()">
        {{ (data.confirm ?? defaultData.confirm) | translate }}
      </button>
    </div>
  </div>
</div>
