import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BookingCreateService, BookingCreateStep, BookingServiceType, GenericModel } from '../../booking-create/booking-create.service';
import { TranslateService } from '@ngx-translate/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService, SelectModel } from '@next-solutions/next-solutions-base';
import { ModuleConst } from '../../../../../modules/module.const';
import { EMPTY, forkJoin, mergeMap, takeUntil } from 'rxjs';
import { collectParams } from '../../../../../utils/booking/api.util';
import { environment as env } from '../../../../../../environments/environment';
import { ServiceModel } from '../../../../../models/service/service.model';
import { DestroyService } from '../../../../../_services/destroy.service';
import { ServicePackageModel } from '../../../../../models/service/service-package.model';
import { SERVICE_LABEL, SuperServiceModel } from 'src/app/models/booking/booking.model';

export interface CarStatus {
  id: number | string,
  value: string,
  ref_id?: string,
}

@Component({
  selector: 'app-doctor-booking-create-step-3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DestroyService],
})
export class Step3Component implements OnInit {
  BookingCreateStep = BookingCreateStep;
  carStatusValues: SelectModel[] = [];
  packagesOptions: (SelectModel[])[] = [];
  carStatusAdditionalValues: CarStatus[] = [];
  servicePackageValues: SelectModel[] = [];
  packageDetails: ServicePackageModel[] = [];
  showInputCarStatus = false;
  controlMaps = {};

  form = this._fb.group({
    carStatuses: new FormControl<number[]>([]),
    carDescription: new FormControl<string>(''),
    kmRun: new FormControl<number|null>(null, [Validators.max(999999)]),
    servicePackageIds: new FormControl<number[]>([]),
    carStatusInput: new FormControl<string>(''),
    serviceIds: new FormControl<number[]>([]),
    servicePackageFormArray: new FormArray<any>([]),
  });
  inputErrorMsg = new Map<string, () => string>()
    .set('max', () => this._translate.instant('booking.common.validation.max', { max: 999999 }))
    .set('minlength', () => this._translate.instant('booking.common.validation.minlength', { maxLength: 3 }));

  constructor(
    private _bookingCreateService: BookingCreateService,
    private _apiService: ApiService,
    private _translate: TranslateService,
    private _destroy: DestroyService,
    private _cdr: ChangeDetectorRef,
    private _fb: FormBuilder,
  ) { }

  get serviceIds() {
    return this.form.controls.serviceIds;
  }

  get servicePackageFormArray() {
    return this.form.controls.servicePackageFormArray;
  }

  ngOnInit(): void {
    this.initData();
    // this.servicePackageChangeHandler();
    this.fetchMetaData().pipe(takeUntil(this._destroy))
      .subscribe(([carStatus, services]) => {
        this.carStatusValues = carStatus.content.map((item: CarStatus) => ({
          value: item.id,
          displayValue: item.value,
          rawData: item,
        }));
        // this.servicePackageValues = servicePackage.content.map((item: ServiceModel) => ({
        //   value: item.id,
        //   displayValue: item.name,
        //   rawData: item,
        // }));
        this.servicePackageValues = [];
        services.forEach((service: SuperServiceModel) => {
          if (service.type === SERVICE_LABEL.SERVICE) {
            this.servicePackageValues.push({
              value: service.id,
              displayValue: service.name,
              rawData: service,
              disabled: false,
            });
          }
        });

        this._cdr.markForCheck();
      });
  }

  initData() {
    const { step3 } = this._bookingCreateService.getData();
    if (!step3) return;
    const { carStatuses, carDescription, kmRun, servicePackageIds, serviceIds, carStatusAdditionalValues, servicePackageFormArray } = step3;
    this.carStatusAdditionalValues = carStatusAdditionalValues ?? [];

    // // Init form array
    // servicePackageIds?.forEach((pkg, index) => {
    //   this.packagesOptions.push([]);
    //   this.servicePackageFormArray.push(new FormControl<number[]>([]));
    //   this.controlMaps[pkg] = index;
    // });

    this.form.patchValue({
      carStatuses, carDescription, kmRun, servicePackageIds, serviceIds, servicePackageFormArray
    });
    this.form.updateValueAndValidity({ emitEvent: false, onlySelf: false });
    this._cdr.markForCheck();
  }

  servicePackageChangeHandler() {
    this.form.get('servicePackageIds')?.valueChanges.pipe(
      mergeMap((packages) => {
        if (!packages) return EMPTY;
        const pagingParams = collectParams({ pageSize: env.INTEGER_MAX_VALUE, pageNumber: '1', status: 'ACCEPTED' });

        // Remove controls
        let removeAts: number[] = [];
        Object.keys(this.controlMaps).forEach((pkg) => {
          if (!packages.includes(+pkg)) {
            removeAts.push(this.controlMaps[pkg]);
            delete this.controlMaps[pkg];
          }
        });
        removeAts = removeAts.sort((a, b) => b - a);
        removeAts.forEach(index => {
          this.servicePackageFormArray.removeAt(index);
          Object.keys(this.controlMaps).forEach((pkg) => {
            if (this.controlMaps[pkg] > index) {
              this.controlMaps[pkg] -= 1;
            }
          });
        });

        const packageDetails = packages.map((pkg: number) => {
          // Add controls
          if (this.controlMaps[pkg] === undefined) {
            this.controlMaps[pkg] = this.servicePackageFormArray.controls.length;
            this.packagesOptions.push([]);
            this.servicePackageFormArray.push(new FormControl<number[]>([]));
          }
          return this._apiService.get<ServicePackageModel>(ModuleConst.CMS + `/service-package/${pkg}`, pagingParams);
        });
        return forkJoin<ServicePackageModel[]>(packageDetails);
      }),
      takeUntil(this._destroy),
    ).subscribe((packageDetails: ServicePackageModel[]) => {
      this.packageDetails = packageDetails;
      this.packagesOptions = packageDetails.map((_, index) => this.getPackageOptions(index));
    })
  }

  nextStep() {
    const { carStatuses, carDescription, kmRun, servicePackageIds, serviceIds, servicePackageFormArray } = this.form.value;
    this._bookingCreateService.saveData({
      step3: {
        carStatuses: carStatuses || [],
        carDescription: carDescription || '',
        kmRun,
        servicePackageFormArray: servicePackageFormArray || [],
        servicePackageIds: servicePackageIds || [],
        serviceIds: serviceIds || [],
        carStatusAdditionalValues: this.carStatusAdditionalValues,
        rawCarStatuses: this.carStatusValues.map(_ => _.rawData),
        rawServices: this.servicePackageValues.map(_ => _.rawData),
      }
    })
    this._bookingCreateService.nextStep();
  }

  prevStep() {
    this._bookingCreateService.prevStep();
    this._bookingCreateService.prevStep();
  }

  // fetchMetaData() {
  //   const { step1 } = this._bookingCreateService.getData();
  //   const serviceType = step1 ? step1.serviceType : '';
  //   const pagingParams = collectParams({ pageSize: env.INTEGER_MAX_VALUE, pageNumber: '1', status: 'ACCEPTED', type: serviceType });
  //   return forkJoin([
  //     this._apiService.get<GenericModel>(ModuleConst.BOOKING + '/car-status', pagingParams),
  //     this._apiService.get<SuperServiceModel[]>(ModuleConst.CMS + '/service/supers-service', pagingParams),
  //   ]);
  // }

  fetchMetaData() {
    const serviceType = '';
    const pagingParams = collectParams({ pageSize: env.INTEGER_MAX_VALUE, pageNumber: '1', status: 'ACCEPTED', type: serviceType });
    return forkJoin([
      this._apiService.get<GenericModel>(ModuleConst.BOOKING + '/car-status', pagingParams),
      this._apiService.get<SuperServiceModel[]>(ModuleConst.CMS + '/service/supers-service', pagingParams),
    ]);
  }

  getPackageName(index: number) {
    let pkgDetail: ServicePackageModel | undefined;
    Object.keys(this.controlMaps).forEach((pkg) => {
      if (this.controlMaps[pkg] === index) {
        pkgDetail = this.packageDetails[index];
      }
    });
    return pkgDetail?.name || '';
  }

  getPackageOptions(index: number) {
    let pkgDetail: ServicePackageModel | undefined;
    Object.keys(this.controlMaps).forEach((pkg) => {
      if (this.controlMaps[pkg] === index) {
        pkgDetail = this.packageDetails[index];
      }
    });
    return (pkgDetail?.services || []).map((item: ServiceModel) => ({
        value: item.id,
        displayValue: item.name,
        rawData: item,
        disabled: false,
      }));
  }

  addCarStatus(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.form.get('carStatusInput')?.invalid || !this.form.value.carStatusInput) return;
      this.carStatusAdditionalValues.push({
        id: `local-values-${Date.now()}`,
        value: this.form.value.carStatusInput || '',
      });
      this.form.get('carStatusInput')?.patchValue('');
      this.form.get('carStatusInput')?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }
  }

  removeCarStatus(status: CarStatus) {
    this.carStatusAdditionalValues = this.carStatusAdditionalValues.filter(_ => _.id !== status.id);
  }

  get isValidForm() {
    // if (this.form.get('carStatuses')?.value?.length === 0 && this.carStatusAdditionalValues.length === 0) {
    //   return false;
    // }
    // if (this.form.invalid && this.form.get('carStatuses')?.valid) {
    //   return false;
    // }
    return true;
  }
}
