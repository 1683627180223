import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  ApiService,
  AuthoritiesService,
  BaseSearchLayout,
  FormStateService,
  SelectModel,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DestroyService} from 'src/app/_services/destroy.service';
import {LazyScrollTriggerOptions} from '../directives/lazy-scroll-trigger.directive';
import {InspectionModel} from 'src/app/components/doctor/booking/models/inspection.model';
import {catchError, combineLatest, debounceTime, forkJoin, map, mergeMap, Subject, takeUntil, throwError} from 'rxjs';
import {
  BookingCreateService,
  GenericModel,
  PagingResponseModel
} from 'src/app/components/doctor/booking/booking-create/booking-create.service';
import {CarStatus} from 'src/app/components/doctor/booking/models/car-status.model';
import {collectParams} from 'src/app/utils/booking/api.util';
import {environment as env} from 'src/environments/environment';
import {ModuleConst} from 'src/app/modules/module.const';
import {ServiceTicketService} from 'src/app/models/booking/booking.model';
import {DriverModel} from 'src/app/models/cms/driver.model';
import {DriverCarModel} from 'src/app/models/cms/driver-car.model';

interface OwnerCar {
  name: string;
  phone: string;
  driverAddresses: string;
  id: number;
}

interface InspectionHistoryResponse {
  currentOwnerCar: OwnerCar,
  brandName: string,
  carNumber: string,
  inspectionHistory: PagingResponseModel<any>
}

const PAGE_SIZE = env.INTEGER_MAX_VALUE;
const licensePlateRegex = /^\d\d[A-Z]\-\d\d\d\.\d\d$/g;

@Component({
  selector: 'app-doctor-booking-inspection',
  templateUrl: 'inspection.component.html',
  styleUrls: ['inspection.component.scss'],
  providers: [DestroyService],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionComponent extends BaseSearchLayout implements OnInit {
  inspections: InspectionModel[] = [];
  carStatusValues: SelectModel[] = [];
  driverInfo?: DriverModel;
  servicePackageValues: SelectModel[] = [];
  currentOwnerCar?: OwnerCar;
  selectedCar?: DriverCarModel;
  isSearchDriverError = false;
  isEmptyCar = false;
  brandName = '';
  carNumber = '';
  pageNumber = 1;
  canNext = true;
  isFirst = true;
  isLoading = false;
  inputErrorMsg = new Map<string, () => string>()
    .set('required', () => this.translateService.instant('common.required'))
    .set('invalidLicensePlate', () => this.translateService.instant('booking.inspection.licensePlate.invalid'))
    .set('licensePlateNotFound', () => this.translateService.instant('booking.inspection.search.license.plate.notfound'))
    .set('phoneNotFound', () => this.translateService.instant('booking.inspection.search.phone.notfound'))
  get options(): LazyScrollTriggerOptions {
    return { canTrigger: this.canNext, trigger: this.fetchInspectionHistory.bind(this) as any, threshold: 100 };
  }

  get inspectionInfo(): InspectionModel | undefined {
    return this.inspections ? this.inspections[0] : undefined;
  }

  constructor(
    protected formBuilder: FormBuilder,
    protected router: Router,
    protected apiService: ApiService,
    protected utilsService: UtilsService,
    protected uiStateService: FormStateService,
    protected translateService: TranslateService,
    protected injector: Injector,
    protected activatedRoute: ActivatedRoute,
    protected authoritiesService: AuthoritiesService,
    private _destroy: DestroyService,
    private _cdr: ChangeDetectorRef,
    private _bookingCreateService: BookingCreateService,
  ) {
    super(router, apiService, utilsService, uiStateService, translateService, injector, activatedRoute, authoritiesService,
      formBuilder.group({
        licensePlate: [''],
        driverPhoneNumber: [''],
        statuses: [[]],
        services: [[]],
      }));
  }

  ngOnInit() {
    this.fetchMetaData();
    this.searchForm.get('licensePlate')?.valueChanges
      .pipe(
        debounceTime(500),
        takeUntil(this._destroy),
      )
      .subscribe((licensePlate) => {
        if (this.searchForm.get('licensePlate')?.hasError('pattern')) return;
        this.fetchMetaData(() => {
          this.isFirst = true;
          this.canNext = true;
          this.fetchInspectionHistory(() => {
          });
        });
      });

    this.searchForm.get('driverPhoneNumber')?.valueChanges
      .pipe(
        debounceTime(500),
        takeUntil(this._destroy),
      )
      .subscribe((driverPhoneNumber) => {
        console.log(driverPhoneNumber);
        if (!driverPhoneNumber) return;
        this.searchDriver(driverPhoneNumber || '').subscribe(data => {
          if (data?.driverCars && data?.driverCars.length > 0 && data?.phone === driverPhoneNumber) {
            this.driverInfo = data;
            this.selectCar(data.driverCars[0]);
            this.isEmptyCar = false;
          } else if (data?.driverCars && data?.driverCars.length === 0 && data?.phone === driverPhoneNumber) {
            this.driverInfo = data;
            this.selectCar(undefined);
            this.isEmptyCar = true;
          } else {
            this.searchForm.get('driverPhoneNumber')?.setErrors({phoneNotFound: true});
            this.driverInfo = undefined;
            this.selectCar(undefined);
            this.isEmptyCar = true;
          }
          this._cdr.markForCheck();
        });
      });
    this.initData();

    combineLatest(
      [
        this.searchForm.get('statuses')?.valueChanges ?? new Subject(),
        this.searchForm.get('services')?.valueChanges ?? new Subject(),
      ]
    )
      .pipe(
        debounceTime(500),
        takeUntil(this._destroy),
      )
      .subscribe(() => {
        if (this.searchForm.hasError('invalidLicensePlate')) return;
        this.isFirst = true;
        this.canNext = true;
        this.fetchInspectionHistory(() => {});
      });
  }

  initData() {
    const licensePlate = this.activatedRoute.snapshot.queryParamMap.get('carNumber');
    const driverPhoneNumber = this.activatedRoute.snapshot.queryParamMap.get('driverPhone');
    if (licensePlate !== this.searchForm.get('licensePlate')?.value) {
      this.searchForm.get('licensePlate')?.setValue(licensePlate, {emitEvent: true, onlySelf: true});
    }
    if (driverPhoneNumber !== this.searchForm.get('driverPhoneNumber')?.value) {
      this.searchForm.get('driverPhoneNumber')?.setValue(driverPhoneNumber, {emitEvent: true, onlySelf: true});
    }
  }

  onInputLicensePlate($event: any) {
    if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes($event.key)) return;
    this.searchForm.patchValue({licensePlate: $event.target.value});
    this.searchForm.get('licensePlate')?.updateValueAndValidity({onlySelf: true, emitEvent: true});
  }

  onInputDriverPhone($event: any) {
    if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes($event.key)) return;
    this.searchForm.patchValue({driverPhoneNumber: $event.target.value});
    this.searchForm.get('driverPhoneNumber')?.updateValueAndValidity({onlySelf: true, emitEvent: true});
  }

  fetchInspectionHistory(callback: () => void) {
    if (this.isLoading) return;
    this.isLoading = true;

    if (this.isFirst) {
      this.pageNumber = 1;
      this.inspections = [];
    }
    const {licensePlate, driverPhoneNumber, statuses, services} = this.searchForm.value;
    if ((!licensePlate && !driverPhoneNumber) || !this.canNext) {
      this.isLoading = false;
      return;
    };
    // this.updateRouterParams(licensePlate);
    const pagingParams = {
      pageSize: PAGE_SIZE, pageNumber: this.pageNumber,
      carNumber: licensePlate ? licensePlate : this.selectedCar?.licensePlate,
      driverPhone: driverPhoneNumber,
      declaredStatuses: statuses, usedServices: services,
    };
    this.apiService.post(ModuleConst.BOOKING + '/expert/inspection-result-profile', pagingParams)
      .pipe(
        map(item => item as InspectionHistoryResponse),
        takeUntil(this._destroy),
        catchError(error => {
          this.isLoading = false
          return throwError(() => error)
        })
      )
      .subscribe((page) => {
        this.isLoading = false;
        const { currentOwnerCar, brandName, carNumber, inspectionHistory } = page;
        if (!currentOwnerCar) {
          this.searchForm.get('licensePlate')?.setErrors({ licensePlateNotFound: true });
        } else {
          this.searchForm.get('licensePlate')?.setErrors(null);
        }
        this.currentOwnerCar = currentOwnerCar;
        this.brandName = brandName;
        this.carNumber = carNumber;
        this.inspections?.push(...inspectionHistory.content);
        this.canNext = !inspectionHistory.last;
        if (this.canNext) this.pageNumber += 1;
        this.isFirst = false;
        callback();
        this._cdr.markForCheck();
      });
  }

  searchDriver(phone = '') {
    this.isSearchDriverError = false;
    const driverParams = collectParams({phone}, true);
    return this.apiService.get<GenericModel>(ModuleConst.CMS + '/driver', driverParams)
      .pipe(
        map(resp => {
          if (!resp?.content) return;
          const content: GenericModel = resp.content;
          if (!content || !content[0]) {
            this.driverInfo = undefined;
            this.selectCar(undefined);
            this.searchForm.get('driverPhoneNumber')?.setErrors({phoneNotFound: true});
            this._cdr.markForCheck();
          }
          return content ? content[0] : undefined
        }),
        mergeMap((driver) => {
          const detailParams = collectParams({}, false)
          return this.apiService.get<GenericModel>(ModuleConst.BOOKING + `/driver/${driver?.id}`, detailParams)
            .pipe(
              map((driverDetail) => ({
                ...driver,
                ...driverDetail,
              }))
            )
        }),
        catchError(error => {
          this.isSearchDriverError = true;
          this._cdr.markForCheck();
          return throwError(error);
        })
      );
  }

  viewInspection(inspection: InspectionModel) {
    const nextWindow = window.open(`/#/booking/inspection/${inspection.requestTicketId}`, '_blank');
    const token = window.sessionStorage.getItem('token');
    nextWindow?.sessionStorage.setItem('token', token || '');
  }

  inspectionId(index: number, item: InspectionModel) {
    return item.id;
  }

  getCarStatuses(statuses: CarStatus[] = []) {
    if (!statuses) return '';
    return statuses.reduce((acc, status, index) => {
      if (index === statuses.length - 1) return acc + `${status.value}`;
      return acc + `${status.value}, `;
    }, '');
  }

  selectCar(carInfo?: DriverCarModel) {
    console.log(carInfo);
    this.selectedCar = carInfo;
    this.fetchMetaData(() => {
      this.isFirst = true;
      this.canNext = true;
      this.fetchInspectionHistory(() => {
      });
    });
  }

  getCarServices(services: ServiceTicketService[] = []) {
    if (!services) return '';
    return services.reduce((acc, service, index) => {
      if (index === services.length - 1) return acc + `${service.name}`;
      return acc + `${service.name}, `;
    }, '');
  }

  fetchMetaData(callback?: (arg: any) => void) {
    const licensePlate = this.searchForm.get('licensePlate')?.value ? this.searchForm.get('licensePlate')?.value : this.selectedCar?.licensePlate;
    const driverPhoneNumber = this.searchForm.get('driverPhoneNumber')?.value;
    const pagingParams = collectParams({
      pageSize: env.INTEGER_MAX_VALUE,
      pageNumber: '1',
      type: 'STATUSES_DECLARED',
      carLicensePlate: licensePlate,
      driverPhone: driverPhoneNumber
    });
    const pagingServiceParams = collectParams({
      pageSize: env.INTEGER_MAX_VALUE,
      pageNumber: '1',
      type: 'SERVICES_USED',
      carLicensePlate: licensePlate,
      driverPhone: driverPhoneNumber
    });
    return forkJoin([
      this.apiService.get<PagingResponseModel<string>>(ModuleConst.BOOKING + '/history/car-profile', pagingParams),
      this.apiService.get<PagingResponseModel<string>>(ModuleConst.BOOKING + '/history/car-profile', pagingServiceParams),
    ]).pipe(takeUntil(this._destroy))
      .subscribe(([carStatus, services]) => {
        this.carStatusValues = carStatus.content.map((item: string) => ({
          value: item,
          displayValue: item,
          rawData: item,
          disabled: false,
        }));
        this.servicePackageValues = services.content.map((item: string) => ({
          value: item,
          displayValue: item,
          rawData: item,
          disabled: false,
        }));
        if (callback) {
          callback(licensePlate);
        }
      });
  }

  async updateRouterParams(carNumber = '') {
    this.router.onSameUrlNavigation = 'ignore';
    await this.router.navigate(['.'], {
      queryParams: {carNumber},
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
      // skipLocationChange: true,
    });
    this.router.onSameUrlNavigation = 'reload';
  }
}
