import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { BookingDoneData } from '../../booking-create/booking-create.service';

@Component({
  selector: 'app-doctor-booking-done',
  templateUrl: './done.component.html',
  styleUrls: ['./done.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DoneComponent {
  @Output() done = new EventEmitter<void>();
  @Input() data?: BookingDoneData;
  constructor() { }
}
