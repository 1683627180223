import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BookingCreateService, BookingCreateStep, BookingDoneData } from '../../booking-create/booking-create.service';
import { ApiService, Page } from '@next-solutions/next-solutions-base';
import { GarageModel } from 'src/app/models/cms/garage.model';
import { collectParams } from 'src/app/utils/booking/api.util';
import { environment as env } from '../../../../../../environments/environment';
import { ModuleConst } from 'src/app/modules/module.const';
import { DestroyService } from 'src/app/_services/destroy.service';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { FirebaseService } from '../../booking-create/firebase.service';
import { MessageType } from '../../booking-create/firebase.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

enum StepPages {
  VIEW_LIST,
  VIEW_DETAIL,
  WAITING,
  DONE,
}

@Component({
  selector: 'app-doctor-booking-create-step-5',
  templateUrl: './step5.component.html',
  styleUrls: ['./step5.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [DestroyService]
})
export class Step5Component implements OnInit {
  BookingCreateStep = BookingCreateStep;
  StepPages = StepPages;
  currentPage = new BehaviorSubject<StepPages>(StepPages.VIEW_LIST);
  garages: GarageModel[] = [];
  selectedGarage?: GarageModel;
  viewingGarageId?: number;
  bookingScheduled = false;
  bookingDate = '2022-10-26 00:00:00.000Z';
  bookingTime = '08:14';
  currentLat = '';
  currentLng = '';
  maxDistance = '1000';
  serviceIds = [2,3,4];
  servicePackageIds = [];
  garageSupportBrandIds = []
  currentAppointmentId?: string | number;
  bookingDoneData?: BookingDoneData;

  constructor(
    private _bookingCreateService: BookingCreateService,
    private _firebaseService: FirebaseService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _router: Router,
    private _apiService: ApiService,
    private _destroy: DestroyService,
    private _cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.initData();
    this.registerBookingEventListener();
    this.getGarages();
  }

  initData() {
    const { step5 } = this._bookingCreateService.getData();
    this.selectedGarage = step5?.garage;
  }

  getGarages() {
    const { step4, step3, step2 } = this._bookingCreateService.getData();
    if (step4 && step3 && step2) {
      const { bookingDate, bookingHour, placeId, utilities, distance } = step4;
      const { servicePackageIds, servicePackageFormArray = [] } = step3;
      const { carInfo, driverInfo } = step2;
      const params = collectParams({
        pageSize: env.INTEGER_MAX_VALUE,
        pageNumber: '1',
        status: 'ACCEPTED',
        bookingDate,
        bookingTime: bookingHour,
        placeId,
        serviceIds: servicePackageFormArray.reduce((acc, item) => [...acc, ...item], []),
        carId: carInfo?.carId,
        driverId: driverInfo?.id,
      });
      return this._apiService.get<Page>(ModuleConst.BOOKING + '/garage/to-booking', params)
        .subscribe((page) => {
          this.garages = page.content;
          this._cdr.markForCheck();
        });
    }
  }

  selectGarage(garage: GarageModel) {
    if (this.selectedGarage?.id === garage.id) {
      this.selectedGarage = undefined;
    } else {
      this.selectedGarage = garage;
    }
    this._bookingCreateService.saveData({ step5: { garage }});
  }

  viewGarage(garage: GarageModel) {
    if (this.selectedGarage?.id !== garage.id) {
      this.selectedGarage = garage;
      this._bookingCreateService.saveData({ step5: { garage }});
    }
    this.viewingGarageId = this.selectedGarage?.id || undefined;
    this.currentPage.next(StepPages.VIEW_DETAIL);
  }

  viewList() {
    if (this.currentAppointmentId) {
      this._apiService.post(ModuleConst.BOOKING + `/appointment-schedule/${this.currentAppointmentId}/canceled`,
        { reason: 'Cancelled by expert.' }
      ).subscribe({ next: console.log, error: console.log });
    }
    this.viewingGarageId = undefined;
    this.currentPage.next(StepPages.VIEW_LIST);
  }

  registerBookingEventListener() {
    this._firebaseService.getMessage()
      .pipe(takeUntil(this._destroy))
      .subscribe((message) => {
        console.log('Message is', message);
        console.log('Appointment Id is ' + message?.data?.appointmentId);
        if (!message?.data) return;
        if (message.data.appointmentId?.toString() !== this.currentAppointmentId?.toString()) return;
        if (message.data.type === MessageType.EXPERT_GARAGE_REJECT_APPOINTMENT) {

          // Thông báo cho người dùng vì sao lại huỷ
          this._toastrService.error(
            message.body || '',
            message.title || '',
          );
          return this._router.navigate(['booking', 'detail', message.data.appointmentId]);
        }

        if (message.data.type === MessageType.EXPERT_CREATE_APPOINTMENT) {
          console.log('Expert create success');
          // Thông báo cho người dùng đã đặt lịch thành công
          this._toastrService.success(
            message.body || '',
            message.title || '',
          );
          return this._router.navigate(['booking', 'detail', message.data.appointmentId]);
        }
      });
  }

  nextStep() {
    this._bookingCreateService.createBooking()
      .subscribe((res: any) => {
        console.log(res);
        this.bookingDoneData = {
          name: res.garage_name,
          address: res.garage_address,
          time: new Date(res.appointment_date),
        }
        this.currentAppointmentId = res.id;
        this.currentPage.next(StepPages.WAITING);
        this._cdr.markForCheck();
      });
  }

  assignGarage() {
    this._bookingCreateService.assignGarage()
      .subscribe((res: any) => {
        this.bookingDoneData = {
          name: res.garage_name,
          address: res.garage_address,
          time: new Date(res.appointment_date),
        }
        this.currentAppointmentId = res.id;
        // this.currentPage.next(StepPages.WAITING);
        // this._cdr.markForCheck();
        return this._router.navigate(['booking', 'detail', this.currentAppointmentId]);
      });
  }

  prevStep() {
    this._bookingCreateService.prevStep();
  }

  done() {
    this._bookingCreateService.clearData();
  }
}
