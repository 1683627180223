import { HttpParams } from '@angular/common/http';
import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import {
  AlignEnum,
  ApiService,
  AuthoritiesService,
  BaseSearchLayout,
  ButtonFields,
  ColumnFields,
  DateUtilService,
  FormStateService,
  IconTypeEnum,
  Page,
  SelectModel,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import { TranslateService } from '@ngx-translate/core';
import { BookingModel, EXPERT_PROCESSING_STATUS, SERIVCE_TYPE } from 'src/app/models/booking/booking.model';
import { ModuleConst } from 'src/app/modules/module.const';
import { BookingUtil } from 'src/app/utils/booking/booking.util';
import { EnumUtil } from 'src/app/utils/enum.util';
import { environment } from 'src/environments/environment';
import { GaragesComponent } from './garages/garages.component';
import { PopupChangeProcessingStatusComponent } from '../components/popup-change-processing-status/popup-change-processing-status.component';
import { DatePipe } from '@angular/common';
import { ZEGO_CONVERSATION_TYPE } from 'src/app/models/zegocloud/zego.model';
import { SuperStatusEnum } from 'src/app/enums/super.status.enum';
import { ExpertModel } from 'src/app/models/cms/expert.model';

@Component({
  selector: 'app-booking-upgrade-list',
  templateUrl: './booking-upgrade-list.component.html',
})
export class BookingUpgradeListComponent extends BaseSearchLayout implements OnInit {
  moduleName = 'booking';
  DESCRIPTION_MAX_LENGTH = 20;

  columns: ColumnFields[] = [];
  buttons: ButtonFields[] = [];

  expertProcessingStatusVaules: SelectModel[] = [];
  expertValues: SelectModel[] = [];

  get expandHeaderButton() {
    return environment.EXPAND_HEADER_BUTTON;
  }

  constructor(
    protected formBuilder: FormBuilder,
    protected router: Router,
    protected apiService: ApiService,
    protected utilsService: UtilsService,
    protected uiStateService: FormStateService,
    protected translateService: TranslateService,
    protected injector: Injector,
    protected activatedRoute: ActivatedRoute,
    protected authoritiesService: AuthoritiesService,
    protected dateUtilService: DateUtilService,
    private datePipe: DatePipe,
    public bookingUtil: BookingUtil,
    public matDialog: MatDialog,
    @Optional() public matDialogRef: MatDialogRef<BookingModel>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: boolean,
  ) {
    super(
      router,
      apiService,
      utilsService,
      uiStateService,
      translateService,
      injector,
      activatedRoute,
      authoritiesService,
      formBuilder.group({
        driver: [''],
        expertProcessingStatus: [''],
        expertIds: [''],
        timeBooking: [''],
        startDate: [''],
        endDate: [''],
      }),
    );

    this.initColumns();
    this.initButtons();
    this.initDataFilters();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onSubmit();
  }

  ngOnDestroy(): void {
    this.onResetForm();
  }

  initColumns() {
    this.columns.push(
      {
        columnDef: 'stt',
        header: 'stt',
        title: (e: BookingModel) => `${UtilsService.calcPosition(e, this.results, this.paging)}`,
        cell: (e: BookingModel) => `${UtilsService.calcPosition(e, this.results, this.paging)}`,
        className: 'mat-column-stt',
        align: AlignEnum.CENTER,
        display: () => true,
        isShowHeader: true,
      },
      {
        columnDef: 'driverName',
        header: 'customer',
        title: (e: BookingModel) => `${e.driverName ?? ''}`,
        cell: (e: BookingModel) => `${e.driverName ?? ''}`,
        className: 'mat-column-driver-name',
        display: () => true,
        isShowHeader: true,
      },
      {
        columnDef: 'driverPhone',
        header: 'phone_number',
        title: (e: BookingModel) => `${e.driverPhone ?? ''}`,
        cell: (e: BookingModel) => `${e.driverPhone ?? ''}`,
        className: 'mat-column-driver-phone',
        display: () => true,
        isShowHeader: true,
      },
      {
        columnDef: 'timeCreate',
        header: 'time_create',
        title: (e: BookingModel) =>
          `${e.createDate ? this.datePipe.transform(e.createDate, 'HH:mm, dd/MM/yyyy', 'UTC') : ''}`,
        cell: (e: BookingModel) =>
          `${e.createDate ? this.datePipe.transform(e.createDate, 'HH:mm, dd/MM/yyyy', 'UTC') : ''}`,
        className: 'mat-column-time-create',
        display: () => true,
        isShowHeader: true,
      },
      {
        columnDef: 'description',
        header: 'description',
        title: (e: BookingModel) => `${e.description ?? ''}`,
        cell: (e: BookingModel) =>
          `${
            e.description && e.description.length >= this.DESCRIPTION_MAX_LENGTH
              ? `${e.description.substring(0, this.DESCRIPTION_MAX_LENGTH)}...`
              : e.description
          }`,
        className: 'mat-column-description',
        display: () => true,
        isShowHeader: true,
      },
      {
        columnDef: 'expertProcessingStatus',
        header: 'expert_processing_status',
        title: (e: BookingModel) =>
          e.expertProcessingStatus
            ? `${this.utilsService.getEnumValueTranslated(EXPERT_PROCESSING_STATUS, e.expertProcessingStatus)}`
            : '',
        cell: (e: BookingModel) =>
          e.expertProcessingStatus
            ? `${this.utilsService.getEnumValueTranslated(EXPERT_PROCESSING_STATUS, e.expertProcessingStatus)}`
            : '',
        className: 'mat-column-expert-processing-status',
        display: () => true,
      },
      {
        columnDef: 'expert',
        header: 'expert',
        title: (e: BookingModel) => `${e.expertName ?? ''}`,
        cell: (e: BookingModel) => `${e.expertName ?? ''}`,
        className: 'mat-column-expert',
      },
    );
  }

  initDataFilters() {
    EnumUtil.enum2SelectModel(
      EXPERT_PROCESSING_STATUS,
      this.expertProcessingStatusVaules,
      'SEARCH',
      true,
      this.utilsService,
    );

    const params = new HttpParams()
      .set('status', EnumUtil.getKeysByValues(SuperStatusEnum, [SuperStatusEnum._ACCEPTED]))
      .set('pageNumber', '1')
      .set('pageSize', environment.INTEGER_MAX_VALUE);

    this.apiService.get<Page>(ModuleConst.CMS + '/expert', params).subscribe((pageExpert) => {
      if (pageExpert?.content?.length >= 0) {
        this.expertValues = pageExpert.content.map((expert: ExpertModel) => new SelectModel(expert.id, expert.name));
      }
    });
  }

  initButtons() {
    this.buttons.push(
      {
        columnDef: 'change_status',
        color: 'warn',
        icon: 'fa fa-pen',
        iconType: IconTypeEnum.FONT_AWESOME,
        click: 'changeExpertProcessingStatus',
        className: 'primary',
        title: 'booking.table.header.expert_processing_status',
        display: () => true,
      },
      {
        columnDef: 'audio_call',
        color: 'warn',
        icon: 'fa fa-phone-alt',
        iconType: IconTypeEnum.FONT_AWESOME,
        click: 'audioCall',
        className: 'primary',
        title: 'booking.common.audio_call',
        display: () => true,
      },
      {
        columnDef: 'video_call',
        color: 'warn',
        icon: 'fa fa-solid fa-video',
        iconType: IconTypeEnum.FONT_AWESOME,
        click: 'videoCall',
        className: 'primary',
        title: 'booking.common.video_call',
        display: () => true,
      },
    );
  }

  search() {
    const params = this.collectParams();
    this._fillData(ModuleConst.BOOKING + '/expert/appointment-schedule', params);
  }

  collectParams(): HttpParams {
    const map = Object.fromEntries(['driver', 'startDate', 'endDate', 'expertIds'].map((s) => [s, s]));
    let params = this._collectParams(this.searchForm, map);

    params = params.set('serviceTypes', EnumUtil.getKeysByValues(SERIVCE_TYPE, [SERIVCE_TYPE._UPGRADE]));
    params = params.set(
      'expertProcessingStatus',
      `${this.searchForm.get('expertProcessingStatus')?.value}`.replace('_', '').replace(/,_/g, ','),
    );

    return params;
  }

  onResetForm() {
    this.searchForm.reset();
    this.searchForm.patchValue({
      driver: [],
      expertProcessingStatus: [],
      expertIds: [],
      timeBooking: [],
    });
  }

  audioCall(row: BookingModel) {
    this.openZegoCloud(ZEGO_CONVERSATION_TYPE.AUDIO_CALL, row);
  }

  videoCall(row: BookingModel) {
    this.openZegoCloud(ZEGO_CONVERSATION_TYPE.VIDEO_CALL, row);
  }

  openZegoCloud(type: ZEGO_CONVERSATION_TYPE, row: BookingModel) {
    const url = `/#/booking/ipcc/call/${row.requestTicketId}?type=${type}`;
    const nextWindow = window.open(url, '_blank');
    const token = window.sessionStorage.getItem('token');
    nextWindow?.sessionStorage.setItem('token', token || '');
  }

  changeExpertProcessingStatus(row: BookingModel) {
    this.matDialog
      .open(PopupChangeProcessingStatusComponent, {
        maxWidth: '90vw',
        maxHeight: '90vh',
        panelClass: 'change-processing-status-modal',
        data: {
          ticket: row,
          page: 'UPGRADE',
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.search();
      });
  }

  getListGara() {
    this.matDialog.open(GaragesComponent, {
      maxWidth: '90vw',
      maxHeight: '90vh',
      panelClass: 'upgrade-garage-modal',
    });
  }
}
