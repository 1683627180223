import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DestroyService } from 'src/app/_services/destroy.service';
import { NotificationService } from '../../services/notification.service';
import { FirebaseService } from '../../booking-create/firebase.service';
import { Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs';
import { NotificationModel } from 'src/app/components/doctor/booking/models/notification.model';
import { collectParams } from 'src/app/utils/booking/api.util';
import { LazyScrollTriggerOptions } from 'src/app/components/doctor/booking/directives/lazy-scroll-trigger.directive';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { AuthoritiesService } from '@next-solutions/next-solutions-base';

@Component({
  selector: 'app-doctor-booking-notification-indicator',
  templateUrl: 'notification-indicator.component.html',
  styleUrls: ['notification-indicator.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DestroyService],
})
export class NotificationIndicatorComponent implements OnInit {
  @ViewChild('notificationListTemplate', { static: true }) notificationListTemplate!: ElementRef;
  @ViewChild('notificationAnchor', { static: true }) notificationAnchor!: ElementRef;
  overlayRef?: OverlayRef;
  count = 0;
  pageSize = 5;
  pageNumber = 1;
  notificationList: NotificationModel[] = [];
  options: LazyScrollTriggerOptions = { canTrigger: true, trigger: this.getNotification.bind(this), threshold: 100 };

  constructor(
    private _notificationService: NotificationService,
    private _firebaseService: FirebaseService,
    private _authoritiesService: AuthoritiesService,
    private _destroy: DestroyService,
    private _router: Router,
    private _overlay: Overlay,
  ) {}

  ngOnInit() {
    this.initData();
    this.registerNotificationUpdateHandler();
  }

  initData() {
    this._authoritiesService.me$
      .pipe(takeUntil(this._destroy))
      .subscribe((me) => {
        if (me.userAuthentication) {
          this.getNotification(() => {});
          this._notificationService.getCountNotifications()
            .pipe(takeUntil(this._destroy))
            .subscribe((response) => {
              this.count = response?.countUnseen;
            });
        }
      });
  }

  registerNotificationUpdateHandler() {
    this._firebaseService.getMessage()
      .pipe(takeUntil(this._destroy))
      .subscribe((data) => {
        this.initData();
      });
  }

  getNotification(callback: () => void) {
    const params = collectParams({ pageSize: this.pageSize, pageNumber: this.pageNumber });
    this._notificationService.getNotifications(params)
      .pipe(
        takeUntil(this._destroy),
        finalize(callback),
      )
      .subscribe((response) => {
        if (this.pageNumber === 1) {
          this.notificationList = response.content || [];
        } else {
          this.notificationList.push(...(response.content || []));
        }
        this.options.canTrigger = !response.last;
        if (this.options.canTrigger) {
          this.pageNumber += 1;
        }
        callback();
      })
  }

  openNotificationList() {
    this.pageNumber = 1;
    this.getNotification(() => {
      this.markAllSeenNotification();
      if (!this.overlayRef) {
        const positionStrategy = this._overlay.position()
          .flexibleConnectedTo(this.notificationAnchor)
          .withPositions([{
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          }]);
        const scrollStrategy = this._overlay.scrollStrategies.reposition();
        this.overlayRef = this._overlay.create({ positionStrategy, scrollStrategy, });
        // this.overlayRef.updateSize({ width: '300px' });
        this.overlayRef.outsidePointerEvents().subscribe(() => {
          if (this.overlayRef?.hasAttached()) {
            this.overlayRef?.detach();
          }
        });
      }
      if (!this.overlayRef?.hasAttached()) {
        this.overlayRef.attach(this.notificationListTemplate);
      }
    });
  }

  notifyId(index: number, item: NotificationModel) {
    return item.id;
  }

  goNotification() {
    this._router.navigate(['booking', 'notification']);
  }

  readNotification(notification: NotificationModel) {
    notification.read = true;
    return this._notificationService.putNotification(notification).subscribe(() => {
      this.updateReadNotification(notification.id);
    });
  }

  openNotification(notification: NotificationModel) {
    this.readNotification(notification);
    this.closeNotificationList();
    this._notificationService.goNotification(notification);
  }

  updateReadNotification(id: number) {
    const notification = this.notificationList.find(_ => _.id === id);
    if (!notification) return;
    notification.isRead = true;
    return notification;
  }

  markAllSeenNotification() {
    return this._notificationService.seenAllNotification().subscribe(() => {
      this.initData();
    });
  }

  closeNotificationList() {
    if (this.overlayRef?.hasAttached()) {
      this.overlayRef?.detach();
    }
  }
}
