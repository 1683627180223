<div class="searchFormGroup">
  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="form">
    <fieldset style="min-width: 60vw">
      <legend>{{ moduleName + '.table.header.choose_garage' | translate }}</legend>
      <div fxLayout="row wrap" class="row-wrap-padding">
        <ns-input
          [placeholder]="moduleName + '.table.header.garage'"
          name="name"
          formControlName="name"
          fxLayout="column"
          fxFlex="25%"
          fxFlex.xs="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="25%"
        >
        </ns-input>
        <ns-input
          [placeholder]="moduleName + '.table.header.phone_number'"
          name="contactPointPhone"
          formControlName="contactPointPhone"
          fxLayout="column"
          fxFlex="25%"
          fxFlex.xs="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="25%"
        >
        </ns-input>
        <ns-multi-select-autocomplete
          [multiple]="false"
          [options]="provinceOptions"
          [placeholder]="moduleName + '.table.header.province_id'"
          (selectedOptionDataChange)="onProvinceChange($event)"
          name="provinceId"
          formControlName="provinceId"
          fxLayout="column"
          fxFlex="25%"
          fxFlex.xs="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="25%"
        >
        </ns-multi-select-autocomplete>
        <ns-multi-select-autocomplete
          [multiple]="false"
          [options]="districtOptions"
          [placeholder]="moduleName + '.table.header.district_id'"
          name="districtId"
          formControlName="districtId"
          fxLayout="column"
          fxFlex="25%"
          fxFlex.xs="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="25%"
        >
        </ns-multi-select-autocomplete>
      </div>
      <div class="buttonArea">
        <button mat-button type="submit" class="primary Update-btn" [disabled]="searchForm.invalid">
          {{ 'btnSearch' | translate }}
        </button>
        <button mat-button type="button" class="secondary outline reset-btn" (click)="onResetForm()">
          {{ 'btnReset' | translate }}
        </button>
      </div>
    </fieldset>
  </form>
  <div class="margin-bottom-20" fxLayout fxLayoutAlign="flex-end">
    <div class="button-area" fxLayout>
      <button mat-button class="secondary outline" (click)="closeModal()">
        {{ moduleName + '.common.cancel' | translate }}
      </button>
      <button [disabled]="!selectedGarageId" mat-button class="primary reset-btn" (click)="assignGarage()">
        {{ moduleName + '.table.header.assign' | translate }}
      </button>
    </div>
  </div>
  <div class="acbox">
    <ns-table
      [columns]="columns"
      [paging]="paging"
      [moduleName]="moduleName"
      [results]="results"
      (pagingChange)="pagingChange($event)"
      (clickAction)="onRowButtonClick($event)"
    >
    </ns-table>
  </div>
</div>
