import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DestroyService } from 'src/app/_services/destroy.service';
import { GarageModel } from 'src/app/models/cms/garage.model';
import { takeUntil } from 'rxjs';
import { BookingCreateService } from '../../booking-create/booking-create.service';
import { ApiService } from '@next-solutions/next-solutions-base';
import { AreaModel } from 'src/app/models/cms/area.model';
import { CountryModel } from 'src/app/models/config/country.model';

@Component({
  selector: 'app-doctor-booking-garage-info',
  templateUrl: './garage-info.component.html',
  styleUrls: ['./garage-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [DestroyService],
})
export class GarageInfoComponent implements OnInit {
  garage?: GarageModel;
  @Input()
  set garageId(id: number) {
    if (id) {
      this.fetchGarage(id);
    }
  };
  areas: AreaModel[] = [];
  countries: CountryModel[] = [];
  services: any[] = [];
  constructor(
    private _bookingCreateService: BookingCreateService,
    private _apiService: ApiService,
    private _destroy: DestroyService,
    private _cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this._bookingCreateService.fetchAddresses().pipe(takeUntil(this._destroy))
      .subscribe(({ areas, countries }) => {
        this.areas = areas;
        this.countries = countries;
        this._cdr.markForCheck();
      });
  }

  fetchGarage(id: number) {
    this._bookingCreateService.fetchGarage(id).subscribe(garage => {
      this.garage = garage;
      const tempServices = this.buildServices(garage);
      this.services = Object.keys(tempServices).map(_ => ({ key: _, data: tempServices[_]}))
      this._cdr.markForCheck();
    });
  }

  buildServices({ services = [] }: any) {
    return services.reduce((acc: any, item: { serviceTypes: any; }) => {
      const { serviceTypes = [] } = item;
      serviceTypes.forEach((service: { type: string | number; }) => {
        if (service.type) {
          if (acc[service.type]) {
            acc[service.type].push(item);
          } else {
            acc[service.type] = [];
            acc[service.type].push(item);
          }
        }
      });
      return acc;
    }, {});
  }

  buildAddress(): string {
    if (!this.garage) return '';
    this.garage.address = this.garage.address ?? [];
    const { districtId, provinceId, wardId, address } = this.garage;
    const district = this.areas.find(area => area.type === 'DISTRICT' && area.id === districtId);
    const province = this.areas.find(area => area.type === 'PROVINCE' && area.id === provinceId);
    const ward = this.areas.find(area => area.type === 'WARD' && area.id === wardId);
    return `${address}, ${ward?.name}, ${district?.name}, ${province?.name}`;
  }
}
