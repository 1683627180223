import {Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
  AlignEnum,
  ApiService,
  AuthoritiesService,
  BaseSearchLayout,
  ButtonFields,
  ColumnFields,
  FormStateService,
  IconTypeEnum,
  SelectModel,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import {HttpParams} from '@angular/common/http';
import {EnumUtil} from 'src/app/utils/enum.util';
import {environment} from 'src/environments/environment';
import {CarStatusModel} from 'src/app/models/booking/booking.model';
import {SuperStatusEnum} from 'src/app/enums/super.status.enum';
import {ModuleConst} from 'src/app/modules/module.const';
import {BookingUtil} from 'src/app/utils/booking/booking.util';


@Component({
  selector: 'app-booking-car-status',
  templateUrl: './car-status.component.html',
  styleUrls: ['./car-status.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CarStatusComponent extends BaseSearchLayout implements OnInit {

  moduleName = 'booking';
  columns: ColumnFields[] = [];
  buttons: ButtonFields[] = [];
  statusValues: SelectModel[] = [];

  get expandHeaderButton() {
    return environment.EXPAND_HEADER_BUTTON;
  }

  constructor(protected formBuilder: FormBuilder,
              protected router: Router,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected uiStateService: FormStateService,
              protected translateService: TranslateService,
              protected injector: Injector,
              protected bookingUtil: BookingUtil,
              protected activatedRoute: ActivatedRoute,
              protected authoritiesService: AuthoritiesService) {
    super(router, apiService, utilsService, uiStateService, translateService, injector, activatedRoute, authoritiesService,
      formBuilder.group({
        value: [''],
        status: ['_'],
      }));
    this.columns.push(
      {
        columnDef: 'stt', header: 'stt',
        title: (e: CarStatusModel) => `${UtilsService.calcPosition(e, this.results, this.paging)}`,
        cell: (e: CarStatusModel) => `${UtilsService.calcPosition(e, this.results, this.paging)}`,
        className: 'mat-column-stt',
        align: AlignEnum.CENTER,
      },
      {
        columnDef: 'car_status', header: 'car_status',
        title: (e: CarStatusModel) => `${e.value}`,
        cell: (e: CarStatusModel) => `${e.value}`,
        className: 'mat-column-car-status',
        align: AlignEnum.CENTER,
      },
      {
        columnDef: 'status', header: 'status',
        title: (e: CarStatusModel) => `${this.utilsService.getEnumValueTranslated(SuperStatusEnum, `${e.status}`)}`,
        cell: (e: CarStatusModel) => `${this.utilsService.getEnumValueTranslated(SuperStatusEnum, `${e.status}`)}`,
        className: 'mat-column-status',
        align: AlignEnum.CENTER,
      }
    )

    this.buttons.push(
      {
        columnDef: 'edit',
        color: 'warn',
        icon: 'fa fa-pen',
        iconType: IconTypeEnum.FONT_AWESOME,
        click: 'addOrEdit',
        className: 'primary',
        title: 'common.title.edit',
        display: (e: CarStatusModel) => true,
      },
      {
        columnDef: 'detail',
        color: 'warn',
        icon: 'fa fa-eye',
        iconType: IconTypeEnum.FONT_AWESOME,
        click: 'viewDetail',
        className: 'primary',
        title: 'common.title.detail',
        display: (e: CarStatusModel) => true,
      },
      {
        columnDef: 'accept',
        color: 'primary',
        icon: 'fa fa-check',
        iconType: IconTypeEnum.FONT_AWESOME,
        click: 'accept',
        className: 'primary',
        title: 'common.title.accept',
        display: (e: CarStatusModel) => this.bookingUtil.hasPermission(null),
        disabled: (e: CarStatusModel) => this.bookingUtil.isDisableAccept(e),
      },
      {
        columnDef: 'reject',
        color: 'warn',
        icon: 'fa fa-ban',
        iconType: IconTypeEnum.FONT_AWESOME,
        click: 'reject',
        className: 'danger',
        title: 'common.title.reject',
        display: (e: CarStatusModel) => this.bookingUtil.hasPermission(null),
        disabled: (e: CarStatusModel) => this.bookingUtil.isDisableReject(e),
      }
    );
  }

  async ngOnInit() {
    EnumUtil.enum2SelectModel(SuperStatusEnum, this.statusValues, 'SEARCH');
    super.ngOnInit();
    this.onSubmit();
  }

  search() {
    const params = this.collectParams();
    this._fillData(ModuleConst.BOOKING + '/car-status', params);
  }

  onResetForm() {
    this.searchForm.reset();
    this.searchForm.patchValue({
      value: '',
      status: '_',
    })
  }

  collectParams(): HttpParams {
    const map = Object.fromEntries(['value'].map(s => [s, s]));
    let params = this._collectParams(this.searchForm, map);
    params = params.set('status', `${this.searchForm.get('status')?.value}`.replace('_', ''));
    return params;
  }

  addOrEdit(row: CarStatusModel | null) {
    if (row) {
      this.router.navigate([this.router.url, 'edit', row.id]).then();
    } else {
      this.router.navigate([this.router.url, 'add']).then();
    }
  }

  viewDetail(row: CarStatusModel) {
    this.router.navigate([this.router.url, 'detail', row.id]).then();
  }

  accept(row: CarStatusModel) {
    console.log('123');
    this.bookingUtil.onAccept(row, this.onSuccessFunc);
  }

  reject(row: CarStatusModel) {
    console.log('123');
    this.bookingUtil.onReject(row, this.onSuccessFunc);
  }
}
