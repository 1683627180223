import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import {FileSaverModule} from 'ngx-filesaver';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from './material.module';
import {CommonModule} from '@angular/common';
import {NextSolutionsModules, SingletonTranslateService, UtilsService} from '@next-solutions/next-solutions-base';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {environment} from '../../environments/environment';
import {QuillModule} from 'ngx-quill';
import { TimeAgoPipe } from 'src/app/components/doctor/booking/pipes/time-ago.pipe';
import { NotificationIndicatorComponent } from 'src/app/components/doctor/booking/components/notification-indicator/notification-indicator.component';
import { LazyScrollTriggerDirective } from 'src/app/components/doctor/booking/directives/lazy-scroll-trigger.directive';
import { PortalModule } from '@angular/cdk/portal';
import { ImageDirective } from 'src/app/components/doctor/booking/directives/image.directive';
import { ChatIndicatorComponent } from 'src/app/components/doctor/booking/components/chat-indicator/chat-indicator.component';
import { ListPendingIndicatorComponent } from 'src/app/components/doctor/booking/components/list-pending-indicator/list-pending-indicator.component';

@NgModule({
  declarations: [
    LazyScrollTriggerDirective,
    ImageDirective,
    TimeAgoPipe,
    NotificationIndicatorComponent,
    ChatIndicatorComponent,
    ListPendingIndicatorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FileSaverModule,
    FlexLayoutModule,
    MaterialModule,
    NextSolutionsModules,
    TranslateModule,
    QuillModule.forRoot(),
    PortalModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FileSaverModule,
    FlexLayoutModule,
    MaterialModule,
    NextSolutionsModules,
    QuillModule,
    NotificationIndicatorComponent,
    ChatIndicatorComponent,
    LazyScrollTriggerDirective,
    ImageDirective,
    TimeAgoPipe,
    ListPendingIndicatorComponent,
  ],
  providers: [UtilsService],
})
export class SharedModule {
  constructor(private translate: TranslateService, protected singletonTranslateService: SingletonTranslateService) {
    if (!window.sessionStorage.getItem('lang' + environment.CLIENT_ID) || window.sessionStorage.getItem('lang' + environment.CLIENT_ID) === '') {
      window.sessionStorage.setItem('lang' + environment.CLIENT_ID, environment.DEFAULT_LANGUAGE);
      translate.setDefaultLang(environment.DEFAULT_LANGUAGE);
      singletonTranslateService.currentLanguage.next(environment.DEFAULT_LANGUAGE);
    } else {
      singletonTranslateService.currentLanguage.next('' + window.sessionStorage.getItem('lang' + environment.CLIENT_ID));
    }
    singletonTranslateService.currentLanguage$.subscribe((lang: string) => {
      this.translate.use(lang);
    });
  }
}
