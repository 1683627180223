import { Injectable } from '@angular/core';
import { ApiService, AuthoritiesService, UtilsService } from '@next-solutions/next-solutions-base';
import { SuperStatusEnum } from '../../enums/super.status.enum';
import { MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';
import { GarageModel } from '../../models/cms/garage.model';
import { ModuleConst } from '../../modules/module.const';

@Injectable({providedIn: 'root'})
export class GarageUtil {
  constructor(protected authoritiesService: AuthoritiesService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected translateService: TranslateService) {
  }

  hasAddEditPermission(e: GarageModel | null): boolean {
    if (e && e.id) {
      return this.authoritiesService.hasAuthority('patch/garage/{id}');
    } else {
      return this.authoritiesService.hasAuthority('post/garage');
    }
  }

  hasViewDetailPermission(): boolean {
    return this.authoritiesService.hasAuthority('get/garage/{id}');
  }

  hasAcceptPermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/garage/{id}/accept')
  }

  isDisableAccept(e: GarageModel | null): boolean {
    if (e) {
      return SuperStatusEnum['_' + e.status] === SuperStatusEnum._ACCEPTED;
    }
    return false;
  }

  hasExportDataPermission(): boolean {
    return this.authoritiesService.hasAuthority('post/export-data/garage');
  }

  hasImportExcelPermission(): boolean {
    return this.authoritiesService.hasAuthority('post/import-data/garage');
  }

  hasDownloadTemplatePermission(): boolean {
    return this.authoritiesService.hasAuthority('post/download-template/garage');
  }

  hasImportGarageServiceExcelPermission(): boolean {
    return this.authoritiesService.hasAuthority('post/import-data/garage-service');
  }

  hasDownloadGarageServiceTemplatePermission(): boolean {
    return this.authoritiesService.hasAuthority('post/download-template/garage-service');
  }

  hasImportGarageServicePackageExcelPermission(): boolean {
    return this.authoritiesService.hasAuthority('post/import-data/garage-service-package');
  }

  hasDownloadGarageServicePackageTemplatePermission(): boolean {
    return this.authoritiesService.hasAuthority('post/download-template/garage-service-package');
  }

  hasRejectPermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/garage/{id}/reject');
  }

  isDisableReject(e: GarageModel | null): boolean {
    if (e) {
      return SuperStatusEnum['_' + e.status] === SuperStatusEnum._REJECTED;
    }
    return true;
  }

  onAccept(e: GarageModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (e) {
      const method = this.apiService.patch(ModuleConst.CMS + '/garage/' + e.id + '/accept', null);
      this.utilsService.execute(method, onSuccessFunc, 'common.accept.success',
        'common.confirm.accept', ['garage.']);
    }
  }

  onReject(e: GarageModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (e) {
      const strOk = this.translateService.instant('common.ok');
      const strCancel = this.translateService.instant('common.cancel');
      const strReasonReject = this.translateService.instant('common.reject.reason');
      const dialogConfig: MatDialogConfig<any> = {
        data: {
          customClass: 'reject_reason_dialog hint-outside',
          msg: strReasonReject,
          type: 'INPUT_CONFIRM',
          btnOKString: strOk,
          btnCancelString: strCancel,
        }
      };
      this.utilsService.showConfirmInputDialog('common.reject.reason', [], dialogConfig)
        .afterClosed().subscribe(result => {
        if (result && result.value) {
          const params = new HttpParams().set('rejectReason', result.value);
          this.apiService.patch(`/cms/garage/${e.id}/reject`, null, {params})
            .subscribe(data => {
                onSuccessFunc(data, 'common.reject.success');
              },
              error => {
                this.utilsService.showError(error);
              });
        }
      });
    }
  }

}
