import { Directive, ElementRef, Input } from '@angular/core';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { getFileUrl } from 'src/app/utils/booking/api.util';
import { DestroyService } from 'src/app/_services/destroy.service';

@Directive({
  selector: '[image]',
  providers: [DestroyService],
})
export class ImageDirective {
  @Input()
  set imageSrc(src: string) {
    if (!src) {
      this.el.nativeElement.style.backgroundImage = `url(assets/icons/booking/default-car.svg)`;
      return;
    }
    this.fetchImageAsBinary(src).then();
  };
  @Input() isBackground = false;
  @Input() imageClass = '';
  imageBinary? = undefined;
  loading$ = new BehaviorSubject(false);
  constructor(
    private el: ElementRef,
    private _destroy: DestroyService,
  ) {
    this.el.nativeElement.classList.add('image-directive-wrapper');
    this.el.nativeElement.classList.add(this.imageClass ? this.imageClass : 'image');
    this.loading$.pipe(takeUntil(this._destroy)).subscribe(value => {
      if (value) {
        return this.el.nativeElement.classList.add('loading');
      }
      return this.el.nativeElement.classList.remove('loading');
    });
  }

  async fetchImageAsBinary(path = '') {
    try {
      this.loading$.next(true);
      const token = this.getToken();
      const data = await fetch(this.buildImageUrl(path), {
        credentials: 'include',
        cache: 'force-cache',
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      const url = await data.blob();
      this.el.nativeElement.style.backgroundImage = `url(${URL.createObjectURL(url)})`;
    } catch (error) {
      console.error(error);
    } finally {
      this.loading$.next(false);
    }
  }

  buildImageUrl(path: string): string {
    if (/^(http|https|blob)/.test(path)) {
      return path;
    }
    return getFileUrl(path);
  }

  getToken(): string {
    try {
      const data = JSON.parse(sessionStorage.getItem('token') || '');
      return data?.access_token || '';
    } catch (error) {
      return '';
      console.log('Error:', error);
    }
  }
}
