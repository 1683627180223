import {Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
  AlignEnum,
  ApiService,
  AuthoritiesService,
  BaseSearchLayout,
  ColumnFields,
  FormStateService,
  Paging,
  SuperEntity,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {SosTrackingModel} from "../../../models/sos-tracking.model";
import {HttpParams} from "@angular/common/http";
import {SosTrackingUtil} from "../../../utils/sos-tracking.util";
import {MatTableDataSource} from "@angular/material/table";


@Component({
  selector: 'app-sos-tracking-support',
  templateUrl: './sos-tracking-support.component.html',
  styleUrls: ['./sos-tracking-support.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SosTrackingSupportComponent extends BaseSearchLayout implements OnInit {

  moduleName = 'sos-tracking-support';
  operatorSearchCustomerRequestedColumns: ColumnFields[] = [];
  operatorSearchCustomerRequestedResults: MatTableDataSource<SuperEntity> = new MatTableDataSource<SuperEntity>([]);
  operatorSearchCustomerRequestedPaging: Paging = new Paging();
  operatorSearchRequestedGarageColumns: ColumnFields[] = [];
  operatorSearchRequestedGarageResults: MatTableDataSource<SuperEntity> = new MatTableDataSource<SuperEntity>([]);
  operatorSearchRequestedGaragePaging: Paging = new Paging();

  constructor(protected formBuilder: FormBuilder,
              protected router: Router,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected uiStateService: FormStateService,
              protected translateService: TranslateService,
              protected injector: Injector,
              protected activatedRoute: ActivatedRoute,
              public matDialog: MatDialog,
              protected authoritiesService: AuthoritiesService,
              protected datePipe: DatePipe,
              protected sosTrackingUtil: SosTrackingUtil) {
    super(router, apiService, utilsService, uiStateService, translateService, injector, activatedRoute, authoritiesService,
      formBuilder.group({}));
  }

  ngOnInit() {
    this.operatorSearchCustomerRequestedResults = new MatTableDataSource<SuperEntity>([]);
    this.operatorSearchRequestedGarageResults = new MatTableDataSource<SuperEntity>([]);
    this.operatorSearchCustomerRequestedColumns = [
      {
        columnDef: 'stt', header: 'stt',
        title: (e: SosTrackingModel) =>
          `${UtilsService.calcPosition(e, this.operatorSearchCustomerRequestedResults, this.paging)}`,
        cell: (e: SosTrackingModel) =>
          `${UtilsService.calcPosition(e, this.operatorSearchCustomerRequestedResults, this.paging)}`,
        className: 'mat-column-stt',
        align: AlignEnum.CENTER,
      },
      ...this.sosTrackingUtil.getColumns()
    ];
    this.operatorSearchRequestedGarageColumns = [
      {
        columnDef: 'stt', header: 'stt',
        title: (e: SosTrackingModel) =>
          `${UtilsService.calcPosition(e, this.operatorSearchRequestedGarageResults, this.paging)}`,
        cell: (e: SosTrackingModel) =>
          `${UtilsService.calcPosition(e, this.operatorSearchRequestedGarageResults, this.paging)}`,
        className: 'mat-column-stt',
        align: AlignEnum.CENTER,
      },
      ...this.sosTrackingUtil.getColumns()
    ];
    super.ngOnInit();
    this.onSubmit();
  }

  search() {
    this.apiService.get<SosTrackingModel[]>('/cms/sos-tracking/operator-search/customer-requested', new HttpParams())
      .subscribe(res => {
        this.operatorSearchCustomerRequestedResults = new MatTableDataSource<SuperEntity>(res);
        this.operatorSearchCustomerRequestedPaging = new Paging();
        this.operatorSearchCustomerRequestedPaging.pageSize = res.length;
        this.operatorSearchCustomerRequestedPaging.pageNumber = 1;
        this.operatorSearchCustomerRequestedPaging.totalElements = res.length;
      });
    this.apiService.get<SosTrackingModel[]>('/cms/sos-tracking/operator-search/requested-garage', new HttpParams())
      .subscribe(res => {
        this.operatorSearchRequestedGarageResults = new MatTableDataSource<SuperEntity>(res);
        this.operatorSearchRequestedGaragePaging = new Paging();
        this.operatorSearchRequestedGaragePaging.pageSize = res.length;
        this.operatorSearchRequestedGaragePaging.pageNumber = 1;
        this.operatorSearchRequestedGaragePaging.totalElements = res.length;
      });
  }

  addOrEdit(row: SosTrackingModel) {
    this.router.navigate([this.router.url, 'edit', row.id]).then();
  }

  viewDetail(row: SosTrackingModel) {
    this.router.navigate([this.router.url, 'detail', row.id]).then();
  }

  garageReject(row: SosTrackingModel) {
    this.sosTrackingUtil.onGarageReject(row, this.onSuccessFunc);
  }

  customerCancel(row: SosTrackingModel) {
    this.sosTrackingUtil.onCustomerCancel(row, this.onSuccessFunc);
  }

  confirmCustomer(row: SosTrackingModel) {
    this.sosTrackingUtil.onConfirmCustomer(row, this.onSuccessFunc);
  }
}
