import { HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import {
  BaseSearchLayout,
  ColumnFields,
  SelectModel,
  ApiService,
  UtilsService,
  FormStateService,
  AuthoritiesService,
  DateUtilService,
  AlignEnum,
  Page,
} from '@next-solutions/next-solutions-base';
import { TranslateService } from '@ngx-translate/core';
import { AreaTypeEnum } from 'src/app/enums/area.type.enum';
import { SuperStatusEnum } from 'src/app/enums/super.status.enum';
import { SERIVCE_TYPE } from 'src/app/models/booking/booking.model';
import { AreaModel } from 'src/app/models/cms/area.model';
import { GarageModel } from 'src/app/models/cms/garage.model';
import { ModuleConst } from 'src/app/modules/module.const';
import { GarageUtil } from 'src/app/utils/cms/garage.util';
import { EnumUtil } from 'src/app/utils/enum.util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-booking-garages',
  templateUrl: './garages.component.html',
  styleUrls: ['./garages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class GaragesComponent extends BaseSearchLayout implements OnInit {
  moduleName = 'garage';
  PAGE_SIZE = 5;

  columns: ColumnFields[] = [];
  areas: AreaModel[] = [];
  serviceTypeValues: SelectModel[] = [];
  provinceOptions: SelectModel[] = [];
  districtOptions: SelectModel[] = [];

  get expandHeaderButton() {
    return environment.EXPAND_HEADER_BUTTON;
  }

  constructor(
    protected formBuilder: FormBuilder,
    protected router: Router,
    protected apiService: ApiService,
    protected utilsService: UtilsService,
    protected uiStateService: FormStateService,
    protected translateService: TranslateService,
    protected injector: Injector,
    protected activatedRoute: ActivatedRoute,
    protected authoritiesService: AuthoritiesService,
    protected dateUtilService: DateUtilService,
    public matDialog: MatDialog,
    public garageUtil: GarageUtil,
  ) {
    super(
      router,
      apiService,
      utilsService,
      uiStateService,
      translateService,
      injector,
      activatedRoute,
      authoritiesService,
      formBuilder.group({
        serviceType: ['_UPGRADE'],
        districtId: [''],
        provinceId: [''],
      }),
    );

    this.paging.pageSize = this.PAGE_SIZE;
    this.initColumns();
    this.initDataFilters();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onSubmit();
  }

  ngOnDestroy(): void {
    this.onResetForm();
  }

  initColumns() {
    this.columns.push(
      {
        columnDef: 'stt',
        header: 'stt',
        title: (e: GarageModel) => `${UtilsService.calcPosition(e, this.results, this.paging)}`,
        cell: (e: GarageModel) => `${UtilsService.calcPosition(e, this.results, this.paging)}`,
        className: 'mat-column-stt',
        align: AlignEnum.CENTER,
      },
      {
        columnDef: 'name',
        header: 'name',
        title: (e: GarageModel) => `${e.name ? e.name : ''}`,
        cell: (e: GarageModel) => `${e.name ? e.name : ''}`,
        className: 'mat-column-gara-name',
      },
      {
        columnDef: 'contactPointPhone',
        header: 'contactPointPhone',
        title: (e: GarageModel) => `${e.contactPointPhone ? e.contactPointPhone : ''}`,
        cell: (e: GarageModel) => `${e.contactPointPhone ? e.contactPointPhone : ''}`,
        className: 'mat-column-contactPointPhone',
        align: AlignEnum.CENTER,
      },
      {
        columnDef: 'provinceName',
        header: 'provinceId',
        title: (e: GarageModel) =>
          `${e.provinceId ? this.areas.find((province) => province.id == e.provinceId)?.name : ''}`,
        cell: (e: GarageModel) =>
          `${e.provinceId ? this.areas.find((province) => province.id == e.provinceId)?.name : ''}`,
        className: 'mat-column-province',
      },
      {
        columnDef: 'districtName',
        header: 'districtId',
        title: (e: GarageModel) =>
          `${e.districtId ? this.areas.find((district) => district.id == e.districtId)?.name : ''}`,
        cell: (e: GarageModel) =>
          `${e.districtId ? this.areas.find((district) => district.id == e.districtId)?.name : ''}`,
        className: 'mat-column-district',
      },
      {
        columnDef: 'address',
        header: 'address',
        title: (e: GarageModel) => `${e.address ?? ''}`,
        cell: (e: GarageModel) => `${e.address ?? ''}`,
        className: 'mat-column-address',
      },
    );
  }

  initDataFilters() {
    const params = new HttpParams()
      .set('status', EnumUtil.getKeysByValues(SuperStatusEnum, [SuperStatusEnum._ACCEPTED]))
      .set('pageNumber', '1')
      .set('pageSize', environment.INTEGER_MAX_VALUE);

    this.apiService.get<Page>(ModuleConst.CMS + '/area', params).subscribe((pageArea) => {
      if (pageArea && pageArea.hasOwnProperty('content')) {
        this.areas = pageArea.content;
        this.provinceOptions = this.areas
          .filter((a) => a.type === EnumUtil.getKeysByValues(AreaTypeEnum, [AreaTypeEnum._PROVINCE]))
          ?.map((a) => new SelectModel(a.id, a.name))
          .sort((a: SelectModel, b: SelectModel) => {
            return a.displayValue.localeCompare(b.displayValue);
          });
      }
    });
    EnumUtil.enum2SelectModel(SERIVCE_TYPE, this.serviceTypeValues, 'SEARCH', true, this.utilsService);
  }

  onProvinceChange(province: SelectModel[]) {
    if (province.length > 0) {
      this.loadDistrictByProvince(province);
    }
  }

  loadDistrictByProvince(province: any) {
    this.districtOptions = this.areas
      .filter(
        (a) =>
          a.type === EnumUtil.getKeysByValues(AreaTypeEnum, [AreaTypeEnum._DISTRICT]) &&
          a.parentId === province[0]?.value,
      )
      ?.map((a) => new SelectModel(a.id, a.name))
      .sort((a: SelectModel, b: SelectModel) => {
        return a.displayValue.localeCompare(b.displayValue);
      });
    this.searchForm.patchValue({ districtId: '' });
  }

  collectParams(): HttpParams {
    const map = Object.fromEntries(['districtId', 'provinceId'].map((s) => [s, s]));
    let params = this._collectParams(this.searchForm, map);
    params = params.set('serviceType', `${this.searchForm.get('serviceType')?.value}`.replace('_', ''));

    return params;
  }

  search() {
    const params = this.collectParams();
    this._fillData(ModuleConst.CMS + '/garage', params);
  }

  onResetForm() {
    this.searchForm.reset();
    this.searchForm.patchValue({
      districtId: '',
      provinceId: '',
    });
    this.districtOptions = [];
  }
}
