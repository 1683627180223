<form [formGroup]="addEditForm" class="form">
  <div class="content">
    <fieldset style="min-width: 60vw">
      <legend>{{ 'booking.common.change_status' | translate }}</legend>
      <div class="fieldSetContent" fxLayout="row wrap" class="row-wrap-padding">
        <ng-container *ngIf="data.page === PAGE.UPGRADE">
          <ns-input
            [placeholder]="moduleName + '.table.header.customer'"
            name="driverName"
            formControlName="driverName"
            fxLayout="column"
            fxFlex="25%"
            [disabled]="true"
            fxFlex.xs="100%"
            fxFlex.sm="50%"
            fxFlex.md="50%"
            fxFlex.lg="25%"
          >
          </ns-input>
          <ns-input
            [placeholder]="moduleName + '.table.header.phone_number'"
            name="driverPhone"
            formControlName="driverPhone"
            fxLayout="column"
            fxFlex="25%"
            [disabled]="true"
            fxFlex.xs="100%"
            fxFlex.sm="50%"
            fxFlex.md="50%"
            fxFlex.lg="25%"
          >
          </ns-input>
          <ns-input
            [placeholder]="moduleName + '.table.header.time_create'"
            name="createDate"
            formControlName="createDate"
            fxLayout="column"
            fxFlex="25%"
            [disabled]="true"
            fxFlex.xs="100%"
            fxFlex.sm="50%"
            fxFlex.md="50%"
            fxFlex.lg="25%"
          >
          </ns-input>
          <ns-input
            [placeholder]="moduleName + '.table.header.description'"
            name="description"
            formControlName="description"
            [multiline]="true"
            fxLayout="column"
            fxFlex="25%"
            [disabled]="true"
            fxFlex.xs="100%"
            fxFlex.sm="50%"
            fxFlex.md="50%"
            fxFlex.lg="25%"
          >
          </ns-input>
          <ns-multi-select-autocomplete
            [multiple]="false"
            [options]="processStatusValues"
            [placeholder]="moduleName + '.table.header.expert_processing_status'"
            name="expertProcessingStatus"
            formControlName="expertProcessingStatus"
            fxLayout="column"
            fxFlex="25%"
            fxFlex.xs="100%"
            fxFlex.sm="50%"
            fxFlex.md="50%"
            fxFlex.lg="25%"
          >
          </ns-multi-select-autocomplete>
        </ng-container>
        <ng-container *ngIf="data.page === PAGE.CANCEL">
          <ns-input
            [placeholder]="moduleName + '.table.header.request_ticket'"
            name="requestTicketId"
            formControlName="requestTicketId"
            fxLayout="column"
            fxFlex="25%"
            [disabled]="true"
            fxFlex.xs="100%"
            fxFlex.sm="50%"
            fxFlex.md="50%"
            fxFlex.lg="25%"
          >
          </ns-input>
          <ns-input
            [placeholder]="moduleName + '.table.header.service_ticket'"
            name="serviceTicketId"
            formControlName="serviceTicketId"
            fxLayout="column"
            fxFlex="25%"
            [disabled]="true"
            fxFlex.xs="100%"
            fxFlex.sm="50%"
            fxFlex.md="50%"
            fxFlex.lg="25%"
          >
          </ns-input>
          <ns-input
            [placeholder]="moduleName + '.table.header.customer'"
            name="driverName"
            formControlName="driverName"
            fxLayout="column"
            fxFlex="25%"
            [disabled]="true"
            fxFlex.xs="100%"
            fxFlex.sm="50%"
            fxFlex.md="50%"
            fxFlex.lg="25%"
          >
          </ns-input>
          <ns-input
            [placeholder]="moduleName + '.table.header.phone_number'"
            name="driverPhone"
            formControlName="driverPhone"
            fxLayout="column"
            fxFlex="25%"
            [disabled]="true"
            fxFlex.xs="100%"
            fxFlex.sm="50%"
            fxFlex.md="50%"
            fxFlex.lg="25%"
          >
          </ns-input>
          <ns-input
            [placeholder]="moduleName + '.table.header.time_booking'"
            name="appointmentDate"
            formControlName="appointmentDate"
            fxLayout="column"
            fxFlex="25%"
            [disabled]="true"
            fxFlex.xs="100%"
            fxFlex.sm="50%"
            fxFlex.md="50%"
            fxFlex.lg="25%"
          >
          </ns-input>
          <ns-input
            [placeholder]="moduleName + '.table.header.time_cancel'"
            name="canceledDate"
            formControlName="canceledDate"
            fxLayout="column"
            fxFlex="25%"
            [disabled]="true"
            fxFlex.xs="100%"
            fxFlex.sm="50%"
            fxFlex.md="50%"
            fxFlex.lg="25%"
          >
          </ns-input>
          <ns-input
            [placeholder]="moduleName + '.table.header.reason'"
            name="reason"
            formControlName="reason"
            [multiline]="true"
            fxLayout="column"
            fxFlex="25%"
            [disabled]="true"
            fxFlex.xs="100%"
            fxFlex.sm="50%"
            fxFlex.md="50%"
            fxFlex.lg="25%"
          >
          </ns-input>
          <ns-multi-select-autocomplete
            [multiple]="false"
            [options]="processStatusValues"
            [placeholder]="moduleName + '.table.header.expert_processing_status'"
            name="expertProcessingStatus"
            formControlName="expertProcessingStatus"
            fxLayout="column"
            fxFlex="25%"
            fxFlex.xs="100%"
            fxFlex.sm="50%"
            fxFlex.md="50%"
            fxFlex.lg="25%"
          >
          </ns-multi-select-autocomplete>
        </ng-container>
      </div>
      <div fxLayout="row wrap" class="row-wrap-padding" style="justify-content: center">
        <div class="btn-sec">
          <button
            mat-button
            type="submit"
            class="primary Update-btn"
            (click)="onSubmit()"
            [disabled]="addEditForm.invalid || !addEditForm.dirty"
          >
            <i class="fa fa-save"></i> {{ 'btnAddEdit' | translate }}
          </button>
          <button mat-button type="submit" class="primary Update-btn" (click)="onBack()">
            <i class="fa fa-times"></i> {{ 'btnBack' | translate }}
          </button>
        </div>
      </div>
    </fieldset>
  </div>
</form>
