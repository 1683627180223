<form [formGroup]="addEditForm" class="form">
  <div class="content">
    <fieldset>
      <legend></legend>
      <!-- Service info -->
      <div fxLayout="row wrap" class="row-wrap-padding">
        <legend class="margin-vertical-20"></legend>
        <div class="fieldSetContent" fxLayout="row wrap" class="row-wrap-padding" fxFlex="100%" fxFlex.lt-md="100%">
          <ns-input
            [placeholder]="'booking.table.header.booking_id'"
            name="serviceTicketId"
            formControlName="serviceTicketId"
            fxLayout="column"
            fxFlex="25%"
            [disabled]="true"
            fxFlex.xs="100%"
            fxFlex.sm="50%"
            fxFlex.md="50%"
            fxFlex.lg="25%"
          >
          </ns-input>
          <ns-multi-select-autocomplete
            [multiple]="false"
            [options]="serviceTypeValues"
            [placeholder]="'booking.table.header.booking_type'"
            [disabled]="true"
            name="serviceType"
            formControlName="serviceType"
            fxLayout="column"
            fxFlex="25%"
            fxFlex.xs="100%"
            fxFlex.sm="50%"
            fxFlex.md="50%"
            fxFlex.lg="25%"
          >
          </ns-multi-select-autocomplete>
          <ns-multi-select-autocomplete
            [multiple]="false"
            [options]="serviceTicketValues"
            [placeholder]="'booking.table.header.status'"
            [disabled]="true"
            name="serviceTicketStatus"
            formControlName="serviceTicketStatus"
            fxLayout="column"
            fxFlex="25%"
            fxFlex.xs="100%"
            fxFlex.sm="50%"
            fxFlex.md="50%"
            fxFlex.lg="25%"
          >
          </ns-multi-select-autocomplete>
        </div>
      </div>
      <hr style="padding: 10px 0" />
      <!-- Inspection info -->
      <div fxLayout="row wrap" class="row-wrap-padding">
        <legend class="margin-vertical-20"></legend>
        <div class="fieldSetContent" fxLayout="row wrap" class="row-wrap-padding" fxFlex="100%" fxFlex.lt-md="100%">
          <label class="padding-horizontal-15 margin-bottom-15">{{
            'booking.inspection.result_info.result_text' | translate
          }}</label>
          <div class="padding-horizontal-15" fxLayout="row" fxFlex="100%">
            <div
              fxLayout="row"
              fxLayoutGap="15px"
              class="margin-bottom-15"
              *ngIf="addEditForm.get('serviceBookingMediaResult')?.value?.length"
            >
              <div
                image
                [imageSrc]="image.src"
                (click)="open(i)"
                *ngFor="let image of _albums; let i = index"
              ></div>
            </div>
          </div>

          <div fxLayout="column" fxFlex="50%" fxFlex.lt-md="100%">
            <div *ngIf="selectingResultImage" image [imageSrc]="selectingResultImage" class="large"></div>
          </div>
          <ns-input
            [placeholder]="''"
            [label]=""
            [multiline]="true"
            name="carInspectionResult"
            formControlName="carInspectionResult"
            fxLayout="column"
            fxFlex="100%"
            [disabled]="true"
          >
          </ns-input>
        </div>
      </div>
      <hr style="padding: 10px 0" />
      <!-- Bao gia info -->
      <div fxLayout="row wrap" class="row-wrap-padding">
        <legend class="margin-vertical-20"></legend>
        <div class="fieldSetContent" fxLayout="row wrap" class="row-wrap-padding" fxFlex="100%" fxFlex.lt-md="100%">
          <ns-input
            [placeholder]="'booking.table.header.fee'"
            [label]="'booking.table.header.fee'"
            name="totalPrice"
            formControlName="totalPrice"
            fxLayout="column"
            fxFlex="25%"
            fxFlex.xs="100%"
            fxFlex.sm="50%"
            fxFlex.md="50%"
            fxFlex.lg="25%"
            [disabled]="true"
          >
          </ns-input>
          <ns-date-picker
            name="expectedDeliveryDate"
            formControlName="expectedDeliveryDate"
            [disabled]="true"
            [placeholder]="'booking.table.header.handover_date'"
            [label]="'booking.table.header.handover_date'"
            fxLayout="column"
            fxFlex="25%"
            fxFlex.xs="100%"
            fxFlex.sm="50%"
            fxFlex.md="50%"
            fxFlex.lg="25%"
          >
          </ns-date-picker>
        </div>
      </div>
      <hr style="padding: 10px 0" />
      <!-- Ảnh báo giá info -->
      <div fxLayout="row wrap" class="row-wrap-padding">
        <legend class="margin-vertical-20"></legend>
        <div class="fieldSetContent" fxLayout="row wrap" class="row-wrap-padding" fxFlex="100%" fxFlex.lt-md="100%">
          <div
            class="padding-horizontal-15"
            fxLayout="row"
            fxFlex="100%"
            *ngIf="addEditForm.get('serviceTicketServicesMedia')?.value?.length"
          >
            <div
              fxLayout="row"
              fxLayoutGap="15px"
              class="margin-bottom-15"
              *ngIf="addEditForm.get('serviceTicketServicesMedia')?.value?.length"
            >
              <div
                image
                [imageSrc]="image.src"
                (click)="openService(i)"
                *ngFor="let image of _albumsService; let i = index"
              ></div>
            </div>
          </div>
          <div fxLayout="row" fxFlex="100%" fxLayoutGap="15px" class="padding-horizontal-15">
            <div fxLayout="column" fxFlex="50%" fxFlex.lt-md="100%">
              <div *ngIf="selectingImage" image [imageSrc]="selectingImage" class="large"></div>
              <span *ngIf="selectingImage" class="text-center padding-5"
                >{{ getImageIndex(selectingImage) }} /{{
                  addEditForm.get('serviceTicketServicesMedia')?.value.length
                }}</span
              >
            </div>
            <div fxLayout="column" fxFlex="50%" fxFlex.lt-md="100%" [formGroup]="serviceTicketServiceSubForm">
              <div fxLayout="row" fxLayoutAlign="flex-end center" class="margin-bottom-15">
                <button
                  mat-button
                  class="secondary outline"
                  [disabled]="
                    isView ||
                    (!isView && !isQuoteByImage) ||
                    !bookingUtil.hasPermission(booking || null) ||
                    !bookingUtil.checkBookingBehavior(BOOKING_BEHAVIORS.ADD_SERVICE, booking || null)
                  "
                  (click)="onAddService()"
                >
                  <i class="fas fa-plus"></i>{{ 'booking.service-detail.btnAddService' | translate }}
                </button>
              </div>
              <div
                class="padding-horizontal-15"
                fxLayout="column"
                formArrayName="serviceTicketServiceFormArray"
                *ngIf="serviceTicketServiceFormArray.length"
              >
                <div
                  fxLayout="row wrap"
                  class="row-wrap-padding"
                  *ngFor="let serviceControl of serviceTicketServiceFormArray.controls; let i = index"
                >
                  <div fxLayout="row wrap" fxFlex="100%" [formGroup]="serviceControl">
                    <div
                      class="service-ticket-service"
                      fxLayout="row"
                      fxFlex="100%"
                      *ngIf="serviceValues && serviceValues.length"
                    >
                      <ns-multi-select-autocomplete
                        [multiple]="false"
                        [options]="serviceValues"
                        [placeholder]="'booking.table.header.service'"
                        [label]="'booking.table.header.service'"
                        formControlName="refId"
                        [disabled]="isView || !isQuoteByImage"
                        fxLayout="column"
                        fxFlex="100%"
                      >
                      </ns-multi-select-autocomplete>
                      <span
                        *ngIf="!isView && isQuoteByImage"
                        class="service-ticket-remove"
                        (click)="onRemoveService(i)"
                      >
                        <i class="fa fa-trash"></i>
                      </span>
                    </div>
                    <div fxLayout="row" fxFlex="100%" fxLayoutGap="15px">
                      <ns-input
                        [isFormControl]="false"
                        [text]="bookingUtil.formatCurrency(serviceControl.get('price')?.value, '.', '', '') || ''"
                        [placeholder]="'booking.table.header.fill_fee'"
                        [label]="'booking.table.header.fill_fee'"
                        [required]="true"
                        [errorMessages]="serviceControl.get('price')?.errors"
                        (onChange)="onPriceChange(serviceControl.get('price'))($event)"
                        (keydown)="onKeyDown($event)"
                        type="text"
                        [maxLength]="14"
                        [patternFilter]="'^[0-9\.]*$'"
                        fxLayout="column"
                        fxFlex="50%"
                        fxFlex.xs="100%"
                        fxFlex.sm="100%"
                        fxFlex.md="50%"
                        [disabled]="isView || !isQuoteByImage"
                      >
                      </ns-input>
                      <ns-date-picker
                        formControlName="warrantyTime"
                        [disabled]="isView || !isQuoteByImage"
                        [placeholder]="'booking.table.header.fill_warranty'"
                        [label]="'booking.table.header.fill_warranty'"
                        [minDate]="minDate"
                        fxLayout="column"
                        fxFlex="50%"
                        fxFlex.xs="100%"
                        fxFlex.sm="100%"
                        fxFlex.md="50%"
                      >
                      </ns-date-picker>
                    </div>
                    <ns-input
                      formControlName="details"
                      [multiline]="true"
                      [placeholder]="'booking.table.header.detail'"
                      [label]="'booking.table.header.detail'"
                      fxLayout="column"
                      fxFlex="100%"
                      [maxLength]="500"
                      [disabled]="isView || !isQuoteByImage"
                    >
                    </ns-input>
                  </div>
                  <hr style="padding: 10px 0" />
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between" class="padding-vertical-15">
                  <strong class="required">{{ 'booking.table.header.fee' | translate }}</strong>
                  <strong>{{ calculateTotalServicePrice() }}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </fieldset>
  </div>
</form>
