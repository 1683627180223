import {Injectable} from "@angular/core";
import {ApiService, AuthoritiesService, UtilsService} from "@next-solutions/next-solutions-base";
import {AreaModel} from "../../models/cms/area.model";
import {SuperStatusEnum} from "../../enums/super.status.enum";
import {MatDialogConfig} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {HttpParams} from "@angular/common/http";
import {ModuleConst} from "../../modules/module.const";

@Injectable({providedIn: 'root'})
export class AreaUtil {
  constructor(protected authoritiesService: AuthoritiesService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected translateService: TranslateService) {
  }

  hasAddEditPermission(area: AreaModel | null): boolean {
    if (area && area.id) {
      return this.authoritiesService.hasAuthority('patch/area/{id}');
    } else {
      return this.authoritiesService.hasAuthority('post/area');
    }
  }

  hasViewDetailPermission(): boolean {
    return this.authoritiesService.hasAuthority('get/area/{id}');
  }

  hasAcceptPermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/area/{id}/accept');
  }

  isDisableAccept(a: AreaModel | null): boolean {
    if (a) {
      return SuperStatusEnum['_' + a.status] === SuperStatusEnum._ACCEPTED;
    }
    return false;
  }

  hasRejectPermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/area/{id}/reject');
  }

  isDisableReject(a: AreaModel | null): boolean {
    if (a) {
      return SuperStatusEnum['_' + a.status] === SuperStatusEnum._REJECTED;
    }
    return true;
  }

  onAccept(area: AreaModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (area) {
      const method = this.apiService.patch(ModuleConst.CMS + '/area/' + area.id + '/accept', null);
      this.utilsService.execute(method, onSuccessFunc, 'common.accept.success',
        'common.confirm.accept', ['area.']);
    }
  }

  onReject(area: AreaModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (area) {
      const strOk = this.translateService.instant('common.ok');
      const strCancel = this.translateService.instant('common.cancel');
      const strReasonReject = this.translateService.instant('common.reject.reason');
      const dialogConfig: MatDialogConfig<any> = {
        data: {
          customClass: 'reject_reason_dialog hint-outside',
          msg: strReasonReject,
          type: 'INPUT_CONFIRM',
          btnOKString: strOk,
          btnCancelString: strCancel,
        }
      };
      this.utilsService.showConfirmInputDialog('common.reject.reason', [], dialogConfig)
        .afterClosed().subscribe(result => {
        if (result && result.value) {
          const params = new HttpParams().set('rejectReason', result.value);
          this.apiService.patch(`/cms/area/${area.id}/reject`, null, {params})
            .subscribe(data => {
                onSuccessFunc(data, 'common.reject.success');
              },
              error => {
                this.utilsService.showError(error);
              });
        }
      });
    }
  }

  hasExportDataPermission(): boolean {
    return this.authoritiesService.hasAuthority('post/export-data/area');
  }

}
