<div fxFlex="100%" fxLayout="column">
  <h3 class="page-title">Chi tiết hồ sơ khám</h3>
  <div fxLayout="row" fxLayout.lt-sm="column" class="inspection-detail-wrapper">
    <div fxFlex="50%" fxFlex.lt-sm="100%" fxLayout="column" class="margin-bottom-15 customer-info" *ngIf="inspection">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-10">
        <span class="label--title">{{'booking.inspection.booking_info.title' | translate}}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
        <span class="label">{{'booking.inspection.booking_info.book_time' | translate}}</span>
        <span class="value" *ngIf="inspection.appointmentDate">
          {{ inspection.appointmentDate | date : 'HH:mm, ' : 'UTC +0' }}
          {{ 'ngày' | translate }}
          {{ inspection.appointmentDate | date : ' dd/MM/yyyy' : 'UTC +0' }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
        <span class="label">{{'booking.inspection.booking_info.handover_time' | translate}}</span>
        <span class="value" *ngIf="inspection.garageHandOverCarDate">
          {{ inspection.garageHandOverCarDate | date : 'HH:mm, ' : 'UTC +0' }}
          {{ 'ngày' | translate }}
          {{ inspection.garageHandOverCarDate | date : ' dd/MM/yyyy' : 'UTC +0' }}</span>
      </div>
      <div fxFlex='100%' class="padding-20"></div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-10">
        <span class="label--title">{{'booking.inspection.customer_info.title' | translate}}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
        <span class="label">{{'booking.inspection.customer_info.name' | translate}}</span>
        <span class="value">{{ inspection.driverName }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
        <span class="label">{{'booking.inspection.customer_info.phone' | translate}}</span>
        <span class="value">{{ inspection.driverPhone }}</span>
      </div>
      <div *ngIf="inspection.driverAddress" fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
        <span class="label">{{'booking.inspection.customer_info.address' | translate}}</span>
        <span class="value">{{ inspection.driverAddress }}</span>
      </div>
      <div fxFlex='100%' class="padding-20"></div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-10">
        <span class="label--title">{{'booking.inspection.garage_info.title' | translate}}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
        <span class="label">{{'booking.inspection.garage_info.name' | translate}}</span>
        <span class="value">{{ inspection.garageName }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-5">
        <span class="label">{{'booking.inspection.garage_info.phone' | translate}}</span>
        <span class="value">{{ inspection.garagePhone }}</span>
      </div>
      <div *ngIf="inspection.garageAddress" fxLayout="row" fxLayoutAlign="space-between start" class="margin-bottom-5">
        <span class="label">{{'booking.inspection.garage_info.address' | translate}}</span>
        <span class="value garage-address">{{ inspection.garageAddress }}</span>
      </div>
    </div>

    <div fxFlex="50%" fxFlex.lt-sm="100%" *ngIf="inspection" class="margin-bottom-15 customer-info" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-10">
        <span class="label--title">{{'booking.inspection.result_info.title' | translate}}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-10">
        <span class="label">{{'booking.inspection.result_info.car_status' | translate}}</span>
      </div>

      <div fxLayout="row wrap" class="car-status-image-wrapper">
        <div
          fxLayout="row"
          fxLayoutGap="15px"
          class="margin-bottom-15"
          *ngIf="inspection.serviceBookingMediaResult"
        >
          <div image [imageSrc]="image.imageUrl"
               (click)="selectServiceBookingMediaResultImage(image.imageUrl)"
               [ngClass]="{ selected: image.imageUrl === selectingServiceBookingMediaResultImage }"
               *ngFor="let image of inspection.serviceBookingMediaResult">
          </div>
        </div>
      </div>
      <div fxLayout="row" fxFlex="50%" fxFlex.lt-md="100%">
        <div *ngIf="selectingServiceBookingMediaResultImage" image
             [imageSrc]="selectingServiceBookingMediaResultImage" class="large"></div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-vertical-15">
        <span class="label">{{'booking.inspection.result_info.result_text' | translate}}</span>
      </div>
      <div fxLayout="row" class="result-text margin-top-10">
        {{ inspection.carInspectionResult }}
      </div>
    </div>
  </div>

  <div fxFlex="100%" fxLayout="row" fxLayout.lt-sm="column" class="quote-info">
    <div fxFlex="50%" fxFlex.lt-sm="100%" fxLayout="column">
      <div fxLayout="column" class="customer-info full-width">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-10">
          <span class="label--title">{{'booking.inspection.service-list.title' | translate}}</span>
        </div>
        <div fxLayout="row wrap" class="car-status-image-wrapper">
          <div fxLayout="row" fxLayoutGap="15px" class="margin-bottom-15"
               *ngIf="inspection && inspection.serviceTicketServicesMedia">
            <div image [imageSrc]="image.imageUrl"
                 (click)="selectServiceTicketMediaResultImage(image.imageUrl)"
                 [ngClass]="{ selected: image.imageUrl === selectingServiceTicketMediaResultImage }"
                 *ngFor="let image of inspection.serviceTicketServicesMedia">
            </div>
          </div>
        </div>
        <div fxLayout="row" fxFlex="50%" fxFlex.lt-md="100%">
          <div *ngIf="selectingServiceTicketMediaResultImage" image
               [imageSrc]="selectingServiceTicketMediaResultImage" class="large"></div>
        </div>
      </div>
      <div fxLayout="column" class="customer-info full-width">
        <div fxFlex="100%" fxLayout="column" *ngIf="inspection && inspection.serviceTicketServices">
          <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="label">{{ 'Dịch vụ' | translate }}</span>
            <span class="label">{{ 'Giá' | translate }}</span>
          </div>
          <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between center"
               *ngFor="let sub of inspection.serviceTicketServices"
               class="margin-bottom-10">
            <span class="value value--service">{{ sub?.name }} <br> <span *ngIf="sub?.warrantyTime" class='value value--warranty'>( Thời hạn bảo hành: {{ sub?.warrantyTime | date: 'dd/MM/yyyy' }})</span> </span>
            <span class="value value--service">{{ formatCurrency(sub?.price, '.', '') }}</span>
          </div>
          <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="label">{{ 'Tổng chi phí' | translate }}</span>
            <span class="value">{{ formatCurrency(inspection.totalPrice) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="50%" fxFlex.lt-sm="100%"></div>
  </div>
</div>
