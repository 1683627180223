import { Component, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum PopupConfirmationResult {
  CANCEL,
  CONFIRM,
}

export interface PopupConfirmDataIn {
  title?: string;
  body?: string;
  confirm?: string;
  cancel?: string;
}

export const DefaultPopupConfirmDataIn = {
  title: 'popup.confirmation.default.title',
  body: 'popup.confirmation.default.body',
  confirm: 'popup.confirmation.default.confirm',
  cancel: 'popup.confirmation.default.cancel',
}

@Component({
  selector: 'app-booking-popup-confirmation',
  templateUrl: './popup-confirmation.component.html',
  styleUrls:['./popup-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PopupConfirmationComponent {
  defaultData = DefaultPopupConfirmDataIn;
  constructor(
    private _dialogRef: MatDialogRef<PopupConfirmationComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: PopupConfirmDataIn,
  ) {
  }
  cancel() {
    this._dialogRef.close(PopupConfirmationResult.CANCEL);
  }
  confirm() {
    this._dialogRef.close(PopupConfirmationResult.CONFIRM);
  }
}
