import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DestroyService } from 'src/app/_services/destroy.service';
import { takeUntil } from 'rxjs';
import { ChatService } from 'src/app/components/doctor/booking/chat/chat.service';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/components/doctor/booking/booking-create/firebase.service';
import {ApiService, AuthoritiesService} from '@next-solutions/next-solutions-base';
import {NotificationService} from 'src/app/components/doctor/booking/services/notification.service';
import {HttpParams} from '@angular/common/http';
import {EnumUtil} from 'src/app/utils/enum.util';
import {SuperStatusEnum} from 'src/app/enums/super.status.enum';
import {environment} from 'src/environments/environment';
import {PagingResponseModel} from 'src/app/components/doctor/booking/booking-create/booking-create.service';
import {ModuleConst} from 'src/app/modules/module.const';

@Component({
  selector: 'app-doctor-list-pending-indicator',
  templateUrl: 'list-pending-indicator.component.html',
  styleUrls: ['list-pending-indicator.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DestroyService],
})
export class ListPendingIndicatorComponent implements OnInit {
  count: number | null = null;
  constructor(
    private _chatService: ChatService,
    private _firebaseService: FirebaseService,
    private _destroy: DestroyService,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    private _authoritiesService: AuthoritiesService,
    private _notificationService: NotificationService,
    private apiService: ApiService,
  ) {}

  ngOnInit() {
    this.initData();
  }

  initData() {
    this._authoritiesService.me$
      .pipe(takeUntil(this._destroy))
      .subscribe((me) => {
        if (me.userAuthentication) {
          const params = new HttpParams()
            .set('status', EnumUtil.getKeysByValues(SuperStatusEnum, [SuperStatusEnum._ACCEPTED]))
            .set('pageNumber', '1')
            .set('pageSize', environment.INTEGER_MAX_VALUE)
            .set('requestTicketStatus', 'PENDING');
          this.apiService.get<PagingResponseModel<any>>(ModuleConst.BOOKING + '/expert/appointment-schedule', params)
            .pipe(takeUntil(this._destroy))
            .subscribe((response) => {
              this.count = response.totalElements;
            });
        }
      });
  }

  goListPendingRequest() {
    console.log(this.count);
    this._router.navigate(['booking', 'list-pending']);
  }
}
