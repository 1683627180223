<ns-breadcrumb></ns-breadcrumb>
<div class='searchFormGroup'>
  <form [formGroup]='searchForm' (ngSubmit)='onSubmit()' class='form'>
    <div class='buttonArea'>
      <div>
        <button mat-button type='submit' class='primary Update-btn'
                *ngIf="sosTrackingUtil.hasOperatorSearchPermission()">
          {{'btnRefresh'|translate}}
        </button>
      </div>
    </div>
  </form>
  <div class='acbox'>
    <div fxFlex="50%" fxLayout='column' class="split">
      <h3>{{'sos-tracking.resolve.customer-requested'|translate}}</h3>
      <ns-table
        *ngIf="sosTrackingUtil.hasOperatorSearchPermission() && sosTrackingUtil.hasData(operatorSearchCustomerRequestedResults)"
        [columns]='operatorSearchCustomerRequestedColumns' [buttons]='sosTrackingUtil.getButtons()'
        [paging]='operatorSearchCustomerRequestedPaging' [moduleName]='"sos-tracking"'
        [results]='operatorSearchCustomerRequestedResults'
        (pagingChange)='pagingChange($event)'
        (clickAction)='onRowButtonClick($event)'
        fxFlex="100%">
      </ns-table>
    </div>
    <div fxFlex="50%" fxLayout='column' class="split">
      <h3>{{'sos-tracking.resolve.garage-requested'|translate}}</h3>
      <ns-table
        *ngIf="sosTrackingUtil.hasOperatorSearchPermission() && sosTrackingUtil.hasData(operatorSearchRequestedGarageResults)"
        [columns]='operatorSearchRequestedGarageColumns' [buttons]='sosTrackingUtil.getButtons()'
        [paging]='operatorSearchRequestedGaragePaging' [moduleName]='"sos-tracking"'
        [results]='operatorSearchRequestedGarageResults'
        (pagingChange)='pagingChange($event)'
        (clickAction)='onRowButtonClick($event)'
        fxFlex="100%">
      </ns-table>
    </div>
  </div>
</div>


