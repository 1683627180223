<ns-breadcrumb></ns-breadcrumb>
<form [formGroup]="addEditForm" class="form">
  <div class="content">
    <fieldset>
      <legend></legend>
      <div class="fieldSetContent" fxLayout="row wrap" class="row-wrap-padding">
        <ns-input [placeholder]="moduleName+'.table.header.car_status'"
                  name="value" formControlName="value"
                  [required]="true"
                  fxLayout="column" fxFlex="25%"
                  [disabled]="isView"
                  fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="50%" fxFlex.lg="25%">
        </ns-input>
        <ns-multi-select-autocomplete [multiple]="false"
                                      [options]="statusValues"
                                      [placeholder]="moduleName+'.table.header.status'"
                                      [disabled]="true"
                                      name="status" formControlName="status"
                                      fxLayout="column" fxFlex="25%"
                                      fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="50%" fxFlex.lg="25%">
        </ns-multi-select-autocomplete>
      </div>
      <div fxLayout="row wrap" class="row-wrap-padding">
        <div class="btn-sec">
          <button mat-button
                  type="button"
                  class="primary"
                  (click)="onAccept()"
                  *ngIf="this.bookingUtil.hasPermission(null)"
                  [disabled]="this.bookingUtil.isDisableAccept(carStatus)">
            {{'btnAccept'|translate}}
          </button>
          <button mat-button
                  type="button"
                  class="primary"
                  (click)="onReject()"
                  *ngIf="this.bookingUtil.hasPermission(null)"
                  [disabled]="this.bookingUtil.isDisableReject(carStatus)">
            {{'btnReject'|translate}}
          </button>
          <button mat-button type="submit" class="primary Update-btn" (click)="onSubmit()"
                  [disabled]="addEditForm.invalid || isDetail">
            <i class="fa fa-save"></i> {{'btnAddEdit'|translate}}
          </button>
          <button mat-button type="submit" class="primary Update-btn" (click)="back()">
            <i class="fa fa-times"></i> {{'btnBack'|translate}}
          </button>
        </div>
      </div>
    </fieldset>
  </div>
</form>
