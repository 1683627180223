import { HttpParams } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';

interface ParamsMap {
  [key: string]: any;
}

export const removeEmptyParams = (params: HttpParams): HttpParams => {
  params.keys().forEach(key => {
    const value = params.get(key) ? params.get(key) + '' : '';
    if (value) {
      params = params.set(key, value.trim());
    }
  });
  const emptyValuesKey = params.keys().filter(key => !params.get(key));
  emptyValuesKey.forEach(key => {
    params = params.delete(key);
  });
  return params;
}

export const collectParams = (map: ParamsMap, isList = false): HttpParams => {
  let params = new HttpParams();
  for (const key of Object.keys(map)) {
    const value = map[key];
    if (value) {
      params = params.set(key, value ?? '');
    }
  }
  params = removeEmptyParams(params);
  if (isList) {
    const options = { pageNumber: '1', pageSize: '10'};
    params = params.append('pageNumber', params.get('pageNumber') || options.pageNumber)
      .append('pageSize', params.get('pageSize') || options.pageSize);
  }
  return params;
}

export const getFileUrl = (path: string): string => {
  if (path.startsWith('booking')) return `${env.BASE_URL}/${path}`;
  if (path.includes('assets/icons')) return `${path}`;
  return env.BASE_FILE_URL + path;
}
