import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { DriverCarModel } from 'src/app/models/cms/driver-car.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyService } from 'src/app/_services/destroy.service';
import { ApiService } from '@next-solutions/next-solutions-base';
import { ModuleConst } from 'src/app/modules/module.const';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-doctor-booking-car-info-view',
  templateUrl: './car-info-view.component.html',
  styleUrls: ['./car-info-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [DestroyService],
})
export class CarInfoViewComponent implements OnInit {
  driverCar?: DriverCarModel;
  constructor(
    private apiService: ApiService,
    private destroy: DestroyService,
    private cdr: ChangeDetectorRef,
    @Optional() public matDialogRef: MatDialogRef<boolean>,
    @Inject(MAT_DIALOG_DATA) public carId: number,
  ) { }

  ngOnInit() {
    this.initData();
  }

  initData() {
    this.apiService.get<DriverCarModel>(ModuleConst.BOOKING + `/driver/driver-car/${this.carId}`, new HttpParams())
      .subscribe((driverCar) => {
        this.driverCar = driverCar;
        this.cdr.markForCheck();
      });
  }

  close() {
    this.matDialogRef.close();
  }
}
