import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {
  ApiService,
  AuthoritiesService,
  BaseAddEditLayout,
  DateUtilService,
  Page,
  SelectModel,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {HttpParams} from '@angular/common/http';
import {ActionTypeEnum} from '../../../enums/action.type.enum';
import {EnumUtil} from '../../../utils/enum.util';
import {SosStatusEnum} from '../../../enums/sos.status.enum';
import {environment} from '../../../../environments/environment';
import {ModuleConst} from '../../../modules/module.const';
import {OperatorModel} from '../../../models/cms/operator.model';
import {SosTrackingModel} from '../../../models/sos-tracking.model';
import {SuperStatusEnum} from '../../../enums/super.status.enum';
import {AreaModel} from '../../../models/cms/area.model';
import {SosTrackingUtil} from "../../../utils/sos-tracking.util";
import {GarageSosDto, GarageSosSearchResultDto} from 'src/app/models/garage-sos-search-result-dto';

declare global {
  interface Window {
    vtmapgl: any;
    Directions: any;
  }
}

@Component({
  selector: 'app-add-edit-sos-tracking',
  templateUrl: './add-edit-sos-tracking.component.html',
  styleUrls: ['./add-edit-sos-tracking.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddEditSosTrackingComponent extends BaseAddEditLayout implements OnInit {

  moduleName = 'sos-tracking';
  areas: AreaModel[] = [];
  statusValues: SelectModel[] = [];
  garageValues: SelectModel[] = [];
  operatorValues: SelectModel[] = [];
  sosStatusValues: SelectModel[] = [];
  sosTracking: SosTrackingModel | null = null;
  garages: GarageSosDto[] = [];

  isView = false;
  currentMarker: any | undefined;

  constructor(protected activatedRoute: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected location: Location,
              protected translateService: TranslateService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected authoritiesService: AuthoritiesService,
              protected sosTrackingUtil: SosTrackingUtil,
              protected dateUtilService: DateUtilService
  ) {
    super(activatedRoute, location, translateService, utilsService, authoritiesService);
    this.addEditForm = this.formBuilder.group({
      id: [''],
      customerName: [''],
      customerPhone: [''],
      logDate: [''],
      latitude: [''],
      longitude: [''],
      customerLocation: [''],
      garageId: [''],
      garage: [''],
      operatorId: [''],
      status: [''],
      note: [''],
      amount: [''],
      minutes: [''],
      expectedTime: ['']
    });
    this.isView = this.activatedRoute.routeConfig?.data?.actionType === ActionTypeEnum._VIEW;
  }

  async ngOnInit() {
    super.ngOnInit();
    EnumUtil.enum2SelectModel(SosStatusEnum, this.sosStatusValues, 'EDIT');
    if (this.isEdit) {
      this.sosTracking = await this.apiService.get(ModuleConst.CMS + '/sos-tracking/' + this.id, new HttpParams()).toPromise() as SosTrackingModel;
      if (!this.sosTracking.note) {
        this.sosTracking.note = 'Biển số:\n'
          + 'Loại xe:\n'
          + 'Tình trạng:\n'
          + 'Phương thức cứu hộ:';
      }
      this.addEditForm.setValue(UtilsService.reduceEntityAttributeForFormControl(this.addEditForm, this.sosTracking));
      this.addEditForm.patchValue({
        expectedTime: this.sosTracking.expectedTime ? this.dateUtilService.convertDateTimeToDisplay(this.sosTracking.expectedTime) : ''
      })
    }
    const params = new HttpParams()
      .set('status', EnumUtil.getKeysByValues(SuperStatusEnum, [SuperStatusEnum._ACCEPTED]))
      .set('pageNumber', '1')
      .set('pageSize', environment.INTEGER_MAX_VALUE);
    const bookingLatParams = this.sosTracking?.latitude ? this.sosTracking.latitude : '';
    const bookingLngParams = this.sosTracking?.longitude ? this.sosTracking.longitude : '';
    const bookingParams = new HttpParams()
      .set('pageSize', environment.INTEGER_MAX_VALUE)
      .set('pageNumber', '1')
      .set('maxDistance', '100')
      .set('currentLat', bookingLatParams)
      .set('currentLng', bookingLngParams)
    Promise.all([
      this.apiService.get<GarageSosSearchResultDto>(ModuleConst.CMS + '/garage/sos', bookingParams).toPromise(),
      this.apiService.get<Page>(ModuleConst.CMS + '/operator', params).toPromise(),
      this.apiService.get<Page>(ModuleConst.CMS + '/area', params).toPromise(),
    ]).then(([garageSosDto, pageOperator, pageArea]) => {
      if (garageSosDto && garageSosDto.page && garageSosDto.page.content?.length >= 0) {
        this.garages = garageSosDto.page.content;
        this.garageValues = this.garages.map((garage: GarageSosDto) => new SelectModel(garage.id, garage.name));
      }
      if (pageOperator && pageOperator.content?.length >= 0) {
        this.operatorValues = pageOperator.content.map((operator: OperatorModel) => new SelectModel(operator.id, operator.name));
      }
      if (pageArea && pageArea.hasOwnProperty('content')) {
        this.areas = (pageArea as Page).content;
      }
    });

    window.vtmapgl.accessToken = 'd353070a772a526aa6bf282e766aef16';
    // Tao map
    const map = new window.vtmapgl.Map({
      container: 'map',
      style: window.vtmapgl.STYLES.VTRANS,
      center: [this.sosTracking?.longitude, this.sosTracking?.latitude],
      zoom: 10
    });
    // Tao chi duong
    const direction: any = new window.Directions({
      accessToken: window.vtmapgl.accessToken,
      controls: {
        profileSwitcher: false
      },
      profile: 'driving'
    });
    map.addControl(direction, 'top-left');
    map.on('load', () => {
      this.garages.forEach((garage: GarageSosDto) => {
        const popup = new window.vtmapgl.Popup({closeButton: true})
          .setHTML(`<span class="gara" id="gara" style="font-size: 20px;color: black"></span>`);
        popup._content.innerHTML = `<strong>${garage?.name}</strong>` +
          `<br>` +
          `${garage ? garage.address : ''}` +
          `<br>` +
          `Số điện thoại: ${garage.phone}`;
        const el = document.createElement('div');
        el.innerHTML = '<div class="custom-marker">'
          + '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAG+SURBVHgBjVRLUtwwEH0tz1TMKl5kH7NzdpMLTMwNMlWBJKsZTgA3GDgBcALMiuJTBdxAwAVghXf4Bng3gLGalrBdw8B8XpXKUkt66vfUMqHCWScOihHvg7hjiPe+sHeOCbyQ2WBgYKC2/93p3TpOkwuPo1/3AIeYAiHZ/ptebY3HaDyT50ezSYQNCWtmvp0kIFBfNmQMc7CWXicfSCwOozhsAeFqqjU+gZ1vEzreCLqX6byOt5pMwjh4YdFM9FskYZoYY5Ab3wQySOqoqjulzztM2MQ8KOsX7Z9EcfwukzNJs4AZiGa9ml6uzOJwt/hoHiSroQx1k0mBMq6SPcAc9G50bg9jcGwtaEgIqu8kwdNYBGQu7OdpqRy44WnU7ZvGJMqwMFwtZW2olZYBbbkSIsiVcSDdYD5Bc5h4yUPVMI7Ucsnq5xvZTIJkLb1cRilrK7jbEaOyqnjy4x/dmRR1TbQ95EVNK5serATruFMqrmOuGpLHKeucdEq8P9++L0nt241h1RZBJM23nRKqJ8aqxIMZvs3x+vjDmoajqJvIwbYssv+pzlq+L9pG2FNEXwsojQXgkTqHPHP5v7iX/gp0tq2Ir8e4bQAAAABJRU5ErkJggg==" style="border-radius: 50%;">'
          + '</div>';
        // Them gara vao map
        const marker = new window.vtmapgl.Marker(el)
          .setLngLat([garage.longitude, garage.latitude])
          .setPopup(popup)
          .addTo(map);
        // Custom sự kiện bấm vào marker
        marker.getElement().addEventListener('click', (ev: Event) => {
          ev.stopPropagation();
          if (this.currentMarker) {
            this.currentMarker.togglePopup();
          }
          direction.setDestination([marker.getLngLat().lng, marker.getLngLat().lat]);
          marker.togglePopup();
          this.currentMarker = marker;
        });
        // Nếu gara trùng với gara được chọn từ trước thì:
        //    - Chỉ đường lần đầu load map
        //    - hiện popup
        if (this.sosTracking?.garage?.id === garage.id) {
          const originLngLat = [this.sosTracking?.longitude, this.sosTracking?.latitude];
          const destinationLngLat = [garage.longitude, garage.latitude];
          new window.vtmapgl.Marker()
            .setLngLat(originLngLat)
            .addTo(map);
          direction.setOrigin(originLngLat);
          direction.setDestination(destinationLngLat);
          this.currentMarker = marker;
          marker.togglePopup();
          marker.getElement().addEventListener('load', (ev: Event) => {
            ev.stopPropagation();
            marker.togglePopup();
          });
        }

        // Nếu không có LatLng, garage thì:
        //    - Chọn điểm bắt đầu, click vào vị trí A -> set LatLng
        //    - Chọn gara -> Set gara đc chọn vào phần gara
        if (this.sosTracking?.latitude === null || this.sosTracking?.longitude === null || this.sosTracking?.garageId === null) {
          const input = window.document.querySelectorAll('.vtmapgl-ctrl-geocoder input');
          if (input && input.length > 0) {
            input[0].addEventListener('change', (event: any) => {
              const e = event.target as HTMLInputElement;
              const value = e.value;
              if (this.isLngLat(value)) {
                const originLngLat = value.split(',');
                this.addEditForm.get('longitude')?.setValue(originLngLat[0]);
                this.addEditForm.get('latitude')?.setValue(originLngLat[1]);
              } else {
                this.addEditForm.get('customerLocation')?.setValue(value);
              }
            });
            input[1].addEventListener('change', (event: any) => {
              const e = event.target as HTMLInputElement;
              const value = e.value;
              if (this.isLngLat(value)) {
                const destinationLngLat = value.split(',');
                const choosenGarage = this.garages.find(g => g.longitude ? g.longitude.toFixed(5).toString() === destinationLngLat[0] : false);
                if (choosenGarage) {
                  this.addEditForm.get('garageId')?.setValue(choosenGarage.id);
                }
              }
            });
          }
        }
      });
      // Disable click ra bên ngoài marker
      const elements: NodeListOf<Element> = window.document.querySelectorAll('canvas.vtmapgl-canvas');
      if (elements && elements.length > 0) {
        elements.forEach((e: Element) => {
          e.addEventListener('click', function onClick(ev: Event) {
            ev.preventDefault();
            ev.stopPropagation();
          });
        })
      }
    });
  }

  onSubmit(lastPath: String) {
    const sosTracking = new SosTrackingModel(this.addEditForm);
    const apiCall = this.apiService.patch(ModuleConst.CMS + '/sos-tracking/' + this.id + lastPath, sosTracking);
    this.utilsService.execute(apiCall, this.onSuccessFunc,
      'common.' + 'edit' + '.success',
      'common.confirm.' + 'edit', ['sos-tracking.']);
  }

  isLngLat(str: string) {
    const lngLatRegex  = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d{1,8})?),\s*[-+]?([1-8]?\d(\.\d{1,8})?|90(\.0+)?)$/;
    return lngLatRegex.test(str);
  }
}
