import { Injectable } from '@angular/core';
import { ApiService, AuthoritiesService, UtilsService } from '@next-solutions/next-solutions-base';
import { SuperStatusEnum } from '../../enums/super.status.enum';
import { MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GarageOwnerModel } from '../../models/cms/garage-owner.model';
import { ModuleConst } from '../../modules/module.const';

@Injectable({providedIn: 'root'})
export class GarageOwnerUtil {
  constructor(protected authoritiesService: AuthoritiesService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected translateService: TranslateService) {
  }

  hasAddEditPermission(e: GarageOwnerModel | null): boolean {
    if (e && e.id) {
      return this.authoritiesService.hasAuthority('patch/garageowner/{id}')
        && this.authoritiesService.hasAuthority('patch/sso/user/{id}');
    } else {
      return this.authoritiesService.hasAuthority('post/garageowner')
        && this.authoritiesService.hasAuthority('post/sso/user');
    }
  }

  hasViewDetailPermission(): boolean {
    return this.authoritiesService.hasAuthority('get/garageowner/{id}');
  }

  hasAcceptPermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/garageowner/{id}/accept');
  }

  isDisableAccept(e: GarageOwnerModel | null): boolean {
    if (e) {
      return SuperStatusEnum['_' + e.status] === SuperStatusEnum._ACCEPTED;
    }
    return false;
  }

  hasRejectPermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/garageowner/{id}/reject');
  }

  hasResetPasswordPermission(): boolean {
    return this.authoritiesService.hasAuthority('post/sso/user/reset-password');
  }

  isDisableReject(e: GarageOwnerModel | null): boolean {
    if (e) {
      return SuperStatusEnum['_' + e.status] === SuperStatusEnum._REJECTED;
    }
    return true;
  }

  onAccept(e: GarageOwnerModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (e) {
      const method = this.apiService.patch(ModuleConst.CMS + '/garageowner/' + e.id + '/accept', null);
      this.utilsService.execute(method, onSuccessFunc, 'common.accept.success',
        'common.confirm.accept', ['garage-owner.']);
    }
  }

  onReject(e: GarageOwnerModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (e) {
      const strOk = this.translateService.instant('common.ok');
      const strCancel = this.translateService.instant('common.cancel');
      const strReasonReject = this.translateService.instant('common.reject.reason');
      const dialogConfig: MatDialogConfig<any> = {
        data: {
          customClass: 'reject_reason_dialog hint-outside',
          msg: strReasonReject,
          type: 'INPUT_CONFIRM',
          btnOKString: strOk,
          btnCancelString: strCancel,
        }
      };
      this.utilsService.showConfirmInputDialog('common.reject.reason', [], dialogConfig)
        .afterClosed().subscribe(result => {
        if (result && result.value) {
          const params = new HttpParams().set('rejectReason', result.value);
          this.apiService.patch(`/cms/garageowner/${e.id}/reject`, null, {params})
            .subscribe(data => {
                onSuccessFunc(data, 'common.reject.success');
              },
              error => {
                this.utilsService.showError(error);
              });
        }
      });
    }
  }

  onResetPassword(e: GarageOwnerModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (e) {
      const method = this.apiService.post('/user/reset-password', e.username, {}, environment.BASE_AUTHORIZATION_URL);
      this.utilsService.execute(method, onSuccessFunc, 'common.reset-password.success',
        'common.confirm.reset-password', ['garage-owner.']);
    }
  }

  hasExportDataPermission(): boolean {
    return this.authoritiesService.hasAuthority('post/export-data/garage-owner');
  }

  hasImportExcelPermission(): boolean {
    return this.authoritiesService.hasAuthority('post/import-data/garage-owner');
  }

  hasDownloadTemplatePermission(): boolean {
    return this.authoritiesService.hasAuthority('post/download-template/garage-owner');
  }

}
