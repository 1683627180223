<div class="searchFormGroup">
  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="form">
    <fieldset style="min-width: 60vw">
      <legend>{{ 'booking.garages' | translate }}</legend>
      <div fxLayout="row wrap" class="row-wrap-padding">
        <ns-multi-select-autocomplete
          [multiple]="false"
          [options]="serviceTypeValues"
          [placeholder]="'booking.table.header.service'"
          [disabled]="true"
          name="serviceType"
          formControlName="serviceType"
          fxLayout="column"
          fxFlex="25%"
          fxFlex.xs="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="25%"
        >
        </ns-multi-select-autocomplete>
        <ns-multi-select-autocomplete
          [multiple]="false"
          [options]="provinceOptions"
          [placeholder]="moduleName + '.table.header.provinceId'"
          (selectedOptionDataChange)="onProvinceChange($event)"
          name="provinceId"
          formControlName="provinceId"
          fxLayout="column"
          fxFlex="25%"
          fxFlex.xs="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="25%"
        >
        </ns-multi-select-autocomplete>
        <ns-multi-select-autocomplete
          [multiple]="false"
          [options]="districtOptions"
          [placeholder]="moduleName + '.table.header.districtId'"
          name="districtId"
          formControlName="districtId"
          fxLayout="column"
          fxFlex="25%"
          fxFlex.xs="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="25%"
        >
        </ns-multi-select-autocomplete>
      </div>
      <div class="buttonArea">
        <button mat-button type="submit" class="primary Update-btn" [disabled]="searchForm.invalid">
          {{ 'btnSearch' | translate }}
        </button>
        <button mat-button type="button" class="secondary outline reset-btn" (click)="onResetForm()">
          {{ 'btnReset' | translate }}
        </button>
      </div>
    </fieldset>
  </form>
  <div class="acbox">
    <ns-table
      [columns]="columns"
      [paging]="paging"
      [moduleName]="moduleName"
      [results]="results"
      [headerButtonWidthDefault]="25"
      (pagingChange)="pagingChange($event)"
      (clickAction)="onRowButtonClick($event)"
    >
    </ns-table>
  </div>
</div>
