import { ModuleConst } from '../../../../../modules/module.const';
import { DatePipe, Location } from '@angular/common';
import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  AlignEnum,
  ApiService,
  AuthoritiesService,
  BaseAddEditLayout,
  ButtonFields,
  ColumnFields,
  ColumnTypes,
  SelectModel,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import { TranslateService } from '@ngx-translate/core';
import { BookingModel, EXPERT_PROCESSING_STATUS } from 'src/app/models/booking/booking.model';
import { BookingUtil } from 'src/app/utils/booking/booking.util';
import { EnumUtil } from 'src/app/utils/enum.util';

export enum PAGE {
  UPGRADE = 'UPGRADE',
  CANCEL = 'CANCEL',
}
interface DialogData {
  ticket: BookingModel;
  page: PAGE;
}
@Component({
  selector: 'app-popup-change-processing-status',
  templateUrl: './popup-change-processing-status.component.html',
  styleUrls: ['./popup-change-processing-status.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PopupChangeProcessingStatusComponent extends BaseAddEditLayout implements OnInit {
  moduleName = 'booking';
  DESCRIPTION_MAX_LENGTH = 20;
  disabled = true;
  PAGE = PAGE;

  columns: ColumnFields[] = [];
  bottons: ButtonFields[] = [];
  processStatusValues: SelectModel[] = [];

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected location: Location,
    protected translateService: TranslateService,
    protected apiService: ApiService,
    protected utilsService: UtilsService,
    protected authoritiesService: AuthoritiesService,
    private datePipe: DatePipe,
    public bookingUtil: BookingUtil,
    public matDialog: MatDialog,
    @Optional() public matDialogRef: MatDialogRef<boolean>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    super(activatedRoute, location, translateService, utilsService, authoritiesService);
    this.addEditForm = this.formBuilder.group({
      driverName: [''],
      driverPhone: [''],
      createDate: [''],
      carNumber: [''],
      carBrand: [''],
      description: [''],
      garageName: [''],
      expertProcessingStatus: [''],

      requestTicketId: [''],
      serviceTicketId: [''],
      appointmentDate: [''],
      canceledDate: [''],
      reason: [''],
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initValues();
  }

  initValues() {
    this.addEditForm.setValue(UtilsService.reduceEntityAttributeForFormControl(this.addEditForm, this.data.ticket));
    this.addEditForm.get('expertProcessingStatus')?.setValue(`_${this.data.ticket.expertProcessingStatus}`);
    this.addEditForm
      .get('createDate')
      ?.setValue(this.datePipe.transform(this.data.ticket.createDate, 'HH:mm, dd/MM/yyyy','UTC'));
    this.addEditForm
      .get('appointmentDate')
      ?.setValue(this.datePipe.transform(this.data.ticket.appointmentDate, 'HH:mm, dd/MM/yyyy','UTC'));
    this.addEditForm
      .get('canceledDate')
      ?.setValue(this.datePipe.transform(this.data.ticket.canceledDate, 'HH:mm, dd/MM/yyyy','UTC'));

    EnumUtil.enum2SelectModel(EXPERT_PROCESSING_STATUS, this.processStatusValues, 'SEARCH', true, this.utilsService);
  }

  onSubmit() {
    // Convert form to JSON
    const booking: BookingModel = this.addEditForm.value;
    const params = {
      expertProcessingStatus: booking.expertProcessingStatus?.replace('_', ''),
    };
    const api = this.apiService.put(
      `${ModuleConst.BOOKING}/expert/appointment-schedule/${booking.requestTicketId}`,
      params,
    );

    this.utilsService.execute(
      api,
      this.onSuccessFunc,
      'common.update.success',
      'booking.common.confirm.update_status',
      [
        this.utilsService.getEnumValueTranslated(
          EXPERT_PROCESSING_STATUS,
          this.addEditForm.get('expertProcessingStatus')?.value?.replace('_', ''),
        ),
      ],
    );
  }

  onBack() {
    this.matDialogRef.close(true);
  }

  onSuccessFunc = (data: any, onSuccessMessage?: string): void => {
    this.matDialogRef.close(true);
    this.utilsService.onSuccessFunc(onSuccessMessage ? onSuccessMessage : 'common.default.success');
  };
}
